/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import {
  Breadcrumb,
  Button,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Checkbox,
  Form,
  InputNumber,
  List,
  Skeleton,
  message,
  notification
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import PageTitle from "../components/PageTitle";
import PageBtns from "../components/PageBtns";
import PageLayout from "../components/PageLayout";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { getNavReport } from "../redux/action/NavReport";
import {
  getAdvanceReceiptReport
} from "../newApi/report.api";
import types from "../redux/action/types";
import "antd/dist/antd.css";
import Excel from "exceljs";
import saveAs from "file-saver";
//import AdvanceReceiptReport from './pages/AdvanceReceiptReport'

{/* <Route path='/report/invoicingdailyreport' element={<NavReport />}/>
<Route path='/report/advancereceiptreport' element={<AdvanceReceiptReport />}/> */}
      // {
      //    label:"預收款報表",
      //    key:"report/AdvanceReceiptReport",
      //   //  children:[
      //   //     {
      //   //        "label":"查詢申請",
      //   //        "key":"WFHApplication"
      //   //     }
      //   //  ],
      //    icon:<FileTextFilled  />
      // }

//excel 相關程式
const key = [
  "BU",
  "customerNo",
  "customerName",
  "salespersonCode",
  "salespersonName",
  "salespersonCodeSI",
  "salespersonNameSI",
  "postingDate",
  "documentNo",
  "documentDate",
  "description",
  "invoiceNo",
  "externalDocumentNo",
  "currencyCode",
  "orgAmount",
  "amountLCY",
  "documentType",
  "currencyFactor"
];
const excelWidth = [
  10, 15, 40, 15, 20, 15, 20, 12, 12, 80, 15, 50, 15, 15, 15, 15, 15
];
//excel顯示標題
const title = [
  "BU",
  "customerNo",
  "customerName",
  "SalespersonCode",
  "SalespersonName",
  "SalespersonCode_SI",
  "SalespersonName_SI",
  "PostingDate",
  "DocumentNo",
  "DocumentDate",
  "Description",
  "Invoice_No",
  "ExternalDocumentNo",
  "Currency_Code",
  "orgAmount",
  "Amount_LCY",
  "DocumentType",
  "CurrencyFactor"
];
// const columns = title.map((item, index) => {
//   return {
//     title: item,
//     dataIndex: key[index],
//     key: key[index],
//   };
// });

// console.log(key.length,title.length)
// console.log(columns);

export default function AdvanceReceiptReport() {
  // const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.globalSetting.isLoading);
  const [pageLoading, setPageLoading] = useState(false);
  const [NavForm] = Form.useForm();
  // const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const [startDateError, setStartDateError] = useState(null);
  const [endDateError, setEndDateError] = useState(null);
  const [data, setData] = useState([]);
  const dateFormat = "YYYYMMDD"; // 設定日期格式
  const endDatePickerRef = useRef(null);
  const submitBtnRef = useRef(null);
  // useEffect(() => {
  //   console.log(endDate);
  // }, [endDate]);

  // const StartDateChange = (date, dataString) => {
  //   // console.log(date,dataString,date?.length)
  //   if (
  //     dataString?.length === 8 &&
  //     moment(dataString, dateFormat) < moment().startOf("day")
  //   ) {
  //     NavForm.setFieldValue("startDate", moment(dataString, dateFormat));
  //     setStartDate(dataString);
  //     setStartDateError(false);
  //     endDatePickerRef.current?.focus();
  //   } else if (dataString?.length !== 8) {
  //     setStartDateError(true);
  //     if (!startDate) {
  //       NavForm.setFieldValue("startDate", null);
  //       setStartDate(null);
  //     }
  //   } else {
  //     setStartDateError(true);
  //   }
  // };
  const EndDateChange = (date, dataString) => {
    console.log(moment(dataString, dateFormat) , moment().endOf("day"),moment().startOf("day"));
    if (
      dataString?.length === 8 
      && moment(dataString, dateFormat).endOf("day").isBefore(moment().startOf("day"))
    ) {
      NavForm.setFieldValue("endDate", moment(dataString, dateFormat));
      setEndDate(dataString);
      // setEndDateError(false);
      submitBtnRef.current?.focus();
    } else if (dataString?.length !== 8) {
      if (!endDate) {
        NavForm.setFieldValue("endDate", null);
        setEndDate(null);
      }
      // else{
      //   setEndDateError(true);
      // }
    } 
    // else {
    //   setEndDateError(true);
    // }
  };

  const DateSubmitClick = (value) => {
    // console.log(value)
    // dispatch(
    //   getNavReport({
    //     // start_date: value["startDate"].format(dateFormat),
    //     end_date: value["endDate"]?.format(dateFormat),
    //   })
    // ).then((resp) => {
    //   if (resp && resp.isSuccess) {
    //     setData(resp.data);
    //     exportExcelFile(resp.data);
    //   } else {
    //     notification.error({
    //       message: '失敗',
    //       description: resp?.errorMessage === "" || !resp?.errorMessage
    //       ? "服務異常，請聯繫MIS。"
    //       : resp?.errorMessage
    //     })
    //   }
    // });
    // console.log(value,value?.["endDate"]?.format(dateFormat))
    setPageLoading(true);
    getAdvanceReceiptReport({EndDate: value?.["endDate"]?.format(dateFormat), mail:localStorage.getItem("mockUser") || undefined})
      .then((resp) => {
        if (resp?.isSuccess) {
            setData(resp.data);
            exportExcelFile(resp.data);
          } else {
            notification.error({
              message: '失敗',
              description: resp?.errorMessage === "" || !resp?.errorMessage
              ? "服務異常，請聯繫MIS。"
              : resp?.errorMessage
            })
          }
      })
      .finally(() => setPageLoading(false));
  };

  const onClearSearch = () => {
    NavForm.resetFields();
    // setStartDate(null);
    setEndDate(null);
  };
  const disabledDate = (current) => {
    return current && moment().startOf("day").isBefore(current.endOf("day"));
  };

  //excel下載
  const exportExcelFile = (data) => {
    const workbook = new Excel.Workbook();
    workbook.created = new Date();
    workbook.modified = new Date();
    //data =api回傳
    let exportFileResult = data?.map((item) => {
      let elementObj = {};
      key.forEach((ele) => {
        if (item[ele] === null) {
          elementObj[ele] = "";
        } else {
          elementObj[ele] = item[ele];
        }
      });
      // console.log(elementObj);
      return elementObj;
    }) || [];
    // console.log(exportFileResult);
    // 設定column與寫入資料, 加入localize
    const sheetName = `預收款報表`;
    const preFixName = `${NavForm.getFieldValue("endDate") ? NavForm.getFieldValue("endDate").format(dateFormat) :  moment().subtract(1, 'days').format(dateFormat)}_${sheetName}`;

    let sheet = workbook.addWorksheet(`${sheetName}`);

    sheet.getRow(1).values = title;
    sheet.columns = key.map((item, index) => {
      return {
        key: item,
        width: excelWidth[index],
      };
    });
    // 將資料以row方式寫入excel

    for (let i = 0; i < exportFileResult?.length; i++) {
      sheet.addRow(exportFileResult[i]);
    }

    const fileName = `${preFixName}.xlsx`;
    workbook.xlsx.writeBuffer().then(function (buffer) {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        fileName
      );
    });
  };
  return (
    <PageLayout spinning={pageLoading}>
      <BrowserView>
        {/* 頁面目前位置 */}
        <Col span={24}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
            {/* <Breadcrumb.Item href="/Stamp/StampApplication">
              預收款報表
            </Breadcrumb.Item> */}
            <Breadcrumb.Item>預收款報表{!!localStorage.getItem("mockUser") &&
                <span style={{color:'#ed3b3b'}}>（{localStorage.getItem("mockUser")}）</span>}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {/* 頁面標題 */}
        <PageTitle>預收款報表</PageTitle>
        {/* 頁面搜尋功能 */}
        <BrowserView>
          <Form
            form={NavForm}
            onFinish={
              /*(v)=>console.log(v.startDate.format(dateFormat))*/
              DateSubmitClick
            }
            style={{ backgroundColor: "white", padding: "0px 20px 0px 10px" }}
          >
            <Row
              style={{
                margin: "10px 0px",
                padding: "15px 10px 0px 15px",
                minHeight: "60px",
                width: "100%",
              }}
              span={24}
            >
              <Col style={{ paddingTop: "10px" }} span={15}>
                <Form.Item
                  label="期末日期"
                  name="endDate"
                  style={{maxWidth:'600px'}}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "期末日期未輸入",
                  //   },
                  // ]}
                  extra={<div>允許自行輸入數字，格式YYYYMMDD<br/>
                  <div style={{ color: "#000000", display:'flex', marginTop:'10px' }}>說明：<br/>(1)因資料同步為每日排程，資料最新只到前一日<br/>(2)日期不輸入時，表示查詢至前一天為止<br/>(3)此報表之業務為客戶主檔(Customer Card)中記載之負責業務(Salesperson Code)</div></div>}
                  onChange={(e) => {
                    EndDateChange(
                      moment(e.target.value, dateFormat),
                      e.target.value
                    );
                  }}
                >
                  <DatePicker
                    ref={endDatePickerRef}
                    placeholder="期末日期"
                    onChange={EndDateChange}
                    format={dateFormat}
                    disabledDate={disabledDate}
                    style={{ width: "80%" }}
                    changeOnBlur={true}
                    status={endDateError ? "error" : null}
                  />
                </Form.Item>
              </Col>
              <Col
                span={6}
                flex="130px"
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  paddingTop: "10px",
                  alignSelf: "flex-center",
                }}
              >
                <Button
                  type={"primary"}
                  htmlType="submit"
                  style={{
                    height: "40.14px",
                    fontSize: "15px",
                    padding: "7px",
                    // marginTop: "-10px",
                    width: "240px",
                    paddingTop: "10px" 
                  }}
                  icon={<DownloadOutlined />}
                  ref={submitBtnRef}
                >
                  確認並下載Excel形式報表
                </Button>
              </Col>
              <Col
                span={3}
                flex="130px"
                style={{
                  marginLeft: "7px",
                  // paddingTop: "-5px",
                  paddingBottom: "5px",
                  alignContent: "flex-center",
                  paddingTop: "10px" 
                }}
              >
                <PageBtns onClick={onClearSearch}>清除</PageBtns>
              </Col>
            </Row>
          </Form>
        </BrowserView>
        <Col span={24}>
          <Row
            justify="space-between"
            style={{ margin: "10px 10px" }}
            gutter={[24, 8]}
          >
            {/* {btnDisable ? (
              <Button
                type="primary"
                style={{
                  height: "40.14px",
                  margin: "20px 10px 20px 10px",
                  padding: "7px",
                  backgroundColor: "#7c7c7c",
                  color: "#fff",
                }}
                icon={<DownloadOutlined />}
                // disabled={MobileDateChange}
              >
                下載Excel形式報表
              </Button>
            ) : (
              <Button
                type="primary"
                style={{
                  height: "40.14px",
                  margin: "20px 10px 20px 10px",
                  padding: "7px",
                  backgroundColor: "#00A2DB",
                }}
                icon={<DownloadOutlined />}
                onClick={exportExcelFile}
                onPressEnter={exportExcelFile}
              >
                下載Excel形式報表
              </Button> */}
            {/* )} */}
          </Row>
        </Col>
      </BrowserView>
      <MobileView style={{ marginBottom: "115px" }}>
        {/* 頁面目前位置 */}
        <Col span={24}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
            {/* <Breadcrumb.Item href="/Stamp/StampApplication">
              預收款報表
            </Breadcrumb.Item> */}
            <Breadcrumb.Item>預收款報表</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {/* 頁面標題 */}
        <PageTitle>預收款報表</PageTitle>
        {/* 頁面搜尋功能 */}
        <Col
          style={{
            margin: "10px 0px",
            padding: "10px 10px",
            minHeight: "60px",
            width: "100%",
            backgroundColor: "white",
          }}
          span={24}
        >
          <Form
            form={NavForm}
            onFinish={
              /*(v)=>console.log(v.startDate.format(dateFormat))*/
              DateSubmitClick
            }
          >

            <Row gutter={[8,8]} justify="start" >
              <Col span={24} >
                <Col style={{ padding: '7px 7px 0px 2px', marginBottom: '-10px' }} span={24}>
                <Form.Item
                  label="期末日期"
                  // labelCol={{ span: 540 }}
                  name="endDate"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "期末日期未輸入",
                  //   },
                  // ]}
                  onChange={(e) => {
                    EndDateChange(
                      moment(e.target.value, dateFormat),
                      e.target.value
                    );
                  }}
                  // extra="允許自行輸入數字，格式YYYYMMDD"
                >
                  <DatePicker
                    style={{width:'100%'}}
                    ref={endDatePickerRef}
                    // value={endDate ? moment(endDate, dateFormat) : null}
                    placeholder="期末日期"
                    onChange={EndDateChange}
                    format={dateFormat}
                    disabledDate={disabledDate}
                    changeOnBlur={true}
                    status={endDateError ? "error" : null}
                    inputReadOnly
                  />
                </Form.Item>
                </Col>
                <div style={{ color: "#9f9f9f", margin: "auto 0", fontSize:'12px'}}>
                允許自行輸入數字，格式YYYYMMDD
                <br/><br/>
                <span style={{ color: "#000000"}}>說明：<br/>(1)因資料同步為每日排程，資料最新只到前一日<br/>(2)日期不輸入時，表示查詢至前一天為止<br/>(3)此報表之業務為客戶主檔(Customer Card)中記載之負責業務(Salesperson Code)</span>
              </div>
              </Col>
            </Row>
            <Row justify="bottom">
              <Col
                span={24}
                // flex="130px"
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  alignSelf: "flex-end",
                  paddingTop: "10px",
                }}
              >
                <Button
                  type={"primary"}
                  htmlType="submit"
                  icon={<DownloadOutlined />}
                  ref={submitBtnRef}
                >
                  確認並下載Excel形式報表
                </Button>
              </Col>
              <Col
                span={24}
                // flex="130px"
                style={{
                  marginTop: "4px",
                  display: "flex",
                  alignSelf: "flex-end",
                }}
              >
                <Button onClick={onClearSearch}>清除</Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24} style={{ margin: "10px 0px" }}>
          {" "}
          <Col span={24}>
            <Row
              justify="space-between"
              style={{ margin: "10px 10px" }}
              gutter={[24, 8]}
            >
              {/* {btnDisable ? (
                <Button
                  type="primary"
                  style={{
                    marginTop: "5px",
                    backgroundColor: "#7c7c7c",
                    color: "#fff",
                  }}
                  icon={<DownloadOutlined />}
                  // disabled={MobileDateChange}
                >
                  下載Excel形式報表
                </Button>
              ) : (
                <Button
                  type="primary"
                  style={{
                    marginTop: "5px",
                    backgroundColor: "#00A2DB",
                  }}
                  icon={<DownloadOutlined />}
                  onClick={exportExcelFile}
                >
                  下載Excel形式報表
                </Button>
              )} */}
            </Row>
          </Col>
        </Col>
      </MobileView>
    </PageLayout>
  );
}
