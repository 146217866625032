import React, { useState } from 'react'
import { Spin } from 'antd';

function ImageLoad({show, url}) {
  const [isImgLoaded, setIsImgLoaded] = useState(false)
  return (<div style={show ?{ minHeight:'30vh'}: {display:'none'}}>
    <Spin style={{ position: 'fixed', top: '25%' }} tip="Loading..." spinning={!isImgLoaded}>
      <img style={{maxHeight: '60vh', maxWidth: '100%', minHeight:'30vh'}} onLoad={() => setIsImgLoaded(true)} onError={() => setIsImgLoaded(true)} src={url}/>
    </Spin>
  </div>  
  )
}

export default ImageLoad
