import requests from "./customRequests/requests";

import { message } from "antd";

const mail = localStorage.getItem("mockUser") ? `?mail=${localStorage.getItem("mockUser")}` : ""

export const getWFHExcel = async (params) => {
  const resp = await requests().get(`api/Wfh/GetWfh/Excel`,{
    "responseType": "arraybuffer",
    "params":params
  });
  return resp?.data;
};

export const getWFH = async (params) => {
  const resp = await requests().get(`api/Wfh/GetWfh`,{params});
  return resp?.data;
};

export const getSearchConditions = async (params) => {
  const resp = await requests().get(`api/Wfh/GetConditions`,{
    //URL参數放在params屬性裏面
    params  
  });
  return resp?.data;
};

export const getApplyList = async (params) => {
  const resp = await requests().get(
    `api/Wfh/ApplyList`,{
      //URL参數放在params屬性裏面
      params  
    }
  );
  return resp?.data;
};

export const putWFHApply = async (data) => {
  const resp = await requests().put(`api/Wfh${mail}`, data);
  return resp?.data;
};

//新增申請
export const postWFHApply = async (data) => {
  const resp = await requests().post(`api/Wfh${mail}`, data);
  return resp.data;
};
