import React from 'react'
import { Col, Row } from 'antd'

export default function FormLayout({children}) {
  return (
    <Row>
      <Col 
        span={24}
        style={
          {
            margin: '10px 0px',
            backgroundColor: '#fff',
            minHeight:'30vh',
            padding: '20px'
          }
        }
      >
      {children}
      </Col>
    </Row>
  )
}
