/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  useNavigate,
} from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  Row,
  Col,
  Table,
  Select,
  Divider,
  List,
  Form,
  DatePicker,
  Modal,
  message,
  notification,
  Badge,
  Calendar,
  Tag
} from "antd";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import PageTitle from "../components/PageTitle";
import PageLayout from "../components/PageLayout";
import CalenderDetailModel from "../components/CalenderDetailModel";
import CalenderNameModel from "../components/CalenderNameModel";
import moment from "moment";
import {
  getSearchConditions,
  getWFH,
} from "../newApi/wfh.api";
import { BrowserView, isMobile, MobileView } from "react-device-detect";

import { PlusOutlined } from '@ant-design/icons';

export default function WFHCalendar() {
  const [data, setData] = useState([]);
  
  const [pageLoading, setPageLoading] = useState(false);

  const [departmentConditions, setDepartmentConditions] = useState([]);
  const [dept, setDept] = useState(undefined);

  const [currentMonth, setCurrentMonth] = useState(moment());

  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [detailModalData, setDetailModalData] = useState({});

  const [nameModalOpen, setNameModalOpen] = useState(false);
  const [nameModalData, setNameModalData] = useState([]);

  const handleDetailCancel = () => {
    setDetailModalOpen(false);
    setDetailModalData({})
  };

  const handleNameCancel = () => {
    setNameModalOpen(false);
    setNameModalData([])
  };

  const initial = (params) => {
    setPageLoading(true);
    getWFH(params)
      .then((res) => {
        if (res?.isSuccess) {
          // 初始化結果陣列
          const transformedData = {};
          res?.data?.forEach(item => {
              const { WfhNo, WfhDate, ApplicantName, ApplicantChineseName, Status, WfhReason, AuditComments } = item;
              if(Status === "onReview" || Status === "agree"){
                if (!transformedData[WfhDate]) {
                    transformedData[WfhDate] = [];
                }
                transformedData[WfhDate].push({
                    WfhNo,
                    WfhDate,
                    ApplicantName,
                    ApplicantChineseName,
                    Status,
                    WfhReason,
                    AuditComments
                });
              }
          });
          console.log(transformedData)
          setData(transformedData);
        }
      })
      .finally(() => setPageLoading(false));
  };

  useEffect(() => {
    // 取得當月的月初日期
    const firstDayOfMonth = currentMonth.startOf('month').clone().subtract(14, "day").format('YYYY-MM-DD');
    // 取得當月的月底日期
    const lastDayOfMonth = currentMonth.endOf('month').clone().add(14, "day").format('YYYY-MM-DD');
    initial({
      StartDate: firstDayOfMonth,
      EndDate: lastDayOfMonth,
      DeptCode: dept,
      DateType: "WfhDate",
      GetWfhRequestType: 1,
      PageIndex: 1,
      PageRow: 9999,
      mail: localStorage.getItem("mockUser") || undefined,
    });
  }, [currentMonth, dept]);

  useEffect(() => {
    getSearchConditions({
      mail: localStorage.getItem("mockUser") || undefined,
    }).then((res) => {
      if (res?.isSuccess) {
        setDepartmentConditions(
          res?.data?.DepartmentConditions?.map((i) => {
            return { label: i.Text, value: i.Value };
          })
        );
      }
    });
  }, []);

  const dateButton = (goPreviousMonth, currentMonth, goNextMonth) =>{
    return <>
        <LeftOutlined           
          onClick={goPreviousMonth}
          style={{
            cursor: "pointer",
            fontSize: "18px",
            color: "#383838",
          }}
        />
        <DatePicker
          className="wi-calender"
          value={currentMonth}
          onChange={(date) => setCurrentMonth(date)}
          picker="month"
          format="YYYY-MM"
          allowClear={false}
          inputReadOnly={true}
          style={{
            border: "none",
          }}
        />
        <RightOutlined           
          onClick={goNextMonth}
          style={{
            cursor: "pointer",
            fontSize: "18px",
            color: "#383838",
          }}
        />
      </>
  }
  const dateButtonM = (goPreviousMonth, currentMonth, goNextMonth) =>{
    return <>
        <LeftOutlined           
          onClick={goPreviousMonth}
          style={{
            cursor: "pointer",
            fontSize: "18px",
            color: "#383838",
          }}
        />
        <DatePicker
          className="wi-calender"
          value={currentMonth}
          onChange={(date) => setCurrentMonth(date)}
          picker="month"
          format="YYYY-MM"
          allowClear={false}
          inputReadOnly={true}
          style={{
            border: "none",
          }}
        />
        <RightOutlined           
          onClick={goNextMonth}
          style={{
            cursor: "pointer",
            fontSize: "18px",
            color: "#383838",
          }}
        />
      </>
  }

  const getListData = (value) => {
    if(!!data[value.format('YYYY-MM-DD')]){
      return data[value.format('YYYY-MM-DD')].map((i)=>(
        {
          WfhNo:i.WfhNo,
          WfhDate:i.WfhDate,
          WfhReason:i.WfhReason,
          AuditComments:i.AuditComments,
          ApplicantName:i.ApplicantName,
          ApplicantChineseName:i.ApplicantChineseName,
          Status:i.Status,
          color:i?.Status === "onReview" ? "green" : "blue",
          name:i?.ApplicantName
        }
      ))
    }else{
      return []
    }
  };
  const onTagClick = (date, item) => {
    setDetailModalOpen(true);
    setDetailModalData({
      date,
      item
    })
  };

  const onTagMoreClick = (date, listData) => {
    setNameModalOpen(true);
    console.log("Clicked on button!",listData);
    setNameModalData({
      date,
      listData
    })
  };

  const dateMCell = (date, listData) => (
    <Row className="events">
      {listData.map((item,index) => (index < 3 ?
      <Col key={index} span={24} className='wi-m-calender'>
        <Tag color={item.color}>{item.ApplicantChineseName}</Tag>
      </Col>:index===3?<Col key={index} span={24} className='wi-m-calender'>
        <Tag className="tag-more"><PlusOutlined/>{listData.length-3}</Tag>
      </Col>:null
      ))}
    </Row>
  )

  const dateCell = (date, listData) => (
    <Row align="top" className="events" onClick={(e)=>e.stopPropagation()}>
      {listData.map((item,index) => (index < 3 ?
      <Col key={index} span={24} className='wi-calender'>
        <Tag color={item.color} onClick={()=>onTagClick(date, item)}>{item.name}</Tag>
      </Col>:index===3?<Col key={index} span={24} className='wi-calender'>
        <Tag className="tag-more" onClick={()=>onTagMoreClick(date, listData)}><PlusOutlined/>{listData.length-3}</Tag>
      </Col>:null
      ))}
    </Row>
  )

  const dateCellRender = (date) => {
    // console.log(value.format('YYYY-MM-DD'))
    const listData = getListData(date);
    return (<div style={{height:'100%'}} onClick={(e)=>{
      e.stopPropagation();
      !!listData.length && isMobile && onTagMoreClick(date, listData)
    }}>
      {isMobile?dateMCell(date, listData):dateCell(date, listData)}
      </div>
      );
  };

  const onDeptChange = (value) => {
    setDept(value)
  }

  const handleMonthChange = (newMonth) => {
    // console.log("handleMonthChange",newMonth)
    setCurrentMonth(newMonth);
  };
  const goPreviousMonth = () => {
    handleMonthChange(currentMonth.clone().subtract(1, "month"));
  };
  const goNextMonth = () => {
    handleMonthChange(currentMonth.clone().add(1, "month"));
  };

  const onPanelChange= (date, mode) => {
    handleMonthChange(date);
    if(date.format('YYMM') > currentMonth.format('YYMM')){
      goNextMonth();
    }else{
      goPreviousMonth();
    }
  };
  return (
    <PageLayout spinning={pageLoading}>
      <BrowserView>
        {/* 頁面目前位置 */}
        <Col span={24}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
            <Breadcrumb.Item href="/WFH/WFHApplication">
              WFH申請
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              部門申請狀態
              {!!localStorage.getItem("mockUser") && (
                <span style={{ color: "#ed3b3b" }}>
                  （{localStorage.getItem("mockUser")}）
                </span>
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {/* 頁面標題 */}
        <PageTitle>部門申請狀態</PageTitle>
        {/* 頁面搜尋功能 */}

        <Col span={24}>
          <div className="calender-content" style={{backgroundColor:'white'}}>
            <Calendar
              value={currentMonth}
              dateCellRender={dateCellRender}
              onPanelChange={onPanelChange}
              headerRender={({ value, type, onChange, onTypeChange }) => {
                return (
                  <>
                    <Row justify="center" style={{ height: "50px" }}>
                      <Col
                        span={6}
                        offset={0}
                        style={{ padding: "7px" }}
                      >
                        {dateButton(goPreviousMonth, currentMonth, goNextMonth)}
                      </Col>
                      <Col
                        span={6}
                        offset={12}
                        style={{
                          padding: "7px",
                          justifyItems: "end",
                        }}
                      >
                        <div
                          style={{
                            justifyItems: "end",
                            width: "100%",
                          }}
                        >
                          <Select
                            label={""}
                            style={{ width: "100%", justifyItems: "end" }}
                            placeholder="請選擇部門"
                            allowClear
                            options={departmentConditions}
                            onChange={onDeptChange}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                );
              }}
              style={{
                width: "auto",
                margin: "0 auto",
                padding: "5px 15px 0px 15px",
              }}
            />
            <Col style={{ color: "#000000", padding:'10px'}}>
              <div><Tag color={'green'}>綠色</Tag>表示審核中，<Tag color={'blue'}>藍色</Tag>表示審核通過</div>
            </Col>
          </div>
        </Col>
      </BrowserView>
      <MobileView style={{ marginBottom: "50px" }}>
        {/* 頁面目前位置 */}
        <Col span={24}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
            <Breadcrumb.Item href="/WFH/WFHApplication">
              WFH申請
            </Breadcrumb.Item>
            <Breadcrumb.Item>部門申請狀態</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {/* 頁面標題 */}
        <PageTitle>部門申請狀態</PageTitle>

        <Col span={24}>
          <div className="calender-content-m" style={{backgroundColor:'white'}}>
            <Calendar
              value={currentMonth}
              dateCellRender={dateCellRender}
              onPanelChange={onPanelChange}
              headerRender={({ value, type, onChange, onTypeChange }) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50px"
                    }}
                  >
                    {dateButtonM(goPreviousMonth, currentMonth, goNextMonth)}
                    <Select
                      style={{ width: "80%", margin: "5px" }}
                      placeholder="請選擇部門"
                      allowClear
                      options={departmentConditions}
                      onChange={onDeptChange}
                    ></Select>
                  </div>
                );
              }}
              style={{ width: "auto", margin: "0 auto" }}
            />
            <Col style={{ color: "#000000", padding:'10px'}}>
              <div><Tag color={'green'}>綠色</Tag>表示審核中，<Tag color={'blue'}>藍色</Tag>表示審核通過</div>
            </Col>
          </div>
        </Col>
      </MobileView>
      <CalenderNameModel isModalOpen={nameModalOpen} handleCancel={handleNameCancel} modalData={nameModalData} onTagClick={onTagClick}/>
      <CalenderDetailModel isModalOpen={detailModalOpen} handleCancel={handleDetailCancel} modalData={detailModalData}/>
    </PageLayout>
  );
}
