import React from 'react'
import { Row, Spin } from 'antd'

export default function PageLayout({children, spinning}) {
  return (
    <Spin style={{ position: 'fixed', top: '25%' }} tip="Loading..." spinning={spinning}>
      {children}
    </Spin>
  )
}
