import React from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { useIsAuthenticated } from '@azure/msal-react'
import SideMenu from './components/SideMenu'
import Login from './pages/Login'
import ErrorPage from './pages/404'
import Account from './pages/Account'
import AccountAdd from './pages/AccountAdd'
import AccountUpdate from './pages/AccountUpdate'
import Role from './pages/Role'
import RoleAdd from './pages/RoleAdd'
import RoleUpdate from './pages/RoleUpdate'
import RoleSetting from './pages/RoleSetting'
import Tag from './pages/Tag'
import TagAdd from './pages/TagAdd'
import TagUpdate from './pages/TagUpdate'
import System from './pages/System'
import SystemAdd from './pages/SystemAdd'
import SystemUpdate from './pages/SystemUpdate'
import SystemView from './pages/SystemView'
import ContractMgt from './pages/ContractMgt'
import ContractMgtView from './pages/ContractMgtView'
import ContractMgtAddAndEdit from './pages/ContractMgtAddAndEdit'
import ContractMgtVerify from './pages/ContractMgtVerify'
import StampApplyAddAndView from './pages/StampApplyAddAndView'
import StampApplyRejectAndComplete from './pages/StampApplyRejectAndComplete'
import StampApplySignAndWithdraw from './pages/StampApplySignAndWithdraw'
import StampApplication from './pages/StampApplication'
import StampAudited from './pages/StampAudited'
import StampHistory from './pages/StampHistory'
import StampPendingAudit from './pages/StampPendingAudit'
import StampPendingProcessed from './pages/StampPendingProcessed'
import StampPendingUpload from './pages/StampPendingUpload'
import StampTempUpload from './pages/StampTempUpload'
import StampCloseAudit from './pages/StampCloseAudit'
import StampOwnerAudit from './pages/StampOwnerAudit'
import NavReport from './pages/NavReport'
import WFHApplication from './pages/WFHApplication'
import WFHCalendar from './pages/WFHCalendar'
import WFHApplicationDetail from './pages/WFHApplicationDetail'
import WFHApplicationAudit from './pages/WFHApplicationAudit'
import WFHApplicationView from './pages/WFHApplicationView'
import AdvanceReceiptReport from './pages/AdvanceReceiptReport'
import PtsUpload from './pages/PtsUpload'
import OverDueARReport from './pages/OverDueARReport'

import { useState } from 'react'
import { useEffect } from 'react'
import ScrollToTop from './helper/scrollToTop'

const componentsMap = { 
	"Account":Account, 
	"AccountAdd":AccountAdd, 
	"AccountUpdate":AccountUpdate,
	"Role":Role,
	"RoleAdd":RoleAdd,
	"RoleUpdate":RoleUpdate,
	"RoleSetting":RoleSetting,
	"Tag":Tag,
	"TagAdd":TagAdd,
	"TagUpdate":TagUpdate,
	"System":System,
	"SystemAdd":SystemAdd,
	"SystemUpdate":SystemUpdate,
	"SystemView":SystemView,
	"ContractMgt":ContractMgt,
	"ContractMgtView":ContractMgtView,
	"ContractMgtAddAndEdit":ContractMgtAddAndEdit,
	"ContractMgtVerify":ContractMgtVerify,
	"StampApplyAddAndView": StampApplyAddAndView,
	"StampApplyRejectAndComplete": StampApplyRejectAndComplete,
	"StampApplySignAndWithdraw": StampApplySignAndWithdraw,
	"StampApplication": StampApplication,
	"StampAudited": StampAudited,
	"StampHistory": StampHistory,
	"StampPendingAudit": StampPendingAudit,
	"StampPendingProcessed": StampPendingProcessed,
	"StampPendingUpload": StampPendingUpload,
	"StampTempUpload": StampTempUpload,
	"StampCloseAudit": StampCloseAudit,
	"StampOwnerAudit": StampOwnerAudit,
	"WFHApplication": WFHApplication,
	"WFHCalendar": WFHCalendar,
	"WFHApplicationDetail": WFHApplicationDetail,
	"WFHApplicationAudit ":WFHApplicationAudit ,
	"WFHApplicationView":WFHApplicationView,
};


const RouterConfig = (user, routeList, menuList, loading, roleIds) => {
	const isAuthenticated = useIsAuthenticated()
	if(loading){
		return(
			<BrowserRouter>
				<Routes>
					<Route path='/login' element={<Login />}/>
					<Route path='*' element={<Navigate to='/login' />}/>
				</Routes>
		</BrowserRouter>
		)
	}
	if(user !== null){
		return(
			<BrowserRouter>
				<ScrollToTop>
					<Routes>
					{
						routeList.length ?
						// 依照routeList產生路由與sideMenu
						<>
							<Route path='*' element={<ErrorPage/>}/>
							<Route path='/' element={<SideMenu currentUser={user} menuList={menuList} />}>
								<Route path='/login' element={<Navigate to='/' />}/>
								<Route path='/' element={<Navigate to='/Stamp/StampApplication' />} />
								<Route path='/WFH/WFHApplication' element={<WFHApplication/>}/>
								<Route path='/WFH/WFHCalendar' element={<WFHCalendar/>}/>
								<Route path='/WFH/WFHApplication/add' element={<WFHApplicationDetail />}/>
								<Route path='/WFH/WFHApplication/view/:id' element={<WFHApplicationView />}/>
								<Route path='/WFH/WFHApplication/audit/:id' element={<WFHApplicationAudit/>}/>
								<Route path='/report/invoicingdailyreport' element={<NavReport />}/>
								<Route path='/report/advancereceiptreport' element={<AdvanceReceiptReport />}/>
								<Route path='/file/ptsupload' element={<PtsUpload />}/>
								<Route path='/report/OverDueARReport' element={<OverDueARReport />}/>
								{
									routeList.map(route=>{
										let DynamicComponent = componentsMap[route.element];
										return(
											<Route key={route.path} path={route.path} element={<DynamicComponent roleFunction={route.rolefunction}/>}/>
										)
									})
								}
							</Route>
						</>: null
					}
					</Routes>
				</ScrollToTop>
			</BrowserRouter>
		)
	}
	// 未登入
	else{
		if(window.location.pathname !== '/login' && window.location.pathname !== '/'){
			sessionStorage.setItem('redirectURL',window.location.pathname)
		}
		return(
			<BrowserRouter>
				<Routes>
					<Route path='/login' element={<Login />}/>
					<Route path='*' element={<Navigate to='/login' />}/>
				</Routes>
			</BrowserRouter>
		)
	}
}

export default RouterConfig