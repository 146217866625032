import types from '../action/types'
import axios from 'axios'
import { acquireAccessToken } from '../../msalInstance'

// 有登入時從action接收getState裡面的token，呼叫有token的API
// 此版本baseUrl使用SAAS domain

const StampAuth = (dispatch, navigate) =>{
  const instance = axios.create({
    baseURL: process.env.REACT_APP_H_API_URL,
    timeout: 180000,
  })
  instance.interceptors.request.use(
    async (config) => {
      dispatch({
        type: types.INC_LOADING,
        payload: 1
      })
      const accessToken = await acquireAccessToken()
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`
      }
      config.headers["Content-Type"] = "application/json"
      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )
  instance.interceptors.response.use(function (resp){
    dispatch({
      type: types.DEC_LOADING,
      payload: 1
    })
    return resp
  }, function (err){
    dispatch({
      type: types.DEC_LOADING,
      payload: 1
    })
    return Promise.reject(err)
  })

  return instance
}
export default StampAuth