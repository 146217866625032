/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
import { useMsal } from "@azure/msal-react"
import { Layout, Menu, Row, Col, Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMenuCount } from '../redux/action/Stamp';
import types from '../redux/action/types';
import { FaUserCircle } from 'react-icons/fa'
import { BrowserView, MobileView } from 'react-device-detect'
import { AiOutlineMenuUnfold, AiOutlineMenuFold } from 'react-icons/ai'
import { controlMobileMenuOpen } from '../redux/action/Components';
import { MenuOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

const { Header } = Layout;

const PageHeader = ({currentUser, collapsed, setCollapsed}) => {
  const LoginInfo = currentUser
  const { instance, accounts } = useMsal()
  const [ userName, setUserName ] = useState(LoginInfo.name)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isMobileMenuOpen = useSelector(state => state.globalSetting.isMobileMenuOpen)

  useEffect(() =>{
    if(userName && userName !== undefined){
      let tempName = JSON.parse(JSON.stringify(userName))
      setUserName(tempName.split(' ')[0] + ' ' + tempName.split(' ')[1])
    }
  }, [userName])

  async function handleLogout () {
    localStorage.removeItem('userInfo')
    localStorage.removeItem('routeList')
    localStorage.removeItem('menuList')
    localStorage.removeItem('currentMenuItemsKey')
    localStorage.removeItem('roleIds')
    localStorage.removeItem('refreshTime')
    sessionStorage.removeItem('isLoad')
    const account = accounts[0]
    const logoutRequest = {
      account: account,
      postLogoutRedirectUri: '/login',
    }
    await instance.logoutRedirect(logoutRequest)
  }

  const menu = (
  <Menu>
    <Menu.Item key="5" >
      <a onClick={handleLogout}>登出</a>
    </Menu.Item>
  </Menu>
  );

  return (
    <>
      <BrowserView>
        <Header className='headerlayout' style = {{ width: '100%', margin: '0px auto', position: 'sticky', top: 0}}>
        <Row justify='space-between'>
          <Col span={16}>
            <span className='logo' style={{marginLeft:'-5px'}}>
              <span style={{
                margin:'5px', color:'white', 
                // border:'solid 0.5px gray'
                cursor: 'pointer'
              }}
                onClick={()=>setCollapsed(!collapsed)}
              >
                {collapsed?<MenuUnfoldOutlined style={{margin:'4px' }} />:<MenuFoldOutlined style={{margin:'4px' }} />}
              </span>
              <a onClick={()=> { navigate('/Stamp/StampApplication'); dispatch(getMenuCount()); dispatch({ type: types.MENU_ITEMS_KEY, payload: 'StampApplication'})}}>WiAdvance EIP</a>
            </span>
          </Col>
          {
            LoginInfo ?
              <Col span={8}>
                <Row justify='end'>
                  <Dropdown overlay={menu} trigger={['click']}>
                  <a className="ant-dropdown-link" style={{display: 'flex'}}>
                    <div style={{ display: 'flex' }}>
                      <span className="headertext">{LoginInfo.name}</span>
                      <FaUserCircle style={{ alignSelf: 'center', fontSize: '24px', marginLeft: '5px' }}/>
                    </div>
                  </a>
                  </Dropdown>
                </Row>
              </Col>
            :null
          }
        </Row>
        </Header>
      </BrowserView>
      <MobileView>
        <div className='headerlayout' style={{ overflowY: 'hidden' }}>
          <Header style={{ width: '100%', padding: '0', top: 0, zIndex: 1 }}>
            <Row style={{ width: '100%', padding: '0 10px' }} >
              <Col span={4} style={{ fontSize: '24px', display: 'flex', alignSelf: 'center', justifyContent: 'center' }}>
                {
                  isMobileMenuOpen ? 
                    <AiOutlineMenuFold onClick={() => dispatch(controlMobileMenuOpen(false))} style={{ color: 'white', zIndex: 1000 }}/> : 
                    <AiOutlineMenuUnfold onClick={() => dispatch(controlMobileMenuOpen(true))} style={{ color: 'white', zIndex: 1000 }}/>
                }
              </Col>
              <Col style={{ marginRight: 'auto' }}>
                <div className='moblieLogo' onClick={()=> { navigate('/Stamp/StampApplication'); dispatch(getMenuCount()); dispatch({ type: types.MENU_ITEMS_KEY, payload: 'StampApplication'})}}>
                  <a style={{ fontWeight: 'bolder' }}>WiAdvance EIP</a>
                </div>
              </Col>
              <Col style={{ justifySelf: 'end' }} >
                <Dropdown overlay={menu} trigger={['click']}>
                  <a style={{display: 'flex', color: '#7c7c7c', justifyContent: 'flex-end'}}>
                    <div style={{ display: 'flex' }}>
                      <span style={{ fontWeight: 800, fontSize: '14px', color: '#c7c7c7', marginRight: '7px' }}>{userName}</span>
                      <FaUserCircle style={{ alignSelf: 'center', fontSize: '28px', paddingLeft: '5px' }}/>
                    </div>
                  </a>
                </Dropdown>
              </Col>
            </Row>
          </Header>
        </div>
      </MobileView>
    </>
  )
}

export default (PageHeader)
