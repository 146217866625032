import axios from 'axios'
import { acquireAccessToken } from '../../msalInstance'
import { message, notification } from 'antd'
import moment from 'moment'

const requests = () =>{
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 180000,
  })
  instance.interceptors.request.use(
    async (config) => {
      
      const accessToken = await acquireAccessToken()
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`
      }
      if(!config?.headers?.["Content-Type"]){
        config.headers["Content-Type"] = "application/json"
      }

      var refreshTime = localStorage.getItem('refreshTime') && localStorage.getItem('refreshTime') !== undefined ? JSON.parse(localStorage.getItem('refreshTime')).time : null
      // if(!refreshTime){
      //   localStorage.setItem('refreshTime', JSON.stringify({ time : moment().add(8, 'hours').format('YYYY/MM/DD HH:mm:ss')}) )
      // }
      if( moment().diff(refreshTime) > 0 ){
        //取得新Token或不呼叫API
        return {
          ...config,
          signal: AbortSignal.abort()
        };
      }
      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )
  
  instance.interceptors.response.use(function (resp) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (resp?.data?.isSuccess !== false) {
    } else {
      if(resp?.data?.errorCode === "1057"){
        localStorage.removeItem('mockUser')
        window.location.reload();
      }else{
        if(resp?.data?.validateMessages){
          resp?.data?.validateMessages?.forEach(i=>{
            notification.error({
              message: '失敗',
              description: i.message
            })
          }
          )
        }else{
          notification.error({
            message: '失敗',
            description: '服務異常，請聯繫MIS。'
          })
        }
      }
    }
    return resp;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if(error?.message === "canceled"){
      window.location.reload();
      return error
    }
    console.log(error?.response?.status === 404)
    if(error?.response?.status === 404 && error?.response?.statusText ==="Not Found"){
      notification.error({
        message: '失敗',
        description: error?.response?.statusText
      })
    }else{
      notification.error({
        message: '失敗',
        description: '服務異常，請稍後再試。'
      })
    }
    return Promise.reject(error);
  });
  return instance
}
export default requests