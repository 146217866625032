/* eslint-disable no-loop-func */
import graphRequests from "../api/graphRequest"
import stamp from "../api/stampRequest1"
import types from "./types"

//取得自己上司
export const getMyManager = (email) => async(dispatch) =>{
  const resp = await graphRequests(dispatch).get(`users/${email}/manager`)
  return resp.data
}

//取得id
export const getMSId = (email) => async(dispatch) =>{
  const resp = await graphRequests(dispatch).get(`users/${email}`)
  return resp.data
}

//取得上司清單
export const getManagerChainList = (id) => async (dispatch) =>{

  const resp = await graphRequests(dispatch).get(`users/${id}?$expand=manager($levels=max;$select=id,displayName)&$select=id,displayName&$count=true`, { headers: { ConsistencyLevel: 'eventual' }} )
  
  
  let result = []
  let staff = ''
  for( let i = 0; i < 5; i++ ){
    Object.keys(resp.data).forEach(ele => {
      if(resp.data['manager'] === undefined){
        i = 5
      }
      switch(ele){
        case 'displayName':
          staff = resp.data[ele]
          break
        case 'manager':
          resp.data = resp.data[ele]
          break
        default:
          return null
      }
    })
    result.push({ name: staff, type: ( i === 0 ? '申請人' : '上層主管' ) })
  }
  return result
}

//取得公司內部人員清單
export const getAllWiadvanceUsers = () => async (dispatch) =>{
  const resp = await graphRequests(dispatch).get('groups/60773661-03e3-42f6-8bbe-1ce25e78c2e4/members')
  dispatch({ type: types.GET_ALL_WIADVANCE_USERS, payload: resp.data })
  return resp.data
}   
//Fake取得上司清單
export const fakeGetManagerChainList = (id) => async (dispatch) =>{

  const resp = await stamp(dispatch).get(`Fake/GetManagerList/${id}`)
  let result = []
  let staff = ''
  for( let i = 0; i < 5; i++ ){
    Object.keys(resp.data).forEach(ele => {
      if(resp.data['manager'] === undefined){
        i = 5
      }
      switch(ele){
        case 'displayName':
          staff = resp.data[ele]
          break
        case 'manager':
          resp.data = resp.data[ele]
          break
        default:
          return null
      }
    })
    result.push({ name: staff, type: ( i === 0 ? '申請人' : '上層主管' ) })
  }

  // dispatch({ type: types.GET_MANAGEER_LIST, payload: result })
  return result
}

//Fake 取得自己上司
export const fakeGetMyManager = (mail) => async (dispatch) =>{
  const resp = await stamp(dispatch).get(`Fake/GetManager/${mail}`)
  return resp.data
}