/* eslint-disable no-loop-func */
import React, { useEffect, useState } from "react";
import { useOutletContext, useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  Upload,
  Row,
  Col,
  Modal,
  Select,
  Checkbox,
  Form,
  notification,
  DatePicker,
  Tooltip,
  message,
} from "antd";
// import { UploadOutlined } from "@ant-design/icons";
// import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../components/PageTitle";
import PageLayout from "../components/PageLayout";
import InputPrefix from "../helper/InputPrefix";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import MobilForm from "../components/MobilForm";
import { getApplyList, postWFHApply, putWFHApply } from "../newApi/wfh.api.js";
import PageBtns from "../components/PageBtns";
import MobileBtns from "../components/MobileBtns";

export default function WFHApplicationDetail() {
  const { TextArea } = Input;

  const { currentUser } = useOutletContext();
  // console.log(currentUser?.email)
  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();
  const navigate = useNavigate();
  const [isDisable, setIsDisable] = useState(false);
  // const isLoading = useSelector((state) => state.globalSetting.isLoading);
  const [loading, setLoading] = useState(false);
  const [reLoading, setReLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [checkOpen, setCheckOpen] = useState(false);

  const [red, setRed] = useState([
    // "2023-08-10",
    // "2023-08-03",
    // "2023-08-09",
    // "2023-08-25",
  ]);
  const [disableDate, setDisableDate] = useState([
    // "2023-08-01",
    // "2023-08-02",
    // "2023-08-04",
    // "2023-08-08",
    // "2023-08-11",
    // "2023-08-22",
    // "2023-08-23",
    // "2023-08-24",
  ]);
  const [green, setGreen] = useState([
    // "2023-08-30", "2023-08-16"
  ]);
  const [reject, setReject] = useState([
    // "2023-08-15",
    // "2023-08-18",
    // "2023-08-29",
    // "2023-08-31",
    // "2023-09-01",
  ]);
  const [rejectDic, setRejectDic] = useState({
    // "2023-08-15": {
    //   WfhNo: "123",
    //   WfhDate: "2023-08-16",
    //   WfhReason: "2023-08-16想在家",
    //   RelativeDates: ["2023-08-15", "2023-08-17", "2023-08-18"],
    // },
    // "2023-08-17": {
    //   WfhNo: "123",
    //   WfhDate: "2023-08-16",
    //   WfhReason: "2023-08-16想在家",
    //   RelativeDates: ["2023-08-15", "2023-08-17", "2023-08-18"],
    // },
    // "2023-08-18": {
    //   WfhNo: "123",
    //   WfhDate: "2023-08-16",
    //   WfhReason: "2023-08-16想在家",
    //   RelativeDates: ["2023-08-15", "2023-08-17", "2023-08-18"],
    // },
    // "2023-08-29": {
    //   WfhNo: "1234",
    //   WfhDate: "2023-08-30",
    //   WfhReason: "2023-08-30想在家",
    //   RelativeDates: ["2023-08-29", "2023-08-31", "2023-09-01"],
    // },
    // "2023-08-31": {
    //   WfhNo: "1234",
    //   WfhDate: "2023-08-30",
    //   WfhReason: "2023-08-30想在家",
    //   RelativeDates: ["2023-08-29", "2023-08-31", "2023-09-01"],
    // },
    // "2023-09-01": {
    //   WfhNo: "1234",
    //   WfhDate: "2023-08-30",
    //   WfhReason: "2023-08-30想在家",
    //   RelativeDates: ["2023-08-29", "2023-08-31", "2023-09-01"],
    // },
  });
  // console.log(red,green,disableDate,reject,rejectDic)
  const [maxDate, setMaxDate] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [option, setOption] = useState([
    { label: currentUser?.name, value: currentUser?.email?.toLowerCase() },
  ]);

  const dateValue = Form.useWatch("WfhDate", form);
  const applicantValue = Form.useWatch("Applicant", form);

  const initial = (params) => {
    setPageLoading(true);
    getApplyList(params)
      .then((res) => {
        if (res?.isSuccess) {
          // console.log(res?.data)
          setOption(
            res?.data?.ApplicantInfos?.map((i) => {
              return {
                label: i?.Applicant,
                value: i?.ApplicantMail?.toLowerCase(),
              };
            })
          );
          setMaxDate(res?.data?.MaxDate);
          setMinDate(res?.data?.MinDate);
          form.setFieldsValue(res?.data);
          let disDate = [];
          let r = [];
          let g = [];
          let re = [];
          let rDic = {};
          res?.data?.WfhDateInfos?.forEach((i) => {
            if (i.DisabledDates) {
              disDate = [...disDate, ...i?.DisabledDates];
            }
            if (i.Status === "agree") {
              r.push(i?.WfhDate);
            } else if (i.Status === "onReview") {
              g.push(i?.WfhDate);
              if (i?.RelativeDates) {
                if(rDic[i?.WfhDate]){
                  //已經有了重複的則不給退回刪除
                  re = re.filter(val => !i?.RelativeDates.includes(val));
                  disDate = [...disDate, ...i?.RelativeDates];
                }else{
                  i?.RelativeDates?.forEach((d) => {
                    rDic[d] = {
                      WfhNo: i?.WfhNo,
                      WfhDate: i?.WfhDate,
                      WfhReason: i?.WfhReason,
                      RelativeDates: i?.RelativeDates,
                    };
                  });
                  re = [...re, ...i?.RelativeDates];
                }
              }
            }
          });
          setRejectDic(rDic);
          setDisableDate(disDate);
          setReject(re);
          setGreen(g);
          setRed(r);
        }
      })
      .finally(() => setPageLoading(false));
  };
  useEffect(() => {
    initial({
      ApplicantMail: applicantValue || currentUser?.email.toLowerCase(),
      mail:
        localStorage.getItem("mockUser") || undefined,
    });
    //currentUser?.email
  }, [applicantValue]);

  useEffect(() => {
    if (reject.includes(dateValue?.format("YYYY-MM-DD"))) {
      setOpen(true);
      modalForm.setFieldsValue({
        WfhNo: rejectDic[dateValue?.format("YYYY-MM-DD")]?.WfhNo,
        WfhDate: rejectDic[dateValue?.format("YYYY-MM-DD")]?.WfhDate,
        WfhReason: rejectDic[dateValue?.format("YYYY-MM-DD")]?.WfhReason,
        AuditComments: "改申請其他日期",
        Applicant:form.getFieldValue("Applicant")
      })
    }
  }, [dateValue, reject]);

  const handleRejectOk = async () => {
    modalForm.validateFields().then((res) => {
      // console.log(res);
      setReLoading(true);
      putWFHApply({
        WfhNo: res?.WfhNo,
        Status: "reject",
        AuditComments: res?.AuditComments,
      })
        .then((res) => {
          if (res?.isSuccess) {
            setOpen(false);
            // let newGreen = [];
            // newGreen = green.filter(function (el) {
            //   return (
            //     [rejectDic[dateValue?.format("YYYY-MM-DD")]?.WfhDate].indexOf(
            //       el
            //     ) < 0
            //   );
            // });
            // let newReject = [];
            // newReject = reject.filter(function (el) {
            //   return (
            //     rejectDic[
            //       dateValue?.format("YYYY-MM-DD")
            //     ]?.RelativeDates.indexOf(el) < 0
            //   );
            // });
            // setGreen(newGreen);
            // setReject(newReject);
            initial({
              ApplicantMail: applicantValue || currentUser?.email.toLowerCase(),
              mail:
                localStorage.getItem("mockUser") || undefined
            });
            // notification.success({
            //   message: "成功",
            //   description: "審核退回",
            // });
            onSubmitApply();
          }
        })
        .catch(() => {
          setReLoading(false);
        });
    });

    // setReLoading(true);
    // setTimeout(() => {
    //   setReLoading(false);

    //   let newGreen = [];
    //   newGreen = green.filter(function (el) {
    //     return (
    //       [rejectDic[dateValue?.format("YYYY-MM-DD")]?.WfhDate].indexOf(el) < 0
    //     );
    //   });
    //   let newReject = [];
    //   newReject = reject.filter(function (el) {
    //     return (
    //       rejectDic[dateValue?.format("YYYY-MM-DD")]?.RelativeDates.indexOf(
    //         el
    //       ) < 0
    //     );
    //   });
    //   console.log(newGreen, newReject);
    //   setGreen(newGreen);
    //   setReject(newReject);
    //   message.success("退回成功");
    // }, 3000);
  };

  // 日期更改modal之onCancel
  const modalOnCancel = () => {
    form.setFieldValue("WfhDate", null);
    setOpen(false);
  };
  //"取消modal"之onCancel(確認)
  const confirmCancel = () => {
    setCheckOpen(false);
    // form.setFieldValue(null);
    navigate("/WFH/WFHApplication");
  };

  //送出申請
  const onSubmitApply = async () => {
    await form.validateFields().then((res) => {
      // console.log(res);
      // console.log({
      //   "ApplicantMail": res.Applicant,
      //   "WfhDate": res.WfhDate?.format('YYYY-MM-DD'),
      //   "WfhReason": res.WfhReason
      // })
      setLoading(true);
      setPageLoading(true);
      postWFHApply({
        ApplicantMail: res.Applicant,
        WfhDate: res.WfhDate?.format("YYYY-MM-DD"),
        WfhReason: res.WfhReason,
      })
        .then((res) => {
          if (res?.isSuccess) {
            if (res?.data?.SuccessType === 1) {
              notification.success({
                message: "成功",
                description: "申請單已送出且審核通過",
              });
            } else {
              notification.success({
                message: "成功",
                description: "申請單已送出",
              });
            }
            navigate("/WFH/WFHApplication");
          }
        })
        .finally(() => {
          setLoading(false);
          setPageLoading(false);
        });
    });
  };
  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <>
      {/* 日期選擇Modal */}
      <Modal
        visible={open}
        title="當週待審核申請"
        onCancel={!reLoading && modalOnCancel}
        maskClosable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={reLoading}
            onClick={handleRejectOk}
          >
            退回
          </Button>,
        ]}
      >
        <Form
          form={modalForm}
          autoComplete={false}
          style={{ paddingTop: "10px" }}
          labelCol={{ flex: "130px" }}
        >
          <Row gutter={[24, 8]} style={{ color: "black", padding: "15px" }}>
            <Col span={23}>
              <Form.Item name="WfhNo" label={"申請單號"}>
                {/* {console.log(rejectDic, dateValue?.format("YYYY-MM-DD"))} */}
                <Input
                  style={{ width: "85%" }}
                  disabled
                  bordered={false}
                  // defaultValue={
                  //   rejectDic[dateValue?.format("YYYY-MM-DD")]?.WfhNo
                  // }
                />
              </Form.Item>
              <Form.Item name="WfhDate" label={"申請在家上班日期"}>
                <Input
                  style={{ width: "85%" }}
                  disabled
                  bordered={false}
                  // defaultValue={
                  //   rejectDic[dateValue?.format("YYYY-MM-DD")]?.WfhDate
                  // }
                />
              </Form.Item>
              <Form.Item name="Applicant" label={"申請人"}>
                <Select
                  style={{ width: "85%" }}
                  disabled
                  bordered={false}
                  options={option}
                />
              </Form.Item>
              <Form.Item name="WfhReason" label={"原因"}>
                <TextArea
                  disabled
                  rows={3}
                  maxLength={200}
                  style={{ width: "85%" }}
                />
              </Form.Item>
              <Form.Item
                name="AuditComments"
                label={"審核意見"}
                rules={[
                  {
                    required: true,
                    message: "請輸入審核意見!",
                  },
                ]}
              >
                <Input bordered={false} style={{ width: "85%" }} placeholder={"請輸入審核意見"}/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/* 申請取消Modal */}
      <Modal
        visible={checkOpen}
        onCancel={() => !loading && setCheckOpen(false)}
        title="申請放棄"
        maskClosable={false}
        footer={[
          <PageBtns type="primary" loading={loading} onClick={confirmCancel}>
            確認
          </PageBtns>,
          <PageBtns
            onClick={() => {
              setCheckOpen(false);
              !loading && setCheckOpen(false);
            }}
          >
            取消
          </PageBtns>,
        ]}
      >
        <Row
          gutter={[24, 8]}
          style={{
            color: "#3f3f3f",
            padding: "15px",
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          <Col span={23}>
            <p>確認要放棄此申請？</p>
          </Col>
        </Row>
      </Modal>
      <BrowserView>
        <PageLayout spinning={pageLoading}>
          {/* 頁面目前位置 */}
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
              <Breadcrumb.Item href="/WFH/WFHApplication">
                WFH申請
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                新增WFH申請
                {!!localStorage.getItem("mockUser") &&
                <span style={{color:'#ed3b3b'}}>（{localStorage.getItem("mockUser")}）</span>}
                </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* 頁面標題 */}
          <PageTitle>新增WFH申請</PageTitle>
          <Col span={24}>
            <div style={{ backgroundColor: "white" }}>
              <Form
                form={form}
                autoComplete={false}
                style={{ paddingTop: "10px" }}
                // loading={loading}
                initialValues={{ Applicant: currentUser?.email?.toLowerCase() }}
                // onCancel={confirmCancel}
              >
                {/* 申請單號 */}

                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={5}>
                    <InputPrefix label={"申請單號"} />
                  </Col>
                  <Col span={6}>
                    <Form.Item name="WfhNo">
                      <Input
                        bordered={false}
                        disabled
                        defaultValue={"存檔後自動產生"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <InputPrefix label={"單位"} />
                  </Col>
                  <Col span={6}>
                    <Form.Item name={"Department"}>
                      <Input bordered={false} disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={5}>
                    <InputPrefix require={"true"} label={"申請人"} />
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="Applicant"
                      rules={[
                        {
                          required: true,
                          message: "請輸入申請人!",
                        },
                      ]}
                    >
                      <Select
                        // style={{ width: "80%" }}
                        bordered={false}
                        options={option}
                        showSearch
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <InputPrefix label={"申請在家上班日期"} require={"true"} />
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={"WfhDate"}
                      rules={[
                        {
                          required: true,
                          message: "請選擇申請在家上班日期！",
                        },
                      ]}
                    >
                      <DatePicker
                        bordered={false}
                        inputReadOnly
                        disabledDate={(current) => {
                          // let max = 30;
                          //一般人
                          // let min = 3
                          //主管
                          // let min = -30;

                          return (
                            current.isoWeekday() === 6 ||
                            current.isoWeekday() === 7 ||
                            // current.isoWeekday() === 1 ||
                            (minDate &&
                              current < moment(minDate, "YYYY-MM-DD")) ||
                            (maxDate &&
                              current >
                                moment(maxDate, "YYYY-MM-DD").endOf("day")) ||
                            // green.includes(current.format("YYYY-MM-DD")) ||
                            // red.includes(current.format("YYYY-MM-DD")) ||
                            disableDate.includes(current.format("YYYY-MM-DD"))
                          );
                        }}
                        dateRender={(current) => {
                          // console.log(current.format('YYYY-MM-DD'))
                          // console.log(rejectDic,disableDate,reject,green,red)
                          const style = {};
                          if (red.includes(current.format("YYYY-MM-DD"))) {
                            // style.border = '1px solid black';
                            // style.borderRadius = '50%';
                            style.backgroundColor = "red";
                            style.color = "#A6ACAF";
                          } else if (
                            green.includes(current.format("YYYY-MM-DD"))
                          ) {
                            // style.border = '1px solid black';
                            // style.borderRadius = '50%';
                            style.backgroundColor = "green";
                            style.color = "#A6ACAF";
                          } else if (
                            reject.includes(current.format("YYYY-MM-DD")) &&
                            !(
                              current.isoWeekday() === 6 ||
                              current.isoWeekday() === 7 ||
                              // current.isoWeekday() === 1 ||
                              (minDate &&
                                current < moment(minDate, "YYYY-MM-DD")) ||
                              (maxDate &&
                                current >
                                  moment(maxDate, "YYYY-MM-DD").endOf("day")) ||
                              // green.includes(current.format("YYYY-MM-DD")) ||
                              // red.includes(current.format("YYYY-MM-DD")) ||
                              disableDate.includes(current.format("YYYY-MM-DD"))
                            )
                          ) {
                            // style.border = '1px solid black';
                            // style.borderRadius = '50%';
                            style.backgroundColor = "blue";
                            style.color = "#A6ACAF";
                          }
                          return (
                            <div
                              className="ant-picker-cell-inner"
                              style={style}
                            >
                              {current.date()}
                            </div>
                          );
                        }}
                        renderExtraFooter={() => {
                          return (
                            <div
                              style={
                                !reject.length
                                  ? { height: "50px" }
                                  : { height: "70px" }
                              }
                            >
                              <div
                                style={{
                                  color: "gray",
                                  margin: "-8px 0px -18px",
                                }}
                              >
                                <span
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                  }}
                                >
                                  紅底
                                </span>
                                為當日有已審核完成之申請單
                              </div>
                              <div
                                style={{
                                  color: "gray",
                                  margin: "-8px 0px -18px",
                                }}
                              >
                                <span
                                  style={{
                                    backgroundColor: "green",
                                    color: "white",
                                  }}
                                >
                                  綠底
                                </span>
                                為當日有待審核之申請單
                              </div>
                              {!!reject.length && (
                                <div style={{ color: "gray" }}>
                                  <span
                                    style={{
                                      backgroundColor: "blue",
                                      color: "white",
                                    }}
                                  >
                                    藍底
                                  </span>
                                  為當週可以調整的日期
                                </div>
                              )}
                            </div>
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={5}>
                    <InputPrefix label={"審核人"} />
                  </Col>
                  <Col span={7}>
                    <Form.Item name="Auditor">
                      <Input bordered={false} disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={5}>
                    <InputPrefix label={"原因"} />
                  </Col>
                  <Col span={18}>
                    <Form.Item name="WfhReason">
                      <TextArea
                        rows={6}
                        maxLength={200}
                        showCount
                        disabled={isDisable}
                        placeholder={"請輸入在家上班原因"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  justify="end"
                  style={{
                    color: "black",
                    paddingBottom: "15px",
                    paddingRight: "15px",
                  }}
                >
                  <>
                    <Col span={3} style={{ padding: " 5px" }}>
                      <PageBtns
                        type="primary"
                        onClick={() => onSubmitApply()}
                        loading={loading}
                      >
                        送出
                      </PageBtns>
                    </Col>
                  </>
                  {/* )} */}
                  <Col span={3} style={{ padding: "5px" }}>
                    <PageBtns
                      onClick={() => {
                        setCheckOpen(true);
                      }}
                    >
                      {" "}
                      取消{" "}
                    </PageBtns>
                  </Col>
                  {/* {CheckModal} */}
                </Row>
              </Form>
            </div>
          </Col>
        </PageLayout>
      </BrowserView>
      <MobileView style={{ marginBottom: "115px" }}>
        <PageLayout spinning={pageLoading}>
          {/* 頁面目前位置 */}
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
              <Breadcrumb.Item href="/WFH/WFHApplication">
                WFH申請
              </Breadcrumb.Item>
              <Breadcrumb.Item>新增WFH申請</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* 頁面標題 */}
          <PageTitle>新增WFH申請</PageTitle>
          <Col span={24}>
            <MobilForm
              form={form}
              // loading={loading}
              initialValues={{ Applicant: currentUser?.email?.toLowerCase() }}
            >
              <Row
                justify="space-around"
                gutter={[0, 8]}
                style={{ color: "black", padding: "0 10px 15px 10px" }}
              >
                {/* 申請單號 */}
                <Col span={23} style={{ marginTop: "10px" }}>
                  <Form.Item name="WfhNo" label="申請單號">
                    <Input
                      disabled
                      // style={{ width: "80%" }}
                      bordered={false}
                      defaultValue={"存檔後自動產生"}
                    />
                  </Form.Item>
                </Col>
                <Col span={23}>
                  <Form.Item name="Department" label="單位">
                    <Input
                      disabled
                      // style={{ width: "80%" }}
                      bordered={false}
                    />
                  </Form.Item>
                </Col>
                <Col span={23}>
                  <Form.Item
                    name="Applicant"
                    label="申請人"
                    rules={[
                      {
                        required: true,
                        message: "請輸入申請人！",
                      },
                    ]}
                  >
                    <Select
                      // style={{ width: "80%" }}
                      bordered={false}
                      options={option}
                      showSearch
                      filterOption={filterOption}
                    />
                  </Form.Item>
                </Col>

                {/* 申請日期 */}
                <Col span={23}>
                  <Form.Item
                    label="申請在家上班日期"
                    name={"WfhDate"}
                    rules={[
                      {
                        required: true,
                        message: "請選擇申請在家上班日期！",
                      },
                    ]}
                  >
                    {/* <Input bordered={false} /> */}
                    <DatePicker
                      bordered={false}
                      inputReadOnly
                      disabledDate={(current) => {
                        // let max = 30;
                        //一般人
                        // let min = 3
                        //主管
                        // let min = -30;

                        return (
                          current.isoWeekday() === 6 ||
                          current.isoWeekday() === 7 ||
                          // current.isoWeekday() === 1 ||
                          (minDate &&
                            current < moment(minDate, "YYYY-MM-DD")) ||
                          (maxDate &&
                            current >
                              moment(maxDate, "YYYY-MM-DD").endOf("day")) ||
                          // green.includes(current.format("YYYY-MM-DD")) ||
                          // red.includes(current.format("YYYY-MM-DD")) ||
                          disableDate.includes(current.format("YYYY-MM-DD"))
                        );
                      }}
                      dateRender={(current) => {
                        // console.log(current.format('YYYY-MM-DD'))
                        const style = {};
                        if (red.includes(current.format("YYYY-MM-DD"))) {
                          // style.border = '1px solid black';
                          // style.borderRadius = '50%';
                          style.backgroundColor = "red";
                          style.color = "#A6ACAF";
                        } else if (
                          green.includes(current.format("YYYY-MM-DD"))
                        ) {
                          // style.border = '1px solid black';
                          // style.borderRadius = '50%';
                          style.backgroundColor = "green";
                          style.color = "#A6ACAF";
                        } else if (
                          reject.includes(current.format("YYYY-MM-DD")) &&
                          !(
                            current.isoWeekday() === 6 ||
                            current.isoWeekday() === 7 ||
                            // current.isoWeekday() === 1 ||
                            (minDate &&
                              current < moment(minDate, "YYYY-MM-DD")) ||
                            (maxDate &&
                              current >
                                moment(maxDate, "YYYY-MM-DD").endOf("day")) ||
                            // green.includes(current.format("YYYY-MM-DD")) ||
                            // red.includes(current.format("YYYY-MM-DD")) ||
                            disableDate.includes(current.format("YYYY-MM-DD"))
                          )
                        ) {
                          // style.border = '1px solid black';
                          // style.borderRadius = '50%';
                          style.backgroundColor = "blue";
                          style.color = "#A6ACAF";
                        }
                        return (
                          <div className="ant-picker-cell-inner" style={style}>
                            {current.date()}
                          </div>
                        );
                      }}
                      renderExtraFooter={() => {
                        return (
                          <div
                            style={
                              !reject.length
                                ? { height: "50px" }
                                : { height: "70px" }
                            }
                          >
                            <div
                              style={{
                                color: "gray",
                                margin: "-8px 0px -18px",
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                              >
                                紅底
                              </span>
                              為當日有已審核完成之申請單
                            </div>
                            <div
                              style={{
                                color: "gray",
                                margin: "-8px 0px -18px",
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: "green",
                                  color: "white",
                                }}
                              >
                                綠底
                              </span>
                              為當日有待審核之申請單
                            </div>
                            {!!reject.length && (
                              <div style={{ color: "gray" }}>
                                <span
                                  style={{
                                    backgroundColor: "blue",
                                    color: "white",
                                  }}
                                >
                                  藍底
                                </span>
                                為當週可以調整的日期
                              </div>
                            )}
                          </div>
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={23}>
                  <Form.Item name="Auditor" label="審核人">
                    <Input
                      disabled
                      // style={{ width: "80%" }}
                      bordered={false}
                    />
                  </Form.Item>
                </Col>
                {/* 文件名稱 */}
                <Col span={23}>
                  <Form.Item
                    name="WfhReason"
                    label="原因"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "請輸入文件名稱",
                    //   },
                    // ]}
                  >
                    <TextArea
                      rows={4}
                      maxLength={200}
                      showCount
                      disabled={isDisable}
                      placeholder={"請輸入在家上班原因"}
                    />
                  </Form.Item>
                </Col>
                {/* 內容簡述 */}
              </Row>

              <Col span={24}>
                {" "}
                <Row gutter={[8]} justify="center">
                  <MobileBtns
                    type="primary"
                    loading={loading}
                    onClick={() => onSubmitApply()}
                  >
                    送出
                  </MobileBtns>
                  <MobileBtns
                    onClick={() => {
                      setCheckOpen(true);
                    }}
                  >
                    取消
                  </MobileBtns>
                </Row>
              </Col>
            </MobilForm>
          </Col>
        </PageLayout>
      </MobileView>
    </>
  );
}
