/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import {
  Breadcrumb,
  Button,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Checkbox,
  Form,
  InputNumber,
  List,
  Skeleton,
  message,
  notification
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import PageTitle from "../components/PageTitle";
import PageBtns from "../components/PageBtns";
import PageLayout from "../components/PageLayout";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { getNavReport } from "../redux/action/NavReport";
import types from "../redux/action/types";
import "antd/dist/antd.css";
import Excel from "exceljs";
import saveAs from "file-saver";

//excel 相關程式
const key = [
  "serial_number",
  "item_number",
  "summary",
  "quantity_initial_inventory",
  "unit_price",
  "amount_initial",
  "quantity_purchase",
  "amount_purchase",
  "quantity_purchase_return",
  "amount_purchase_return",
  "quantity_sales",
  "amount_sales",
  "quantity_sales_return",
  "amount_sales_return",
  "quantity_usage_or_other_return",
  "amount_usage_or_other_return",
  "quantity_usage_or_other",
  "amount_usage_or_other",
  "quantity_closing_inventory",
  "unit_price_closing",
  "amount_closing",
];
const excelWidth = [
  10, 20, 80, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18,
  18, 18,
];
const title = [
  `流水號`,
  `料號`,
  `摘要`,
  `數量_期初存貨`,
  `單價`,
  `金額_期初`,
  `數量_進貨`,
  `金額_進貨`,
  `數量_進貨退出`,
  `金額_進貨退出`,
  `數量_銷貨`,
  `金額_銷貨`,
  `數量_銷貨退回`,
  `金額_銷貨退回`,
  `數量_領用/其他(退回)`,
  `金額_領用/其他(退回)`,
  `數量_領用/其他`,
  `金額_領用/其他`,
  `數量_期末庫存`,
  `單價_期末`,
  `金額_期末`,
];
// const columns = title.map((item, index) => {
//   return {
//     title: item,
//     dataIndex: key[index],
//     key: key[index],
//   };
// });

// console.log(key.length,title.length)
// console.log(columns);

export default function NavReport() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.globalSetting.isLoading);
  const [NavForm] = Form.useForm();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateError, setStartDateError] = useState(null);
  const [endDateError, setEndDateError] = useState(null);
  const [data, setData] = useState([]);
  const dateFormat = "YYYYMMDD"; // 設定日期格式
  const endDatePickerRef = useRef(null);
  const submitBtnRef = useRef(null);
  useEffect(() => {
    console.log(startDate, endDate);
  }, [startDate, endDate]);

  const StartDateChange = (date, dataString) => {
    // console.log(date,dataString,date?.length)
    if (
      dataString?.length === 8 &&
      moment(dataString, dateFormat) < moment().startOf("day")
    ) {
      NavForm.setFieldValue("startDate", moment(dataString, dateFormat));
      setStartDate(dataString);
      setStartDateError(false);
      endDatePickerRef.current?.focus();
    } else if (dataString?.length !== 8) {
      setStartDateError(true);
      if (!startDate) {
        NavForm.setFieldValue("startDate", null);
        setStartDate(null);
      }
    } else {
      setStartDateError(true);
    }
  };
  const EndDateChange = (date, dataString) => {
    console.log(date, dataString, date?.length);
    if (
      dataString?.length === 8 &&
      moment(dataString, dateFormat) < moment().startOf("day")
    ) {
      NavForm.setFieldValue("endDate", moment(dataString, dateFormat));
      setEndDate(dataString);
      setEndDateError(false);
      submitBtnRef.current?.focus();
    } else if (dataString?.length !== 8) {
      setEndDateError(true);
      if (!endDate) {
        NavForm.setFieldValue("endDate", null);
        setEndDate(null);
      }
    } else {
      setEndDateError(true);
    }
  };

  const DateSubmitClick = (value) => {
    dispatch(
      getNavReport({
        start_date: value["startDate"].format(dateFormat),
        end_date: value["endDate"].format(dateFormat),
      })
    ).then((resp) => {
      if (resp && resp.isSuccess) {
        setData(resp.data);
        exportExcelFile(resp.data);
      } else {
        notification.error({
          message: '失敗',
          description: resp?.errorMessage === "" || !resp?.errorMessage
          ? "服務異常，請聯繫MIS。"
          : resp?.errorMessage
        })
      }
    });
  };

  const onClearSearch = () => {
    NavForm.resetFields();
    setStartDate(null);
    setEndDate(null);
  };
  const disabledDate = (current) => {
    return current && current > moment().startOf("day");
  };

  //excel下載
  const exportExcelFile = (data) => {
    const workbook = new Excel.Workbook();
    workbook.created = new Date();
    workbook.modified = new Date();
    //data =api回傳
    let exportFileResult = data?.map((item) => {
      let elementObj = {};
      key.forEach((ele) => {
        if (item[ele] === null) {
          elementObj[ele] = "";
        } else {
          elementObj[ele] = item[ele];
        }
      });
      console.log(elementObj);
      return elementObj;
    }) || [];
    console.log(exportFileResult);
    // 設定column與寫入資料, 加入localize
    const sheetName = `進銷存日報表`;
    const preFixName = `${NavForm.getFieldValue("startDate").format(
      dateFormat
    )}_${NavForm.getFieldValue("endDate").format(dateFormat)}_${sheetName}`;

    let sheet = workbook.addWorksheet(`${sheetName}`);

    sheet.getRow(1).values = title;
    sheet.columns = key.map((item, index) => {
      return {
        key: item,
        width: excelWidth[index],
      };
    });
    // 將資料以row方式寫入excel

    for (let i = 0; i < exportFileResult?.length; i++) {
      sheet.addRow(exportFileResult[i]);
    }

    const fileName = `${preFixName}.xlsx`;
    workbook.xlsx.writeBuffer().then(function (buffer) {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        fileName
      );
    });
  };
  return (
    <PageLayout spinning={isLoading !== 0 ? true : false}>
      <BrowserView>
        {/* 頁面目前位置 */}
        <Col span={24}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
            <Breadcrumb.Item href="/Stamp/StampApplication">
              進銷存日報表
            </Breadcrumb.Item>
            <Breadcrumb.Item>進銷存日報表</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {/* 頁面標題 */}
        <PageTitle>進銷存日報表</PageTitle>
        {/* 頁面搜尋功能 */}
        <BrowserView>
          <Form
            form={NavForm}
            onFinish={
              /*(v)=>console.log(v.startDate.format(dateFormat))*/
              DateSubmitClick
            }
            style={{ backgroundColor: "white", padding: "0px 20px 10px 10px" }}
          >
            <Row
              style={{
                margin: "10px 0px",
                padding: "15px 10px",
                minHeight: "60px",
                width: "100%",
              }}
              span={24}
            >
              <Col style={{ paddingTop: "10px" }} span={12}>
                <Form.Item
                  label="期初日期"
                  labelCol={{ span: 240 }}
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: "期初日期未輸入",
                    },
                  ]}
                  extra="允許自行輸入數字，格式YYYYMMDD"
                  onChange={(e) => {
                    StartDateChange(
                      moment(e.target.value, dateFormat),
                      e.target.value
                    );
                  }}
                >
                  <DatePicker
                    autoFocus={true}
                    changeOnBlur={true}
                    format={dateFormat}
                    disabledDate={disabledDate}
                    placeholder="期初日期"
                    style={{ width: "80%" }}
                    onChange={StartDateChange}
                    status={startDateError ? "error" : null}
                  />
                </Form.Item>
              </Col>
              <Col style={{ paddingTop: "10px" }} span={12}>
                <Form.Item
                  label="期末日期"
                  name="endDate"
                  rules={[
                    {
                      required: true,
                      message: "期末日期未輸入",
                    },
                  ]}
                  extra="允許自行輸入數字，格式YYYYMMDD"
                  onChange={(e) => {
                    EndDateChange(
                      moment(e.target.value, dateFormat),
                      e.target.value
                    );
                  }}
                >
                  <DatePicker
                    ref={endDatePickerRef}
                    placeholder="期末日期"
                    onChange={EndDateChange}
                    format={dateFormat}
                    disabledDate={disabledDate}
                    style={{ width: "80%" }}
                    changeOnBlur={true}
                    status={endDateError ? "error" : null}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              {" "}
              <Col
                span={6}
                flex="130px"
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  paddingTop: "10px",
                  alignSelf: "flex-center",
                }}
              >
                <Button
                  type={"primary"}
                  htmlType="submit"
                  style={{
                    height: "40.14px",
                    fontSize: "15px",
                    padding: "7px",
                    marginTop: "-10px",
                    width: "240px",
                    // marginLeft:'100px'
                  }}
                  icon={<DownloadOutlined />}
                  ref={submitBtnRef}
                >
                  確認並下載Excel形式報表
                </Button>
              </Col>
              <Col
                span={3}
                flex="130px"
                style={{
                  marginLeft: "4px",
                  paddingTop: "-5px",
                  paddingBottom: "5px",
                  alignContent: "flex-center",
                }}
              >
                <PageBtns onClick={onClearSearch}>清除</PageBtns>
              </Col>
            </Row>
          </Form>
        </BrowserView>
        <Col span={24}>
          <Row
            justify="space-between"
            style={{ margin: "10px 10px" }}
            gutter={[24, 8]}
          >
            {/* {btnDisable ? (
              <Button
                type="primary"
                style={{
                  height: "40.14px",
                  margin: "20px 10px 20px 10px",
                  padding: "7px",
                  backgroundColor: "#7c7c7c",
                  color: "#fff",
                }}
                icon={<DownloadOutlined />}
                // disabled={MobileDateChange}
              >
                下載Excel形式報表
              </Button>
            ) : (
              <Button
                type="primary"
                style={{
                  height: "40.14px",
                  margin: "20px 10px 20px 10px",
                  padding: "7px",
                  backgroundColor: "#00A2DB",
                }}
                icon={<DownloadOutlined />}
                onClick={exportExcelFile}
                onPressEnter={exportExcelFile}
              >
                下載Excel形式報表
              </Button> */}
            {/* )} */}
          </Row>
        </Col>
      </BrowserView>
      <MobileView style={{ marginBottom: "115px" }}>
        {/* 頁面目前位置 */}
        <Col span={24}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
            <Breadcrumb.Item href="/Stamp/StampApplication">
              進銷存日報表
            </Breadcrumb.Item>
            <Breadcrumb.Item>進銷存日報表</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {/* 頁面標題 */}
        <PageTitle>進銷存日報表</PageTitle>
        {/* 頁面搜尋功能 */}
        <Col
          style={{
            margin: "10px 0px",
            padding: "10px 10px",
            minHeight: "60px",
            width: "100%",
            backgroundColor: "white",
          }}
          span={24}
        >
          <Form
            form={NavForm}
            onFinish={
              /*(v)=>console.log(v.startDate.format(dateFormat))*/
              DateSubmitClick
            }
          >
            <Row
              style={{
                margin: "10px 0px",
                padding: "10px 10px",
                minHeight: "60px",
                width: "100%",
                backgroundColor: "white",
              }}
              span={24}
            >
              <Col style={{ padding: "10px" }} span={12}>
                <Form.Item
                  label="期初日期"
                  labelCol={{ span: 240 }}
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: "期初日期未輸入",
                    },
                  ]}
                  onChange={(e) => {
                    StartDateChange(
                      moment(e.target.value, dateFormat),
                      e.target.value
                    );
                  }}
                  // extra="允許自行輸入數字，格式YYYYMMDD"
                >
                  <DatePicker
                    autoFocus={true}
                    changeOnBlur={true}
                    // value={startDate ? moment(startDate, dateFormat) : null}
                    onChange={StartDateChange}
                    format={dateFormat}
                    disabledDate={disabledDate}
                    placeholder="期初日期"
                    status={startDateError ? "error" : null}
                  />
                </Form.Item>
              </Col>
              <Col style={{ padding: "10px" }} span={12}>
                <Form.Item
                  label="期末日期"
                  labelCol={{ span: 240 }}
                  name="endDate"
                  rules={[
                    {
                      required: true,
                      message: "期末日期未輸入",
                    },
                  ]}
                  onChange={(e) => {
                    EndDateChange(
                      moment(e.target.value, dateFormat),
                      e.target.value
                    );
                  }}
                  // extra="允許自行輸入數字，格式YYYYMMDD"
                >
                  <DatePicker
                    ref={endDatePickerRef}
                    // value={endDate ? moment(endDate, dateFormat) : null}
                    placeholder="期末日期"
                    onChange={EndDateChange}
                    format={dateFormat}
                    disabledDate={disabledDate}
                    changeOnBlur={true}
                    status={endDateError ? "error" : null}
                  />
                </Form.Item>
              </Col>
              <div style={{ color: "#9f9f9f", margin: "-25px auto auto 0" }}>
                允許自行輸入數字，格式YYYYMMDD
              </div>
            </Row>
            <Row justify="bottom">
              <Col
                span={24}
                // flex="130px"
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  alignSelf: "flex-end",
                  paddingTop: "10px",
                }}
              >
                <Button
                  type={"primary"}
                  htmlType="submit"
                  icon={<DownloadOutlined />}
                  ref={submitBtnRef}
                >
                  確認並下載Excel形式報表
                </Button>
              </Col>
              <Col
                span={24}
                // flex="130px"
                style={{
                  marginTop: "4px",
                  display: "flex",
                  alignSelf: "flex-end",
                }}
              >
                <Button onClick={onClearSearch}>清除</Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24} style={{ margin: "10px 0px" }}>
          {" "}
          <Col span={24}>
            <Row
              justify="space-between"
              style={{ margin: "10px 10px" }}
              gutter={[24, 8]}
            >
              {/* {btnDisable ? (
                <Button
                  type="primary"
                  style={{
                    marginTop: "5px",
                    backgroundColor: "#7c7c7c",
                    color: "#fff",
                  }}
                  icon={<DownloadOutlined />}
                  // disabled={MobileDateChange}
                >
                  下載Excel形式報表
                </Button>
              ) : (
                <Button
                  type="primary"
                  style={{
                    marginTop: "5px",
                    backgroundColor: "#00A2DB",
                  }}
                  icon={<DownloadOutlined />}
                  onClick={exportExcelFile}
                >
                  下載Excel形式報表
                </Button>
              )} */}
            </Row>
          </Col>
        </Col>
      </MobileView>
    </PageLayout>
  );
}
