import React, {useEffect,useState} from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, Button, Input, Steps, Row, Col, Switch, Divider, Select, DatePicker, Form, Upload, notification, Popconfirm } from 'antd'
import { UploadOutlined, PlusOutlined, MinusCircleOutlined, EyeOutlined  } from '@ant-design/icons';
import PageTitle from '../components/PageTitle';
import FormLayout from '../components/FormLayout';
import PageLayout from '../components/PageLayout';
import moment from 'moment';
import InputPrefix from '../helper/InputPrefix';

export default function ContractMgtAddAndEdit({roleFunction}){

  const { RangePicker } = DatePicker
  const [form] = Form.useForm()
  const { Step } = Steps
  let { id } = useParams()
  const { Option } = Select
  const navigate  = useNavigate()
  const [ isEmergencyOpen, setIsEmergencyOpen ] = useState(false)
  const [ currrentStep, setCurrentStep ] = useState(0)
  const [ contractInfo, setCotractInfo ] = useState({})
  const [ serialNum, setSerialNum ] = useState(1)
  const [ isTaxIDEmpty, setIsTaxIDEmpty ] = useState(true)

  useEffect(() =>{
    if(!id){
      form.setFieldsValue({
        status: '新增',
        contractNO: '20220830',
      })
    }else{
      setIsTaxIDEmpty(false)
      form.setFieldsValue({
        status: '作用中',
        contractNO: '20220830',
        companyName: "緯謙科技",
        companyAddress: '新北市汐止區新台五路一段',
        postalCode: '22121',
        taxID: '11111',
        microsoftDomain: 'Test',
        mainContactName: 'Ray',
        mainContactMobile: '09811145123',
        mainContactEmail: 'test@gmail.com',
        serviceType: 'CSP',
        subscriptionStatus: 'newPurchase',
        productType: '品項',
        expectedStartToEndDate: [moment(), moment()],
        uploadContract: 1,
        MCA: 1,
        paymentCondition: 'transfer'
      })
    }
  }, [])

  // useEffect(() =>{
  //   console.log(contractInfo)
  // }, [contractInfo])

  const onNextStep = (value) =>{
    let tempInfo = Object.assign({}, contractInfo ,value)
    setCotractInfo(tempInfo)
    if (currrentStep !== 2){
      setCurrentStep(currrentStep + 1)
    }
    else{
      navigate('/ContractMgt')
      notification.success({
        message:'成功',
        description:'操作成功!'
      })
    }
  }


  const onValidateFailed = () =>{
    notification.error({
      message: '錯誤',
      description: '請確認必填欄位皆有填寫!'
    })
  }

  //服務類型下拉選單
  const serviceTypeDropdown = [
    {
      'key': 'CSP',
      'value': 'CSP'
    },
    {
      'key': 'CSP 2-T',
      'value': 'CSP 2-T'
    },
    {
      'key': 'OPEN',
      'value': 'OPEN'
    },
    {
      'key': 'EA',
      'value': 'EA'
    },
    {
      'key': '其他',
      'value': 'others'
    }
  ]

  // ['CSP', 'CSP 2-T',].map(item => return {
  //   key: '',
  //   value
  // })
  //訂閱狀態下拉選單
  const subscriptionStatusDropdown = [
    {
      'key': '新購',
      'value': 'newPurchase'
    },
    {
      'key': '增購',
      'value': 'additionalPurchase'
    },
    {
      'key': '續約',
      'value': 'renew'
    }
  ]

  //付款條件下拉選單
  const paymentTermsDropdown = [
    {
      'key': '匯款',
      'value': 'transfer'
    },
    {
      'key': '月結',
      'value': 'monthly'
    },
    {
      'key': '線上訂購',
      'value': 'onlineOrder'
    }
  ]

  const stepOne = () =>{
    return(
      <>
        <Form
          form={form}
          autoComplete='off'
          onFinish={onNextStep}
          onFinishFailed={onValidateFailed}
        >
          <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
            <Col span={5}>
              <InputPrefix label={'狀態'} />
            </Col>  
            <Col span={6} >
              <Form.Item
                name='status'
              >
                <Input disabled/>
              </Form.Item>
            </Col>
            <Col span={5}>
              <InputPrefix label={'合約編號'} />
            </Col>
            <Col span={6} >
              <Form.Item
                name= 'contractNO'
              >
                <Input disabled/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
            <Col span={5}>
              <InputPrefix label={'統一編號'} require/>
            </Col>
            <Col span={6} >
              <Form.Item
                name= 'taxID'
                rules={[
                  {
                    required: true,
                    message: '統一編號為必填'
                  }
                ]}
              >
                <Input onBlur={(e) => e.target.value ? setIsTaxIDEmpty(false) : setIsTaxIDEmpty(true) }/>
              </Form.Item>
            </Col>
            <Col span={5}>
              <InputPrefix label={'公司名稱'} require/>
            </Col>  
            <Col span={6} >
              <Form.Item
                name='companyName'
                rules={[
                  {
                    required: true,
                    message: '公司名稱為必填'
                  }
                ]}
              >
                <Input disabled={isTaxIDEmpty}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
            <Col span={5}>
              <InputPrefix label={'用戶名稱(開通對象)'} />
            </Col>
            <Col span={6} >
              <Form.Item
                name= 'microsoftClientName'
              >
                <Input disabled={isTaxIDEmpty}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
            <Col span={5}>
              <InputPrefix label={'微軟識別網域名稱'} require/>
            </Col>  
            <Col span={10} >
              <Form.Item
                name='microsoftDomain'
                rules={[
                  {
                    required: true,
                    message: '微軟識別網域名稱為必填'
                  }
                ]}
              >
                <Input disabled={isTaxIDEmpty}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
            <Col span={5}>
              <InputPrefix label={'郵遞區號'} require/>
            </Col>  
            <Col span={6} >
              <Form.Item
                name='postalCode'
                rules={[
                  {
                    required: true,
                    message: '郵遞區號為必填'
                  }
                ]}
              >
                <Input disabled={isTaxIDEmpty}/>
              </Form.Item>
            </Col>
            <Col span={5}>
              <InputPrefix label={'公司地址'} require/>
            </Col>
            <Col span={6} >
              <Form.Item
                name= 'companyAddress'
                rules={[
                  {
                    required: true,
                    message: '公司地址為必填'
                  }
                ]}
              >
                <Input disabled={isTaxIDEmpty}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
            <Col span={5}>
              <InputPrefix label={'主要聯繫人姓名'} require/>
            </Col>  
            <Col span={6} >
              <Form.Item
                name='mainContactName'
                rules={[
                  {
                    required: true,
                    message: '主要聯繫人姓名為必填'
                  }
                ]}
              >
                <Input disabled={isTaxIDEmpty}/>
              </Form.Item>
            </Col>
            <Col span={5}>
              <InputPrefix label={'主要聯繫人電話'} require/>
            </Col>
            <Col span={6} >
              <Form.Item
                name= 'mainContactMobile'
                rules={[
                  {
                    required: true,
                    message: '主要聯繫人電話為必填'
                  }
                ]}
              >
                <Input disabled={isTaxIDEmpty}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
            <Col span={5}>
              <InputPrefix label={'主要聯繫人Email'} require/>
            </Col>  
            <Col span={10} >
              <Form.Item
                name='mainContactEmail'
                rules={[
                  {
                    required: true,
                    message: '主要聯繫人Email為必填'
                  }
                ]}
              >
                <Input disabled={isTaxIDEmpty}/>
              </Form.Item>
            </Col>
          </Row>
          <Form.List name='subContactInfo'>
            {(fields, { add, remove }) =>(
              <>
                {fields.map((field) =>(
                  <>
                  {(console.log(field, fields))}
                    <Form.Item
                      shouldUpdate={(prev, cur) =>
                        prev.subcontactName !== cur.subcontactName || prev.subcontactMobile !== cur.subcontactMobile || prev.subcontactEmail !== cur.subcontactEmail
                      }
                    >
                      {() =>(
                      <div style={{ marginLeft: '-10px' }}>
                      <Divider/>
                        <Row gutter={[24,8]} style={{ color: 'black', padding: '15px 0' }}>
                          <Col span={5}>
                            <InputPrefix label={'次要聯繫人姓名'}/>
                          </Col>  
                          <Col span={6} >
                            <Form.Item
                              {...field}
                              name= {[field.name, 'subcontactName']}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <InputPrefix label={'次要聯繫人電話'}/>
                          </Col>  
                          <Col span={6} >
                            <Form.Item
                              {...field}
                              name= {[field.name, 'subcontactMobile']}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
                          <Col span={5}>
                            <InputPrefix label={'次要聯繫人Email'}/>
                          </Col>  
                          <Col span={10} >
                            <Form.Item
                              {...field}
                              name= {[field.name, 'subcontactEmail']}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={3}/>
                          <Col span={5}>
                            <Form.Item>
                            <Button style={{ backgroundColor: 'red' }} icon={<MinusCircleOutlined />} onClick={() => remove(field.name)}> 移除此次要聯繫人</Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      )}
                    </Form.Item>
                  </>
                ))}

                <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
                  <Col span={1}/>
                  <Col span={5}>
                    <Form.Item>
                     <Button disabled={isTaxIDEmpty} type='primary' icon={<PlusOutlined />} onClick={() => add()}> 增加次要聯繫人欄位</Button>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
          <Row justify='end' style={{ paddingTop: '15px' }}>
            <Col span={16}/>
            <Col span={3} style={{ paddingRight:'10px' }}>
              <Button onClick={() => navigate('/ContractMgt')}> 返回 </Button> 
            </Col>
            <Col span={3} style={{ paddingRight:'10px' }}>
              <Form.Item>
                <Button disabled={isTaxIDEmpty} type='primary' htmlType='submit'> 下一步 </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    )
  }

  const stepTwo = () =>{
    return(
    <>
      <Form
        form={form}
        autoComplete='off'
        onFinish={onNextStep}
        onFinishFailed={onValidateFailed}
      >
        <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
          <Col span={5}>
           <InputPrefix label={'服務類型'} require/>
          </Col>  
          <Col span={6} >
            <Form.Item
              name='serviceType'
              rules={[
                {
                  required: true,
                  message: '請選擇服務類型!'
                }
              ]}
            >
              <Select
                style={{ width:'100%' }}
              >
                {
                  serviceTypeDropdown.map(ele => {
                    return <Option key={ele.value}> {ele.key} </Option>
                  })
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <InputPrefix label={'訂閱狀態'} require/>
          </Col>  
          <Col span={6} >
            <Form.Item
              name='subscriptionStatus'
              rules={[
                {
                  required: true,
                  message: '請選擇訂閱狀態!'
                }
              ]}
            >
              <Select
                style={{ width:'100%' }}
              >
                {
                  subscriptionStatusDropdown.map(ele => {
                    return <Option key={ele.value}> {ele.key} </Option>
                  })
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
          <Col span={5}>
           <InputPrefix label={'產品品項'} require/>
          </Col>  
          <Col span={6} >
            <Form.Item
              name='productType'
              rules={[
                {
                  required: true,
                  message: '產品品項為必填!'
                }
              ]}
            >
              <Input/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
          <Col span={5}>
           <InputPrefix label={'預計啟用至結束日期'} require/>
          </Col>  
          <Col span={10} >
            <Form.Item
              name='expectedStartToEndDate'
              rules={[
                {
                  required: true,
                  message: '請選擇預計啟用至結束日期!'
                }
              ]}
            >
              <RangePicker style={{ width:'100%' }}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
          <Col span={5}>
           <InputPrefix label={'實際啟用至結束日期'} />
          </Col>  
          <Col span={10} >
            <Form.Item
              name='actualStartToEndate'
            >
              <RangePicker style={{ width:'100%' }}/>
            </Form.Item>
          </Col>
        </Row>
        <Divider/>
        <Form.List name={`${form.getFieldValue('contractNO')}-${serialNum}`}>
          {(fields, { add, remove }) =>(
            <>
              {fields.map((field) =>(
                <>
                {(console.log(field, fields))}
                  <Form.Item
                    shouldUpdate={(prev, cur) =>
                      prev.serviceType !== cur.serviceType 
                  ||  prev.subscriptionStatus !== cur.subscriptionStatus 
                  ||  prev.productType !== cur.productType
                  ||  prev.expectedStartDate !== cur.expectedStartDate 
                  ||  prev.expectedEndDate !== cur.expectedEndDate
                  ||  prev.actualStartDate !== cur.actualStartDate 
                  ||  prev.actualEndDate !== cur.actualEndDate
                    }
                  >
                    {() =>(
                    <div style={{ marginLeft: '-10px' }}>
                      <Row gutter={[24,8]} style={{ color: 'black', padding: '15px 0' }}>
                        <Col span={5}>
                          <InputPrefix label={'服務類型'} require/>
                        </Col>  
                        <Col span={6} >
                          <Form.Item
                            {...field}
                            name= {[field.name, 'serviceType']}
                            rules={[
                              {
                                required: true,
                                message: '請選擇服務類型!'
                              }
                            ]}
                          >
                            <Select
                              style={{ width:'100%' }}
                            >
                              {
                                serviceTypeDropdown.map(ele => {
                                  return <Option key={ele.value}> {ele.key} </Option>
                                })
                              }
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <InputPrefix label={'訂閱狀態'} require/>
                        </Col>  
                        <Col span={6} >
                          <Form.Item
                            {...field}
                            name= {[field.name, 'subscriptionStatus']}
                            rules={[
                              {
                                required: true,
                                message: '請選擇訂閱狀態!'
                              }
                            ]}
                          >
                            <Select
                              style={{ width:'100%' }}
                            >
                              {
                                subscriptionStatusDropdown.map(ele => {
                                  return <Option key={ele.value}> {ele.key} </Option>
                                })
                              }
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
                        <Col span={5}>
                          <InputPrefix label={'產品品項'} require/>
                        </Col>  
                        <Col span={6} >
                          <Form.Item
                            {...field}
                            name={[field.name, 'productType']}
                            rules={[
                              {
                                required: true,
                                message: '產品品項為必填!'
                              }
                            ]}
                          >
                            <Input/>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
                        <Col span={5}>
                        <InputPrefix label={'預計啟用日期'} require/>
                        </Col>  
                        <Col span={10} >
                          <Form.Item
                            {...field}
                            name={[field.name, 'expectedStartToEndDate']}
                            rules={[
                              {
                                required: true,
                                message: '請選擇預計啟用至結束日期!'
                              }
                            ]}
                          >
                            <RangePicker style={{ width:'100%' }}/>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
                        <Col span={5}>
                        <InputPrefix label={'op實際啟用至結束日期'} />
                        </Col>  
                        <Col span={10} >
                          <Form.Item
                            {...field}
                            name={[field.name, 'actualStartToEndDate']}
                          >
                            <RangePicker style={{ width:'100%' }}/>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
                        <Col span={16}/>
                        <Col span={5}>
                          <Form.Item>
                          <Button style={{ backgroundColor: 'red' }} icon={<MinusCircleOutlined />} onClick={() => remove(field.name)}> 移除此服務類型</Button>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider/>
                    </div>
                    )}
                  </Form.Item>
                </>
              ))}

              <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
                <Col span={1}/>
                <Col span={5}>
                  <Form.Item>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => add()}> 新增服務欄位 </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
        <Row justify='end' style={{ paddingTop: '15px' }}>
          <Col span={16}/>
          <Col span={3} style={{ paddingRight:'10px' }}>
            <Button onClick={() => setCurrentStep(currrentStep -1 )}> 上一步 </Button> 
          </Col>
          <Col span={3} style={{ paddingRight:'10px' }}>
            <Form.Item>
              <Button type='primary' htmlType='submit'> 下一步 </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
    )
  }

  const stepThree = () =>{
    return(
      <>
      <Form
          form={form}
          autoComplete='off'
          onFinish={onNextStep}
          onFinishFailed={onValidateFailed}
          initialValues={{
            emergencyOpening:false
          }}
        >
          <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
            <Col span={5}>
              <InputPrefix label={'上傳開通文件'} require/>
            </Col>  
            <Col span={6} >
            {
              form.getFieldValue('uploadContract') !== undefined ?
                <Button icon={<EyeOutlined />} type='primary'> 查看開通文件 </Button>
                :
                  <Form.Item
                    name='uploadContract'
                    rules={[
                      {
                        required: true,
                        message: '需上傳開通文件'
                      }
                    ]}
                  >
                    <Upload
                      name='uploadContract'
                    > <Button icon={<UploadOutlined />} type='primary' > 上傳開通文件 </Button> </Upload>
                  </Form.Item>
              }
            </Col>
          </Row>
          <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
            <Col span={5}>
              <InputPrefix label={'緊急開通'} />
            </Col>  
            <Col span={6} >
              <Form.Item
                name='emergencyOpening'
                valuePropName='checked'
              >
                <Switch onChange={(e) => setIsEmergencyOpen(e)} />
              </Form.Item>
            </Col>
              <Col span={5} style={{ display: `${ isEmergencyOpen ? 'block' : 'none' }`}}>
                <InputPrefix label={'緊急開通主管同意信'} />
              </Col>
              <Col span={5} style={{ display: `${ isEmergencyOpen ? 'block' : 'none' }`}}>
                <Form.Item
                  name= 'consentLetter'
                >
                  <Button icon={<UploadOutlined />} > 上傳緊急開通主管同意信 </Button>
                </Form.Item>
              </Col>
          </Row>
          <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
            <Col span={5}>
              <InputPrefix label={'Microsoft Customer Agreement'} require/>
            </Col>  
            <Col span={6} >
            {
              form.getFieldValue('MCA') !== undefined ?
                <Button icon={<EyeOutlined />} type='primary'> 查看同意書 </Button>
                  :
                <Form.Item
                  name='MCA'
                  rules={[
                    {
                      required: true,
                      message: '需上傳同意書檔案'
                    }
                  ]}
                >
                  <Upload
                    name='MCA'
                  > <Button  icon={<UploadOutlined />} type='primary' > 上傳同意書 </Button> </Upload>
                </Form.Item>
            }

            </Col>
            <Col span={5}>
              <InputPrefix label={'付款方式'} require/>
            </Col>  
            <Col span={6} >
              <Form.Item
                name='paymentCondition'
                rules={[
                  {
                    required: true,
                    message: '請選擇付款條件'
                  }
                ]}
              >
                <Select
                  style={{ width:'100%' }}
                >
                  {
                    paymentTermsDropdown.map(ele =>{
                      return <Option value={ele.value}> {ele.key} </Option>
                    })
                  }
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
            <Col span={5}>
              <InputPrefix label={'上單單號'} />
            </Col>  
            <Col span={6} >
              <Form.Item
                name='orderNum'
              >
                <Input/>
              </Form.Item>
            </Col>
            <Col span={5}>
              <InputPrefix label={'發票號碼'} />
            </Col>
            <Col span={6} >
              <Form.Item
                name= 'InvoiceNum'
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row justify='end' style={{ paddingTop: '15px' }}>
            <Col span={12}/>
            <Col span={3} style={{ paddingRight:'10px' }}>
              <Button onClick={() => navigate('/ContractMgt')}> 返回 </Button> 
            </Col>
            <Col span={3} style={{ paddingRight:'10px' }}>
              <Button onClick={() => setCurrentStep(currrentStep - 1)}> 上一步 </Button>
            </Col>
            <Col span={3} style={{ paddingRight:'10px' }}>
              <Form.Item>
                <Popconfirm 
                  title="確認要暫存?"
                  okText="確定"
                  okButtonProps={{
                    className:"for-popconfirm-btn",
                    onClick:() => form.submit()
                  }}
                  cancelText="取消"
                  cancelButtonProps={{
                    className:"for-popconfirm-btn",
                    style:{backgroundColor:'red'}
                  }}
                >
                  <Button style={{ backgroundColor: '#1890ff' }}> 暫存 </Button>
                </Popconfirm>
              </Form.Item>
            </Col> 
            <Col span={3} style={{ paddingRight:'10px' }}>
              <Form.Item>
                <Popconfirm 
                  title="確認要送審?"
                  okText="確定"
                  okButtonProps={{
                    className:"for-popconfirm-btn",
                    onClick:() => form.submit()
                  }}
                  cancelText="取消"
                  cancelButtonProps={{
                    className:"for-popconfirm-btn",
                    style:{backgroundColor:'red'}
                  }}
                >
                  <Button type='primary'> 送審 </Button>
                </Popconfirm>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    )
  }

  const stepProps = [
    {
      title:'第一步',
      description: '',
      content: stepOne()
    },
    {
      title:'第二步',
      description: '',
      content: stepTwo()
    },
    {
      title:'第三步',
      description: '',
      content: stepThree()
    },
  ]

  return(
    <PageLayout spinning={false}>
      {/* 頁面目前位置 */}
      <Col span={24}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item href='/'>WiAdvance EIP</Breadcrumb.Item>
          <Breadcrumb.Item href="/ContractMgt">合約管理</Breadcrumb.Item>
          <Breadcrumb.Item > { id ? '編輯合約' : '新增合約' } </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      {/* 頁面標題 */}
      <PageTitle>
        { id ? '編輯合約' : '新增合約' }
      </PageTitle>
      <Col span={24}
      style={{
        margin:'10px 0px',
      }}
      >
      </Col>
      <Col span={24}>
        <div style={{ backgroundColor:'white'}}>
          <Steps current={currrentStep} style={{ backgroundColor:'white', padding:'32px' }}>
           {
            stepProps.map(ele =>{
              return <Step title={ele.title} description={ele.description} />
            })
           }
          </Steps>
          <FormLayout>
            {stepProps[currrentStep].content}
          </FormLayout>
        </div>
      </Col>
    </PageLayout>
  )
}