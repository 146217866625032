import React from "react"
import { Col, Row } from 'antd'
const InputPrefix = ({label, require, width, visibility})=>{
    return <Col 
      flex={width? width: '200px'}
      style={{
        color:'black',
        fontWeight: 600,
        margin: '6.5px 11px',
        fontSize: '16px',
        borderRight: '1px solid #ddd',
        visibility: `${visibility}`
        }}
      > 
        <Row justify="space-between">
          <Col>
          {label}
          {   
          require? 
          <span style={{'color':'red','display': 'inline-block','position':'relative','top':'-3.5px', 'left':'1px'}}>
            *
          </span>
          :null
          }
          </Col>
          {/* <Col>
          :
          </Col> */}
        </Row>
    </Col>
}
export default InputPrefix;