import React, { useEffect, useState } from "react";
import { Button,Form } from 'antd';
  
  function PageBtns(props) {
    return (
      <Button
        style={{
          height: "40.14px",
          // fontSize:'15px',
          // padding: "7px",
          // width: "130px",
        }}
        {...props}
    >
      {props.children}
      {/* <PlusCircleOutlined /> */}
    </Button>
  )
  }
  
  export default PageBtns