
import React, {useState} from 'react'
import { useOutletContext, useNavigate } from "react-router-dom";
import { Breadcrumb, Button, Input, Dropdown, Menu, Space, Row, Col, Table, Switch, Modal } from 'antd'
import { SearchOutlined, CaretDownOutlined, AlignLeftOutlined, PlusCircleOutlined} from '@ant-design/icons';
import PageTitle from '../components/PageTitle';
import PageSearch from '../components/PageSearch';
import PageLayout from '../components/PageLayout';

const { Search } = Input;

export default function Account({roleFunction}) {
  const navigate = useNavigate();
  const [data, setData] = useState([
    {
      'account':'test@test.com',
      'time':'2022/03/04 上午9:43:39',
      'status':'啟用中',
      'action':true
    },
    {
      'account':'test1@test.com',
      'time':'2022/04/04 上午9:43:39',
      'status':'啟用中',
      'action':false
    },
    {
      'account':'test2@test.com',
      'time':'2022/05/04 上午9:43:39',
      'status':'啟用中',
      'action':true
    },
    {
      'account':'test3@test.com',
      'time':'2022/06/04 上午9:43:39',
      'status':'啟用中',
      'action':true
    }
  ])
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false)
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const columns = [
    {
      title: `帳號`,
      dataIndex: 'account',
      key: 'account'
      // render:(item,record)=>{
      //   return <div onClick={() =>handleonClick(record)}>{item}</div>
      // }
    },
    {
      title: `異動時間`,
      dataIndex: 'time',
      key: 'time',
      align:'center'
    },
    {
      title: `狀態`,
      dataIndex: 'status',
      key: 'status',
      // width: '30%',
      align:'center'
    },
    {
      title: ``,
      dataIndex: 'action',
      key: 'action',
      width: '200px',
      render: (item,record)=>{
        return <Row justify='space-around'>
          <a onClick={()=>navigate(`/account/setting`)}>權限</a>
          <Switch checkedChildren="啟用" unCheckedChildren="停用" checked={item}  />
          <a onClick={showModal}>紀錄</a>
        </Row>

      },
      align:'center'
    }
  ];
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a >
          角色一
        </a>
      </Menu.Item>
      <Menu.Item key="1">
        <a >
          角色二
        </a>
      </Menu.Item>
      <Menu.Item key="2">
        <a >
          角色三
        </a>
      </Menu.Item>
    </Menu>
  );
  const menuChoice = (
    <Menu>
      <Menu.Item key="0">
        <a >
          全部
        </a>
      </Menu.Item>
      <Menu.Item key="1">
        <a >
          停用
        </a>
      </Menu.Item>
    </Menu>
  );
  const { currentUser } = useOutletContext();
  return (
    <PageLayout spinning={false}>
      <Col span={24}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item href='/'>WiAdvance EIP</Breadcrumb.Item>
          <Breadcrumb.Item >帳號管理</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <PageTitle>
        帳號管理
      </PageTitle>
      <PageSearch>
        <Row justify="start" style={{margin:'10px 0px'}}>
            <Col flex="100px" style={{margin: 'auto 10px'}}>
              <Dropdown overlay={menuChoice} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>
                  <Space style={{'color':'#7C7C7C'}}>
                    <AlignLeftOutlined />
                      全部
                    <CaretDownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </Col>
            <Col flex="100px" style={{margin: 'auto'}}>
              <Dropdown overlay={menu} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>
                  <Space style={{'color':'#7C7C7C'}}>
                    <AlignLeftOutlined />
                      角色
                    <CaretDownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </Col>
            <Col flex="auto">
              <Search
                style={{
                width:'100%'
                }}
                enterButton={'查尋'}
                allowClear
                size="large"
                placeholder={'查尋使用者帳號'}
                prefix={<SearchOutlined/>}
              />
            </Col>
            <Col flex="150px" style={{margin: 'auto 10px'}}>
              <Button style={{height:'40.14px'}}>清除</Button>
            </Col>
        </Row>

      </PageSearch>
      <Col span={24}>
        <Modal title="紀錄頁面" visible={isModalVisible} onCancel={handleCancel} footer={<Button onClick={handleCancel}>關閉</Button>}>
          <p>1 修改時間：2022/01/03 上午11:29:32</p>
          <p>紀錄：因為有多重角色</p>

          <p>2 修改時間：2021/12/01 上午06:01:03</p>
          <p>紀錄：換另一個角色</p>

          <p>3 修改時間：2021/11/13 下午06:01:02</p>
          <p>紀錄：錯誤設置更新</p>

          <p>4 修改時間：2021/10/03 下午09:20:22</p>
          <p>紀錄：再新增一個角色.</p>
        </Modal>
        <Row justify="space-between" style={{margin: '5px 10px'}}>
          <Col flex="150px">
            <Button 
              type={'primary'} 
              style={{height:'40.14px'}}
              onClick={()=>navigate('/account/add')}
            >
              新增<PlusCircleOutlined />
            </Button>
          </Col>
          <Col flex="auto">
            <div style={{'textAlign':'end', 'margin':'8px', 'color':'#7C7C7C'}}>
              共有{data? data.length : 0}筆資料，每頁顯示10<CaretDownOutlined />筆
            </div>
          </Col>
        </Row>
      </Col>
      {/* <PageSearch>
      <Row justify="space-between" style={{margin: '20px 10px'}}>
        <Col flex="150px">
          <Button 
            type={'primary'} 
            style={{height:'40.14px'}}
            onClick={()=>navigate('/account/add')}
          >
            新增<PlusCircleOutlined />
          </Button>
        </Col>
        <Col flex="auto">
          <div style={{'textAlign':'end', 'margin':'8px', 'color':'#7C7C7C'}}>
            共有{data? data.length : 0}筆資料，每頁顯示10<CaretDownOutlined />筆
          </div>
        </Col>
      </Row>
      </PageSearch> */}
      <Col span={24}
      style={{
        margin:'10px 0px',
      }}
      >
        <Table 
          columns = {columns} 
          dataSource = {data} 
        />
      </Col>
    </PageLayout>
  )
}
