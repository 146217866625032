/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import {
  useOutletContext,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  Row,
  Col,
  Table,
  Switch,
  message,
  Modal,
  Form,
  notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../components/PageTitle";
import PageLayout from "../components/PageLayout";
import InputPrefix from "../helper/InputPrefix";
import moment from "moment";
import { getWFH, putWFHApply } from "../newApi/wfh.api";
import { BrowserView, MobileView } from "react-device-detect";
import MobilForm from "../components/MobilForm";
import PageBtns from "../components/PageBtns";
export default function WFHApplicationAudit() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { currentUser } = useOutletContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isDisable, setIsDisable] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const isLoading = useSelector((state) => state.globalSetting.isLoading);
  const [checkOpen, setCheckOpen] = useState(false);
  const [passCheckOpen, setPassCheckOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const blankInfo = () => {
    notification.warning({
      message: "提示",
      description: "查無此單號或權限不足！",
    });
    navigate(`/WFH/WFHApplication`);
  };
  const auditedInfo = () => {
    notification.warning({
      message: "提示",
      description: "此申請已審核完畢！",
    });
    navigate(`/WFH/WFHApplication/view/${id}`);
  };
  const revokedInfo = () => {
    notification.warning({
      message: "提示",
      description: "此申請已被抽回！",
    });
    navigate(`/WFH/WFHApplication/view/${id}`);
  };

  const initial = (params) => {
    setPageLoading(true);
    getWFH(params)
      .then((res) => {
        if (res?.isSuccess) {
          // setData(res?.data);
          form.setFieldsValue(res?.data?.[0]);
          if (res?.data.length === 0) {
            return (
              <p spinning={loading} tip="Loading..." size="large">
                {blankInfo()}
              </p>
            );
          } else if (
            res?.data?.[0].Status === "agree" ||
            res?.data?.[0].Status === "reject"
          ) {
            return (
              <p spinning={loading} tip="Loading..." size="large">
                {auditedInfo()}
              </p>
            );
          } else if (res?.data?.[0].Status === "revoke") {
            return (
              <p spinning={loading} tip="Loading..." size="large">
                {revokedInfo()}
              </p>
            );
          }
        }
      })
      .finally(() => setPageLoading(false));
  };
  useEffect(() => {
    initial({
      WfhNo: id,
      PageRow: "50",
      PageIndex: "1",
      mail:
        localStorage.getItem("mockUser") || undefined,
    });
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const auditReject = (value) => {
    // console.log(value);
    if (inputValue.trim() === "") {
      Modal.error({
        title: "錯誤訊息",
        content: (
          <div
            style={{
              color: "#3f3f3f",
              padding: "15px",
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            退回時請填寫審核意見
          </div>
        ),
      });
    } else if (inputValue.trim() !== "") {
      setCheckOpen(true);
      // putWFHApply({
      //   "WfhNo": value?.WfhNo,
      //   "Status": "reject",
      //   "AuditComments": value?.AuditComments
      // }).then((res) => {
      //   if (res?.isSuccess) {
      //     message.success({
      //       content: "審核退回",
      //     });
      //     navigate("/WFH/WFHApplication");
      //   }
      // });
    }
  };

  const auditPass = (value) => {
    setPassCheckOpen(true);
  };

  return (
    <>
      {/* 申請通過Modal */}
      <Modal
        visible={passCheckOpen}
        onCancel={() => {
          !loading && setPassCheckOpen(false);
        }}
        title="申請通過"
        maskClosable={false}
        footer={[
          <PageBtns
            loading={loading}
            type="primary"
            onClick={async () => {
              setLoading(true);
              setPageLoading(true);
              await form.validateFields().then((resForm) => {
                putWFHApply({
                  WfhNo: resForm?.WfhNo,
                  Status: "agree",
                  AuditComments: resForm?.AuditComments,
                })
                  .then((res) => {
                    if (res?.isSuccess) {
                      notification.success({
                        message: "成功",
                        description: `${resForm?.ApplicantName}-${resForm?.WfhDate} WHF 審核通過`,
                      });
                      navigate("/WFH/WFHApplication");
                    }
                    else {
                      navigate("/WFH/WFHApplication");
                    }
                  })
                  .finally(() => {
                    setLoading(false);
                    setPageLoading(false);
                  });
              });
            }}
          >
            確認
          </PageBtns>,
          <PageBtns
            onClick={() => {
              !loading && setPassCheckOpen(false);
            }}
          >
            取消
          </PageBtns>,
        ]}
      >
        <Row
          gutter={[24, 8]}
          style={{
            color: "#3f3f3f",
            padding: "15px",
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          <Col span={23}>
            <p>
              您確定要通過 <br />
              {`${form.getFieldValue("ApplicantName")}`} 在{" "}
              {`${form.getFieldValue("WfhDate")}`} WFH的申請?
            </p>
          </Col>
        </Row>
      </Modal>
      {/* 申請退回Modal */}
      <Modal
        loading={loading}
        visible={checkOpen}
        onCancel={() => {
          !loading && setCheckOpen(false);
        }}
        title="申請退回"
        maskClosable={false}
        footer={[
          <PageBtns
            loading={loading}
            type="primary"
            onClick={async () => {
              setLoading(true);
              setPageLoading(true);
              await form.validateFields().then((resForm) => {
                putWFHApply({
                  WfhNo: resForm?.WfhNo,
                  Status: "reject",
                  AuditComments: resForm?.AuditComments,
                })
                  .then((res) => {
                    if (res?.isSuccess) {
                      notification.success({
                        message: "成功",
                        description: `${resForm?.ApplicantName}-${resForm?.WfhDate} WHF 審核退回`,
                      });
                      navigate("/WFH/WFHApplication");
                    } else {
                      navigate("/WFH/WFHApplication");
                    }
                  })
                  .finally(() => {
                    setLoading(false);
                    setPageLoading(false);
                  });
              });
            }}
          >
            確認
          </PageBtns>,
          <PageBtns
            onClick={() => {
              !loading && setCheckOpen(false);
            }}
          >
            取消
          </PageBtns>,
        ]}
      >
        <Row
          gutter={[24, 8]}
          style={{
            color: "#3f3f3f",
            padding: "15px",
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          <Col span={23}>
            <p>
              您確定要退回 {`${form.getFieldValue("ApplicantName")}`} 在{" "}
              {`${form.getFieldValue("WfhDate")}`} WFH的申請?
            </p>
          </Col>
        </Row>
      </Modal>
      <PageLayout spinning={pageLoading}>
        <BrowserView>
          {/* 頁面目前位置 */}
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
              <Breadcrumb.Item href="/WFH/WFHApplication">
                WFH申請
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {" "}
                WFH申請審核
                {!!localStorage.getItem("mockUser") &&
                <span style={{color:'#ed3b3b'}}>（{localStorage.getItem("mockUser")}）</span>}
                </Breadcrumb.Item>{" "}
            </Breadcrumb>
          </Col>
          {/* 頁面標題 */}
          <PageTitle>WFH申請審核</PageTitle>
          <Col span={24}>
            <div style={{ backgroundColor: "white" }}>
              <Form
                form={form}
                autoComplete={false}
                style={{ paddingTop: "10px" }}
                loading={loading}
                initialValues={
                  id
                    ? {
                        WfhNo: id,
                      }
                    : null
                }
              >
                {/* 申請單號 */}
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={5}>
                    <InputPrefix label={"申請單號"} />
                  </Col>
                  <Col span={6}>
                    <Form.Item name="WfhNo">
                      <Input bordered={false} disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={5}>
                    <InputPrefix label={"申請人"} />
                  </Col>
                  <Col span={6}>
                    <Form.Item name="ApplicantName">
                      <Input bordered={false} disabled />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <InputPrefix label={"申請在家上班日期"} />
                  </Col>
                  <Col span={6}>
                    {
                      //檢視頁面
                      id ? (
                        <Form.Item name={"WfhDate"}>
                          <Input bordered={false} disabled />
                        </Form.Item>
                      ) : (
                        <Form.Item>
                          <Input
                            defaultValue={moment().format("YYYY-MM-DD")}
                            bordered={false}
                            disabled
                          />
                        </Form.Item>
                      )
                    }
                  </Col>
                </Row>
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={5}>
                    <InputPrefix label={"原因"} />
                  </Col>
                  <Col span={18}>
                    <Form.Item name="WfhReason">
                      <TextArea
                        rows={6}
                        maxLength={200}
                        showCount
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={5}>
                    <InputPrefix label={"審核意見"} />
                  </Col>
                  <Col span={18}>
                    <Form.Item name="AuditComments">
                      <TextArea
                        rows={6}
                        maxLength={200}
                        showCount
                        disabled={isDisable}
                        value={inputValue}
                        onChange={handleInputChange}
                        // required={selectedBtn === "退回"}
                        placeholder={"駁回時需填寫審核意見"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  justify="end"
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={3} style={{ padding: "5px" }}>
                    <PageBtns
                      type="primary"
                      onClick={auditPass}
                      loading={loading}
                    >
                      通過
                    </PageBtns>
                  </Col>
                  <Col span={3} style={{ padding: " 5px" }}>
                    <Button
                      style={{
                        color: "white",
                        height: "40.14px",
                        backgroundColor: "#aa1f1f",
                      }}
                      onClick={auditReject}
                    >
                      退回
                    </Button>
                  </Col>
                  <Col span={3} style={{ padding: "5px" }}>
                    <PageBtns onClick={() => navigate("/WFH/WFHApplication")}>
                      返回
                    </PageBtns>
                  </Col>
                  <Col span={1} />
                </Row>
              </Form>
            </div>
          </Col>
        </BrowserView>
        <MobileView style={{ marginBottom: "115px" }}>
            {/* 頁面目前位置 */}
            <Col span={24}>
              <Breadcrumb separator=">">
                <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
                <Breadcrumb.Item href="/WFH/WFHApplication">
                  WFH申請
                </Breadcrumb.Item>
                <Breadcrumb.Item>WFH申請審核</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            {/* 頁面標題 */}
            <PageTitle>WFH申請審核</PageTitle>
            <Col span={24}>
              <MobilForm
                form={form}
                loading={loading}
                initialValues={
                  id
                    ? {
                        WfhNo: id,
                        ApplicantName: id.ApplicantName,
                      }
                    : null
                }
              >
                <Row
                  justify="space-around"
                  gutter={[0, 8]}
                  style={{ color: "black", padding: "0 10px 15px 10px" }}
                >
                  {/* 申請單號 */}
                  <Col span={23} style={{ marginTop: "10px" }}>
                    <Form.Item name="WfhNo" label="申請單號">
                      <Input
                        disabled
                        // style={{ width: "80%" }}
                        bordered={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={23}>
                    <Form.Item name="ApplicantName" label="申請人">
                      <Input
                        disabled
                        // style={{ width: "80%" }}
                        bordered={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={23}></Col>

                  {/* 申請日期 */}
                  <Col span={23}>
                    {
                      //檢視頁面
                      id ? (
                        <Form.Item label="申請在家上班時間" name={"WfhDate"}>
                          <Input bordered={false} disabled />
                        </Form.Item>
                      ) : (
                        <Form.Item label="申請在家上班時間" name={"WfhDate"}>
                          <Input
                            defaultValue={moment().format("YYYY-MM-DD")}
                            bordered={false}
                            // style={{ width: "80%" }}
                            disabled
                          />
                        </Form.Item>
                      )
                    }
                  </Col>
                  {/* 原因 */}
                  <Col span={23}>
                    <Form.Item name="WfhReason" label="原因">
                      <TextArea rows={4} maxLength={200} showCount disabled />
                    </Form.Item>
                  </Col>
                  {/* 審核意見 */}
                  <Col span={23}>
                    <Form.Item name="AuditComments" label="審核意見">
                      <TextArea
                        rows={4}
                        maxLength={200}
                        showCount
                        disabled={isDisable}
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder={"駁回時需填寫審核意見"}
                        // required={selectedBtn === "退回"}
                      />
                    </Form.Item>
                  </Col>
                  {/* 按鈕 */}
                </Row>
                <Col span={24} style={{ marginBottom: "15px" }}></Col>
                {/* 按鈕 */}
                <Col span={16} style={{ padding: "0px 5px" }}>
                  <PageBtns type="primary" onClick={auditPass}>
                    通過
                  </PageBtns>
                </Col>
                <Col span={16} style={{ padding: "0px 5px" }}>
                  <Button
                    style={{ height: "40.14px", backgroundColor: "#aa1f1f" }}
                    onClick={auditReject}
                  >
                    退回
                  </Button>
                </Col>
                <Col span={16} style={{ padding: "0px 5px" }}>
                  <PageBtns onClick={() => navigate("/WFH/WFHApplication")}>
                    返回
                  </PageBtns>
                </Col>
              </MobilForm>
            </Col>
         
        </MobileView>
        {/* <ViewFileModal />
      <SignRecordModal />
      <SignAndRejectModal
        stampOwnerList={stampOwnerList}
        type={signoffType}
        status={"pendingStamp"}
      /> */}
      </PageLayout>
    </>
  );
}
