/* eslint-disable no-loop-func */
import { Modal, Table, Tooltip, Row, Col, Button, List, Divider } from "antd";
import { useDispatch, useSelector } from 'react-redux'
import { setSignReocrdModalVisible } from "../redux/action/Components";
import { useEffect, useState } from "react";
import { getStampDetail, getStampQueryLog } from "../redux/action/Stamp";
import { useParams } from "react-router-dom";
import moment from "moment";
import html2pdf from 'html2pdf.js'
import StampStatusObj from "../helper/StampStatusObj";
import types from "../redux/action/types";
import { BrowserView, MobileView } from "react-device-detect";
import InfiniteScroll from 'react-infinite-scroll-component';

export default function SignRecordModal(){

  const { id } = useParams()
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.globalSetting.isLoading)
  const stampList = useSelector(state => state.Stamp.stampList)
  const signRecordModalVisible = useSelector(state => state.Modal.signRecordModalVisible)
  const [ signoffHistory, setSignoffHistory ] = useState([])
  const [ stampDetail, setStampDetail ] = useState({})

  useEffect(() =>{
    if(signRecordModalVisible){
      dispatch(getStampQueryLog(id)).then(resp =>{
        dispatch(getStampDetail(id)).then(detailRes =>{
          setSignoffHistory(resp)
          setStampDetail(detailRes)
        })
      })
    }
  }, [signRecordModalVisible])

  const signRecordPDFColumns = [
    {
      title: '審核人員',
      width: '15%'
    },
    {
      title: '職位',
      width: '15%'
    },
    {
      title: '審核時間',
      width: '20%'
    },
    {
      title: '審核結果',
      width: '15%'
    },
    {
      title: '審核意見',
      width: '50%'
    },
  ]

  const SignReocordPrintTable = (props) => {
    return (
      <>
        <table className='CustomPrintTable'>
          <tr>
            {
              props.columns.map(item => {
                return (<th bgcolor='#585A5D' style={{ width: item.width}}>{item.title}</th>)
              })
            }
          </tr>
          {
            props.rowData.map(item => {
              return (
                <tr className='CustomPrintTable_keeptogether'>
                  <td className='CustomPrintTable_keeptogether'>{item.auditor_name}</td>
                  <td className='CustomPrintTable_keeptogether'>{item.auditor_type ==='上級主管' ? '上層主管' : item.auditor_type}</td>
                  <td className='CustomPrintTable_keeptogether'>{ moment.unix( (item.audit_date)/1000 ).format('YYYY/MM/DD HH:mm:ss') }</td>
                  <td className='CustomPrintTable_keeptogether'>{StampStatusObj[item.audit_status]}</td>
                  <td className='CustomPrintTable_keeptogether'>{item.audit_command}</td>
                </tr>
              )
            })
          }
        </table>
      </>
    )
  }

  const handleDownloadPDF = async () =>{
    dispatch({ type: types.INC_LOADING, payload: 1 })
    let element = document.getElementById('signRecordTemplate')
    let option = {
      margin: 1,
      filename: `${id}_${moment().format('YYYY/MM/DD hh:mm:ss')}簽核紀錄.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      pagebreak: {avoid: 'tr', mode: 'avoid-all'},
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    }

    await html2pdf().set(option).from(element).save()
    dispatch({ type: types.DEC_LOADING, payload: 1 })
  }

  // pdf template主體
  const PDfTemplate = ({printData}) => {
    let stamp = ''
    if( stampList.length > 0 && Object.keys(stampDetail).length > 0){
      let temp = []
      stampDetail['stamp_type'].forEach(ele => {
        temp.push(stampList.filter(subele => subele.lookup_code === ele)[0].description)
      })
      temp.forEach((ele, index) =>{
        if(index === 0){
          stamp = ele
        }
        else{
          stamp = stamp + ', ' + ele
        }
      })
      console.log(stamp)
    }
    return (
      <div id='signRecordTemplate'>
        <div style={{ padding: 20 }}>
          {
            printData ?
            <>
            <div style={{ borderTop: '1px solid #82C200', marginBottom: '20px', height: 10 }}></div>
            <div style={{ fontSize: '35px', color: '#82C200', fontWeight: 'bold', margin: 10, textAlign: 'center' }}>用印簽核紀錄</div>
              <Row className='CustomPrintTable_header' justify='space-between' style={{ width: '100%' }}>
                <Col span={9}>
                  <Row justify='start'>
                    <Col span={6}>單號</Col>
                    <Col span={18}>{id}</Col>
                  </Row>
                </Col>
              </Row>
              <Row className='CustomPrintTable_header' justify='space-between' style={{ width: '100%' }}>
                <Col span={9}>
                  <Row justify='start'>
                    <Col span={6}>文件名稱</Col>
                    <Col span={18}>{ Object.keys(stampDetail).length > 0 ? stampDetail['document'] : ''}</Col>
                  </Row>
                </Col>
              </Row>
              <Row className='CustomPrintTable_header' justify='space-between' style={{ width: '100%' }}>
                <Col span={9}>
                  <Row justify='start'>
                    <Col span={6}>章別</Col>
                    <Col span={18}> { stamp } </Col>
                  </Row>
                </Col>
              </Row>
              <Row justify='center'>
                <Col span={24}>
                  <SignReocordPrintTable columns={signRecordPDFColumns} rowData = {printData} />
                </Col>
              </Row>
            </> : null
          }
        </div>
      </div>
    )
  }

  const columns = [
    {
      title: '審核人員',
      dataIndex: 'auditor_name',
      align: 'center'
    },
    {
      title: '職位',
      dataIndex: 'auditor_type',
      align: 'center',
      render:(item)=>{
        if(item ==='上級主管'){
          return '上層主管'
        }else{
          return item
        }
      }
    },
    {
      title: '審核時間',
      align: 'center',
      render:(_, record) =>{
        return moment.unix( (record.audit_date)/1000 ).format('YYYY/MM/DD HH:mm:ss')
      }
    },
    {
      title: '審核結果',
      dataIndex: 'audit_status',
      align: 'center',
      render:(_, record) =>{
        return StampStatusObj[record.audit_status]
      }
    },
    {
      title: '審核意見',
      dataIndex: 'audit_command',
      align: 'center',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render:(command) =>{
        return(
          <Tooltip placement="topLeft" title={command}>
            {command}
          </Tooltip>
        )
      }
    },
  ]

  return(
    <>
      <BrowserView>
        <div style={{ overflow: 'hidden', height: 0 }}>
          {/* 匯出報表範本 */}
          <PDfTemplate printData={signoffHistory}/>
        </div>
        <Modal
          visible={signRecordModalVisible}
          onCancel={() => dispatch(setSignReocrdModalVisible(false))}
          footer={[null]}
          title='簽核紀錄'
          width={'80%'}
        >
          <Row justify='end'>
            <Col xs={8} span={5}>
              <Button disabled={signoffHistory.length === 0} onClick={() => handleDownloadPDF()} type="primary" style={{ marginBottom: '10px', width: '100%' }}> 下載簽核紀錄 </Button>
            </Col>
          </Row>
          <Table
            loading={{ spinning: isLoading !== 0, tip: 'Loading...' }}
            columns={columns}
            dataSource={signoffHistory}
          />
        </Modal>
      </BrowserView>
      <MobileView>
        <div style={{ overflow: 'hidden', height: 0 }}>
          {/* 匯出報表範本 */}
          <PDfTemplate printData={signoffHistory}/>
        </div>
        <Modal
          visible={signRecordModalVisible}
          onCancel={() => dispatch(setSignReocrdModalVisible(false))}
          footer={[null]}
          title='簽核紀錄'
          width={'80%'}
          centered
          bodyStyle={{ height: '80vh' }}
        >
          <Row justify='end'>
            <Col span={12}>
              <Button disabled={signoffHistory.length === 0} onClick={() => handleDownloadPDF()} type="primary" style={{ marginBottom: '10px', width: '100%' }}> 下載簽核紀錄 </Button>
            </Col>
          </Row>
          <Divider style={{ margin: '24px 0 8px 0' }}/>
          <div
            id="scrollableDiv"
            style={{
              height: '80%' ,
              overflowY: 'auto',
              zIndex: 1000
            }}
          >
            <InfiniteScroll
              dataLength={signoffHistory.length}
              // next={loadMoreData}
              // hasMore={total - signoffHistory.length > 0}
              scrollableTarget="scrollableDiv"
            >
              <List
                dataSource={signoffHistory}
                loading={{ spinning: isLoading !== 0, tip: 'Loading...' }}
                renderItem={(record) => {
                  return(
                    <Row gutter={[ 0, 16 ]}>
                      <Col span={12}>
                        審核人員
                      </Col>
                      <Col span={12}>
                        {record.auditor_name}
                      </Col>
                      <Col span={12}>
                        職位
                      </Col>
                      <Col span={12}>
                        {record.auditor_type}
                      </Col>
                      <Col span={12}>
                        審核時間
                      </Col>
                      <Col span={12}>
                        {moment.unix((record.audit_date)/1000 ).format('YYYY/MM/DD HH:mm:ss')}
                      </Col>
                      <Col span={12}>
                        審核結果
                      </Col>
                      <Col span={12}>
                        {StampStatusObj[record.audit_status]}
                      </Col>
                      <Col span={12}>
                        審核意見
                      </Col>
                      <Col span={12}>
                        {record.audit_command}
                      </Col>
                      <Divider style={{ margin: '0 0 8px 0' }}/>
                    </Row>
                  )
                }}
              />
            </InfiniteScroll>
          </div>
        </Modal>
      </MobileView>
    </>
  )
}