import { Breadcrumb, Button, Input, Space, Row, Col, Table, Switch, message, Modal, Divider, Select, DatePicker, Form, Checkbox, Drawer } from 'antd'
import { EyeOutlined, EditOutlined, SendOutlined } from '@ant-design/icons'
import PageTitle from '../components/PageTitle';
import PageLayout from '../components/PageLayout';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useEffect, useState } from 'react';
import InputPrefix from '../helper/InputPrefix';

export default function ContractMgtView({roleFuntion}){

  const { TextArea } = Input
  const { Option } = Select
  const { currentUser } = useOutletContext();
  const navigate = useNavigate()
  const [ drawerInfo, setDrawerInfo ] = useState({
    title: '',
    content: '',
    isVisible: false
  })

  const [ modalInfo, setModalInfo ] = useState({
    title: '',
    isVisible: false
  })

  const openEditContractDrawer = (Title, IsVisible, Content) => {
    setDrawerInfo({ title: Title, isVisible: IsVisible, content: Content })
  }

  function tempContent(){
    return(
      <div style={{ color:'black' }}>
        <Row style={{ paddingBottom: '10px' }}>
          <span style={{ paddingBottom: '5px' }}> 狀態: </span>
          <Input value={'新增'} disabled />
        </Row>
        <Row style={{ paddingBottom: '10px' }}>
          <span style={{ paddingBottom: '5px' }}> 合約編號: </span>
          <Input value={'10000'} disabled />
        </Row>
        <Row style={{ paddingBottom: '10px' }}>
          <span style={{ paddingBottom: '5px' }}> 負責業務: </span>
          <Input value={currentUser.name}  />
        </Row>
        <Row style={{ paddingBottom: '10px' }}>
          <span style={{ paddingBottom: '5px' }}> 統一編號: </span>
          <Input value={'00000'}  />
        </Row>
        <Row style={{ paddingBottom: '10px' }}>
          <span style={{ paddingBottom: '5px' }}> 公司名稱: </span>
          <Input value={'緯謙科技'}  />
        </Row>
        <Row style={{ paddingBottom: '10px' }}>
          <span style={{ paddingBottom: '5px' }}> 公司地址: </span>
          <Input value={'新北市'}  />
        </Row>
        <Row style={{ paddingBottom: '10px' }}>
          <span style={{ paddingBottom: '5px' }}> 郵遞區號: </span>
          <Input value={'0000'}  />
        </Row>
        <Row style={{ paddingBottom: '10px' }}>
          <span style={{ paddingBottom: '5px' }}> 微軟用戶名稱: </span>
          <Input value={'Ray'}  />
        </Row>
        <Row style={{ paddingBottom: '10px' }}>
          <span style={{ paddingBottom: '5px' }}> 微軟識別網域名稱: </span>
          <Input value={'a@s.com'}  />
        </Row>
        <Row style={{ paddingBottom: '10px' }}>
          <span style={{ paddingBottom: '5px' }}> 主要聯繫人姓名: </span>
          <Input value={'Ray'}  />
        </Row>
        <Row style={{ paddingBottom: '10px' }}>
          <span style={{ paddingBottom: '5px' }}> 主要聯繫人電話: </span>
          <Input value={'09118841215'}  />
        </Row>
        <Row style={{ paddingBottom: '10px' }}>
          <span style={{ paddingBottom: '5px' }}> 主要聯繫人Email: </span>
          <Input value={'a@s.com'}  />
        </Row>
        <Row style={{ paddingBottom: '10px' }}>
          <span style={{ paddingBottom: '5px' }}> 次要聯繫人姓名: </span>
          <Input value={'Ray'}  />
        </Row>
        <Row style={{ paddingBottom: '10px' }}>
          <span style={{ paddingBottom: '5px' }}> 次要聯繫人電話: </span>
          <Input value={'09118841215'}  />
        </Row>
        <Row style={{ paddingBottom: '10px' }}>
          <span style={{ paddingBottom: '5px' }}> 次要聯繫人Email: </span>
          <Input value={'a@s.com'}  />
        </Row>
      </div>
    )
  }
  
  return(
    <PageLayout spinning={false}>
      {/* 頁面目前位置 */}
      <Col span={24}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item href='/'>WiAdvance EIP</Breadcrumb.Item>
          <Breadcrumb.Item href="/ContractMgt">合約管理</Breadcrumb.Item>
          <Breadcrumb.Item > 查看合約</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      {/* 頁面標題 */}
      <PageTitle>
        查看合約
      </PageTitle>
      <Col span={24}>
        <div style={{ backgroundColor:'white', color:'black', padding:'10px 3%'}}>
          <Drawer 
            title={drawerInfo.title}
            visible={drawerInfo.isVisible}
            closable={false}
            footer={[
              <>
              <Row justify='end'>
                <Col span={6} style={{ paddingRight: '10px' }}>
                  <Button type='primary' onClick={() => setDrawerInfo({isVisible: false})}> 確認 </Button>
                </Col>
                <Col span={6}>
                  <Button onClick={() => setDrawerInfo({isVisible: false})}> 取消 </Button>
                </Col>
              </Row>
              </>
            ]}
          >
            {drawerInfo.content}
          </Drawer>
          <Modal
            title={modalInfo.title}
            visible={modalInfo.isVisible}
            footer={[
              <>
              <Row justify='end'>
                <Col span={6} style={{ paddingRight: '10px' }}>
                  <Button type='primary' onClick={() => setModalInfo({isVisible: false})}> 發送 </Button>
                </Col>
                <Col span={6}>
                  <Button onClick={() => setModalInfo({isVisible: false})}> 取消 </Button>
                </Col>
              </Row>
              </>
            ]}
          >
            備註: <TextArea rows={4}/>
          </Modal>
          <Row>
            <span style={{ alignItems: 'center', fontWeight: 'bold', fontSize: 'larger' }}> 基本訊息 </span>
            <Button icon={<EditOutlined/>} type='link' onClick={() => openEditContractDrawer('編輯基本資料', true, tempContent())}> 編輯 </Button>
          </Row>
          <Divider />
          <div style={{ padding: '0 5%' }}>
            <Row gutter={[24,0]} style={{ paddingBottom: '15px' }}>
              <Col span={8}>
                狀態: 新增
              </Col>
              <Col span={8}>
                合約編號: 10000
              </Col>
            </Row>
            {/* 公司地址 */}
            <Row gutter={[24,0]} style={{ paddingBottom: '15px' }}>
              <Col span={8}>
                負責業務: {currentUser.name}
              </Col>
              <Col span={8}>
                統一編號: 00000
              </Col>
            </Row>
            {/* 郵遞區號，微軟用戶名稱，微軟識別網域名稱 */}
            <Row gutter={[24,0]} style={{ paddingBottom: '15px' }}>
              <Col span={8}>
                公司名稱: 緯謙科技
              </Col>
              <Col span={8}>
                公司地址: 新北市
              </Col>
            </Row>
            {/* 主要聯繫人姓名，主要聯繫人電話*/}
            <Row gutter={[24,0]} style={{ paddingBottom: '15px' }}>
              <Col span={8}>
                郵遞區號: 0000
              </Col>
              <Col span={8}>
                微軟用戶名稱: Ray
              </Col>
            </Row>
            {/* 主要聯繫人Email */}
            <Row gutter={[24,0]} style={{ paddingBottom: '15px' }}>
              <Col span={8} >
                微軟識別網域名稱: a@s.com
              </Col>
            </Row>
            {/* 次要聯繫人姓名，次要聯繫人電話，次要聯繫人Email */}
            <Row gutter={[24,0]} style={{ paddingBottom: '15px' }}>
              <Col span={8}>
                主要聯繫人姓名: Ray
              </Col>
              <Col span={8}>
                主要聯繫人電話: 09118841215
              </Col>
              <Col span={8}>
                主要聯繫人Email: a@s.com
              </Col>
            </Row>
            {/* 次要聯繫人Email */}
            <Row gutter={[24,0]} style={{ paddingBottom: '15px' }}>
              <Col span={8}>
                次要聯繫人姓名: Ray
              </Col>
              <Col span={8}>
                次要聯繫人電話: 09118841215
              </Col>
              <Col span={8}>
                次要聯繫人Email: a@s.com
              </Col>
            </Row>
          </div>
          <Row >
            <span style={{ alignItems: 'center', fontWeight: 'bold', fontSize: 'larger' }}> 合約內容 </span>
            <Button icon={<EditOutlined/>} type='link' onClick={() => openEditContractDrawer('編輯合約內容', true, tempContent())}> 編輯 </Button>
          </Row>
          <Divider />
          <div style={{ padding: '0 5%' }}>
            <Row gutter={[24,0]} style={{ paddingBottom: '15px' }}>
              <Col span={8}>
                服務類型: EA
              </Col>
              <Col span={8}>
                訂閱狀態: 新增
              </Col>
            </Row>
            {/* 預計啟用至結束日期，op實際啟用日期，op產品到期日期 */}
            <Row gutter={[24,0]} style={{ paddingBottom: '15px' }}>
              <Col span={8}>
                產品品項: 這裡是產品品項的值
              </Col>
              <Col span={8}>
                訂閱狀態: 新增
              </Col>
            </Row>
            {/* 上單單號，發票號碼，合約書/報價單 */}
            <Row gutter={[24,0]} style={{ paddingBottom: '15px' }}>
              <Col span={8} >
                預計啟用至結束日期: 2022/08/23 ~ 2022/08/30 (分權限)
              </Col>
              <Col span={8} style={{ color: 'red' }}>
                實際啟用至結束日期: 2022/08/23 ~ 2022/08/30
              </Col>
              <Col span={8} >
                <span>
                  計費模式:
                </span>
                <Select style={{ width: '25%', paddingLeft: '10px' }}>
                  <Option value='month'> 月 </Option>
                  <Option value='year'> 年 </Option>
                </Select>
              </Col>
            </Row>
            <Row gutter={[24,0]} style={{ paddingBottom: '15px' }}>
              <Col span={4}>
                <TextArea rows={4} />
              </Col>
            </Row>
          </div>
          <Row >
            <span style={{ alignItems: 'center', fontWeight: 'bold', fontSize: 'larger' }}> 其他 </span>
            <Button icon={<EditOutlined/>} type='link' onClick={() => openEditContractDrawer('編輯其他', true, tempContent())}> 編輯 </Button>
          </Row>
          <Divider />
          <div style={{ padding: '0 5%' }}>
            <Row gutter={[24,0]} style={{ paddingBottom: '15px' }}>
              <Col span={8}>
              上單單號: 000000000
              </Col>
              <Col span={8}>
                發票號碼: 000000000
              </Col>
            </Row>
            {/* 緊急開通，MCA，付款條件 */}
            <Row gutter={[24,0]} style={{ paddingBottom: '15px' }}>
              <Col span={8} style={{ alignSelf: 'center' }}>
                合約書/報價單: 
              </Col>
              <Col span={10}>
                <span style={{ paddingRight: '10px' }}> 緊急開通: <Checkbox defaultChecked disabled></Checkbox> </span>
                <Button style={{ width:'55%' }} icon={<EyeOutlined />}> 查看緊急開通主管同意信 </Button>
              </Col>
            </Row>
            <Row gutter={[24,0]} style={{ paddingBottom: '15px' }}>
              <Col span={8}>
                MCA : 已簽署
              </Col>
              <Col span={8}>
                付款條件: 匯款 
              </Col>
            </Row>
            <Row gutter={[24,0]} style={{ paddingBottom: '15px' }}>
              <Col span={4}>
                <Button style={{ backgroundColor: '#40a9ff' }} icon={<EyeOutlined/>} onClick={() => setModalInfo({ title: '發送到期通知信', isVisible: true })}> 到期通知信 </Button>
              </Col>
              <Col span={4}>
                <Button style={{ backgroundColor: '#1890ff' }} icon={<EyeOutlined/>} onClick={() => setModalInfo({ title: '發送停用通知信', isVisible: true })}> 停用通知信 </Button>
              </Col>
            </Row>
          </div>
          <Row>
            <Col span={20}/>
            <Col span={3}>
              <Button style={{ backgroundColor: 'red' }} onClick={() =>navigate('/ContractMgt')}> 返回 </Button>
            </Col>
          </Row>
        </div>
      </Col>
    </PageLayout>
  )
}