import ReactDOM from 'react-dom/client';
import App from './App';
import './scss/index.scss';
import 'antd/dist/antd.css'
import { msalInstance } from "./msalInstance"
import { MsalProvider } from "@azure/msal-react"
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './redux/reducers/rootReducer';
import zhTW from 'antd/es/locale-provider/zh_TW'
import moment from 'moment'
import 'moment/locale/zh-tw'
import { ConfigProvider } from 'antd';
moment.locale('zh-tw')


const root = ReactDOM.createRoot(document.getElementById('root'));

const store = configureStore({
  reducer: rootReducer,
})

root.render(
  <ConfigProvider locale={zhTW}>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </Provider>
  </ConfigProvider>
);
