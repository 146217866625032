// 浩哥的Auth
import StampAuth from "../api/StampRequest"; 
// A羅的Auth
import stamp from '../api/stampRequest1'
import types from "./types";

//號哥說這個用不到,僅供觀賞
export const getAllLookupCode = () => async (dispatch) =>{
  const resp = await StampAuth(dispatch).get('api/LookupCode/GetAllLookupCode')
  return resp.data
}

//sideMenu number
export const getMenuCount = () => async (dispatch) =>{
  let menuItemMap = {
    "application": "E04F01",
    "history": "E04F02",
    "pending_audit": "E04F03",
    "audited": "E04F04",
    "pending_upload": "E04F05",
    "pending_processed": "E04F06"
  }

  let result = {}
  const resp = await stamp(dispatch, true).get('Stamp/GetMenus')
  resp.data.data.forEach(ele =>{
    Object.assign(result, { [menuItemMap[ele.menu]]: ele.count })
  })
  dispatch({ type: types.MENU_COUNTS, payload: result })
}

//依照lookup_type, lookup_code發送
// lookup_type : stampStatus,
//               stampType
// lookup_code : pendingAudit, 審核中
//               pendingStamp, 用印中
//               pendingUpload, 上傳中
//               pendingProcessed, 處理中
//               reject, 駁回
//               cancel, 取消申請
//               done, 已結案
export const getLookupCodeList = ( lookuptype, lookupcode ) => async (dispatch) =>{
  const resp = await StampAuth(dispatch).post('api/LookupCode/GetLookupCode', { lookup_code: lookupcode, lookup_type: lookuptype })
  let stmapNo = []
  let result = []

  if(resp){
    resp.data.data.forEach( ele =>{
      stmapNo.push(ele.lookup_value)
    })
    stmapNo.sort( ( a, b ) => a - b)
    
    stmapNo.forEach( no =>{
      resp.data.data.forEach( ele =>{
        if( no === ele['lookup_value'] ){
          result.push(ele)
        }
      })
    })
  }

  dispatch({ type:types.GET_STAMP, payload: result })
  return resp.data.data
}

//取得我的申請
export const queryApplication = (data) => async (dispatch) =>{
  const resp = await StampAuth(dispatch).post('api/Stamp/QueryApplication', data)
  dispatch({ type: types.MY_APPLICATION_LIST, payload: resp.data })
  return resp.data
}

//取得歷史單據
export const queryHistory = (data) => async (dispatch) =>{
  const resp = await StampAuth(dispatch).post('api/Stamp/QueryHistory', data)
  dispatch({ type:types.STAMP_HISTORY_LIST, payload: resp.data })
}

//取得待審核
export const queryPendingAudit = (data) => async (dispatch) =>{
  const resp = await StampAuth(dispatch).post('api/Stamp/QueryPending', data)
  dispatch({ type: types.PENDING_AUDIT_LIST, payload: resp.data })
}

//取得已審核
export const queryAudited = (data) => async (dispatch) =>{
  const resp = await StampAuth(dispatch).post('api/Stamp/QueryAudited', data)
  dispatch({ type: types.AUDITED_LIST, payload: resp.data })
}

//上面全部等Alex修正後統一更改Request方式
// 設定搜尋值
export const setGlobalSearchParams = (data) =>{
  return ({ type: types.SET_GLOBAL_PARAMS, payload: data })
}

// 取得用印清單
export const queryStampTableList = (data) => async (dispatch, getstate) =>{

  let cr_dateArr = []
  let result = []
  // let data = getstate().Stamp.globalParams

  if(Object.keys(data).length !== 0){
    const resp = await stamp(dispatch).post('Stamp/Query', data)
    if(resp){
      dispatch({ type: types.TABLE_PROPS, payload: resp.data.pager_info }) 
      resp.data.data.forEach( ele =>{
        cr_dateArr.push(ele.cr_date)
      })
      cr_dateArr.sort( ( a, b ) => b - a)
      
      cr_dateArr.forEach( date =>{
        resp.data.data.forEach( ele =>{
          if( date === ele['cr_date'] ){
            result.push(ele)
          }
        })
      })
    }
    dispatch({ type: types.GET_STAMP_LIST, payload: result })
  }
  return result
}

// 取得已審核
export const getAudited = (data) => async (dispatch) =>{
  let cr_dateArr = []
  let result = []
  // let data = getstate().Stamp.globalParams

  if(Object.keys(data).length !== 0){
    const resp = await stamp(dispatch).post('Stamp/Query/IsAudit', data)
    if(resp){
      dispatch({ type: types.TABLE_PROPS, payload: resp.data.pager_info }) 
      resp.data.data.forEach( ele =>{
        cr_dateArr.push(ele.cr_date)
      })
      cr_dateArr.sort( ( a, b ) => b - a)
      
      cr_dateArr.forEach( date =>{
        resp.data.data.forEach( ele =>{
          if( date === ele['cr_date'] ){
            result.push(ele)
          }
        })
      })
    }
    dispatch({ type: types.GET_STAMP_LIST, payload: result })
  }
  return result
}

// 取得簽核紀錄
export const getStampQueryLog = (stampNo) => async (dispatch) => {
  let cr_dateArr = []
  let result = []
  const resp = await stamp(dispatch).get(`Stamp/QueryLog/${stampNo}`)

  if(resp){
    resp.data.data.forEach( ele =>{
      cr_dateArr.push(ele.audit_date)
    })
    cr_dateArr.sort( ( a, b ) => b - a)
    
    cr_dateArr.forEach( date =>{
      resp.data.data.forEach( ele =>{
        if( date === ele['audit_date'] ){
          result.push(ele)
        }
      })
    })
  }
  return result
}

// 取得該筆用印資料
export const getStampDetail = (stampNo) => async (dispatch) =>{
  const resp = await stamp(dispatch).get(`Stamp/GetDetail/${stampNo}`)
  return resp.data.data[0]
}

// 新增用印
export const addStamp = (data) => async (dispatch) =>{
  const resp = await stamp(dispatch).post('Stamp/AddStamp', data)
  return resp.data
}

// 審核者同意用印
export const auditerAgree = (data) => async (dispatch) =>{
  const resp = await stamp(dispatch).post('Stamp/Agree', data)
  return resp.data
}

// 審核者駁回用印
export const auditerReject = (data) => async (dispatch) =>{
  const resp = await stamp(dispatch).post('Stamp/Reject', data)
  return resp.data
}

// 抽回該筆用印
export const withdrawStamp = (stampNo) => async (dispatch) =>{
  const resp = await stamp(dispatch).get(`Stamp/Cancel/${stampNo}`)
  return resp.data
}

// 印章保管者同意用印
export const stampOwnerAgree = (data) => async (dispatch) =>{
  const resp = await stamp(dispatch).post(`Stamp/StampAgree`, data)
  return resp.data
}

// 印章保管者駁回用印
export const stmapOwnerReject = (data) => async (dispatch) =>{
  const resp = await stamp(dispatch).post('Stamp/StampReject', data)
  return resp.data
}

// 總經理同意
export const CEOAgree = (data) => async (dispatch) =>{
  const resp = await stamp(dispatch).post('Stamp/CEOAgree', data)
  return resp.data
}

// 待上傳留存檔案
export const pendingDone = (data) => async (dispatch) =>{
  const resp = await stamp(dispatch).post('Stamp/Add/PendingDone', data)
  return resp.data
}

//待處理同意
export const processedAgree = (data) => async (dispatch) =>{
  const resp = await stamp(dispatch).post('Stamp/ProcessedAgree', data)
  return resp.data
}

//待處理駁回
export const processedReject = (data) => async (dispatch) =>{
  const resp = await stamp(dispatch).post('Stamp/ProcessedReject', data)
  return resp.data
}

//新竹章API
export const hsinchuInvoice = (data) => async (dispatch) =>{
  const resp = await stamp(dispatch).post('Stamp/AddBillStamp', data)
  return resp.data
}