import requests from "./customRequests/requests";

import { message } from "antd";

const mail = localStorage.getItem("mockUser") ? `?mail=${localStorage.getItem("mockUser")}` : ""

export const getAdvanceReceiptReport = async (params) => {
  const resp = await requests().get(`api/Report/BCAdvanceReceiptReport`,{params});
  return resp?.data;
};

export const BCOverDueARReport = async (params) => {
  const resp = await requests().get(`api/Report/BCOverDueARReport`,{params});
  return resp?.data;
};


export const BCOverDueARExcel = async (params) => {
  const resp = await requests().get(`api/Report/BCOverDueARReport/Excel`,{
    "responseType": "arraybuffer",
    "params":params
  });
  return resp?.data;
};