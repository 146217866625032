
import React from 'react';
import { useNavigate } from "react-router-dom";
import { Breadcrumb, Button, Input, Row, Col, Form, Checkbox } from 'antd';
import PageTitle from '../components/PageTitle';
import FormLayout from '../components/FormLayout';
import PageLayout from '../components/PageLayout';
import InputPrefix from '../helper/InputPrefix';

export default function AccountUpdate() {
  const navigate = useNavigate();
  const options = [
    { label: `Role1`, value: 'Id1' },
    { label: `Role2`, value: 'Id2' },
    { label: `Role3`, value: 'Id3' },
  ]
  return (
    <PageLayout spinning={false}>
      <Col span={24}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item href='/'>WiAdvance EIP</Breadcrumb.Item>
          <Breadcrumb.Item href='/'>帳號管理</Breadcrumb.Item>
          <Breadcrumb.Item >權限資料</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <PageTitle>
        權限資料
      </PageTitle>
      <Col span={24}>  
      <Form
        name="basic"
        // initialValues={{ remember: true }}
        onFinish={(value)=>{console.log(value)}}
        autoComplete="off"
      >
      <FormLayout>
        <Row align='top'>
          <InputPrefix label={'設定角色權限'} require={true}/>
          <Col flex="auto">
            <Form.Item
              name="role"
            >
              <Checkbox.Group options={options}/>
            </Form.Item>
          </Col>
        </Row>
        <Row align='top'>
          <InputPrefix label={'修改紀錄'} require={true}/>
          <Col flex="auto">
            <Form.Item
              name="record"
              rules={[{ 
                  required: true,
                  message: '請輸入修改紀錄' 
              }]}
              >
                <Input.TextArea placeholder="請輸入修改紀錄" rows={4}/>
            </Form.Item>
          </Col>
        </Row>
      </FormLayout>
      <Row align='bottom' style={{marginTop:'30px'}}>
          <Col>
            <Form.Item>
              <Button style={{width:'150px', marginRight:'10px'}} type="primary" htmlType="submit">確定</Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button style={{width:'150px'}} onClick={()=>navigate('/account')}>取消</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      </Col>
    </PageLayout>
  )
}
