import types from "./types"

//簽核紀錄Modal Visible
export const setSignReocrdModalVisible = (value) =>{
  return({
    type: types.SET_SIGN_RECORD_MODAL_VISIBLE,
    payload: value
  })
}

//簽核Modal Visible
export const setSignAndRejectModalVisible = (value) =>{
  return({
    type: types.SET_SIGN_AND_REJECT_MODAL_VISIBLE,
    payload: value
  })
}

//查看檔案Modal Visible
export const setViewFileModalVisible = (value) =>{
  return({
    type: types.VIEW_FILE_MODAL_VISIBLE, 
    payload: value
  })
}

// 查看檔案url
export const viewFiledata = (arr) =>{
  return({
    type: types.VIEW_FILE_DATA,
    payload: arr
  })
}

//重送資料帶入
export const setReApplyData = (obj) =>{
  return({
    type: types.RE_APPLY_DATA,
    payload: obj
  })
}

//Table 頁數、每頁大小調整
export const setPageProps = (data) =>{
  return({
    type:types.PAGE_PROPS,
    payload: data
  })
}

//控制手機板Menu開闔
export const controlMobileMenuOpen = (bool) =>{
  return({
    type:types.MOBILE_MENU_OPEN,
    payload: bool
  })
}

//儲存ms Token
export const setToken = (value) =>{
  return({
    type: types.SET_TOKEN,
    payload: value
  })
}