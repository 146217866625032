
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Breadcrumb, Button, Row, Col, Form, Input, Switch } from 'antd';
import PageTitle from '../components/PageTitle';
import FormLayout from '../components/FormLayout';
import PageLayout from '../components/PageLayout';
import InputPrefix from '../helper/InputPrefix';

export default function SystemUpdate() {
  const navigate = useNavigate();
  return (
    <PageLayout spinning={false}>
      <Col span={24}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item href='/'>WiAdvance EIP</Breadcrumb.Item>
          <Breadcrumb.Item href='/'>系統參數管理</Breadcrumb.Item>
          <Breadcrumb.Item >編輯系統參數</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <PageTitle>
        編輯系統參數
      </PageTitle>
      <Col span={24}>  
      <Form
        name="basic"
        // initialValues={{ remember: true }}
        onFinish={(value)=>{console.log(value)}}
        autoComplete="off"
      >
      <FormLayout>
        <Row justify='space-between'>
            <Col span={12}>
              <Row align='top'>
                <InputPrefix label={'系統參數類型'} require={true}/>
                <Col flex="auto">
                  <Form.Item
                    name="type"
                    rules={[{ 
                        required: true,
                        message: '請輸入系統參數類型' 
                    }]}
                  >
                      <Input placeholder="請輸入系統參數類型" size={'large'} bordered={false} style={{borderBottom: '1px solid #ddd'}}/>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row align='top'>
                <InputPrefix label={'系統參數'} require={true}/>
                <Col flex="auto">
                  <Form.Item
                    name="parameter"
                    rules={[{ 
                        required: true,
                        message: '請輸入系統參數' 
                    }]}
                  >
                      <Input placeholder="請輸入系統參數" size={'large'} bordered={false} style={{borderBottom: '1px solid #ddd'}}/>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
        </Row>
        <Row align='top'>
          <InputPrefix label={'參數說明'} require={true}/>
          <Col flex="auto">
            <Form.Item
              name="caption"
              rules={[{ 
                  required: true,
                  message: '請輸入參數說明' 
              }]}
              >
                <Input placeholder="請輸入參數說明" size={'large'} bordered={false} style={{borderBottom: '1px solid #ddd'}}/>
            </Form.Item>
          </Col>
        </Row>
        <Row justify='space-between'>
            <Col span={12}>
              <Row align='top'>
                <InputPrefix label={'順序'} require={true}/>
                <Col flex="auto">
                  <Form.Item
                    name="order"
                    rules={[{ 
                        required: true,
                        message: '請輸入順序' 
                    }]}
                  >
                      <Input placeholder="請輸入順序" size={'large'} bordered={false} style={{borderBottom: '1px solid #ddd'}}/>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row align='top'>
                <InputPrefix label={'刪除狀態'} require={true}/>
                <Col flex="auto">
                  <Form.Item
                    name="status"
                    >
                      <Switch checkedChildren="啟用" unCheckedChildren="停用" checked={true}  />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
        </Row>
        <Row align='top'>
          <InputPrefix label={'備用註記一'} require={true}/>
          <Col flex="auto">
            <Form.Item
              name="mark1"
              rules={[{ 
                  required: true,
                  message: '請輸入備用註記一' 
              }]}
              >
                <Input placeholder="請輸入備用註記一" size={'large'} bordered={false} style={{borderBottom: '1px solid #ddd'}}/>
            </Form.Item>
          </Col>
        </Row>
        <Row align='top'>
          <InputPrefix label={'備用註記二'} require={true}/>
          <Col flex="auto">
            <Form.Item
              name="mark2"
              rules={[{ 
                  required: true,
                  message: '請輸入備用註記二' 
              }]}
              >
                <Input placeholder="請輸入備用註記二" size={'large'} bordered={false} style={{borderBottom: '1px solid #ddd'}}/>
            </Form.Item>
          </Col>
        </Row>
        <Row align='top'>
          <InputPrefix label={'備用註記三'} require={true}/>
          <Col flex="auto">
            <Form.Item
              name="mark3"
              rules={[{ 
                  required: true,
                  message: '請輸入備用註記三' 
              }]}
              >
                <Input placeholder="請輸入備用註記三" size={'large'} bordered={false} style={{borderBottom: '1px solid #ddd'}}/>
            </Form.Item>
          </Col>
        </Row>
        <Row align='top'>
          <InputPrefix label={'備註'} require={true}/>
          <Col flex="auto">
            <Form.Item
              name="remark"
              rules={[{ 
                  required: true,
                  message: '請輸入備註' 
              }]}
              >
                <Input.TextArea placeholder="請輸入備註" rows={4}/>
            </Form.Item>
          </Col>
        </Row>
      </FormLayout>
      <Row align='bottom' style={{marginTop:'30px'}}>
          <Col>
            <Form.Item>
              <Button style={{width:'150px', marginRight:'10px'}} type="primary" htmlType="submit">確定</Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button style={{width:'150px'}} onClick={()=>navigate('/system')}>取消</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      </Col>
    </PageLayout>
  )
}
