const types ={
  GET_MANAGEER_LIST: 'GET_MANAGEER_LIST',
  GET_ALL_WIADVANCE_USERS: 'GET_ALL_WIADVANCE_USERS',
  SET_SIGN_RECORD_MODAL_VISIBLE: 'SET_SIGN_RECORD_MODAL_VISIBLE',
  SET_SIGN_AND_REJECT_MODAL_VISIBLE: 'SET_SIGN_AND_REJECT_MODAL_VISIBLE',
  RE_APPLY_DATA: 'RE_APPLY_DATA',
  INC_LOADING: 'INC_LOADING',
  DEC_LOADING: 'DEC_LOADING',
  MY_APPLICATION_LIST : 'MY_APPLICATION_LIST',
  STAMP_HISTORY_LIST : 'STAMP_HISTORY_LIST',
  PENDING_AUDIT_LIST : 'PENDING_AUDIT_LIST',
  AUDITED_LIST : 'AUDITED_LIST',
  MENU_COUNTS: 'MENU_COUNTS',
  SET_OPEN_MENU_ITEMS_KEY: 'SET_OPEN_MENU_ITEMS_KEY',
  GET_STAMP: 'GET_STAMP',
  GET_STAMP_LIST: 'GET_STAMP_LIST',
  TABLE_PROPS : 'TABLE_PROPS',
  VIEW_FILE_MODAL_VISIBLE: 'VIEW_FILE_MODAL_VISIBLE',
  VIEW_FILE_DATA: 'VIEW_FILE_DATA',
  SET_GLOBAL_PARAMS: 'SET_GLOBAL_PARAMS',
  PAGE_PROPS: 'PAGE_PROPS',
  MENU_ITEMS_KEY: 'MENU_ITEMS_KEY',
  MOBILE_MENU_OPEN: 'MOBILE_MENU_OPEN',
  SET_TOKEN: 'SET_TOKEN'
}

export default types