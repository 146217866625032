
import React, {useEffect,useState} from 'react'
import { useOutletContext, useNavigate } from "react-router-dom";
import { Breadcrumb, Button, Input, Dropdown, Menu, Space, Row, Col, Table, Switch, message, Modal, Divider } from 'antd'
import { SearchOutlined, CaretDownOutlined, AlignLeftOutlined, PlusCircleOutlined} from '@ant-design/icons';
import PageTitle from '../components/PageTitle';
import PageSearch from '../components/PageSearch';
import PageLayout from '../components/PageLayout';

const { Search } = Input;

export default function System({roleFunction}) {
  const navigate = useNavigate();
  const [data, setData] = useState([
    {
      'id':'1',
      'type':'accountType',
      'parameters':'admin',
      'caption':'tenant主帳號',
      'is_system_use':'N',
      'action':true
    },
    {
      'id':'2',
      'type':'accountType',
      'parameters':'user',
      'caption':'tenant子帳號',
      'is_system_use':'Y',
      'action':false
    },
    {
      'id':'3',
      'type':'tenantStatus',
      'parameters':'assignment',
      'caption':'待分配',
      'is_system_use':'N',
      'action':true
    },
    {
      'id':'4',
      'type':'tenantStatus',
      'parameters':'inReview',
      'caption':'審核中',
      'is_system_use':'N',
      'action':true
    },
    {
      'id':'4',
      'type':'tenantStatus',
      'parameters':'reject',
      'caption':'審核不過',
      'is_system_use':'Y',
      'action':true
    }
  ])
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false)
  const columns = [
    {
      title: `排序`,
      dataIndex: 'id',
      key: 'id',
      align:'center'
    },
    {
      title: `系統參數類型`,
      dataIndex: 'type',
      key: 'type',
      align:'left'
    },
    {
      title: `系統參數`,
      dataIndex: 'parameters',
      key: 'parameters'
    },
    {
      title: `參數說明`,
      dataIndex: 'caption',
      key: 'caption'
    },
    {
      title: ``,
      dataIndex: 'action',
      key: 'action',
      width: '200px',
      render: (item,record)=>{
        return <Row justify='space-around'>
          {record.is_system_use ==='Y' ? 
          <a onClick={()=>navigate(`/system/view`)}>檢視</a>
          :<a onClick={()=>navigate(`/system/update`)}>編輯</a>
        }
          
          <Switch checkedChildren="啟用" unCheckedChildren="停用" checked={item}  />
        </Row>

      },
      align:'center'
    }
  ];
  const menuChoice = (
    <Menu>
      <Menu.Item key="0">
        <a >
          全部
        </a>
      </Menu.Item>
      <Menu.Item key="1">
        <a >
          accountType
        </a>
      </Menu.Item>
      <Menu.Item key="2">
        <a >
          tenantStatus
        </a>
      </Menu.Item>
      <Menu.Item key="3">
        <a >
          addressType
        </a>
      </Menu.Item>
      <Menu.Item key="4">
        <a >
          contactType
        </a>
      </Menu.Item>
    </Menu>
  );
  const { currentUser } = useOutletContext();
  console.log(currentUser,roleFunction)
  return (
    <PageLayout spinning={false}>
      <Col span={24}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item href='/'>WiAdvance EIP</Breadcrumb.Item>
          <Breadcrumb.Item >系統參數管理</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <PageTitle>
        系統參數管理
      </PageTitle>
      <PageSearch>
        <Row justify="start" style={{margin:'10px 0px'}}>
            <Col flex="100px" style={{margin: 'auto 10px'}}>
              <Dropdown overlay={menuChoice} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>
                  <Space style={{'color':'#7C7C7C'}}>
                    <AlignLeftOutlined />
                      全部
                    <CaretDownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </Col>
            <Col flex="auto">
              <Search
                style={{
                width:'100%'
                }}
                enterButton={'查尋'}
                allowClear
                size="large"
                placeholder={'查尋系統參數'}
                prefix={<SearchOutlined/>}
              />
            </Col>
            <Col flex="150px" style={{margin: 'auto 10px'}}>
              <Button style={{height:'40.14px'}}>清除</Button>
            </Col>
        </Row>

      </PageSearch>
      <Col span={24}>
        <Row justify="space-between" style={{margin: '5px 10px'}}>
          <Col flex="150px">
            <Button 
              type={'primary'} 
              style={{height:'40.14px'}}
              onClick={()=>navigate('/system/add')}
            >
              新增<PlusCircleOutlined />
            </Button>
          </Col>
          <Col flex="auto">
            <div style={{'textAlign':'end', 'margin':'8px', 'color':'#7C7C7C'}}>
              共有{data? data.length : 0}筆資料，每頁顯示10<CaretDownOutlined />筆
            </div>
          </Col>
        </Row>
      </Col>
      {/* <PageSearch>
      <Row justify="space-between" style={{margin: '20px 10px'}}>
        <Col flex="150px">
          <Button 
            type={'primary'} 
            style={{height:'40.14px'}}
            onClick={()=>navigate('/account/add')}
          >
            新增<PlusCircleOutlined />
          </Button>
        </Col>
        <Col flex="auto">
          <div style={{'textAlign':'end', 'margin':'8px', 'color':'#7C7C7C'}}>
            共有{data? data.length : 0}筆資料，每頁顯示10<CaretDownOutlined />筆
          </div>
        </Col>
      </Row>
      </PageSearch> */}
      <Col span={24}
      style={{
        margin:'10px 0px',
      }}
      >
        <Table 
          columns = {columns} 
          dataSource = {data} 
        />
      </Col>
    </PageLayout>
  )
}
