/* eslint-disable no-loop-func */
import React, {useEffect,useState} from 'react'
import { useOutletContext, useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, Button, Input, Upload, Popconfirm, Row, Col, Table, Switch, message, Modal, Divider, Select, DatePicker, Checkbox, Form, Radio, AutoComplete, notification } from 'antd'
import { UploadOutlined, PlusOutlined, MinusCircleOutlined, EyeOutlined, SendOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../components/PageTitle';
import PageLayout from '../components/PageLayout';
import InputPrefix from '../helper/InputPrefix';
import moment from 'moment';
import SignRecordModal from '../components/SignRecordModal'
import { setSignReocrdModalVisible, setViewFileModalVisible, viewFiledata } from '../redux/action/Components';
import { getStampDetail, pendingDone, getMenuCount } from '../redux/action/Stamp';
import types from '../redux/action/types';
import { acquireAccessToken } from '../msalInstance';
import axios from 'axios';
import mimeTypeObj from '../helper/mimeTypeObj';
import ViewFileModal from '../components/ViewFileModal';
import { BrowserView, MobileView } from 'react-device-detect';

export default function StampTempUpload () {

	const { TextArea } = Input
	const dispatch = useDispatch()
	let { id } = useParams()
	const { currentUser } = useOutletContext();
	const [form] = Form.useForm()
	const navigate  = useNavigate()
	const [ fileList, setFileList ] = useState([])
	const [ stampInfo, setStampInfo ] = useState([])
	const [ uploadedList, setUploadedList ] = useState([])
	const [ applicant, setApplicant ] = useState('')
  const stampList = useSelector(state => state.Stamp.stampList)
	const isLoading = useSelector(state => state.globalSetting.isLoading)

	//初始設定
	useEffect(() =>{
		dispatch(getStampDetail(id)).then(res =>{
			if(res['stamp_status'] === 'done'){
				notification.error({
					message: '錯誤',
					description: '此單據已結案!',
					duration: 6
				})
				navigate('/Stamp/StampApplication')
			}
			setApplicant(res['applicant_name'])
			setStampInfo(res)
			res['cr_date'] = moment.unix(res['cr_date']/1000).format('YYYY/MM/DD hh:mm:ss')
			if(res['file']){
				res['file'].forEach( ele =>{
					let type = ''
					let tempUrl = ele
					ele = decodeURI(ele).replace( /\+/g, ' ' )
					ele = ele.split('/')
					ele = ele[ele.length-1]
					type = ele.slice(-3)
					setFileList(prev => [ ...prev, { url: tempUrl, name: ele, type: mimeTypeObj[type] }])
				})
			}
			form.setFieldsValue(res)
		})
	},[])

	//上傳檔案限制
	const uploadFileProps = {
		beforeUpload: (file) =>{
			switch(file.type){
				case 'application/pdf':
					return null
				default:
					notification.error({
						message:'錯誤',
						description: `檔案格式錯誤`
					})
					return Upload.LIST_IGNORE
			}
		}
	}

	//打開檔案瀏覽Modal
	const openViewFileModal = () =>{
		dispatch(viewFiledata(fileList))
		dispatch(setViewFileModalVisible(true))
	}

	// 上傳動作
	const fileUpload = async (option) =>{
		const { file } = option
		const accessToken = await acquireAccessToken()
		const formData = new FormData()
		formData.append('file', file)

		dispatch({ type: types.INC_LOADING,payload: 1 })
		const resp = await axios({
			method: 'post',
			headers: { Authorization: accessToken, 'Content-Type': 'multipart/form-data' },
			url: `https://eip-portal-api.azurewebsites.net/Upload/UploadFile`,
			data: formData
		}).then((resp) =>{
			dispatch({ type: types.DEC_LOADING,payload: 1 })
			return resp
		})
		if(resp.data.code === 200){
			setUploadedList( prev => [ ...prev, { name: file.name, url: resp.data.data }] )
			notification.success({
				message: '成功',
				description: '上傳成功'
			})
		}
		else{
			notification.error({
				message: '失敗',
				description: '上傳失敗'
			})
		}
	}

	// 刪除已上傳檔案
	const removeUploadedList = (props) =>{
		let result = []
		uploadedList.map( ele =>{
			if( ele['uid'] !== props['uid'] ){
				result.push(ele)
			}
		})
		setUploadedList(result)
	}

	const onConfirmRetain = () =>{
		form.validateFields().then(res =>{
			if(!uploadedList.length){
				notification.error({
					message: '錯誤',
					description: '請上傳檔案!'
				})
			}
			else{
				let result = {
					stamp_no: '',
					upload_file_url: [],
					agreeList: ''
				}
				let stampOwnerList = []
				result.stamp_no = res['stamp_no']
				uploadedList.map(ele => {
					return result.upload_file_url.push(ele.url)
				})
				stampList.forEach(stamp =>{
					if(stampInfo.stamp_type.indexOf(stamp.lookup_code) > -1){
						if(stampOwnerList.indexOf(stamp.text1) === -1){
							stampOwnerList.push(stamp.text1)
						}
					}
				})
				let stampstr = stampOwnerList.join()
				result['agreeList'] = stampstr

				dispatch(getStampDetail(id)).then(detailResp =>{
					if( detailResp['stamp_status'] !== "pendingUpload" && detailResp['applicant_mail'].toLowerCase() !== currentUser['email'].toLowerCase() ){
						notification.error({
							message: '失敗',
							description: detailResp.messages.tw
						})
					}
					else{
						dispatch(pendingDone(result)).then(res =>{
							if(res && res.code === 200){
								dispatch(getMenuCount())
								notification.success({
									message: '成功',
									description: '留存成功'
								})
								navigate('/Stamp/StampApplication')
							}
							else{
								notification.error({
									message: '失敗',
									description: res.meesage
								})
							}
						})
					}
				})
			}
		})
	}

	return(
		<PageLayout spinning={ isLoading !== 0 ? true : false }>
			<BrowserView>
				{/* 頁面目前位置 */}  
				<Col span={24}>
					<Breadcrumb separator=">">
						<Breadcrumb.Item href='/'>WiAdvance EIP</Breadcrumb.Item>
						<Breadcrumb.Item href="/Stamp/StampApplication">用印申請</Breadcrumb.Item>
						<Breadcrumb.Item href="/Stamp/StampPendingUpload">待上傳</Breadcrumb.Item>
						<Breadcrumb.Item > 申請者上傳檔案 </Breadcrumb.Item>
					</Breadcrumb>
				</Col>
				{/* 頁面標題 */}
				<PageTitle>
					申請者上傳檔案
				</PageTitle>
				<Col span={24}>
					<div style={{ backgroundColor:'white'}}>
						<Form
							form={form}
							autoComplete={false}
							style={{ paddingTop: '10px' }}
						>
							{/* 申請單號 */}
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'申請單號'} />
								</Col>  
								<Col span={6} >
									<Form.Item
										name='stamp_no'
									>
										<Input bordered={false}  disabled />
									</Form.Item>
								</Col>
							</Row>
							{/* 申請人，被代理人申請 */}
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'申請人'} />
								</Col>  
								<Col span={6} >
									<Form.Item
										name='applicant_name'
									>
										<Input bordered={false} disabled/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										name='is_agent'
										valuePropName="checked"
										style={{ alignItems: 'center' }}
									>
										<Row>
											<Checkbox checked={form.getFieldValue('is_agent') === 'Y' ? true : false} disabled > 被代理人 </Checkbox>
												<Form.Item
													name='agent_name'
													hidden={ form.getFieldValue('is_agent') === 'N' }
													style={{ width: '80%', marginBottom: '0' }}
												>
													<Input disabled style={{ width: '80%' }} bordered={false} /> 
											</Form.Item> 
										</Row>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'簽核印信'} />
								</Col>  
								<Col span={6} >
									<Form.Item
										name='stamp_type'
									>
										<Checkbox.Group style={{ width: '100%' }} disabled >
											<Row gutter={[0, 8]}>
											{
												stampList.map( item =>{
													return(
														<>
															<Col span={12}>
																<Checkbox value={item.lookup_code}> {item.description} </Checkbox>
															</Col>
														</>
													)
												})
											}
											</Row>
										</Checkbox.Group>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'申請日期'} />
								</Col>  
								<Col span={6} >
									<Form.Item
										name='cr_date'
									>
										<Input bordered={false} disabled/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'文件名稱'} />
								</Col>
								<Col span={6} >
									<Form.Item
										name='document'
									>
										<TextArea rows={4} maxLength={50} showCount disabled/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'內容簡述'} />
								</Col>  
								<Col span={6} >
									<Form.Item
										name='description'
									>
										<TextArea rows={4} maxLength={50} showCount disabled/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'檔案'} />
								</Col>  
								<Col span={6} >
									<Form.Item>
										<Button onClick={() => openViewFileModal()}> 查看檔案 </Button>
									</Form.Item>
								</Col>
							</Row>
							{
								applicant === currentUser['name'] ?
								<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
									<Col span={5} />
									<Col span={6} >
									<Upload
										name='uploadContract'
										{...uploadFileProps}
										fileList={uploadedList}
										customRequest={fileUpload}
										onRemove={(props) => removeUploadedList(props)}
										accept='application/pdf'
									> 
										<Button icon={<UploadOutlined />} type='primary'> 上傳留存檔案 </Button> 
									</Upload>
										<p style={{ color: 'red', paddingTop: '5px' }}> 僅能上傳 pdf 檔 </p> 
									</Col>
								</Row> :null
							}
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'簽核流程'} />
								</Col>  
								<Col span={19} style={{ display: 'flex', alignItems:'center' }}>
									待上傳
								</Col>
							</Row>
							<Row justify='end' style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={3} style={{ padding: '0px 5px' }}>
									<Button type='primary' onClick={() => dispatch(setSignReocrdModalVisible(true))}> 簽核紀錄 </Button>
								</Col> 
								{
									applicant === currentUser['name'] ?
									<Col span={3} style={{ padding: '0px 5px' }}>
										<Popconfirm
											title='確定要送出留存?'
											onConfirm={() => onConfirmRetain()}
											okText='確定'
											cancelText='取消'
											okButtonProps={{ style: { width: '45%', height: '45px' } }}
											cancelButtonProps={{ style: { width: '45%', height: '45px' } }}
											placement='topRight'
										>
											<Button type='primary' style={{ background: 'blue' }} > 送出留存 </Button>
										</Popconfirm>
									</Col>  :null
								}
								<Col span={3} style={{ padding: '0px 5px' }}>
									<Button onClick={() => navigate('/Stamp/StampPendingUpload')}> 返回 </Button>
								</Col>
							</Row>
						</Form>
					</div>
				</Col>
			</BrowserView>
			<MobileView style={{ marginBottom: '115px' }}>
				{/* 頁面目前位置 */}  
				<Col span={24}>
					<Breadcrumb separator=">">
						<Breadcrumb.Item href='/'>WiAdvance EIP</Breadcrumb.Item>
						<Breadcrumb.Item href="/Stamp/StampApplication">用印申請</Breadcrumb.Item>
						<Breadcrumb.Item href="/Stamp/StampPendingUpload">待上傳</Breadcrumb.Item>
						<Breadcrumb.Item > 申請者上傳檔案 </Breadcrumb.Item>
					</Breadcrumb>
				</Col>
				{/* 頁面標題 */}
				<PageTitle>
				申請者上傳檔案
				</PageTitle>
				<Col span={24}>
					<div style={{ backgroundColor:'white'}}>
						<Form
							form={form}
							autoComplete={false}
							labelAlign={'left'}
							labelCol={{ span: 24 }}
						>
							<Row justify='space-around' gutter={[0,8]} style={{ color: 'black', padding: '0 10px 15px 10px' }}>
								{/* 申請單號 */}
								<Col span={24} >
									<Form.Item
										name='stamp_no'
										label='申請單號'
									>
										<Input bordered={false}  disabled />
									</Form.Item>
								</Col> 
								{/* 申請人 */}
								<Col span={24} >
									<Form.Item
										name='applicant_name'
										label='申請人'
									>
										<Input bordered={false} disabled/>
									</Form.Item>
								</Col>
								{/* 被代理人 */}
								<Col span={24}>
									<Form.Item
										name='is_agent'
										valuePropName="checked"
										style={{ alignItems: 'center' }}
									>
										<Row>
											<Checkbox checked={form.getFieldValue('is_agent') === 'Y' ? true : false} disabled > 被代理人 </Checkbox>
												<Form.Item
													name='agent_name'
													hidden={ form.getFieldValue('is_agent') === 'N' }
													style={{ width: '80%', marginBottom: '0' }}
												>
													<Input disabled style={{ width: '80%' }} bordered={false} /> 
											</Form.Item> 
										</Row>
									</Form.Item>
								</Col>
								{/* 簽核印信 */}
								<Col span={24} >
									<Form.Item
										label='簽核印信'
										name='stamp_type'
									>
										<Checkbox.Group style={{ width: '100%' }} disabled >
											<Row style={{ padding: '0 20px' }} justify='center' gutter={[0, 8]}>
												{
													stampList.map( item =>{
														return(
															<>
																<Col span={12}>
																	<Checkbox value={item.lookup_code}> {item.description} </Checkbox>
																</Col>
															</>
														)
													})
												}
											</Row>
										</Checkbox.Group>
									</Form.Item>
								</Col>
								{/* 申請日期 */}
								<Col span={24}>
									<Form.Item
										label='申請日期'
										name={'cr_date'}
									>
										<Input bordered={false} disabled/>
									</Form.Item>
								</Col>
								{/* 文件名稱 */}
								<Col span={24} >
									<Form.Item
										name='document'
										label='文件名稱'
									>
										<TextArea rows={4} maxLength={50} showCount disabled/>
									</Form.Item>
								</Col>
								{/* 內容簡述 */}
								<Col span={24} >
									<Form.Item
										name='description'
										label='內容簡述'
									>
										<TextArea rows={4} maxLength={50} showCount disabled/>
									</Form.Item>
								</Col>
								{/* 檔案 */}
								<Col span={24}>
									<span style={{ color:'black', fontWeight: 600, fontSize: '16px' }}> 檔案 </span>
								</Col>
								<Col span={24}>
									<Form.Item
										name={'file'}
									>
										<Button onClick={() => openViewFileModal('apply')}> 查看檔案 </Button>
									</Form.Item>
								</Col>
								{
									applicant === currentUser['name'] ?
										<Col span={24} >
										<Upload
											name='uploadContract'
											{...uploadFileProps}
											fileList={uploadedList}
											customRequest={fileUpload}
											onRemove={(props) => removeUploadedList(props)}
											accept='application/pdf'
										> 
											<Button icon={<UploadOutlined />} type='primary'> 上傳留存檔案 </Button> 
										</Upload>
											<p style={{ color: 'red', paddingTop: '5px' }}> 僅能上傳 pdf 檔 </p> 
										</Col>:null
								}
								{/* 簽核流程 */}
								<Col span={24}>
									<span style={{ color:'black', fontWeight: 600, fontSize: '16px' }}> 簽核流程 </span>
								</Col>  
								<Col span={24} style={{ marginBottom: '15px' }}>
									待上傳
								</Col>
								{/* 按鈕 */}
								<Col span={8} style={{ padding: '0px 5px' }}>
									<Button onClick={() => navigate('/Stamp/StampPendingUpload')}> 返回 </Button>
              	</Col> 
								<Col span={8} style={{ padding: '0px 5px' }}>
									<Button type='primary' onClick={() => dispatch(setSignReocrdModalVisible(true))}> 簽核紀錄 </Button>
								</Col>
								{
									applicant === currentUser['name'] ?
									<Col span={8} style={{ padding: '0px 5px' }}>
										<Popconfirm
											title='確定要送出留存?'
											onConfirm={() => onConfirmRetain()}
											okText='確定'
											cancelText='取消'
											okButtonProps={{ style: { width: '45%', height: '45px' } }}
											cancelButtonProps={{ style: { width: '45%', height: '45px' } }}
											placement='topRight'
										>
											<Button type='primary' style={{ background: 'blue' }} > 送出留存 </Button>
										</Popconfirm>
									</Col>  :null
								}
							</Row>
						</Form>
					</div>
				</Col>
			</MobileView>
		<SignRecordModal />
		<ViewFileModal />
	</PageLayout>
	)
}