/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef, useSearchParams } from "react";
import {
  Breadcrumb,
  Button,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Switch,
  Form,
  InputNumber,
  Tooltip,
  Card,
  notification,
  Segmented,
} from "antd";
import {
  SearchOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import PageTitle from "../components/PageTitle";
import PageBtns from "../components/PageBtns";
import PageLayout from "../components/PageLayout";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { BCOverDueARReport, BCOverDueARExcel } from "../newApi/report.api";
import "antd/dist/antd.css";
import Tab0Content from "../components/Tab00Content";
import Tab1Content from "../components/Tab01Content";
import Tab2Content from "../components/Tab02Content";
import Tab3Content from "../components/Tab03Content";
import Tab4Content from "../components/Tab04Content";
import { startTransition } from "react";

export default function OverDueARReport() {
  // const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.globalSetting.isLoading);
  const [pageLoading, setPageLoading] = useState(false);
  const [DueARForm] = Form.useForm();
  const { Option } = Select;
  const dateFormat = "YYYYMM"; // 設定日期格式
  const currentDate = new Date();
  const initialValue = moment(currentDate).startOf("month").format(dateFormat);
  const [StartDate, setStartDate] = useState(initialValue);
  const [EndDate, setEndDate] = useState(initialValue);
  const [StartDateError, setStartDateError] = useState(null);
  const [EndDateError, setEndDateError] = useState(null);
  const EndDatePickerRef = useRef(null);
  const submitBtnRef = useRef(null);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [searchValues, setSearchValues] = useState(initialValue);
  const [chartsData, setChartsData] = useState([]);
  const [switchChart, setSwitchChart] = useState(false);
  // 手機板
  const [mobileTableData, setMobileTableData] = useState([]);

  useEffect(() => {
    const value = DueARForm.getFieldsValue();
    BCOverDueARReport({
      ...value,
      StartDate: value?.["StartDate"]?.format(dateFormat),
      EndDate: value?.["EndDate"]?.format(dateFormat),
      BCOverDueARReportType: 0,
      mail: localStorage.getItem("mockUser") || undefined,
    })
      .then((resp) => {
        if (resp?.isSuccess) {
          if (isMobile) {
            let tempTableData = JSON.parse(JSON.stringify(mobileTableData));
            setMobileTableData([...tempTableData, ...resp?.data]);
          }
          setChartsData(resp?.data);
        }
      })
      .finally(() => setPageLoading(false));
  }, []);

  const tabListTitle = [
    {
      key: 1,
      tab: "通知次數統計(By 業務)",
    },
    {
      key: 2,
      tab: "通知次數統計(By 客戶)",
    },
    {
      key: 3,
      tab: "通知次數統計(By InvoiceNo)",
    },
    {
      key: 4,
      tab: "通知金額統計(By 業務)",
    },
    {
      key: 5,
      tab: "通知金額統計(By 客戶)",
    },
  ];
  const selectListTitle = [
    {
      value: 1,
      label: "通知次數統計(By 業務)",
    },
    {
      value: 2,
      label: "通知次數統計(By 客戶)",
    },
    {
      value: 3,
      label: "通知次數統計(By InvoiceNo)",
    },
    {
      value: 4,
      label: "通知金額統計(By 業務)",
    },
    {
      value: 5,
      label: "通知金額統計(By 客戶)",
    },
  ];

  const StartDateChange = (date, dataString) => {
    console.log(dataString);
    if (StartDate === null) {
      DueARForm.setFieldValue("StartDate", moment(dataString, dateFormat));
    } else {
      setStartDate(" ");
    }
    setStartDate(dataString);
    setStartDateError(false);
    // if (
    //   dataString?.length === 6 &&
    //   moment(dataString, dateFormat) <= moment().endOf("month")
    // ) {
    //   DueARForm.setFieldValue("StartDate", moment(dataString, dateFormat));
    //   setStartDate(dataString);
    //   setStartDateError(false);
    // EndDatePickerRef.current?.focus();
    // } else if (dataString?.length !== 6) {
    //   setStartDateError(true);
    //   if (!StartDate) {
    //     DueARForm.setFieldValue("StartDate", null);
    //     setStartDate(null);
    //   }
    // } else {
    //   setStartDateError(true);
    // }
  };
  const EndDateChange = (date, dataString) => {
    if (EndDate === null) {
      DueARForm.setFieldValue("EndDate", moment(dataString, dateFormat));
    } else {
      setEndDate(" ");
    }

    setEndDate(dataString);
    // if (
    //   dataString?.length === 6 &&
    //   moment(dataString, dateFormat) >= moment().startOf("month")
    // ) {
    //   DueARForm.setFieldValue("EndDate", moment(dataString, dateFormat));
    //   // setStartDate(dataString);
    //   setEndDate(dataString);
    //   setEndDateError(false);
    // submitBtnRef.current?.focus();
    // } else if (dataString?.length !== 6) {
    //   setEndDateError(true);
    //   if (!EndDate) {
    //     DueARForm.setFieldValue("EndDate", null);
    //     setEndDate(null);
    //   } else {
    //     setEndDateError(true);
    //   }
    // }
  };
  const onTabChange = (key) => {
    setActiveTabKey(key);
    // BCOverDueARReport({
    //   ...searchValues,
    //   StartDate: searchValues?.["StartDate"]?.format(dateFormat),
    //   EndDate: searchValues?.["EndDate"]?.format(dateFormat),
    //   BCOverDueARReportType: activeTabKey,
    //   mail: localStorage.getItem("mockUser") || undefined,
    // })
    console.log(key);
  };

  const DateSubmitClick = () => {
    const value = DueARForm.getFieldsValue();
    console.log(value);
    setPageLoading(true);
    if (StartDate > EndDate) {
      notification.error({
        message: "失敗",
        description: "開始月份不可大於結束月份",
      });
      // setEndDate(initialValue)
      setPageLoading(false);
    } else
      BCOverDueARReport({
        ...value,
        StartDate: value?.["StartDate"]?.format(dateFormat),
        EndDate: value?.["EndDate"]?.format(dateFormat),
        BCOverDueARReportType: 0,
        mail: localStorage.getItem("mockUser") || undefined,
      })
        .then((resp) => {
          if (resp?.isSuccess) {
            // setDate(resp.data);
            setChartsData(resp?.data);
            setSearchValues(resp?.data);
            console.log(chartsData);
          }
        })
        .finally(() => setPageLoading(false));
  };

  const onClearSearch = () => {
    DueARForm.resetFields();
    setStartDate(null);
    setEndDate(null);
  };

  const checkOnChange = () => {
    setSwitchChart(!switchChart);
    // console.log(`checked = ${e.target.checked}`);
  };
  // const disabledStartDate = (current, EndDate) => {
  //   const currentMoment = moment(current);
  //   const EndDateMoment = moment(EndDate);
  //   return (
  //     moment().startOf("day").isBefore(current.endOf("day")) ||
  //     currentMoment.isAfter(EndDateMoment, "month")
  //   );
  // };
  // const disabledEndDate = (current, StartDate) => {
  //   const currentMoment = moment(current);
  //   const StartDateMoment = moment(StartDate);
  //   return (
  //     moment().startOf("day").isBefore(current.endOf("day")) ||
  //     currentMoment.isBefore(StartDateMoment, "month")
  //   );
  // };

  const contentListNoTitle = {
    1: (
      <Tab0Content
        defaultValue="1"
        data={chartsData}
        switchChart={switchChart}
        setSwitchChart={setSwitchChart}
      />
    ),
    2: (
      <Tab1Content
        data={chartsData}
        switchChart={switchChart}
        setSwitchChart={setSwitchChart}
      />
    ),
    3: (
      <Tab2Content
        data={chartsData}
        switchChart={switchChart}
        setSwitchChart={setSwitchChart}
      />
    ),
    4: (
      <Tab3Content
        data={chartsData}
        switchChart={switchChart}
        setSwitchChart={setSwitchChart}
      />
    ),
    5: (
      <Tab4Content
        data={chartsData}
        switchChart={switchChart}
        setSwitchChart={setSwitchChart}
      />
    ),
  };

  // function downloadType(activeTabKey){
  //   return activeTabKey;
  // }
  const exportFile = () => {
    setPageLoading(true);
    BCOverDueARExcel({
      ...searchValues,
      StartDate: searchValues?.["StartDate"]?.format(dateFormat),
      EndDate: searchValues?.["EndDate"]?.format(dateFormat),
      BCOverDueARReportType: activeTabKey,
      mail: localStorage.getItem("mockUser") || undefined,
    })
      .then((res) => {
        console.log(activeTabKey, "test : ) ");
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        const tabKeySuffixMap = {
          1: "通知次數統計(By 業務)",
          2: "通知次數統計(By 客戶)",
          3: "通知次數統計(By InvoiceNo)",
          4: "通知金額統計(By 業務)",
          5: "通知金額統計(By 客戶)",
        };
        const suffix = tabKeySuffixMap[activeTabKey] || "default";
        link.setAttribute(
          "download",
          `${suffix}_${moment().format("YYYYMMDDHHMMSS")}` + `.xlsx`
        );
        console.log(suffix);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => setPageLoading(false));
  };

  return (
    <PageLayout spinning={pageLoading}>
      <BrowserView>
        {/* 頁面目前位置 */}
        <Col span={24}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
            <Breadcrumb.Item href="/">財務報表</Breadcrumb.Item>
            <Breadcrumb.Item>
              逾期帳款查詢
              {!!localStorage.getItem("mockUser") && (
                <span style={{ color: "#ed3b3b" }}>
                  （{localStorage.getItem("mockUser")}）
                </span>
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {/* 頁面標題 */}
        <PageTitle>逾期帳款查詢</PageTitle>
        {/* 頁面搜尋功能 */}

        <Form
          initialValues={{ StartDate: moment(), EndDate: moment() }}
          form={DueARForm}
          onFinish={
            /*(v)=>console.log(v.StartDate.format(dateFormat))*/
            DateSubmitClick
          }
          style={{ backgroundColor: "white", padding: "0px 20px 0px 10px" }}
        >
          <Row
            style={{
              margin: "5px 0px",
              padding: "15px 10px 0px 15px",
              minHeight: "60px",
              width: "100%",
            }}
            span={24}
          >
            <Col style={{ paddingTop: "10px" }} span={8}>
              <Form.Item
                label="開始月份"
                name="StartDate"
                style={{ maxWidth: "600px" }}
                onChange={(e) => {
                  StartDateChange(
                    moment(e.target.value, dateFormat),
                    e.target.value
                  );
                }}
                // extra={
                //   <h5 style={{ marginTop: "2px", color: "#969696" }}>
                //     允許自行輸入數字，格式yyyyMM
                //   </h5>
                // }
              >
                <DatePicker
                  picker="month"
                  placeholder="開始月份"
                  onChange={StartDateChange}
                  format={dateFormat}
                  // disabledDate={(current) =>
                  //   disabledStartDate(current, EndDate)
                  // }
                  placeholderText="Select a date"
                  disabledDate={(cur) => {
                    console.log(moment(EndDate));
                    if (EndDate) {
                      return moment(EndDate) < moment(cur).startOf("day");
                    } else {
                      return false;
                    }
                  }}
                  style={{ width: "80%" }}
                  changeOnBlur={true}
                  status={StartDateError ? "error" : null}
                />
              </Form.Item>
            </Col>
            <Col style={{ paddingTop: "10px" }} span={8}>
              <Form.Item
                label="結束月份"
                name="EndDate"
                style={{ maxWidth: "600px" }}
                onChange={(e) => {
                  EndDateChange(
                    moment(e.target.value, dateFormat),
                    e.target.value
                  );
                }}
                // extra={
                //   <h5 style={{ marginTop: "2px", color: "#969696" }}>
                //     允許自行輸入數字，格式yyyyMM
                //   </h5>
                // }
              >
                <DatePicker
                  initialValues={initialValue}
                  picker="month"
                  ref={EndDatePickerRef}
                  placeholder="結束月份"
                  onChange={EndDateChange}
                  format={dateFormat}
                  // disabledDate={(current) =>
                  //   disabledEndDate(current, StartDate)
                  // }
                  disabledDate={(cur) => {
                    if (StartDate) {
                      return moment(StartDate) > moment(cur).endOf("day");
                    } else {
                      return false;
                    }
                  }}
                  style={{ width: "80%" }}
                  changeOnBlur={true}
                  status={EndDateError ? "error" : null}
                />
              </Form.Item>
            </Col>
            <Col
              span={3}
              flex="130px"
              style={{
                marginLeft: "auto",
                display: "flex",
                paddingTop: "10px",
                alignSelf: "flex-center",
              }}
            >
              <Button
                type={"primary"}
                htmlType="submit"
                style={{
                  height: "40.14px",
                  fontSize: "15px",
                  padding: "7px",
                  // marginTop: "-10px",
                  width: "240px",
                  paddingTop: "10px",
                }}
                icon={<SearchOutlined />}
                ref={submitBtnRef}
              >
                查詢
              </Button>
            </Col>
            <Col
              span={3}
              flex="130px"
              style={{
                marginLeft: "7px",
                // paddingTop: "-5px",
                paddingBottom: "5px",
                alignContent: "flex-center",
                paddingTop: "10px",
              }}
            >
              <PageBtns onClick={onClearSearch}>清除</PageBtns>
            </Col>
          </Row>
        </Form>

        <Col span={24}>
          <Row
            justify="space-between"
            style={{ margin: "0px", padding: "0px" }}
            // gutter={[24, 8]}
          >
            {" "}
            {/* <Button
              ghost
              type={"primary"}
              style={{
                height: "40.14pghostx",
                border: "1px solid #00a2dd",
                marginBottom: "10px",
              }}
              onClick={exportFile}
              disabled={!chartsData?.length}
            >
              匯出
            </Button> */}
            <Card
              style={{ width: "100%", padding: "5px 0px" }}
              tabList={tabListTitle}
              activeTabKey={activeTabKey}
              tabBarExtraContent={
                <div>
                  {" "}
                  {/* <a style={{margin:"0 15px 0 15px"}} onClick={exportFile}
              disabled={!chartsData?.length}>匯出</a> */}
                  {/* <Tooltip placement="bottom" title={"匯出檔案"}>
                    <Button
                      type="primary"
                      ghost
                      style={{
                        margin: "0 15px 0 15px",
                        border: "2px solid #00a2dd",
                      }}
                      shape="circle"
                      icon={<DownloadOutlined />}
                      size={"small"}
                      onClick={exportFile}
                      disabled={!chartsData?.length}
                    />
                  </Tooltip> */}
                  <Tooltip placement="bottom" title={"切換檢視模式"}>
                    <Switch
                      className="switch-style"
                      onChange={checkOnChange}
                      checkedChildren="顯示為表格"
                      unCheckedChildren="顯示為圖表"
                      checked={switchChart ? false : true}
                      style={{ color: "#7db926" }}
                    />
                  </Tooltip>
                </div>
              }
              onTabChange={onTabChange}
              tabProps={{
                size: "middle",
              }}
            >
              {contentListNoTitle[activeTabKey]}
            </Card>
          </Row>
        </Col>
      </BrowserView>
      <MobileView style={{ marginBottom: "115px" }}>
        {/* 頁面目前位置 */}
        <Col span={24}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
            <Breadcrumb.Item href="/">財務報表</Breadcrumb.Item>
            <Breadcrumb.Item>逾期帳款查詢</Breadcrumb.Item>
            {!!localStorage.getItem("mockUser") && (
              <span style={{ color: "#ed3b3b" }}>
                （{localStorage.getItem("mockUser")}）
              </span>
            )}
          </Breadcrumb>
        </Col>
        {/* 頁面標題 */}
        <PageTitle>逾期帳款查詢</PageTitle>
        {/* 頁面搜尋功能 */}
        <Col
          style={{
            margin: "10px 0px",
            padding: "10px 10px",
            minHeight: "60px",
            width: "100%",
            backgroundColor: "white",
          }}
          span={24}
        >
          <Form
            initialValues={{ StartDate: moment(), EndDate: moment() }}
            form={DueARForm}
            onFinish={
              /*(v)=>console.log(v.StartDate.format(dateFormat))*/
              DateSubmitClick
            }
          >
            <Row gutter={[8, 8]} justify="start">
              <Col span={24}>
                <Col
                  style={{ padding: "7px 7px 0px 2px", marginBottom: "-10px" }}
                  span={24}
                >
                  <Form.Item
                    label="開始月份"
                    name="StartDate"
                    onChange={(e) => {
                      StartDateChange(
                        moment(e.target.value, dateFormat),
                        e.target.value
                      );
                    }}
                  >
                    <DatePicker
                      picker="month"
                      placeholder="開始月份"
                      onChange={StartDateChange}
                      format={dateFormat}
                      disabledDate={(cur) => {
                        console.log(moment(EndDate));
                        if (EndDate) {
                          return moment(EndDate) < moment(cur).startOf("day");
                        } else {
                          return false;
                        }
                      }}
                      style={{ width: "100%" }}
                      changeOnBlur={true}
                      status={StartDateError ? "error" : null}
                    />
                  </Form.Item>
                  <Form.Item
                    label="結束月份"
                    name="EndDate"
                    onChange={(e) => {
                      EndDateChange(
                        moment(e.target.value, dateFormat),
                        e.target.value
                      );
                    }}
                  >
                    <DatePicker
                      picker="month"
                      style={{ width: "100%" }}
                      ref={EndDatePickerRef}
                      placeholder="結束月份"
                      onChange={EndDateChange}
                      format={dateFormat}
                      disabledDate={(cur) => {
                        if (StartDate) {
                          return moment(StartDate) > moment(cur).endOf("day");
                        } else {
                          return false;
                        }
                      }}
                      changeOnBlur={true}
                      status={EndDateError ? "error" : null}
                    />
                  </Form.Item>
                </Col>
              </Col>
            </Row>

            <Row gutter={[8]} justify="center">
              <Col span={12} style={{ padding: "5px" }}>
                <Button
                  style={{ height: "40.14px" }}
                  type={"primary"}
                  htmlType="submit"
                  icon={<SearchOutlined />}
                  ref={submitBtnRef}
                >
                  查詢
                </Button>
              </Col>
              <Col span={12} style={{ padding: "5px" }}>
                <Button onClick={onClearSearch}>清除</Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24} style={{ margin: "10px 0px" }}>
          {" "}
          <Col span={24}>
            <Row
              justify="space-between"
              style={{ margin: "0px 0px" }}
              gutter={[24, 8]}
            >
              <Select
                style={{ width: "100%", marginBottom: "8px" }}
                defaultValue={1}
                onChange={(value) => {
                  setActiveTabKey(value);
                  console.log(value);
                }}
                options={selectListTitle}
              >
                {/* {optionListNoTitle.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))} */}
              </Select>
              <Col span={24}>
                <Row gutter={[8]} justify="center" style={{ margin: "0" }}>
                 <Col span={12} style={{ padding: "5px" }}>
                    {/* <Button
                      // type={"primary"}
                      ghost
                      type={"primary"}
                      style={{
                        height: "40.14px",
                        border: "1px solid #00a2dd",
                        marginBottom: "10px",
                      }}
                      onClick={exportFile}
                      disabled={!mobileTableData?.length}
                    >
                      匯出
                    </Button> */}
                  </Col> 
                  <Col
                    span={12}
                    style={{ padding: "10px 0 10px 0", textAlign: "right" }}
                  >
                    <Switch
                      className="switch-style"
                      size="large"
                      onChange={checkOnChange}
                      checkedChildren="顯示為表格"
                      unCheckedChildren="顯示為圖表"
                      checked={switchChart ? false : true}
                      style={{ marginBottom: "10px" }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              justify="space-between"
              style={{ margin: "0px" }}
              gutter={[24, 8]}
            >
              {contentListNoTitle[activeTabKey]}
            </Row>
          </Col>
        </Col>
      </MobileView>
    </PageLayout>
  );
}
