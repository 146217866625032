const StampStatusObj = {
  pendingAudit: "審核中",
  pendingStamp: "用印中",
  pendingUpload: "上傳中",
  pendingProcessed: "結案中",
  reject: "駁回",
  auditAgree: '審核同意',
  auditReject:'審核駁回',
  stampReject: '用印駁回',
  stampAgree: "用印同意",
  cancel: "取消申請",
  done: "已結案",
  doneAgree: "結案完成",
  doneReject: "結案駁回",
}

export default StampStatusObj