import requests from "./customRequests/requests";

import { message } from "antd";

const mail = localStorage.getItem("mockUser") ? `?mail=${localStorage.getItem("mockUser")}` : ""
//uploadPtsList
export const uploadPtsList = async (data) => {
  const resp = await requests().post( `api/File/PTS${mail}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return resp.data;
};

export const checkAuth = async (params) => {
  const resp = await requests().get( `api/Auth`, {params});
  return resp?.data;
};