import React, { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { Layout, Menu, Drawer } from "antd";
import PageHeader from "./PageHeader";
import Icon, {
  SettingOutlined,
  AppstoreOutlined,
  FileTextOutlined,
  ProfileOutlined,
  HomeOutlined,
  FileTextFilled,
  MenuOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { getLookupCodeList, getMenuCount } from "../redux/action/Stamp";
import { BrowserView, MobileView } from "react-device-detect";
import { controlMobileMenuOpen } from "../redux/action/Components";
import moment from "moment";
import { checkAuth } from "../newApi/pts.api";

const { Content, Sider } = Layout;

// const iconMap = {
//   "account":SettingOutlined,
//   "subscription":AppstoreOutlined,
//   "ContractMgt":FileTextOutlined,
//   "E04": PrinterOutlined
// };

const menuMap = {
  E04: "Stamp",
  E04F01: "StampApplication",
  E04F02: "StampHistory",
  E04F03: "StampPendingAudit",
  E04F04: "StampAudited",
  E04F05: "StampPendingUpload",
  E04F06: "StampPendingProcessed",
};
let first = true;
export default function SideMenu({ currentUser, menuList }) {
  const navigate = useNavigate();
  let history = useLocation();
  const dispatch = useDispatch();
  const menuCount = useSelector((state) => state.Stamp.menuCount);
  const stampList = useSelector((state) => state.Stamp.stampList);
  const menuItemsKey = useSelector((state) => state.globalSetting.menuItemsKey);
  const isMobileMenuOpen = useSelector(
    (state) => state.globalSetting.isMobileMenuOpen
  );
  const [resultMenuItems, setResultMenuItems] = useState([]);
  // const [ isBroken, setIsBroken ] = useState(false)
  const [selectedMenuItems, setSelectedMenuItems] = useState();
  const [selectedSubMenu, setSelecetedSubMenu] = useState([
    history.pathname.split("/")[1],
  ]);
  const [collapsed, setCollapsed] = useState(false);
  // console.log(menuList)
  // console.log(selectedSubMenu, selectedMenuItems)
  //左上icon導向
  useEffect(() => {
    if (menuItemsKey) {
      setSelectedMenuItems(menuItemsKey);
    }
  }, [menuItemsKey]);

  useEffect(() => {
    // console.log(history.pathname.toLowerCase().split("/")[2])
    switch (history.pathname.toLowerCase().split("/")[2]) {
      case "stampapplication":
        setSelectedMenuItems("StampApplication");
        setSelecetedSubMenu(["Stamp"]);
        break;
      case "stampapplyview":
      case "stampapplywithdraw":
      case "stampapplicationcomplete":
        setSelectedMenuItems("StampApplication");
        setSelecetedSubMenu(["Stamp"]);
        break;
      case "stamphistory":
      case "stampapplyreject":
      case "stampapplycomplete":
      case "stampcancel":
        setSelectedMenuItems("StampHistory");
        setSelecetedSubMenu(["Stamp"]);
        break;
      case "stamppendingaudit":
      case "stampapplysign":
        setSelectedMenuItems("StampPendingAudit");
        setSelecetedSubMenu(["Stamp"]);
        break;
      case "stampaudited":
      case "stampapplyviewAudited":
      case "stampapplyrejectauditor":
        setSelectedMenuItems("StampAudited");
        setSelecetedSubMenu(["Stamp"]);
        break;
      case "stamppendingupload":
      case "stampapplyviewpendingupload":
      case "stamptempupload":
        setSelectedMenuItems("StampPendingUpload");
        setSelecetedSubMenu(["Stamp"]);
        break;
      case "stamppendingprocessed":
      case "stampapplyviewpendingprocessed":
      case "stampowneraudit":
      case "stampcloseaudit":
        setSelectedMenuItems("StampPendingProcessed");
        break;
      // case 'advancereceiptreport':
      //   setSelectedMenuItems('report/AdvanceReceiptReport')
      //   setSelecetedSubMenu([])
      //   // setSelecetedSubMenu(['WFH'])
      //   break
      case "advancereceiptreport":
        setSelectedMenuItems("AdvanceReceiptReport");
        setSelecetedSubMenu(["report"]);
        // setSelecetedSubMenu(['WFH'])
        break;
      case "overduearreport":
        setSelectedMenuItems("OverDueARReport");
        setSelecetedSubMenu(["report"]);
        break;
      case "ptsupload":
        setSelectedMenuItems("file/PtsUpload");
        setSelecetedSubMenu([]);
        break;
      case "wfhapplication":
        setSelectedMenuItems("WFHApplication");
        // setSelecetedSubMenu([])
        setSelecetedSubMenu(["WFH"]);
        break;
      case "wfhcalendar":
        setSelectedMenuItems("WFHCalendar");
        // setSelecetedSubMenu([])
        setSelecetedSubMenu(["WFH"]);
        break;
      default:
        setSelectedMenuItems("StampApplication");
        setSelecetedSubMenu(["Stamp"]);
    }
  }, [history.pathname]);
  const stampSvg = () => (
    <svg
      version="1.0"
      viewBox="0 0 802.000000 785.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,785.000000) scale(0.100000,-0.100000)"
        fill="#ffffff"
        stroke="none"
      >
        <path
          d="M3844 7432 c3 -5 -20 -6 -51 -4 -38 3 -54 1 -49 -6 4 -7 -5 -9 -30
      -5 -27 4 -35 3 -30 -5 5 -8 -2 -10 -25 -5 -24 4 -30 3 -25 -6 5 -8 1 -10 -16
      -7 -27 6 -161 -35 -158 -48 2 -10 -19 -26 -35 -26 -5 0 -3 5 5 10 24 15 4 12
      -34 -7 -19 -9 -33 -20 -31 -24 3 -4 -2 -10 -11 -13 -9 -3 -12 -2 -8 5 3 6 -8
      3 -25 -5 -17 -9 -31 -19 -31 -22 0 -3 5 -2 10 1 6 3 10 4 10 1 0 -3 -10 -10
      -21 -17 -16 -8 -20 -8 -14 1 6 9 2 9 -14 1 -11 -7 -21 -14 -21 -17 0 -3 5 -2
      12 2 7 4 8 3 4 -5 -4 -6 -13 -10 -20 -9 -6 2 -11 0 -11 -3 0 -3 -6 -13 -14
      -22 -15 -19 -36 -23 -25 -5 5 7 3 8 -5 4 -7 -5 -10 -11 -7 -14 7 -7 -24 -42
      -37 -42 -5 0 -3 8 4 17 25 32 -85 -64 -137 -118 -65 -70 -123 -150 -163 -224
      -34 -64 -32 -61 41 50 58 87 170 219 218 254 19 14 -4 -15 -51 -64 -102 -107
      -168 -198 -230 -317 -25 -49 -47 -86 -50 -84 -2 2 -4 -8 -5 -22 -1 -15 -16
      -81 -34 -147 -26 -99 -33 -145 -37 -261 -7 -169 5 -263 53 -432 19 -68 31
      -128 28 -135 -4 -7 -3 -9 1 -4 5 4 27 -28 51 -72 53 -100 109 -182 169 -249
      26 -29 45 -55 42 -58 -7 -8 -103 109 -159 196 -60 92 -72 103 -31 31 52 -92
      108 -162 200 -251 48 -47 74 -70 58 -52 -16 17 -24 32 -19 32 14 0 76 -65 69
      -72 -3 -3 2 -11 12 -19 11 -9 14 -10 9 -1 -4 6 -3 12 2 12 14 0 33 -26 25 -34
      -4 -4 0 -4 8 -1 9 3 22 -2 30 -11 12 -15 12 -17 0 -9 -10 5 -12 4 -7 -4 4 -6
      12 -9 17 -5 5 3 25 -4 44 -16 39 -24 47 -36 13 -20 -21 10 -22 10 -5 -4 11 -8
      24 -13 31 -10 20 8 118 -60 150 -104 l31 -44 8 -231 c13 -352 0 -1314 -18
      -1402 -20 -96 -82 -217 -150 -292 -57 -64 -136 -129 -106 -88 8 11 -2 3 -22
      -19 -24 -25 -42 -36 -52 -32 -9 3 -14 2 -11 -3 6 -9 -44 -35 -52 -26 -3 3 1 5
      8 5 7 0 11 2 9 5 -3 2 -31 -4 -64 -14 -32 -10 -52 -21 -44 -24 8 -3 -4 -3 -25
      0 -25 4 -37 2 -33 -4 4 -6 -12 -8 -45 -5 -29 3 -49 1 -45 -4 3 -5 -434 -8
      -1050 -6 -580 1 -1052 -1 -1049 -6 2 -4 -13 -7 -33 -7 -52 0 -106 -39 -134
      -95 -22 -45 -22 -49 -22 -515 0 -430 2 -474 18 -517 18 -46 76 -108 100 -108
      7 1 -1 8 -16 16 -42 23 -27 27 16 5 23 -12 33 -21 25 -25 -7 -3 2 -3 21 0 18
      3 31 2 28 -2 -3 -5 1400 -9 3149 -9 1735 0 3154 3 3154 7 0 3 10 4 23 1 12 -3
      19 -2 14 2 -4 5 9 18 30 29 86 49 96 117 90 630 -4 421 -7 443 -52 510 -20 30
      -22 35 -6 27 10 -6 29 -34 42 -63 24 -53 24 -54 27 -492 2 -286 -1 -456 -8
      -488 -12 -59 -41 -103 -84 -129 -37 -23 -27 -25 14 -4 16 8 41 35 55 58 35 61
      45 172 45 512 0 317 -11 482 -35 543 -18 43 -60 86 -76 76 -5 -3 -17 0 -26 6
      -17 11 -17 11 0 6 11 -4 16 -2 11 5 -3 6 -17 8 -31 4 -18 -4 -23 -2 -17 6 5 9
      -3 11 -30 6 -22 -3 -34 -2 -31 4 4 6 -379 8 -1043 7 -618 -2 -1048 1 -1045 6
      4 5 -15 7 -42 4 -32 -4 -43 -3 -34 4 10 7 1 8 -26 4 -23 -3 -34 -2 -27 3 17
      11 -41 23 -65 14 -11 -5 -14 -3 -8 6 6 10 2 11 -15 6 -17 -5 -21 -4 -15 6 6 9
      3 11 -9 6 -19 -8 -76 18 -76 34 0 6 -4 8 -9 5 -4 -3 -17 2 -27 10 -10 9 -13
      12 -5 8 9 -4 12 -3 7 4 -4 6 -13 9 -20 6 -7 -3 -19 3 -27 13 -13 17 -13 17 6
      3 11 -8 -1 8 -27 37 -89 97 -134 181 -171 318 -20 78 -21 102 -24 842 -3 535
      -1 774 7 803 7 25 27 57 53 83 39 39 40 40 7 16 -19 -14 -45 -45 -58 -70 l-23
      -44 4 -780 c3 -708 5 -786 21 -850 26 -103 88 -225 147 -289 28 -31 46 -56 41
      -56 -17 0 -112 120 -142 181 -83 164 -92 276 -88 1134 3 738 0 698 66 760 36
      35 149 98 161 91 5 -4 13 -1 17 5 5 8 3 9 -6 4 -9 -6 -11 -4 -6 4 5 7 14 10
      22 7 8 -3 13 0 11 7 -1 7 6 11 15 11 10 -1 15 3 12 8 -3 5 1 8 9 6 9 -2 15 0
      14 3 -3 9 24 39 35 39 5 0 6 -5 2 -12 -5 -7 -3 -8 5 -4 7 5 10 11 7 14 -8 7
      44 62 58 62 5 0 -1 -12 -15 -27 -36 -41 70 53 114 100 62 67 143 178 171 235
      15 28 24 52 21 52 -2 0 -16 -22 -29 -49 -35 -68 -133 -195 -202 -261 l-58 -55
      68 75 c228 254 338 539 339 880 1 340 -106 614 -338 870 l-77 85 89 -90 c49
      -49 111 -124 138 -167 72 -110 77 -117 59 -78 -42 89 -157 234 -246 311 -35
      29 -57 46 -49 36 7 -9 9 -17 4 -17 -13 0 -45 35 -38 42 3 3 -3 11 -12 19 -16
      13 -17 12 -4 -3 20 -26 -2 -22 -25 4 -10 12 -13 18 -5 13 9 -5 12 -4 7 3 -4 6
      -13 9 -20 6 -8 -3 -17 0 -22 7 -5 8 -3 10 6 4 8 -5 11 -4 6 3 -4 6 -13 9 -20
      6 -8 -3 -17 0 -22 7 -5 8 -3 10 6 4 8 -5 11 -4 6 3 -4 6 -12 9 -19 6 -7 -2
      -21 3 -32 11 -19 16 -19 16 3 5 40 -19 23 -1 -19 21 -23 11 -38 16 -34 10 4
      -8 -2 -7 -19 0 -14 7 -23 15 -21 19 7 11 -43 30 -62 23 -10 -4 -14 -2 -10 4 9
      15 -70 45 -95 37 -15 -4 -18 -3 -13 7 6 9 2 11 -13 7 -14 -4 -20 -2 -16 4 5 7
      -2 9 -20 4 -20 -5 -26 -4 -20 5 5 8 -1 10 -20 7 -14 -3 -26 -1 -26 5 0 5 -13
      7 -29 5 -18 -3 -28 0 -24 5 4 6 -17 8 -55 4 -36 -3 -60 -1 -56 4 3 5 -26 9
      -66 9 -39 0 -69 -4 -66 -8z"
        />
        <path
          d="M3380 5003 c51 -35 100 -94 110 -133 7 -28 10 -283 8 -813 l-3 -772
      -27 -80 c-32 -94 -84 -184 -153 -263 -48 -57 -48 -57 -6 -17 65 61 137 176
      168 268 l28 82 3 780 c2 555 0 791 -8 818 -13 45 -71 112 -114 133 -31 14 -31
      14 -6 -3z"
        />
        <path
          d="M1002 1008 c4 -258 8 -281 56 -331 58 -61 -131 -57 2840 -57 1927 0
      2720 3 2734 11 15 8 19 8 14 0 -14 -22 10 -10 75 38 84 62 110 110 108 202
      l-2 64 -7 -76 c-4 -41 -12 -84 -18 -95 -14 -26 -92 -98 -123 -114 -28 -14 -21
      -7 42 43 79 62 84 79 84 329 0 223 12 299 18 116 l3 -103 2 108 2 107 -2916 0
      -2916 0 4 -242z"
        />
      </g>
    </svg>
  );
  const StampIcon = (props) => <Icon component={stampSvg} {...props} />;
  // 取得印章
  useEffect(() => {
    if (!stampList.length) {
      dispatch(getLookupCodeList("stampType", ""));
    }
  }, [stampList]);

  //取得側邊欄位Counts
  useEffect(() => {
    dispatch(getMenuCount());
    setInterval(() => {
      dispatch(getMenuCount());
    }, 300000);
  }, [dispatch]);

  //改寫Menu格式
  useEffect(() => {
    let tempMenuList = [];
    let menuItemList = [];

    menuList.function.forEach((menuItem) => {
      Object.keys(menuCount).forEach((countKeys) => {
        if (countKeys === menuItem.code) {
          menuItemList.push({
            label: menuItem.name + `(${menuCount[countKeys]})`,
            key: menuMap[menuItem.code],
          });
        }
      });
    });
    // let DynamicComponent = iconMap[menuList.code]
    tempMenuList.push({
      label: menuList.name,
      key: menuMap[menuList.code],
      children: menuItemList,
      icon: <StampIcon />,
    });
    let defaultMenu = [
      ...tempMenuList,
      {
        label: "WFH申請",
        key: "WFH",
        children: [
          {
            label: "WFH申請查詢",
            key: "WFHApplication",
          },
          {
            label: "部門申請狀態",
            key: "WFHCalendar",
          },
        ],
        icon: <HomeOutlined />,
      },
      {
        label: "財務報表",
        key: "report",
        children: [
          {
            label: "預收款報表",
            key: "AdvanceReceiptReport",
          },
          {
            label: "逾期帳款查詢",
            key: "OverDueARReport",
          },
        ],
        icon: <FileTextFilled />,
      },
    ];
    if (first) {
      setResultMenuItems(defaultMenu);
      first = false;
    }
    checkAuth({
      AuthType: "PTSUpload",
      mail: localStorage.getItem("mockUser") || undefined,
    })
      .then((resp) => {
        if (resp?.isSuccess && resp?.data?.IsPermit) {
          defaultMenu = [
            ...tempMenuList,
            {
              label: "WFH申請",
              key: "WFH",
              children: [
                {
                  label: "WFH申請查詢",
                  key: "WFHApplication",
                },
                {
                  label: "部門申請狀態",
                  key: "WFHCalendar",
                },
              ],
              icon: <HomeOutlined />,
            },
            {
              label: "財務報表",
              key: "report",
              children: [
                {
                  label: "預收款報表",
                  key: "AdvanceReceiptReport",
                },
                {
                  label: "逾期帳款查詢",
                  key: "OverDueARReport",
                },
              ],
              icon: <FileTextFilled />,
            },
            // {
            //   label: "PTS檔案上傳",
            //   key: "file/PtsUpload",
            //   //  children:[
            //   //     {
            //   //        "label":"查詢申請",
            //   //        "key":"WFHApplication"
            //   //     }
            //   //  ],
            //   icon: <UploadOutlined />,
            // },
          ];
        } else {
          defaultMenu = [
            ...tempMenuList,
            {
              label: "WFH申請",
              key: "WFH",
              children: [
                {
                  label: "WFH申請查詢",
                  key: "WFHApplication",
                },
                {
                  label: "部門申請狀態",
                  key: "WFHCalendar",
                },
              ],
              icon: <HomeOutlined />,
            },
            {
              label: "財務報表",
              key: "report",
              children: [
                {
                  label: "預收款報表",
                  key: "AdvanceReceiptReport",
                },
                {
                  label: "逾期帳款查詢",
                  key: "OverDueARReport",
                },
              ],
              icon: <FileTextFilled />,
            },
          ];
        }
      })
      .catch(() => {
        defaultMenu = [
          ...tempMenuList,
          {
            label: "WFH申請",
            key: "WFH",
            children: [
              {
                label: "WFH申請查詢",
                key: "WFHApplication",
              },
              {
                label: "部門申請狀態",
                key: "WFHCalendar",
              },
            ],
            icon: <HomeOutlined />,
          },
          {
            label: "財務報表",
            key: "report",
            children: [
              {
                label: "預收款報表",
                key: "AdvanceReceiptReport",
              },
              {
                label: "逾期帳款查詢",
                key: "OverDueARReport",
              },
            ],
            icon: <FileTextFilled />,
          },
        ];
      })
      .finally(() => setResultMenuItems(defaultMenu));
    // setResultMenuItems(defaultMenu)
  }, [menuCount]);

  // let defaultSubMenu = history.pathname.split('/')[1]

  const onOpen = (keys) => {
    console.log(keys);
    setSelecetedSubMenu(keys);
    if (keys.length) {
      setCollapsed(false);
    }
  };

  const onClick = (e) => {
    if (e["keyPath"][0] === window.location.pathname.split("/")[2]) {
      navigate(e.keyPath.reverse().join("/"));
      // window.location.reload()
    } else {
      setSelectedMenuItems(e.key);
      navigate(e.keyPath.reverse().join("/"));
      dispatch(getMenuCount());
      dispatch(controlMobileMenuOpen(false));
    }
  };

  return (
    <>
      <BrowserView>
        <Layout>
          <PageHeader
            currentUser={currentUser}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
          <Layout>
            <Sider
              breakpoint="xxl"
              // collapsedWidth={0}
              collapsible
              width={200}
              collapsed={collapsed}
              onCollapse={(value) => setCollapsed(value)}
              //   trigger={<MenuOutlined style={{
              //     // boxShadow: '1px 2px 2px 1px gray',
              //     // margin: '3px',
              //     // border:'10px'
              // }} />}
              trigger={null}
              // onBreakpoint={(broken) =>{
              //   setIsBroken(broken)
              // }}
            >
              <Menu
                theme="dark"
                selectedKeys={[selectedMenuItems]}
                openKeys={selectedSubMenu}
                // openKeys={['Stamp']}
                mode="inline"
                items={resultMenuItems}
                onClick={onClick}
                onOpenChange={onOpen}
              />
            </Sider>
            <Content
              id="content"
              style={{
                width: "100%",
                padding: "25px",
                backgroundColor: "#f6f6f6",
                overflowY: "auto",
                height: "calc(100vh - 64px)",
              }}
            >
              <Outlet context={{ currentUser: currentUser }} />
            </Content>
          </Layout>
        </Layout>
      </BrowserView>
      <MobileView>
        <Layout
          style={{
            minHeight: "100vh",
            overflowY: "none",
          }}
        >
          <PageHeader currentUser={currentUser} />
          <Layout style={{ width: "100%", margin: "0px auto" }}>
            <Drawer
              placement="left"
              width={"100%"}
              closable={false}
              visible={isMobileMenuOpen}
              style={{ top: "64px" }}
              bodyStyle={{ backgroundColor: "black", padding: "0" }}
            >
              <Menu
                theme="dark"
                selectedKeys={[selectedMenuItems]}
                // openKeys={['Stamp']}
                mode="inline"
                items={resultMenuItems}
                onClick={onClick}
                inlineCollapsed={!isMobileMenuOpen}
                style={{ paddingLeft: "0px", position: "sticky" }}
                onOpenChange={onOpen}
              />
            </Drawer>
            <Sider
              // breakpoint="sm"
              // collapsedWidth={0}
              width={0}
              // onBreakpoint={(broken) =>{
              //   setIsBroken(broken)
              // }}
              // // collapsed={!isMobileMenuOpen}
              collapsible
            ></Sider>
            <Layout>
              <Content
                id="content"
                style={{
                  width: "100%",
                  padding: "20px 10px",
                  backgroundColor: "#f6f6f6",
                  overflowY: "auto",
                  height: "100vh",
                  marginBottom: "115px",
                }}
              >
                <Outlet context={{ currentUser: currentUser }} />
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </MobileView>
    </>
  );
}
