import React from "react";
import {
  Col,
  Form,
  Select,
  Row,
  Input,
  DatePicker,
  Button,
  Switch,
  notification,
} from "antd";
import { useState } from "react";
import moment from "moment";
import {
  getAudited,
  queryStampTableList,
  setGlobalSearchParams,
  getLookupCodeList,
} from "../redux/action/Stamp";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { MobileView, BrowserView, isMobile } from "react-device-detect";
import StampStatusObj from "../helper/StampStatusObj";
import PageBtns from "../components/PageBtns";

export default function PageSearch(props) {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(moment());
  const [searchParams, setSearchParams] = useState({});
  const [mobileStampType, setMobileStampType] = useState([]);
  const [mobileStamperType, setMobileStamperType] = useState([]);
  const globalParams = useSelector((state) => state.Stamp.globalParams);
  const stampList = useSelector((state) => state.Stamp.stampList);
  const [form] = Form.useForm();
  // 取得印章
  useEffect(() => {
    if (!stampList.length) {
      dispatch(getLookupCodeList("stampType", ""));
    }
    setMobileStamperType(stampList?.map((i) => i.lookup_code));
  }, [stampList]);
  useEffect(() => {
    form.setFieldsValue({
      stamp_type: props.defaultSearchParams,
    });
    setSearchParams({
      stamp_type: props.defaultSearchParams,
      next_auditor_mail: props.nextAuitor,
      stamp_no: null,
      document: null,
      start_date: null,
      end_date: null,
      page_record_set: 10,
      page: 1,
    });
    setMobileStampType(props.defaultSearchParams);
  }, []);

  const onClearSearch = () => {
    if (isMobile) {
      setMobileStampType(props.defaultSearchParams);
      setMobileStamperType(stampList?.map((i) => i.lookup_code));
    }
    form.resetFields();
    form.setFieldsValue({
      stamp_type: props.defaultSearchParams,
    });
    setStartDate(null);
    setEndDate(moment());
  };
  useEffect(() => {
    console.log(startDate, endDate);
  }, [startDate, endDate]);

  //搜尋功能
  const onSearchStampList = (pageNow) => {
    if (isMobile) {
      if (mobileStampType.length === 0) {
        notification.error({
          message: "錯誤",
          description: "請選擇用印狀態!",
        });
        return;
      } else {
        form.setFieldValue("stamp_type", mobileStampType);
        form.setFieldValue("stamper_type", mobileStamperType);
      }
    }
    if (form.getFieldsError().every((ele) => ele.errors.length === 0)) {
      form.validateFields().then((res) => {
        Object.keys(res).forEach((ele) => {
          if (
            res[ele] === undefined ||
            res[ele] === "" ||
            res[ele]?.length === 0
          ) {
            res[ele] = null;
          }
        });
        // 轉換時間格式
        if (res.start_date) {
          res.start_date = Date.parse(moment(res.start_date).startOf("day"));
        }
        if (res.end_date) {
          res.end_date = Date.parse(moment(res.end_date).endOf("day"));
        }
        //單號轉為數字
        if (res.stamp_no) {
          res.stamp_no = parseInt(res.stamp_no);
        }
        // 暫時
        res["next_auditor_mail"] = props.nextAuitor;
        res["page_record_set"] = 10;
        res["page"] = 1;
        if (isMobile) {
          dispatch(setGlobalSearchParams(res));
        } else {
          setSearchParams(res);
        }
      });
    }
  };

  //手機板狀態功能
  const onSelectStatus = (key, value) => {
    let tempMobileStampType = JSON.parse(JSON.stringify(mobileStampType));
    if (value === false) {
      if (tempMobileStampType.indexOf(key) > -1) {
        tempMobileStampType.splice(tempMobileStampType.indexOf(key), 1);
      }
    } else {
      if (tempMobileStampType.indexOf(key) === -1) {
        tempMobileStampType.push(key);
      }
    }
    setMobileStampType(tempMobileStampType);
  };
  //手機板印信功能
  const onSelectStamper = (key, value) => {
    let tempMobileStamperType = JSON.parse(JSON.stringify(mobileStamperType));
    if (value === false) {
      if (tempMobileStamperType.indexOf(key) > -1) {
        tempMobileStamperType.splice(tempMobileStamperType.indexOf(key), 1);
      }
    } else {
      if (tempMobileStamperType.indexOf(key) === -1) {
        tempMobileStamperType.push(key);
      }
    }
    setMobileStamperType(tempMobileStamperType);
  };
  useEffect(() => {
    if (Object.keys(searchParams).length !== 0 && !isMobile) {
      if (props.curPage === "audited") {
        dispatch(getAudited(searchParams));
      } else {
        dispatch(queryStampTableList(searchParams));
      }
      // setInterval(() => {
      //   if(props.curPage === 'audited'){
      //     dispatch(getAudited(searchParams))
      //   }
      //   else{
      //     dispatch(queryStampTableList(searchParams))
      //   }
      // }, 60000);
    }
  }, [searchParams]);

  useEffect(() => {
    if (
      Object.keys(globalParams).length !== 0 &&
      Object.keys(searchParams).length !== 0 &&
      !isMobile
    ) {
      let result = searchParams;
      result["page"] = globalParams["page"];
      result["page_record_set"] = globalParams["page_record_set"];

      if (props.curPage === "audited") {
        dispatch(getAudited(result));
      } else {
        dispatch(queryStampTableList(result));
      }
    }
  }, [globalParams]);

  return (
    <>
      <BrowserView>
        <Col
          style={{
            margin: "10px 0px",
            padding: "10px 10px",
            minHeight: "60px",
            width: "100%",
            backgroundColor: "white",
          }}
          span={24}
        >
          <Form form={form} autoComplete="false">
            <Row gutter={[8, 8]} justify="start">
              {/* 狀態下拉選單 */}
              <Col span={8} style={{ marginTop: "-4.9px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item
                    label="狀態"
                    labelCol={{ span: 24 }}
                    name="stamp_type"
                    rules={[
                      {
                        required: true,
                        message: "請選擇狀態",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="請選擇狀態"
                      allowClear
                    >
                      {props.statusList.map((ele) => {
                        return <Option value={ele.value}> {ele.label} </Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Col>
              {/* 申請單編號輸入框 */}
              <Col span={6} style={{ marginTop: "-2.6px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item
                    label="編號"
                    labelCol={{ span: 24 }}
                    name="stamp_no"
                  >
                    <Input
                      placeholder="請輸入申請單編號..."
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Col>

              {/* 日期選擇框 */}
              <Col span={3} style={{ marginTop: "-2.6px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item
                    label="開始日期"
                    labelCol={{ span: 24 }}
                    name="start_date"
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      disabledDate={(cur) => {
                        return endDate <= moment(cur).subtract(1, "days");
                      }}
                      onChange={(e) => setStartDate(e)}
                    />
                  </Form.Item>
                </Col>
              </Col>
              {/* 日期選擇框 */}
              <Col span={3} style={{ marginTop: "-2.6px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item
                    label="結束日期"
                    labelCol={{ span: 24 }}
                    name="end_date"
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      disabledDate={(cur) => {
                        return startDate >= cur;
                      }}
                      onChange={(e) => {
                        if (e) {
                          setEndDate(e);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            <Row gutter={[8, 8]} justify="start">
              {/* 簽核印信下拉 */}
              <Col span={8} style={{ marginTop: "-4.9px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item
                    label="簽核印信"
                    labelCol={{ span: 24 }}
                    name="stamper_type"
                  >
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="請選擇簽核印信"
                      allowClear
                      options={stampList?.map((i) => {
                        return { label: i.description, value: i.lookup_code };
                      })}
                    ></Select>
                  </Form.Item>
                </Col>
              </Col>
              {/* 申請人 */}
              <Col span={6} style={{ marginTop: "-2.6px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item
                    label="申請人"
                    labelCol={{ span: 24 }}
                    name="applicant_name"
                  >
                    <Input placeholder="請輸入申請人..." />
                  </Form.Item>
                </Col>
              </Col>
              {/* 文件名稱輸入框 */}
              <Col span={6} style={{ marginTop: "-2.6px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item
                    label="文件名稱"
                    labelCol={{ span: 24 }}
                    name="document"
                  >
                    <Input placeholder="請輸入文件名稱..." />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            <Row justify="end" style={{ padding: "7px" }}>
              {/* 清除按鈕 */}
              <Col
                span={3}
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  alignSelf: "flex-end",                  
                }}
              >
                <Button
                  style={{ height: "40.14px" ,margin:"5px"}}
                  type="primary"
                  onClick={() => onSearchStampList(1)}
                >
                  {" "}
                  查詢{" "}
                </Button>
              </Col>
              {/* 查詢按鈕 */}
              <Col
                span={3}
                style={{
                  marginTop: "-2.6px",
                  display: "flex",
                  alignSelf: "flex-end",
                }}
              >
                <Button
                  style={{ height: "40.14px",margin:"5px" }}
                  onClick={() => onClearSearch()}
                >
                  {" "}
                  清除{" "}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </BrowserView>
      <MobileView>
        <Col
          style={{
            margin: "10px 0px",
            padding: "10px 10px",
            minHeight: "60px",
            width: "100%",
            backgroundColor: "white",
          }}
          span={24}
        >
          <Form form={form} autoComplete="false">
            <Row gutter={[8, 8]} justify="start">
              {/* 狀態下拉選單 */}
              <Col span={24} style={{ marginTop: "-4.9px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item
                    label="狀態"
                    labelCol={{ span: 24 }}
                    name="stamp_type"
                    rules={[
                      {
                        required: true,
                        message: "請選擇狀態",
                      },
                    ]}
                  >
                    <Row style={{ textAlign: "center" }} gutter={[0, 10]}>
                      {props.statusList.map((ele) => {
                        return (
                          <>
                            <Col
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                              span={12}
                            >
                              <Switch
                                onChange={(e) =>
                                  onSelectStatus(ele["value"], e)
                                }
                                id={ele["value"]}
                                defaultChecked
                              />
                              <span style={{ marginLeft: "6px" }}>
                                {" "}
                                {StampStatusObj[ele["value"]]}{" "}
                              </span>
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            <Row gutter={[8, 8]} justify="start">
              {/* 簽核印信下拉選單 */}
              <Col span={24} style={{ marginTop: "-4.9px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item
                    label="簽核印信"
                    labelCol={{ span: 24 }}
                    name="stamper_type"
                  >
                    <Row style={{ textAlign: "center" }} gutter={[0, 10]}>
                      {stampList.map((ele) => {
                        return (
                          <>
                            <Col
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                              span={12}
                            >
                              <Switch
                                onChange={(e) => {
                                  onSelectStamper(ele["lookup_code"], e);
                                }}
                                id={ele["lookup_code"]}
                                defaultChecked
                              />
                              <span style={{ marginLeft: "6px" }}>
                                {" "}
                                {ele?.description}{" "}
                              </span>
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            {/* 申請單編號輸入框 */}
            <Row gutter={[8, 8]} justify="start">
              <Col span={24} style={{ marginTop: "-2.6px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item
                    label="編號"
                    labelCol={{ span: 24 }}
                    name="stamp_no"
                  >
                    <Input
                      placeholder="請輸入申請單編號..."
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            {/* 申請人 */}
            <Row gutter={[8, 8]} justify="start">
              <Col span={24} style={{ marginTop: "-2.6px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item
                    label="申請人"
                    labelCol={{ span: 24 }}
                    name="applicant_name"
                  >
                    <Input placeholder="請輸入申請人..." />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            {/* 文件名稱輸入框  */}
            <Row gutter={[8, 8]} justify="start">
              <Col span={24} style={{ marginTop: "-2.6px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item
                    label="文件名稱"
                    labelCol={{ span: 24 }}
                    name="document"
                  >
                    <Input placeholder="請輸入文件名稱..." />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            {/* 日期選擇框  */}
            <Row gutter={[8, 8]} justify="start">
              <Col span={24} style={{ marginTop: "-2.6px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item
                    label="開始日期"
                    labelCol={{ span: 24 }}
                    name="start_date"
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      disabledDate={(cur) => {
                        return endDate <= moment(cur).subtract(1, "days");
                      }}
                      onChange={(e) => setStartDate(e)}
                    />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            {/* 日期選擇框  */}
            <Row gutter={[8, 8]} justify="start">
              <Col span={24} style={{ marginTop: "-2.6px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item
                    label="結束日期"
                    labelCol={{ span: 24 }}
                    name="end_date"
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      disabledDate={(cur) => {
                        return startDate >= cur;
                      }}
                      onChange={(e) => {
                        if (e) {
                          setEndDate(e);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            {/* <Row gutter={[0, 16]} justify='end'>
              {/* 清除按鈕  */}
            {/* <Col span={24}>
                <Button onClick={() => onClearSearch()}> 清除123 </Button>
              </Col> */}
            {/*  查詢按鈕  */}
            {/*<Col span={24}>
                <Button type='primary' onClick={() => onSearchStampList(1)}> 查詢 </Button>
              </Col> 
            </Row>  */}
            <Row gutter={[8]} justify="center">
              {/* 查詢按鈕  */}
              <Col span={12} style={{ padding: "5px" }}>
                <Button
                  style={{ height: "40.14px" }}
                  type="primary"
                  onClick={() => onSearchStampList(1)}
                >
                  {" "}
                  查詢{" "}
                </Button>
              </Col>
              {/* 清除按鈕  */}
              <Col span={12} style={{ padding: "5px" }}>
                <Button
                  style={{ height: "40.14px" }}
                  onClick={() => onClearSearch()}
                >
                  {" "}
                  清除{" "}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </MobileView>
    </>
  );
}
