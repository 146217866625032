import { useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";
import { BackTop } from 'antd';

function ScrollToTop({ children }) {
  const location = useLocation();
  
  useEffect(() => {
    let content = document.getElementById('content')
    if( children && content && children !== undefined && content !== undefined){
      content.scrollTop = 0
    }
  }, [location]);


  return <>{children}
  	<BackTop visibilityHeight={500} target={() => document.getElementById('content')}/>
  </>;
}
export default ScrollToTop;