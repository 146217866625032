import types from "../action/types"

/* eslint-disable import/no-anonymous-default-export */
const INTIAL_STATE = {
  managerList: [],
  allUser: []
}

export default (state = INTIAL_STATE, action) =>{
  switch(action.type){
    case types.GET_MANAGEER_LIST:
      return{ ...state, managerList: action.payload }
    case types.GET_ALL_WIADVANCE_USERS:
      return{ ...state, allUser: action.payload }
    default:
      return state
  }
}