import React from "react";
import {
  Col,
  Form,
  Select,
  Row,
  Input,
  DatePicker,
  Button,
  Switch,
  Modal,
} from "antd";
import { useState } from "react";

import { useOutletContext, useNavigate, useParams } from "react-router-dom";

export default function MobilForm(props) {
  let { id } = useParams();
  const { currentUser } = useOutletContext();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);


  return (
    <>
      <div style={{ backgroundColor: "white" }}>
              <Modal
                title="確認"
                visible={isConfirmModalOpen}
                okText="確認"
                cancelText="取消"
                // onCancel={() => setIsConfirmModalOpen(false)}
                // onOk={() => onSubmitApply()}
                cancelButtonProps={{
                  style: { width: "auto" },
                }}
                okButtonProps={{
                  style: { width: "auto" },
                }}
              >
                確定要同意這筆申請?
              </Modal>
              <Form
                form={props.form}
                autoComplete={false}
                labelAlign={"left"}
                labelCol={{ span: 24 }}
                initialValues={props.initialValues}
              >
                <Row
                  justify="space-around"
                  gutter={[0, 8]}
                  style={{ color: "black", padding: "0 10px 15px 10px" }}
                >
                {props.children}
                </Row>
              </Form>
            </div>
    </>
  );
}
