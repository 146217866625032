import types from "../action/types"

/* eslint-disable import/no-anonymous-default-export */
const INTIAL_STATE = {
  isLoading: 0,
  openMenuItemsKey: null,
  pageProps: {},
  menuItemsKey: '',
  isMobileMenuOpen: false,
  token: ''
}

export default (state = INTIAL_STATE, action) =>{
  switch(action.type){
    case types.INC_LOADING:
      return{ ...state, isLoading: state.isLoading + action.payload }
    case types.DEC_LOADING:
      return{ ...state, isLoading: state.isLoading - action.payload }
    case types.SET_OPEN_MENU_ITEMS_KEY:
      return{ ...state, openMenuItemsKey: action.payload }
    case types.PAGE_PROPS:
      return{ ...state, pageProps: action.payload }
    case types.MENU_ITEMS_KEY:
      return{ ...state, menuItemsKey: action.payload }
    case types.MOBILE_MENU_OPEN:
      return{ ...state, isMobileMenuOpen: action.payload }
    case types.SET_TOKEN:
      return{ ...state, token: action.payload }
    default:
      return state
  }
}