import React from 'react'
import { Col } from 'antd'

export default function PageTitle(props) {
  return (
    <Col 
    span={24}
    style={{    
      padding: '10px 0px',
      fontSize: '32px',
      fontWeight: 700
    }}>
      {props.children}
    </Col>
  )
}
