/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
import React, {useEffect,useState} from 'react'
import { useOutletContext, useNavigate, useParams, useLocation } from "react-router-dom";
import { Breadcrumb, Button, Input, Upload, Space, Row, Col, Table, Switch, message, Modal, Divider, Select, DatePicker, Checkbox, Form, Radio, AutoComplete, notification, Popconfirm } from 'antd'
import { UploadOutlined, PlusOutlined, MinusCircleOutlined, EyeOutlined, SendOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../components/PageTitle';
import PageLayout from '../components/PageLayout';
import InputPrefix from '../helper/InputPrefix';
import moment from 'moment';
import { fakeGetManagerChainList, fakeGetMyManager, getAllWiadvanceUsers, getManagerChainList, getMSId } from '../redux/action/graph';
import SignRecordModal from '../components/SignRecordModal'
import { setSignAndRejectModalVisible, setSignReocrdModalVisible, setViewFileModalVisible, viewFiledata } from '../redux/action/Components';
import SignAndRejectModal from '../components/SignAndRejectModal';
import { getLookupCodeList, getMenuCount, getStampDetail, withdrawStamp } from '../redux/action/Stamp';
import ViewFileModal from '../components/ViewFileModal';
import { useMsal } from '@azure/msal-react';
import mimeTypeObj from '../helper/mimeTypeObj';
import { BrowserView, MobileView } from 'react-device-detect';

export default function StampApplySignAndWithdraw(){

	const dispatch = useDispatch()
	const location = useLocation().pathname.split('/')[2]
	const { TextArea } = Input
	let { id } = useParams()
	const { currentUser } = useOutletContext();
	const [form] = Form.useForm()
	const navigate = useNavigate()
	const {accounts} = useMsal()
	const [ isDisable, setIsDisable ] = useState(false)
	const [ auditer, setAuditer ] = useState(currentUser.email)
	const [ pendingUploadFile, setPendingUploadFile ] = useState([])
	const [ fileList, setFileList ] = useState([])
	const [ curPage, setCurPage ] = useState('')
	const [ finalManagerList, setFinalManagerList ] = useState([])
  const stampList = useSelector(state => state.Stamp.stampList)
	const isLoading = useSelector(state => state.globalSetting.isLoading)
	
	useEffect(() =>{
		let MSId = accounts[0].localAccountId
		setIsDisable(true)
		dispatch(getStampDetail(id)).then(res =>{
			if( location === 'StampApplySign' && res['next_auditor_name'] !== currentUser['name']){
				navigate('/Stamp/StampApplication')
				notification.error({
					message: '錯誤',
					description: '您非此單據的審核人或您已審核過此單據!',
					duration: 6
				})
			}
			else{
				dispatch(getMSId(res['applicant_mail'])).then(IdResp =>{
					MSId = IdResp['id']
					dispatch(getManagerChainList(MSId)).then(resp =>{
						setAuditer(res.next_auditor_name)
						let tempManagerNameList =[]
						let tempManagerList = resp
						dispatch(getLookupCodeList('stampType', '')).then(respStampList =>{
							res['stamp_type'].forEach((stampType, index) =>{
								respStampList.forEach(obj =>{
									if(obj.lookup_code === stampType){
										if( tempManagerNameList.indexOf(obj.text2) === -1){
											tempManagerNameList.push(obj.text2)
										}
									}
								})
							})
							tempManagerNameList.forEach(name =>{
								tempManagerList.push({ name: name, type: '印信保管人' })
							})
							setFinalManagerList(tempManagerList)
						})
						res['cr_date'] = moment.unix(res['cr_date']/1000).format('YYYY/MM/DD hh:mm:ss')
						if(res['file']){
							res['file'].map( ele =>{
								let type = ''
								let tempUrl = ele
								ele = decodeURI(ele).replace( /\+/g, ' ' )
								ele = ele.split('/')
								ele = ele[ele.length-1]
								type = ele.slice(-3)
								setFileList(prev => [ ...prev, { url: tempUrl, name: ele, type: mimeTypeObj[type] }])
							})
						}
						// 留存檔案
						if(res['upload_file_url']){
							res['upload_file_url'].forEach( ele =>{
								let type = ''
								let tempUrl = ele
								ele = decodeURI(ele).replace( /\+/g, ' ' )
								ele = ele.split('/')
								ele = ele[ele.length-1]
								type = ele.slice(-3)
								setPendingUploadFile(prev => [ ...prev, { url: tempUrl, name: ele, type: mimeTypeObj[type] } ])
							})
						}
						form.setFieldsValue(res)
					}) 
				})
			}
		})
		switch(location){
			case 'StampApplySign':
				setCurPage('sign')
				break
			case 'StampApplyWithdraw':
				setCurPage('withdraw')
				break
			default:
				return
		}
	}, [])

	const onWithdraw = () =>{
		dispatch(withdrawStamp(id)).then(resp =>{
			if(resp && resp.code === 200){
				notification.success({
					message: '成功',
					description: '抽回成功'
				})
				navigate('/Stamp/StampApplication')
				dispatch(getMenuCount())
			}
			else{
				notification.error({
					message: '失敗',
					description: '抽回失敗'
				})
			}
		})
	}

	const openViewFileModal = (type) =>{
		if(type === 'apply'){
			dispatch(viewFiledata(fileList))
		}
		else{
			dispatch(viewFiledata(pendingUploadFile))
		}
		dispatch(setViewFileModalVisible(true))
	}

  return(
		<PageLayout spinning={ isLoading !== 0 ? true : false }>
			<BrowserView>
				{/* 頁面目前位置 */}  
				<Col span={24}>
					<Breadcrumb separator=">">
						<Breadcrumb.Item href='/'>WiAdvance EIP</Breadcrumb.Item>
						<Breadcrumb.Item href="/Stamp/StampApplication">用印申請</Breadcrumb.Item>
						{
							curPage === 'sign' ? 
							<Breadcrumb.Item href="/Stamp/StampPendingAudit">待審核</Breadcrumb.Item> :
							<Breadcrumb.Item href="/Stamp/StampApplication">我的申請</Breadcrumb.Item>
						}
						<Breadcrumb.Item > { curPage === 'sign' ? '簽核用印單' : '抽回用印單' } </Breadcrumb.Item>
					</Breadcrumb>
				</Col>
				{/* 頁面標題 */}
				<PageTitle>
					{ curPage === 'sign' ? '簽核用印單' : '抽回用印單' }
				</PageTitle>
				<Col span={24}>
					<div style={{ backgroundColor:'white'}}>
						<Form
							form={form}
							autoComplete={false}
							style={{ paddingTop: '10px' }}
						>
							{/* 申請單號 */}
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'申請單號'} />
								</Col>  
								<Col span={6} >
									<Form.Item
										name='stamp_no'
									>
										<Input bordered={false}  disabled />
									</Form.Item>
								</Col>
							</Row> 
							{/* 申請人，被代理人申請 */}
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'申請人'} />
								</Col>  
								<Col span={6} >
									<Form.Item
										name='applicant_name'
									>
										<Input bordered={false} disabled={isDisable}/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										name='is_agent'
										valuePropName="checked"
										style={{ alignItems: 'center' }}
									>
										<Row>
											<Checkbox checked={form.getFieldValue('is_agent') === 'Y' ? true : false} disabled > 被代理人 </Checkbox>
												<Form.Item
													name='agent_name'
													hidden={form.getFieldValue('is_agent') === 'N'}
													style={{ width: '80%', marginBottom: '0' }}
												>
													<Input disabled style={{ width: '80%' }} bordered={false} /> 
											</Form.Item> 
										</Row>
									</Form.Item>
								</Col>
							</Row>
							{/* 簽核印信 */}
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'簽核印信'} />
								</Col>  
								<Col span={6} >
									<Form.Item
										name='stamp_type'
									>
										<Checkbox.Group style={{ width: '100%' }} disabled={isDisable} >
											<Row gutter={[0, 8]}>
											{
												stampList.map( item =>{
													return(
														<>
															<Col span={12}>
																<Checkbox value={item.lookup_code}> {item.description} </Checkbox>
															</Col>
														</>
													)
												})
											}
											</Row>
										</Checkbox.Group>
									</Form.Item>
								</Col>
							</Row>
							{/* 申請日期 */}
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'申請日期'} />
								</Col>  
								<Col span={6} >
									<Form.Item
										name='cr_date'
									>
										<Input bordered={false} disabled/>
									</Form.Item>
								</Col>
							</Row>
							{/* 文件名稱 */}
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'文件名稱'} />
								</Col>
									<Col span={6} >
										<Form.Item
											name='document'
										>
											<TextArea rows={4} maxLength={50} showCount disabled/>
										</Form.Item>
									</Col>
							</Row>
							{/* 內容簡述 */}
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'內容簡述'} />
								</Col>  
								<Col span={6} >
									<Form.Item
										name='description'
									>
										<TextArea rows={6} maxLength={100} showCount disabled/>
									</Form.Item>
								</Col>
							</Row>
							{/* 檔案 */}
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'檔案'} />
								</Col>  
								<Col span={6} >
									<Form.Item
										name={'file'}
									>
										<Button onClick={() => openViewFileModal('apply')}> 查看檔案 </Button>
									</Form.Item>
								</Col>
							</Row>
							{
								pendingUploadFile.length !== 0 ?
								<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
									<Col span={5} />
									<Col span={6} >
										<Form.Item
										>
											<Button onClick={() => openViewFileModal('keep')}> 查看留存檔案 </Button>
										</Form.Item>
									</Col>
								</Row>
								:null
							}
							{/* 簽核流程 */}
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'簽核流程'} />
								</Col>  
								<Col span={19}>
									<Row gutter={[16]}>
										{
											finalManagerList.map((ele, index) =>{
												return(
													<Col style={{ color: ( auditer === ele.name && ele.type !== '印信保管人'  ? 'blue' : null ), marginTop: ( index > 5  ? '15px' : 0)}} span={4}>
														<p>{ele.type}</p>
														{ele.name}
													</Col>
												)
											})
										}
									</Row>
								</Col>
							</Row>
							<Row justify='end' style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={3} style={{ padding: '0px 5px' }}>
									<Button type='primary' onClick={() => dispatch(setSignReocrdModalVisible(true))}> 簽核紀錄 </Button>
								</Col>
								{
								curPage === 'sign' ? 						
									<Col span={3} style={{ padding: '0px 5px' }}>
										<Button style={{ color: 'white', background: 'blue' }} onClick={() => dispatch(setSignAndRejectModalVisible(true))}> 簽核 </Button>
									</Col> : 
									<Col span={3} style={{ padding: '0px 5px' }}>
										<Popconfirm
											title='確定要抽回?'
											onConfirm={() => onWithdraw()}
											okText='確定'
											cancelText='取消'
											okButtonProps={{ style: { width: '45%', height: '45px' } }}
											cancelButtonProps={{ style: { width: '45%', height: '45px' } }}
											placement='topRight'
										>
											<Button style={{ color: 'white', background: 'blue' }} > 抽回 </Button>
										</Popconfirm>
									</Col>
								}
								<Col span={3} style={{ padding: '0px 5px' }}>
									<Button onClick={() => (auditer === currentUser['name'] ? navigate('/Stamp/StampPendingAudit') : navigate('/Stamp/StampApplication') ) }> 返回 </Button>
								</Col> 
							</Row>
						</Form>
					</div>
				</Col>
			</BrowserView>
			<MobileView style={{ marginBottom: '115px' }}>
				{/* 頁面目前位置 */}  
				<Col span={24}>
					<Breadcrumb separator=">">
						<Breadcrumb.Item href='/'>WiAdvance EIP</Breadcrumb.Item>
						<Breadcrumb.Item href="/Stamp/StampApplication">用印申請</Breadcrumb.Item>
						{
							curPage === 'sign' ? 
							<Breadcrumb.Item href="/Stamp/StampPendingAudit">待審核</Breadcrumb.Item> :
							<Breadcrumb.Item href="/Stamp/StampApplication">我的申請</Breadcrumb.Item>
						}
						<Breadcrumb.Item > { curPage === 'sign' ? '簽核用印單' : '抽回用印單' } </Breadcrumb.Item>
					</Breadcrumb>
				</Col>
				{/* 頁面標題 */}
				<PageTitle>
					{ curPage === 'sign' ? '簽核用印單' : '抽回用印單' }
				</PageTitle>
				<Col span={24}>
					<div style={{ backgroundColor:'white'}}>
						<Form
							form={form}
							autoComplete={false}
							labelAlign={'left'}
							labelCol={{ span: 24 }}
						>
							<Row justify='space-around' gutter={[0,8]} style={{ color: 'black', padding: '0 10px 15px 10px' }}>
								{/* 申請單號 */}
								<Col span={24} >
									<Form.Item
										name='stamp_no'
										label='申請單號'
									>
										<Input bordered={false}  disabled />
									</Form.Item>
								</Col> 
								{/* 申請人 */}
								<Col span={24} >
									<Form.Item
										name='applicant_name'
										label='申請人'
									>
										<Input bordered={false} disabled/>
									</Form.Item>
								</Col>
								{/* 被代理人 */}
								<Col span={24}>
									<Form.Item
										name='is_agent'
										valuePropName="checked"
										style={{ alignItems: 'center' }}
									>
										<Row>
											<Checkbox checked={form.getFieldValue('is_agent') === 'Y' ? true : false} disabled > 被代理人 </Checkbox>
												<Form.Item
													name='agent_name'
													hidden={ form.getFieldValue('is_agent') === 'N' }
													style={{ width: '80%', marginBottom: '0' }}
												>
													<Input disabled style={{ width: '80%' }} bordered={false} /> 
											</Form.Item> 
										</Row>
									</Form.Item>
								</Col>
								{/* 簽核印信 */}
								<Col span={24} >
									<Form.Item
										label='簽核印信'
										name='stamp_type'
									>
										<Checkbox.Group style={{ width: '100%' }} disabled >
											<Row style={{ padding: '0 20px' }} justify='center' gutter={[0, 8]}>
												{
													stampList.map( item =>{
														return(
															<>
																<Col span={12}>
																	<Checkbox value={item.lookup_code}> {item.description} </Checkbox>
																</Col>
															</>
														)
													})
												}
											</Row>
										</Checkbox.Group>
									</Form.Item>
								</Col>
								{/* 申請日期 */}
								<Col span={24}>
									<Form.Item
										label='申請日期'
										name={'cr_date'}
									>
										<Input bordered={false} disabled/>
									</Form.Item>
								</Col>
								{/* 文件名稱 */}
								<Col span={24} >
									<Form.Item
										name='document'
										label='文件名稱'
									>
										<TextArea rows={4} maxLength={50} showCount disabled/>
									</Form.Item>
								</Col>
								{/* 內容簡述 */}
								<Col span={24} >
									<Form.Item
										name='description'
										label='內容簡述'
									>
										<TextArea rows={6} maxLength={100} showCount disabled/>
									</Form.Item>
								</Col>
								{/* 檔案 */}
								<Col span={24}>
									<span style={{ color:'black', fontWeight: 600, fontSize: '16px' }}> 檔案 </span>
								</Col>
								<Col span={24}>
									<Form.Item
										name={'file'}
									>
										<Button onClick={() => openViewFileModal('apply')}> 查看檔案 </Button>
									</Form.Item>
								</Col>
								{
									pendingUploadFile.length !== 0 ?
										<Col span={24} >
											<Form.Item
											>
												<Button onClick={() => openViewFileModal('keep')}> 查看留存檔案 </Button>
											</Form.Item>
										</Col>
									:null
								}
								{/* 簽核流程 */}
								<Col span={24}>
									<span style={{ color:'black', fontWeight: 600, fontSize: '16px' }}> 簽核流程 </span>
								</Col>  
								<Col span={24} style={{ marginBottom: '15px' }}>
									<Row gutter={[10, 30]}>
										{
											finalManagerList.map((ele, index) =>{
												return(
													<Col style={{ color: ( auditer === ele.name && ele.type !== '印信保管人' && curPage !== 'complete' ? 'blue' : null ), marginTop: ( index > 2  ? '15px' : 0) }} span={8}>
														<p>{ele.type}</p>
														{ele.name}
													</Col>
												)
											})
										}
									</Row>
								</Col>
								{/* 按鈕 */}
								<Col span={8} style={{ padding: '0px 5px' }}>
									<Button onClick={() => (auditer === currentUser['name'] ? navigate('/Stamp/StampPendingAudit') : navigate('/Stamp/StampApplication') ) }> 返回 </Button>
              	</Col> 
								<Col span={8} style={{ padding: '0px 5px' }}>
									<Button type='primary' onClick={() => dispatch(setSignReocrdModalVisible(true))}> 簽核紀錄 </Button>
								</Col>
								{
									curPage === 'sign' ? 						
									<Col span={8} style={{ padding: '0px 5px' }}>
										<Button style={{ color: 'white', background: 'blue' }} onClick={() => dispatch(setSignAndRejectModalVisible(true))}> 簽核 </Button>
									</Col> : 
									<Col span={8} style={{ padding: '0px 5px' }}>
										<Popconfirm
											title='確定要抽回?'
											onConfirm={() => onWithdraw()}
											okText='確定'
											cancelText='取消'
											okButtonProps={{ style: { width: '45%', height: '45px' } }}
											cancelButtonProps={{ style: { width: '45%', height: '45px' } }}
											placement='topRight'
										>
											<Button style={{ color: 'white', background: 'blue' }} > 抽回 </Button>
										</Popconfirm>
									</Col>
								}
							</Row>
						</Form>
					</div>
				</Col>
			</MobileView>
		<SignRecordModal />
		<SignAndRejectModal />
		<ViewFileModal />
	</PageLayout>
	)
}