import React, { useEffect, useState } from "react";
import { Button, Form, Col } from "antd";

function MobileBtns(props) {
  return (
    <Col span={12} style={{ padding: "5px" }}>
      <Button
      style={{height: "40.14px"}}
        type={props.type}
        loading={props.loading}
        htmlType={props.htmlType}
        onClick={props.onClick}
        icon={props.icon}
        disabled={props.disabled}
      >
        {props.children}
      </Button>
    </Col>
  );
}

export default MobileBtns;
