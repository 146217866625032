
import React, {useEffect,useState} from 'react'
import { useOutletContext, useNavigate } from "react-router-dom";
import { Breadcrumb, Button, Input, Dropdown, Menu, Space, Row, Col, Table, Switch, message, Modal, Divider } from 'antd'
import { SearchOutlined, CaretDownOutlined, AlignLeftOutlined, PlusCircleOutlined} from '@ant-design/icons';
import PageTitle from '../components/PageTitle';
import PageSearch from '../components/PageSearch';
import PageLayout from '../components/PageLayout';

const { Search } = Input;

export default function Tag({roleFunction}) {
  const navigate = useNavigate();
  const [data, setData] = useState([
    {
      'tag':'tag1',
      'action':true
    },
    {
      'tag':'tag2',
      'action':false
    },
    {
      'tag':'tag3',
      'action':true
    },
    {
      'tag':'tag4',
      'action':true
    }
  ])

  const [loading, setLoading] = useState(false)

  const columns = [
    {
      title: `Tag`,
      dataIndex: 'tag',
      key: 'tag',
      width:'30%'
    },
    {
      title: ``,
      dataIndex: '',
      key: '',
      width:'30%'
    },
    {
      title: ``,
      dataIndex: 'action',
      key: 'action',
      width:'30%',
      render: (item,record)=>{
        return <Row justify='space-around'>
          <a onClick={()=>navigate(`/tag/update`, { state: { 'tag': record.tag, 'status': record.action } })}>編輯</a>
          <Switch checkedChildren="啟用" unCheckedChildren="停用" checked={item}  />
        </Row>

      },
      align:'center'
    }
  ];

  const { currentUser } = useOutletContext();
  console.log(currentUser,roleFunction)
  return (
    <PageLayout spinning={false}>
      <Col span={24}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item href='/'>WiAdvance EIP</Breadcrumb.Item>
          <Breadcrumb.Item >Tag維護管理</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <PageTitle>
        Tag維護管理
      </PageTitle>
      <PageSearch>
        <Row justify="start" style={{margin:'10px 0px'}}>
            <Col flex="auto">
              <Search
                style={{
                width:'100%'
                }}
                enterButton={'查尋'}
                allowClear
                size="large"
                placeholder={'查尋關鍵字'}
                prefix={<SearchOutlined/>}
              />
            </Col>
            <Col flex="150px" style={{margin: 'auto 10px'}}>
              <Button style={{height:'40.14px'}}>清除</Button>
            </Col>
        </Row>

      </PageSearch>
      <Col span={24}>
        <Row justify="space-between" style={{margin: '5px 10px'}}>
          <Col flex="150px">
            <Button 
              type={'primary'} 
              style={{height:'40.14px'}}
              onClick={()=>navigate('/tag/add')}
            >
              新增<PlusCircleOutlined />
            </Button>
          </Col>
          <Col flex="auto">
            <div style={{'textAlign':'end', 'margin':'8px', 'color':'#7C7C7C'}}>
              共有{data? data.length : 0}筆資料，每頁顯示10<CaretDownOutlined />筆
            </div>
          </Col>
        </Row>
      </Col>
      {/* <PageSearch>
      <Row justify="space-between" style={{margin: '20px 10px'}}>
        <Col flex="150px">
          <Button 
            type={'primary'} 
            style={{height:'40.14px'}}
            onClick={()=>navigate('/account/add')}
          >
            新增<PlusCircleOutlined />
          </Button>
        </Col>
        <Col flex="auto">
          <div style={{'textAlign':'end', 'margin':'8px', 'color':'#7C7C7C'}}>
            共有{data? data.length : 0}筆資料，每頁顯示10<CaretDownOutlined />筆
          </div>
        </Col>
      </Row>
      </PageSearch> */}
      <Col span={24}
      style={{
        margin:'10px 0px',
      }}
      >
        <Table 
          columns = {columns} 
          dataSource = {data} 
        />
      </Col>
    </PageLayout>
  )
}
