/* eslint-disable no-loop-func */
import React from "react";

import {
  Col,
  Modal,
  Tag
} from "antd";

import PageTitle from "../components/PageTitle";
import InputPrefix from "../helper/InputPrefix";

import { BrowserView, isMobile, MobileView } from "react-device-detect";

function CalenderNameModel({isModalOpen, handleCancel, modalData, onTagClick}) {
  
  return (
    <Modal title={null} visible={isModalOpen} centered width={isMobile?"100%":350} footer={null} onCancel={handleCancel} zIndex={69} >
      <BrowserView>
        <PageTitle>{modalData?.date?.format('YYYY-MM-DD') || ""}</PageTitle>
        {
          modalData?.listData?.map((item,index) => (
          <Col key={index} span={24} className='more-calender'>
            <Tag color={item.color} onClick={()=>onTagClick(modalData?.date, item)}>{item.name}</Tag>
          </Col>
        ))}
        <Col>
          <span style={{ color: "#000000"}}><Tag color={'green'}>綠色</Tag>表示審核中，<Tag color={'blue'}>藍色</Tag>表示審核通過</span>
        </Col>
      </BrowserView>
      <MobileView>
        <PageTitle>{modalData?.date?.format('YYYY-MM-DD') || ""}</PageTitle>
        {
          modalData?.listData?.map((item,index) => (
          <Col key={index} span={24} className='more-calender'>
            <Tag color={item.color} onClick={()=>onTagClick(modalData?.date, item)}>{item.name}</Tag>
          </Col>
        ))
        }
        <Col>
          <span style={{ color: "#000000"}}><Tag color={'green'}>綠色</Tag>表示審核中，<Tag color={'blue'}>藍色</Tag>表示審核通過</span>
        </Col>
      </MobileView>
    </Modal>
  )
}

export default CalenderNameModel
