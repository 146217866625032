
import React from 'react';
import { useNavigate } from "react-router-dom";
import { Breadcrumb, Button, Row, Col, Form, Select } from 'antd';
import PageTitle from '../components/PageTitle';
import FormLayout from '../components/FormLayout';
import PageLayout from '../components/PageLayout';
import InputPrefix from '../helper/InputPrefix';

const { Option } = Select;
export default function AccountAdd() {
  const navigate = useNavigate();
  return (
    <PageLayout spinning={false}>
      <Col span={24}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item href='/'>WiAdvance EIP</Breadcrumb.Item>
          <Breadcrumb.Item href='/'>帳號管理</Breadcrumb.Item>
          <Breadcrumb.Item >新增帳號</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <PageTitle>
        新增帳號
      </PageTitle>
      <Col span={24}>  
      <Form
        name="basic"
        // initialValues={{ remember: true }}
        onFinish={(value)=>{console.log(value)}}
        autoComplete="off"
      >
      <FormLayout>
        <Row align='top'>
          <InputPrefix label={'帳號'} require={true}/>
          <Col flex="auto">
            <Form.Item
              name="account"
              rules={[{ 
                  required: true,
                  message: '請輸入帳號Email' 
              }]}
            >
                <Select
                  placeholder={'請輸入'}
                  // value={'Option1'}
                  size={'large'} bordered={false} style={{borderBottom: '1px solid #ddd'}}
                >
                  <Option value="Option1" >test@wiadvance.com</Option>
                  <Option value="Option2">test1@wiadvance.com</Option>
                  <Option value="Option3">test2@wiadvance.com</Option>
                </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row align='top'>
          <InputPrefix label={'角色'} require={true}/>
          <Col flex="auto">
            <Form.Item
              name="role"
              rules={[{ 
                  required: true,
                  message: '請輸入角色' 
              }]}
              >
                <Select
                  placeholder={'請輸入'}
                  // value={'Option1'}
                  size={'large'} bordered={false} style={{borderBottom: '1px solid #ddd'}}
                >
                  <Option value="Option1">role1</Option>
                  <Option value="Option2">role2</Option>
                  <Option value="Option3">role3</Option>
                </Select>
            </Form.Item>
          </Col>
        </Row>
      </FormLayout>
      <Row align='bottom' style={{marginTop:'30px'}}>
          <Col>
            <Form.Item>
              <Button style={{width:'150px', marginRight:'10px'}} type="primary" htmlType="submit">確定</Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button style={{width:'150px'}} onClick={()=>navigate('/account')}>取消</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      </Col>
    </PageLayout>
  )
}
