import { Modal, Form, Row, Col, Popconfirm, Button, Input, notification } from "antd"
import InputPrefix from '../helper/InputPrefix'
import { useDispatch, useSelector } from "react-redux"
import { setSignAndRejectModalVisible } from "../redux/action/Components"
import { useState } from "react"
import { useNavigate, useParams, useOutletContext } from "react-router-dom"
import { auditerAgree, auditerReject, CEOAgree, getMenuCount, getStampDetail, processedAgree, processedReject, stampOwnerAgree, stmapOwnerReject } from "../redux/action/Stamp"
import { fakeGetMyManager, getMyManager } from "../redux/action/graph"
import { BrowserView, MobileView } from "react-device-detect"
import { useEffect } from "react"

export default function SignAndRejectModal(props){

	const { currentUser } = useOutletContext();
  const { id } = useParams()
  const navigate = useNavigate()
  const { TextArea } = Input
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [ isCommentRequired, setIsCommentRequired ] = useState(false)
	const stampList = useSelector(state => state.Stamp.stampList)
  const signAndRejectModalVisible = useSelector( state => state.Modal.signAndRejectModalVisible )
  const [ tempNextManager, setTempNextManager ] = useState(currentUser.email)


  const onSubmit = (type) => {
    let tempRequire = null
    if(type === 'reject'){
      setIsCommentRequired(true)
      tempRequire = true
    }
    else{
      setIsCommentRequired(false)
      tempRequire = false
    }
    
    if(!tempRequire){
      dispatch(setSignAndRejectModalVisible(false))
    }
    dispatch(getStampDetail(id)).then(detailResp =>{
      form.validateFields().then((res) =>{
        dispatch(setSignAndRejectModalVisible(false))
        let result = {}
        //駁回與同意都要的參數
        result['stamp_no'] = parseInt(id)
        result['auditor_name'] = currentUser.name
        result['auditor_mail'] = currentUser.email
        if( res.audit_command === undefined ){
          res.audit_command = ''
        }
        result['audit_command'] = res.audit_command
  
        if( type === 'agree'){
          // 用印審核
          if( props.status === 'pendingStamp' ){
            result['stamp_type'] = props.type
            let tempStampOwnerList = props.stampOwnerList.split(',')
            let unStringfyStampOwner = []
            tempStampOwnerList.forEach(ele =>{
              if(ele !== currentUser.email){
                unStringfyStampOwner.push(ele)
              }
            })
            if(unStringfyStampOwner.length === 0){
              result['agreeList'] = 'stampDone'
            }
            else{
              result['agreeList'] = unStringfyStampOwner.join()
            }
            result['auditor_type'] = '印章保管人'

            if(detailResp['stamp_status'] !== "pendingStamp" && detailResp['next_auditor_mail'].toLowerCase().split(',').indexOf(currentUser['email'].toLowerCase()) > -1 ){
              notification.error({
                message: '失敗',
                description: '您非本單據的審核人或此單已審核過!'
              })
              navigate('/Stamp/StampApplication')
            }
            dispatch(stampOwnerAgree(result)).then(res =>{
              if(res && res.code === 200){
                dispatch(getMenuCount())
                notification.success({
                  message: '成功',
                  description: '操作成功'
                })
                navigate('/Stamp/StampAudited')
              }
              else{
                notification.error({
                  message: '失敗',
                  description: res.messages.tw
                })
                navigate('/Stamp/StampApplication')
              }
            })
          }
          //結案審核
          else if( props.status === 'pendingProcessed' ){
            dispatch(getStampDetail(id)).then(resp =>{
              let tempStampOwnerList = resp.next_auditor_mail.split(',')
              let unStringfyStampOwner = []
              tempStampOwnerList.forEach(ele =>{
                if(ele !== currentUser.email){
                  unStringfyStampOwner.push(ele)
                }
              })
  
              if(unStringfyStampOwner.length === 0){
                result['agreeList'] = 'processedDone'
              }
              else{
                result['agreeList'] = unStringfyStampOwner.join()
              }
              result['auditor_type'] = '印章保管人'
  
              if(detailResp['stamp_status'] !== "pendingProcessed" && detailResp['next_auditor_mail'].toLowerCase().split(',').indexOf(currentUser['email'].toLowerCase()) > -1 ){
                notification.error({
                  message: '失敗',
                  description: '您非本單據的審核人或此單已審核過!'
                })
                navigate('/Stamp/StampApplication')
              }
              else{
                dispatch(processedAgree(result)).then(resp =>{
                  if(resp && resp.code === 200){
                    dispatch(getMenuCount())
                    notification.success({
                      message: '成功',
                      description: '操作成功'
                    })
                    navigate('/Stamp/StampAudited')
                  }
                  else{
                    notification.error({
                      message: '失敗',
                      description: resp.messages.tw
                    })
                    navigate('/Stamp/StampApplication')
                  }
                })
              }

            })
          }
          // 一般審核同意
          else{
            // 上級主管審核
            if( detailResp['next_auditor_mail'].toLowerCase() !== 'Michael.Hsia@wiadvance.com'.toLowerCase() ){
              dispatch(getMyManager(tempNextManager)).then(resp =>{
                result['next_auditor_name'] = resp.displayName
                result['next_auditor_mail'] = resp.mail
                result['auditor_type'] = '上級主管'
  
                if(detailResp['stamp_status'] !== "pendingAudit" && detailResp['next_auditor_mail'].toLowerCase() !== currentUser['email'].toLowerCase() ){
                  notification.error({
                    message: '失敗',
                    description: '您非本單據的審核人或此單已審核過!'
                  })
                  navigate('/Stamp/StampApplication')
                }
                else{
                  dispatch(auditerAgree(result)).then(res =>{
                    if(res && res.code === 200){
                      dispatch(getMenuCount())
                      notification.success({
                        message: '成功',
                        description: '操作成功'
                      })
                      navigate('/Stamp/StampAudited')
                    }
                    else{
                      notification.error({
                        message: '失敗',
                        description: res.messages.tw
                      })
                      navigate('/Stamp/StampApplication')
                    }
                  })
                }
              })
            }
            else{
              // 總經理審核過
              dispatch(getStampDetail(id)).then(detail =>{
                let stampOwnerList = []
                stampList.forEach(stamp =>{
                  if(detail.stamp_type.indexOf(stamp.lookup_code) > -1){
                    if(stampOwnerList.indexOf(stamp.text1) === -1){
                      stampOwnerList.push(stamp.text1)
                    }
                  }
                })
                let stampstr = stampOwnerList.join()
                result['next_auditor_mail'] = stampstr
                result['next_auditor_name'] = 'stampOwner'
                result['auditor_type'] = '總經理'

                if(detailResp['stamp_status'] !== "pendingAudit" && detailResp['next_auditor_mail'].toLowerCase() !== currentUser['email'].toLowerCase() ){
                  notification.error({
                    message: '失敗',
                    description: '您非本單據的審核人或此單已審核過!'
                  })
                  navigate('/Stamp/StampApplication')
                }
                else{
                  dispatch(CEOAgree(result)).then(res =>{
                    if(res && res.code === 200){
                      dispatch(getMenuCount())
                      notification.success({
                        message: '成功',
                        description: '操作成功'
                      })
                      navigate('/Stamp/StampAudited')
                    }
                    else{
                      notification.error({
                        message: '失敗',
                        description: res.messages.tw
                      })
                      navigate('/Stamp/StampApplication')
                    }
                  })
                }
              })
            }
            // dispatch(getMyManager(tempNextManager)).then(resp =>{
            //   if(detailResp['next_auditor_mail'].toLowerCase() !== 'Michael.Hsia@wiadvance.com'.toLowerCase()){
            //     result['next_auditor_name'] = resp.displayName
            //     result['next_auditor_mail'] = resp.mail
            //     result['auditor_type'] = '上級主管'
  
            //     if(detailResp['stamp_status'] !== "pendingAudit" && detailResp['next_auditor_mail'].toLowerCase() !== currentUser['email'].toLowerCase() ){
            //       notification.error({
            //         message: '失敗',
            //         description: '您非本單據的審核人或此單已審核過!'
            //       })
            //       navigate('/Stamp/StampApplication')
            //     }
            //     else{
            //       dispatch(auditerAgree(result)).then(res =>{
            //         if(res && res.code === 200){
            //           dispatch(getMenuCount())
            //           notification.success({
            //             message: '成功',
            //             description: '操作成功'
            //           })
            //           navigate('/Stamp/StampAudited')
            //         }
            //         else{
            //           notification.error({
            //             message: '失敗',
            //             description: res.messages.tw
            //           })
            //           navigate('/Stamp/StampApplication')
            //         }
            //       })
            //     }
            //   }
            //   else{

            //   }
            // })
          }
        }
        else{
          //用印駁回
          if( props.status === 'pendingStamp' ){
            result['stamp_type'] = props.type
            result['auditor_type'] = '印信保管人'
            if(detailResp['stamp_status'] !== "pendingStamp" && detailResp['next_auditor_mail'].toLowerCase().split(',').indexOf(currentUser['email'].toLowerCase()) > -1 ){
              notification.error({
                message: '失敗',
                description: '您非本單據的審核人或此單已審核過!'
              })
              navigate('/Stamp/StampApplication')
            }
            else{
              dispatch(stmapOwnerReject(result)).then(res =>{
                if(res && res.code === 200){
                  dispatch(getMenuCount())
                  notification.success({
                    message: '成功',
                    description: '操作成功'
                  })
                  navigate('/Stamp/StampAudited')
                }
                else{
                  notification.error({
                    message: '失敗',
                    description: res.messages.tw
                  })
                }
              })
            }
          }
          //結案駁回
          else if( props.status === 'pendingProcessed' ){
            result['auditor_type'] = '印章保管人'
            if(detailResp['stamp_status'] !== "pendingProcessed" && detailResp['next_auditor_mail'].toLowerCase().split(',').indexOf(currentUser['email'].toLowerCase()) > -1 ){
              notification.error({
                message: '失敗',
                description: '您非本單據的審核人或此單已審核過!'
              })
              navigate('/Stamp/StampApplication')
            }
            else{
              dispatch(processedReject(result)).then(resp =>{
                if(resp && resp.code === 200){
                  dispatch(getMenuCount())
                  notification.success({
                    message: '成功',
                    description: '操作成功'
                  })
                  navigate('/Stamp/StampAudited')
                }
                else{
                  notification.error({
                    message: '失敗',
                    description: resp.message
                  })
                }
              })
            }
          }
          //一般駁回
          else{
            result['auditor_type'] = '上級主管'
            if(detailResp['stamp_status'] !== "pendingAudit" && detailResp['next_auditor_mail'].toLowerCase() !== currentUser['email'].toLowerCase() ){
              notification.error({
                message: '失敗',
                description: '您非本單據的審核人或此單已審核過!'
              })
              navigate('/Stamp/StampApplication')
            }
            else{
              dispatch(auditerReject(result)).then(res =>{
                if(res && res.code === 200){
                  dispatch(getMenuCount())
                  notification.success({
                    message: '成功',
                    description: '操作成功'
                  })
                  navigate('/Stamp/StampAudited')
                }
                else{
                  notification.error({
                    message: '失敗',
                    description: res.messages.tw
                  })
                }
              })
            }
          }
        }
      })
    })
  }

  return(
    <>
      <Form
        form={form}
      >
        <BrowserView>
          <Modal
            title='簽核'
            visible={signAndRejectModalVisible}
            onCancel={() => dispatch(setSignAndRejectModalVisible(false))}
            footer={[
              <>
                <Popconfirm
                  title='確定要駁回?'
                  onConfirm={() => onSubmit('reject')}
                  okText='確定'
                  cancelText='取消'
                  okButtonProps={{ style: { width: '45%', height: '45px' } }}
                  cancelButtonProps={{ style: { width: '45%', height: '45px' } }}
                  placement='topRight'
                >
                    <Button style={{ color: 'white', background: 'red' }} > 駁回 </Button>
                </Popconfirm>
                <Popconfirm
                  title='確定要審核通過?'
                  onConfirm={() => onSubmit('agree')}
                  okText='確定'
                  cancelText='取消'
                  okButtonProps={{ style: { width: '45%', height: '45px' } }}
                  cancelButtonProps={{ style: { width: '45%', height: '45px' } }}
                  placement='topRight'
                >
                  <Button type='primary'> 簽核通過 </Button>
                </Popconfirm>
              </>
            ]}
          >
            <Row>
              <Col span={6}>
                <InputPrefix label={'備註'} />
              </Col>
              <Col span={18}>
                <Form.Item
                  name={'audit_command'}
                  rules={[
                    {
                      required: isCommentRequired,
                      message: '若駁回備註欄位為必填'
                    }
                  ]}
                  labelCol={{span: 6}}
                >
                  <TextArea rows={4} maxLength={200} />
                </Form.Item>
              </Col>
            </Row>
          </Modal>
        </BrowserView>
      </Form>
      <Form
        form={form}
      >
        <MobileView>
          <Modal
            title='簽核'
            visible={signAndRejectModalVisible}
            onCancel={() => dispatch(setSignAndRejectModalVisible(false))}
            width={'80vw'}
            centered
            footer={[
              <>
                <Popconfirm
                  title='確定要駁回?'
                  onConfirm={() => onSubmit('reject')}
                  okText='確定'
                  cancelText='取消'
                  okButtonProps={{ style: { width: '45%', height: '45px' } }}
                  cancelButtonProps={{ style: { width: '45%', height: '45px' } }}
                  placement='topRight'
                >
                    <Button style={{ color: 'white', background: 'red' }} > 駁回 </Button>
                </Popconfirm>
                <Popconfirm
                  title='確定要審核通過?'
                  onConfirm={() => onSubmit('agree')}
                  okText='確定'
                  cancelText='取消'
                  okButtonProps={{ style: { width: '45%', height: '45px' } }}
                  cancelButtonProps={{ style: { width: '45%', height: '45px' } }}
                  placement='topRight'
                >
                  <Button type='primary'> 簽核通過 </Button>
                </Popconfirm>
              </>
            ]}
            // bodyStyle={{ height: '80vh' }}
          >
            <Row>
              <Col span={6}>
                <InputPrefix label={'備註'} />
              </Col>
              <Col span={18}>
                <Form.Item
                  name={'audit_command'}
                  rules={[
                    {
                      required: isCommentRequired,
                      message: '若駁回備註欄位為必填'
                    }
                  ]}
                  labelCol={{span: 6}}
                >
                  <TextArea rows={4} maxLength={200} />
                </Form.Item>
              </Col>
            </Row>
          </Modal>
        </MobileView>
      </Form>
    </>
  )
}