import StampAuth from "../api/StampRequest"
import stamp from "../api/stampRequest1"

export const getSystemUsreInfo = () => async(dispatch) =>{
  const resp = await StampAuth(dispatch).get('api/Login/GetSystemUserInfo')
  return resp.data
}

export const getPermissionTree = (function_codes) => async (dispatch) =>{
  const resp = await StampAuth(dispatch).post('api/Login/GetPermissionTree', { function_codes: function_codes })
  return resp.data
}

export const getRoleId = () => async (dispatch) =>{
  const resp = await stamp(dispatch).get('Auth/GetAccess')
  return resp.data
}

//下面兩個還沒用到
export const authCheck = () => async(dispatch) =>{
  const resp = await stamp(dispatch).get('Auth/Authcheck')
  return resp.data
}

export const Info = () => async(dispatch) =>{
  const resp = await stamp(dispatch).get('Auth/Info')
  return resp.data
}