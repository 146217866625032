import React, { useEffect, useState } from "react";
import { Table, Select } from 'antd';

import {
  useSearchParams,
} from "react-router-dom";

const { Option } = Select;


export default function PageTable(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const p = searchParams.get("p") || 1;
  const ps = searchParams.get("ps") || 10;
  const [pages, setPages] = useState(parseInt(p));
  const [size, setSize] = useState(parseInt(ps));

  useEffect(() => {
    setPages(parseInt(p))
  }, [p]);

  return (
    <Table
    style={props.style}
      rowKey={(record,index)=> index}
      rowClassName={(record, index) =>
        index % 2 === 0 ? "table-row-light" : "table-row-dark"
      }
      pagination={{
        size: "small",
        // hideOnSinglePage:true,
        locale: { items_per_page: ` / 頁` },
        pageSizeOptions: [5, 10, 25, 50, 100],
        onChange: (current, pageSize) => {
          setPages(current);
          setSize(pageSize);
          searchParams.set("p", current);
          searchParams.set("ps", pageSize);
          setSearchParams(searchParams);
          window.scrollTo(0, 0);
        },
        showTotal: (total) =>
          `第${pages}頁，共${Math.ceil(total / size)}頁，共${total}筆`,
        showSizeChanger: true,
        total:props?.total || null,
        pageSize: size,
        current: pages,
        position: ["topRight","bottomRight"],
      }}
      {...props}
    />
  )
}
