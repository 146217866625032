import React from 'react'
import { loginRequest } from "../authConfig"
import { Row, Col, Button, Spin } from 'antd'
import { useMsal, useIsAuthenticated } from '@azure/msal-react'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { MobileView, BrowserView } from 'react-device-detect'


const Login = () => {
  const { instance, accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const isLoading = useSelector(state => state.globalSetting.isLoading)

  async function handleLogin () {
    await instance.loginRedirect(loginRequest)
  }

  async function handleLogout () {
    const account = accounts[0]
    const logoutRequest = {
        account: account,
        postLogoutRedirectUri: '/register',
    }
    await instance.logoutRedirect(logoutRequest)
  }

  const contentStyle={
    padding:'80px', 
    textAlign: 'center'
  }

  const pStyle={
    fontWeight: 600, 
    letterSpacing: '0.05em'
  }

  return (
    <> 
      <Spin tip="Loading..." spinning={ isLoading !== 0 }>
        <BrowserView>
          <Row align='middle' justify='center' style={{ height: '100vh', padding:'80px', margin: '0px auto', overflow:'auto' }}>
            <Col span={11} style={contentStyle}>
              <Row justify='center' align='middle' >
                <Col span={24}>
                  <div 
                  className='wigreen'
                  style={{
                    fontSize: '36px',
                    fontWeight: 700,
                  }}
                  ><span>Welcome</span></div>
                  <div 
                  style={{
                    fontSize: '30px',
                    fontWeight: 700,
                    color: '#ffffff'
                  }}
                  >WiAdvance EIP</div>
                </Col>
                <Col span={24}>
                  <img style={{width:'100%', maxWidth:'720px', marginTop:'20px'}} alt='WiAdvance EIP' src={'/login.png'}/>
                </Col>
              </Row>
            </Col>
            <Col span={1}>
              <div style={{    
                width: '1px',
                margin: '50px auto',
                height: '440px',
                borderRight: '1px solid white'
              }}></div>
            </Col>
            <Col span={12} style={contentStyle}>
              <Row justify='center' align='middle' >
                <Col style={{fontSize: '36px', fontWeight: 700, color: 'white'}}>
                  Sign In
                </Col>
                <Col span={24} style={{marginTop:'100px'}}>
                  <Button
                    type='primary'
                    onClick={()=>  handleLogin()}
                    >
                    Wiadvance Sign In
                  </Button>
                </Col>
                <Col span={24} style={{...pStyle, marginTop:'20px'}}>
                請使用微軟帳戶登入
                </Col>
                <Col span={24} style={pStyle}>
                無法登入或沒有帳戶？請
                <a 
                  className='wigreen'
                  target='_blank'
                  rel='noreferrer' 
                  href='https://www.wiadvance.com/contact/' 
                  style={{ textDecoration: 'underline'}}>聯繫我們</a>.
                </Col>
              </Row>
            </Col>
          </Row>
        </BrowserView>
        <MobileView>
          <Row justify='center' style={{ height: '100vh', padding:'40px 80px', margin: '0px auto' }}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Row justify='center' align='middle' >
                <Col span={24}>
                  <div className='wigreen' style={{ fontSize: '36px', fontWeight: 700 }}>
                    <span>Welcome</span>
                  </div>
                  <div style={{ fontSize: '30px', fontWeight: 700, color: '#ffffff' }}>WiAdvance EIP</div>
                </Col>
                <Col span={24}>
                  <img style={{width:'100%', maxWidth:'720px', marginTop:'20px'}} alt='WiAdvance EIP' src={'/login.png'}/>
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{ width: '100%', margin: '25px auto', height: '1px', borderBottom: '1px solid white'}} />
            <Col span={24}>
              <Row justify='center' align='middle' style={{ textAlign:'center' }} >
                <Col style={{fontSize: '36px', fontWeight: 700, color: 'white', margin: '0 0 100px 0'}}>
                  Sign In
                </Col>
                <Col span={24}>
                  <Button type='primary' onClick={()=>  handleLogin()} >
                    Wiadvance Sign In
                  </Button>
                </Col>
                <Col span={24} style={{...pStyle, marginTop:'20px'}}>
                  請使用微軟帳戶登入
                </Col>
                <Col span={24} style={pStyle}>
                  無法登入或沒有帳戶？請
                <a className='wigreen' target='_blank' rel='noreferrer'  href='https://www.wiadvance.com/contact/' style={{ textDecoration: 'underline'}}>聯繫我們</a>.
                </Col>
              </Row>
            </Col>
          </Row>
        </MobileView>
      </Spin>
    </>
  )
}

export default Login;
