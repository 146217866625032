/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import {
  Breadcrumb,
  Button,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Checkbox,
  Form,
  Upload,
  List,
  Skeleton,
  message,
  notification,
  Modal
} from "antd";
import {
  UploadOutlined,
  CheckCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import PageTitle from "../components/PageTitle";
import PageBtns from "../components/PageBtns";
import PageLayout from "../components/PageLayout";
import { acquireAccessToken } from "../msalInstance";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { uploadPtsList } from "../newApi/pts.api";
import "antd/dist/antd.css";
import axios from "axios";

export default function PtsUpload() {
  const [pageLoading, setPageLoading] = useState(false);
  const [NavForm] = Form.useForm();

  const [uploadedList, setUploadedList] = useState([]);

  // 上傳動作
  const fileUpload = async (option) => {
    const { file } = option;
    const accessToken = await acquireAccessToken();
    const formData = new FormData();
    formData.append("file", file);
    notification.info({
      message: "請耐心等候...",
      description: "上傳資料須稍待片刻",
      key: "loadingNotification",
      duration: 0,
      icon: <LoadingOutlined style={{ color: "#108ee9" }}/>,
    });
    setPageLoading(true);
    uploadPtsList(formData)
      .then((resp) => {
        if (resp?.isSuccess) {
          // notification.success({
          //   message: "成功",
          //   description: "上傳成功",
          //   duration: 0,
          //   // icon: <CheckCircleFilled style={{ color: "#7DB926" }} />,
          // });
          Modal.success({
            title: '上傳成功',
            content: 'PTS檔案上傳成功!',
            okText:'確認'
          });
        } else {
          // notification.error({
          //   message: "失敗",
          //   description:
          //     (resp?.errorMessage === "" || !resp?.errorMessage)
          //       ? "服務異常，請聯繫MIS。"
          //       : resp?.errorMessage,
          // });
          Modal.error({
            title: '上傳失敗',
            content: 'PTS檔案上傳失敗...',
            okText:'確認'
          });
        }
      })
      .finally(() => {
        notification.close("loadingNotification");
        setPageLoading(false);
      });
    // await axios({
    //   method: "post",
    //   headers: {
    //     Authorization: accessToken,
    //     "responseType": "arraybuffer"
    //   },
    //   url: `${process.env.REACT_APP_API_URL}api/File/PTS`,
    //   data: formData,
    //   params: {mail:
    //     localStorage.getItem("mockUser") || undefined,
    // }
    // }).then((resp) => {
    //   if (resp.data.code === 200) {
    //     // setUploadedList((prev) => [
    //     //   ...prev,
    //     //   { name: file.name, url: resp.data.data },
    //     // ]);
    //     notification.success({
    //       message: "成功",
    //       description: "上傳成功",
    //     });
    //   } else {
    //     notification.error({
    //       message: "失敗",
    //       description: "上傳失敗",
    //     });
    //   }
    // }).catch(()=>{
    //   notification.error({
    //     message: "失敗",
    //     description: "上傳失敗",
    //   });
    // })
    // .finally(() => setPageLoading(false));;
  };

  return (
    <PageLayout spinning={pageLoading}>
      <BrowserView>
        {/* 頁面目前位置 */}
        <Col span={24}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
            {/* <Breadcrumb.Item href="/Stamp/StampApplication">
              PTS檔案上傳
            </Breadcrumb.Item> */}
            <Breadcrumb.Item>
              PTS檔案上傳
              {!!localStorage.getItem("mockUser") && (
                <span style={{ color: "#ed3b3b" }}>
                  （{localStorage.getItem("mockUser")}）
                </span>
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {/* 頁面標題 */}
        <PageTitle>PTS檔案上傳</PageTitle>
        {/* 頁面搜尋功能 */}
        <BrowserView>
          <Form form={NavForm} style={{ padding: "0px 10px 0px 10px" }}>
            <Row
              style={{
                padding: "15px 10px 15px 15px",
                minHeight: "60px",
                width: "100%",
              }}
              span={24}
            >
              <Col span={24}>
                <Upload
                  name="uploadContract"
                  // {...uploadFileProps}
                  beforeUpload={(file) => {
                    const isXLSX =
                      file.type ===
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                    if (!isXLSX) {
                      message.error(`不支援此檔案格式，僅支援xlsx`);
                    }
                    return isXLSX;
                  }}
                  fileList={null}
                  customRequest={fileUpload}
                  // onRemove={(props) => removeUploadedList(props)}
                  accept=".xlsx"
                >
                  <Button
                    // type={"primary"}
                    ghost
                    type={"primary"}
                    style={{
                      height: "40.14px",
                      fontSize: "15px",
                      padding: "7px",
                      margin: " 5px 5px",
                      width: "240px",
                      border: "1px solid #00a2dd",
                      backgroundColor: "#ffffff",
                    }}
                    icon={<UploadOutlined />}
                  >
                    上傳檔案
                  </Button>
                </Upload>
              </Col>
              <Col
                span={6}
                flex="130px"
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  paddingTop: "10px",
                  alignSelf: "flex-center",
                }}
              ></Col>
            </Row>
          </Form>
        </BrowserView>
        <Col span={24}>
          <Row
            justify="space-between"
            style={{ margin: "10px 10px" }}
            gutter={[24, 8]}
          ></Row>
        </Col>
      </BrowserView>
      <MobileView style={{ marginBottom: "115px" }}>
        {/* 頁面目前位置 */}
        <Col span={24}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
            {/* <Breadcrumb.Item href="/Stamp/StampApplication">
              PTS檔案上傳
            </Breadcrumb.Item> */}
            <Breadcrumb.Item>PTS檔案上傳</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {/* 頁面標題 */}
        <PageTitle>PTS檔案上傳</PageTitle>
        {/* 頁面搜尋功能 */}
        <Col
          style={{
            // margin: "10px 0px",
            padding: "10px 10px",
            minHeight: "60px",
            width: "100%",
            // backgroundColor: "white",
          }}
          span={24}
        >
          <Form
            form={NavForm}
            // onFinish={
            //   /*(v)=>console.log(v.startDate.format(dateFormat))*/
            //   DateSubmitClick
            // }
          >
            <Row justify="center">
              <Col style={{ padding: "7px" }} span={24}>
                <Upload
                  name="uploadContract"
                  // {...uploadFileProps}
                  beforeUpload={(file) => {
                    const isXLSX =
                      file.type ===
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                    if (!isXLSX) {
                      message.error(`不支援此檔案格式，僅支援xlsx`);
                    }
                    return isXLSX;
                  }}
                  fileList={null}
                  customRequest={fileUpload}
                  // onRemove={(props) => removeUploadedList(props)}
                  accept=".xlsx"
                >
                  <Button
                    // type={"primary"}
                    ghost
                    type={"primary"}
                    style={{
                      height: "40.14px",
                      fontSize: "15px",
                      // marginLeft: "auto",
                      width: "270px",
                      border: "1px solid #00a2dd",
                      // alignSelf: "flex-end",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#ffffff",
                    }}
                    icon={<UploadOutlined />}
                  >
                    上傳檔案
                  </Button>
                </Upload>
              </Col>
            </Row>
          </Form>
        </Col>
      </MobileView>
    </PageLayout>
  );
}
