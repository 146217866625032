
import React, { useEffect, useState } from 'react'
import { useMsal, useIsAuthenticated } from '@azure/msal-react'
import RouterConfig from './Router'
import { useDispatch } from 'react-redux'
import { authCheck, getPermissionTree, getRoleId, getSystemUsreInfo, Info } from './redux/action/Login'
import { setToken } from './redux/action/Components'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { notification } from 'antd'

function App() {
  const dispatch = useDispatch()
  const { accounts, instance } = useMsal()
  const [user, setUser] =useState(JSON.parse(localStorage.getItem('userInfo')) || null)
  const [routeList, setRouteList] = useState(JSON.parse(localStorage.getItem('routeList')) || [])
  const [menuList, setMenuList] = useState(JSON.parse(localStorage.getItem('menuList')) || [])
  const [isLoad, setIsLoad] = useState(JSON.parse(sessionStorage.getItem('isLoad') || false))
  const [loading, setLoading] = useState(false)
  const [roleIds, setRoleIds] = useState(JSON.parse(localStorage.getItem('roleIds')) || [])
  const [ refreshTime, setRefreshTime ] = useState( localStorage.getItem('refreshTime') && localStorage.getItem('refreshTime') !== undefined ? JSON.parse(localStorage.getItem('refreshTime')).time : null )
  const isAuthenticated = useIsAuthenticated()
  const token = useSelector(state => state.globalSetting.token)

  const SaveUserInfo = ({resUser, resRouteList, resMenuList, roleIds})=>{
    localStorage.setItem('refreshTime', JSON.stringify({ time : moment().add(8, 'hours').format('YYYY/MM/DD HH:mm:ss')}) )
    setUser(resUser) 
    localStorage.setItem('userInfo', `${JSON.stringify(resUser)}`)

    setRouteList(resRouteList)
    localStorage.setItem('routeList', `${JSON.stringify(resRouteList)}`)

    setMenuList(resMenuList)
    localStorage.setItem('menuList', `${JSON.stringify(resMenuList)}`)

    setRoleIds(roleIds)
    localStorage.setItem('roleIds', `${JSON.stringify(roleIds)}`)
  }

  useEffect(() =>{
    if(isAuthenticated && !token){
      const account = accounts[0]
      const accessTokenRequest = {
        scopes: ['user.read'],
        account: account
      }
      instance.acquireTokenSilent(accessTokenRequest).then((response) => {
        dispatch(setToken(response.accessToken))
      }).catch((e) => {
        instance.acquireTokenPopup(accessTokenRequest).then((response) => {
          dispatch(setToken(response.accessToken))
        })
      })
    }
  }, [isAuthenticated, token])

  useEffect(() =>{
    if (user){
      // if(!refreshTime){
      //   localStorage.setItem('refreshTime', JSON.stringify({ time : moment().add(8, 'hours').format('YYYY/MM/DD HH:mm:ss')}) )
      //   setUser(null)
      // }
      if( moment().diff(refreshTime) > 0 ){
        // localStorage.setItem('refreshTime', JSON.stringify({ time : moment().add(8, 'hours').format('YYYY/MM/DD HH:mm:ss')}) )
        // setRefreshTime(moment().add(8, 'hours').format('YYYY/MM/DD HH:mm:ss'))
        localStorage.removeItem('userInfo')
        localStorage.removeItem('routeList')
        localStorage.removeItem('menuList')
        localStorage.removeItem('currentMenuItemsKey')
        localStorage.removeItem('roleIds')
        setUser(null)
        notification.info({
          message: '提示',
          description: '您已超過8小時未登出本系統，將自動幫您重新登入!'
        })
      }
    }
  }, [dispatch])

  //  檢查sso登入-> 檢查userInfo -> 跳轉管理頁面
  //                            -> 後端取userInfo -> 400跳轉註冊
  //                            -> 成功儲存localStorage
  //             ->跳登入畫面

  function getUserInfo(){ 
    dispatch(getSystemUsreInfo()).then((res) =>{
      if(res.data){
        dispatch(getRoleId()).then(roleIdResp =>{ 
          if(res?.data?.userPrincipalName === "thomas.wu@wiadvance.com"){ //用戶主體名稱
            roleIdResp = 3
          }
          switch(roleIdResp){
            case 1:
              dispatch(getPermissionTree(["E04F01", "E04F02", "E04F05"])).then((resp) =>{
                if(resp.data.length !== 0){
                  localStorage.setItem('currentMenuItemsKey', 'StampApplication')
                  SaveUserInfo(
                    {
                      'resUser':{
                        'name':accounts[0].name,
                        'email':accounts[0].username
                      },
                      'resRouteList':[
                        {
                          'path':'/Stamp/StampApplyAdd',
                          'element':'StampApplyAddAndView',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyView/:id',
                          'element':'StampApplyAddAndView',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyViewPendingUpload/:id',
                          'element':'StampApplyAddAndView',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyReject/:id',
                          'element':'StampApplyRejectAndComplete',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyRejectAuditor/:id',
                          'element':'StampApplyRejectAndComplete',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyComplete/:id',
                          'element':'StampApplyRejectAndComplete',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyWithdraw/:id',
                          'element':'StampApplySignAndWithdraw',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampApplication',
                          'element': 'StampApplication',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampHistory',
                          'element': 'StampHistory',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampPendingUpload',
                          'element': 'StampPendingUpload',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampTempUpload/:id',
                          'element': 'StampTempUpload',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampCancel/:id',
                          'element': 'StampApplyRejectAndComplete',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampApplicationComplete/:id',
                          'element': 'StampApplyRejectAndComplete',
                          'rolefunction':['']
                        },
                        // {
                        //   'path': '/WFH/WFHApplication',
                        //   'element': 'WFHApplication',
                        //   'rolefunction':['']
                        // },
                        // {
                        //   'path': '/WFH/WFHApplication/:id',
                        //   'element': 'WFHApplicationDetail',
                        //   'rolefunction':['']
                        // }
                      ],
                      'resMenuList': resp.data[0],
                      'roleIds': res.data.roleIds
                    }
                  )
                  setLoading(false)
                }
              })
            break
            case 2:
              dispatch(getPermissionTree(["E04F01", "E04F02", "E04F03", "E04F04", "E04F05"])).then((resp) =>{
                if(resp.data.length !== 0){
                  localStorage.setItem('currentMenuItemsKey', 'StampApplication')
                  SaveUserInfo(
                    {
                      'resUser':{
                        'name':accounts[0].name,
                        'email':accounts[0].username
                      },
                      'resRouteList':[
                        {
                          'path':'/Stamp/StampApplyAdd',
                          'element':'StampApplyAddAndView',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyView/:id',
                          'element':'StampApplyAddAndView',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyViewAudited/:id',
                          'element':'StampApplyAddAndView',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyViewPendingUpload/:id',
                          'element':'StampApplyAddAndView',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyReject/:id',
                          'element':'StampApplyRejectAndComplete',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyRejectAuditor/:id',
                          'element':'StampApplyRejectAndComplete',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyComplete/:id',
                          'element':'StampApplyRejectAndComplete',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplySign/:id',
                          'element':'StampApplySignAndWithdraw',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyWithdraw/:id',
                          'element':'StampApplySignAndWithdraw',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampApplication',
                          'element': 'StampApplication',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampHistory',
                          'element': 'StampHistory',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampPendingAudit',
                          'element': 'StampPendingAudit',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampAudited',
                          'element': 'StampAudited',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampPendingUpload',
                          'element': 'StampPendingUpload',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampTempUpload/:id',
                          'element': 'StampTempUpload',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampCancel/:id',
                          'element': 'StampApplyRejectAndComplete',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampApplicationComplete/:id',
                          'element': 'StampApplyRejectAndComplete',
                          'rolefunction':['']
                        }
                      ],
                      'resMenuList': resp.data[0],
                      'roleIds': res.data.roleIds
                    }
                  )
                  setLoading(false)
                }
              })
              break
            case 3:
              dispatch(getPermissionTree(["E04F01", "E04F02", "E04F03", "E04F04", "E04F05", "E04F06"])).then((resp) =>{
                if(resp.data.length !== 0){
                  localStorage.setItem('currentMenuItemsKey', 'StampApplication')
                  SaveUserInfo(
                    {
                      'resUser':{
                        'name':accounts[0].name,
                        'email':accounts[0].username
                      },
                      'resRouteList':[
                        {
                          'path':'/Stamp/StampApplyAdd',
                          'element':'StampApplyAddAndView',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyView/:id',
                          'element':'StampApplyAddAndView',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyViewAudited/:id',
                          'element':'StampApplyAddAndView',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyViewPendingUpload/:id',
                          'element':'StampApplyAddAndView',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyViewPendingProcessed/:id',
                          'element':'StampApplyAddAndView',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyReject/:id',
                          'element':'StampApplyRejectAndComplete',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyRejectAuditor/:id',
                          'element':'StampApplyRejectAndComplete',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyComplete/:id',
                          'element':'StampApplyRejectAndComplete',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplyWithdraw/:id',
                          'element':'StampApplySignAndWithdraw',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampApplication',
                          'element': 'StampApplication',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampHistory',
                          'element': 'StampHistory',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampAudited',
                          'element': 'StampAudited',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampPendingProcessed',
                          'element': 'StampPendingProcessed',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampPendingUpload',
                          'element': 'StampPendingUpload',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampTempUpload/:id',
                          'element': 'StampTempUpload',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampCloseAudit/:id',
                          'element': 'StampCloseAudit',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampOwnerAudit/:id',
                          'element': 'StampOwnerAudit',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampCancel/:id',
                          'element': 'StampApplyRejectAndComplete',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampApplicationComplete/:id',
                          'element': 'StampApplyRejectAndComplete',
                          'rolefunction':['']
                        },
                        {
                          'path': '/Stamp/StampPendingAudit',
                          'element': 'StampPendingAudit',
                          'rolefunction':['']
                        },
                        {
                          'path':'/Stamp/StampApplySign/:id',
                          'element':'StampApplySignAndWithdraw',
                          'rolefunction':['']
                        },
                      ],
                      'resMenuList': resp.data[0],
                      'roleIds': res.data.roleIds
                    }
                  )
                  setLoading(false)
                }
              })
              break
            default:
              return
          }
        })
      }
    })
  }

  useEffect(() => {
    if(isAuthenticated) {
      if(user === null){
        setLoading(true)
        sessionStorage.setItem('isLoad', true)
        getUserInfo()
      }
      else{
        setLoading(false)
      }
    }
    // else {
    //   if(user){
    //     localStorage.removeItem('userInfo')
    //     localStorage.removeItem('routeList')
    //     localStorage.removeItem('menuList')
    //     localStorage.removeItem('currentMenuItemsKey')
    //     localStorage.removeItem('roleIds')
    //     sessionStorage.removeItem('isLoad')
    //     window.location.reload()
    //   }
    // }
  }, [isAuthenticated, user])

  
  return(
    <div className="App">
      {RouterConfig(user, routeList, menuList, loading, roleIds)}
    </div>
  )

}

export default App;
