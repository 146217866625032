/* eslint-disable no-loop-func */
import React, { useEffect, useState } from "react";
import { useOutletContext, useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  Upload,
  Row,
  Col,
  Modal,
  Select,
  Checkbox,
  Form,
  notification,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { connect, useDispatch, useSelector } from "react-redux";
import PageTitle from "../components/PageTitle";
import PageLayout from "../components/PageLayout";
import InputPrefix from "../helper/InputPrefix";
import moment from "moment";
import {
  fakeGetManagerChainList,
  fakeGetMyManager,
  getAllWiadvanceUsers,
  getManagerChainList,
  getMSId,
  getMyManager,
} from "../redux/action/graph";
import SignRecordModal from "../components/SignRecordModal";
import {
  setSignReocrdModalVisible,
  setViewFileModalVisible,
  viewFiledata,
} from "../redux/action/Components";
import {
  addStamp,
  getLookupCodeList,
  getMenuCount,
  getStampDetail,
  hsinchuInvoice,
} from "../redux/action/Stamp";
import { acquireAccessToken } from "../msalInstance";
import axios from "axios";
import types from "../redux/action/types";
import mimeTypeObj from "../helper/mimeTypeObj";
import ViewFileModal from "../components/ViewFileModal";
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";

export default function StampApplyAddAndView() {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  let { id } = useParams();
  const location = useLocation().pathname.split("/")[2];
  const { currentUser } = useOutletContext();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { accounts } = useMsal();
  const [fileList, setFileList] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [isActing, setIsActing] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [baseSignoffProcessList, setBaseSignoffProcessList] = useState([]);
  const [allUserList, setAllUserList] = useState([]);
  const [agentInfo, setAgentInfo] = useState({});
  const [finalManagerList, setFinalManagerList] = useState([]);
  const [uploadedList, setUploadedList] = useState([]);
  const [pendingUploadFile, setPendingUploadFile] = useState([]);
  const [curPage, setCurPage] = useState("");
  // 0 是還沒選
  // 1 是選了其他
  // 2 是選了發票章
  const [stampSelectStatus, setStampSelectStatus] = useState(0);
  const [stampGroup, setStampGroup] = useState("default");
  const [auditer, setAuditer] = useState(currentUser.email);
  let stampList = useSelector((state) => state.Stamp.stampList);
  const isLoading = useSelector((state) => state.globalSetting.isLoading);
  const reApplyData = useSelector((state) => state.Stamp.reApplyData);
  if (curPage === "add") {
    stampList = stampList?.filter((i) => i?.del_flag === "N");
  }

  //初始設定
  useEffect(() => {
    let MSId = accounts[0].localAccountId;
    //編輯頁面
    if (id) {
      dispatch(getStampDetail(id)).then((res) => {
        switch (res["stamp_status"]) {
          case "cancel":
            setCurPage("cancel");
            break;
          default:
            setCurPage("view");
        }
        dispatch(getMSId(res["applicant_mail"])).then((IdResp) => {
          MSId = IdResp["id"];
          dispatch(getManagerChainList(MSId)).then((resp) => {
            setAuditer(res.next_auditor_name);
            let tempManagerNameList = [];
            let tempManagerList = resp;
            dispatch(getLookupCodeList("stampType", "")).then(
              (respStampList) => {
                let directLook =respStampList.filter(i=>i?.text3 === 'direct').map(i=>i.lookup_code)
                if (
                  // res.stamp_type.indexOf("hsinchuInvoice") > -1 ||
                  // res.stamp_type.indexOf("xizhiInvoice") > -1
                  res.stamp_type.some(r=> directLook.includes(r))
                ) {
                  // let stampOwner = respStampList.find(
                  //   (ele) =>
                  //     ele.lookup_code === "hsinchuInvoice" ||
                  //     ele.lookup_code === "xizhiInvoice"
                  // );
                  let stampOwner = respStampList.find(i=>res.stamp_type.includes(i?.lookup_code))

                  tempManagerNameList.push({
                    name: res["applicant_name"],
                    type: "申請人",
                  });
                  tempManagerNameList.push({
                    name: stampOwner["text2"],
                    type: "印信保管人",
                    mail: stampOwner["text1"],
                  });

                  tempManagerList = JSON.parse(
                    JSON.stringify(tempManagerNameList)
                  );
                } else {
                  res["stamp_type"].forEach((stampType, index) => {
                    respStampList.forEach((obj) => {
                      if (obj.lookup_code === stampType) {
                        if (tempManagerNameList.indexOf(obj.text2) === -1) {
                          tempManagerNameList.push(obj.text2);
                        }
                      }
                    });
                  });
                  tempManagerNameList.forEach((name) => {
                    tempManagerList.push({ name: name, type: "印信保管人" });
                  });
                }
                setFinalManagerList(tempManagerList);
              }
            );

            res["cr_date"] = moment
              .unix(res["cr_date"] / 1000)
              .format("YYYY/MM/DD hh:mm:ss");
            // 申請檔案
            if (res["file"]) {
              res["file"].forEach((ele) => {
                let type = "";
                let tempUrl = ele;
                ele = decodeURI(ele).replace(/\+/g, " ");
                ele = ele.split("/");
                ele = ele[ele.length - 1];
                type = ele.slice(-3);
                setFileList((prev) => [
                  ...prev,
                  { url: tempUrl, name: ele, type: mimeTypeObj[type] },
                ]);
              });
            }
            // 留存檔案
            if (res["upload_file_url"]) {
              res["upload_file_url"].forEach((ele) => {
                let type = "";
                let tempUrl = ele;
                ele = decodeURI(ele).replace(/\+/g, " ");
                ele = ele.split("/");
                ele = ele[ele.length - 1];
                type = ele.slice(-3);
                setPendingUploadFile((prev) => [
                  ...prev,
                  { url: tempUrl, name: ele, type: mimeTypeObj[type] },
                ]);
              });
            }
            form.setFieldsValue(res);
          });
        });
      });
      setIsDisable(true);
    }
    // 有重送Data
    else if (Object.keys(reApplyData).length !== 0) {
      setCurPage("add");
      setAgentInfo({
        label: reApplyData["agent_name"],
        value: reApplyData["agent_mail"],
      });
      if (reApplyData["is_agent"] === "N") {
        setIsActing(false);
      } else {
        setIsActing(true);
      }
      form.setFieldValue("is_agent", reApplyData["is_agent"]);
      form.setFieldValue("agent_name", reApplyData["agent_name"]);
      form.setFieldValue("agent_mail", reApplyData["agent_mail"]);
      form.setFieldValue("document", reApplyData["document"]);
      form.setFieldValue("description", reApplyData["description"]);
      dispatch(getMSId(reApplyData["applicant_mail"])).then((MSIdResp) => {
        MSId = MSIdResp.id;
        dispatch(getManagerChainList(MSId)).then((res) => {
          setBaseSignoffProcessList(res);
          setFinalManagerList(res);
        });
      });
    }
    //新增
    else {
      setCurPage("add");
      dispatch(getManagerChainList(MSId)).then((res) => {
        setBaseSignoffProcessList(res);
        setFinalManagerList(res);
      });
    }
  }, []);

  //呼叫graph API取人員資料
  useEffect(() => {
    if (allUserList.length === 0) {
      let tempList = [];
      dispatch(getAllWiadvanceUsers()).then((resp) => {
        if (resp && resp.value !== 0) {
          resp.value.map((items) => {
            tempList.push({ label: items.displayName, value: items.mail });
          });
          setAllUserList(tempList);
        }
      });
    }
  }, []);

  //是否被代理人
  const onClickActing = (click) => {
    setIsActing(click);
    if (!click) {
      form.setFieldValue("agent_name", "");
    }
  };

  //選擇印信
  const onSelectStamp = (value) => {
    if (value.length > 0) {
      let selectedOwner = [];
      let tempProcess = [];
      let selecetedList = [];

      selecetedList = stampList.filter((ele) => {
        if (value.indexOf(ele.lookup_code) > -1 && ele.text3 === "direct") {
          return ele;
        } else {
          return null;
        }
      });

      //選擇新竹章direct
      if (selecetedList.length > 0) {
        //setStampGroup(他的remark)
        setStampSelectStatus(1);
        selectedOwner = stampList.find((ele) => ele.lookup_code === value[0]);
        value.forEach((selectedStamp) => {
          stampList.forEach((obj) => {
            if (obj.lookup_code === selectedStamp) {
              setStampGroup(obj.remark);
            }
          });
        });
        selecetedList.forEach((ele) => {
          if (tempProcess.length === 0) {
            tempProcess.push({ name: accounts[0].name, type: "申請人" });
            tempProcess.push({
              name: ele["text2"],
              type: "印信保管人",
              mail: ele["text1"],
            });
          } else {
            if (
              tempProcess.find((subEle) => subEle.mail === ele.text1) ===
              undefined
            ) {
              tempProcess.push({
                name: ele["text2"],
                type: "印信保管人",
                mail: ele["text1"],
              });
            }
          }
        });

        setFinalManagerList(() => {
          return [...tempProcess];
        });
      }
      //選擇其他章all
      else {
        setStampSelectStatus(2);
        value.forEach((selectedStamp) => {
          stampList.forEach((obj) => {
            if (obj.lookup_code === selectedStamp) {
              setStampGroup(obj.remark);
              if (selectedOwner.indexOf(obj.text2) === -1) {
                selectedOwner.push(obj.text2);
              }
            }
          });
        });
        console.log(tempProcess, value);
        tempProcess.forEach((ele) => {
          if (ele.type === "印章管理人") {
            selectedOwner = selectedOwner.filter((props) => props !== ele.name);
          }
        });

        selectedOwner.forEach((ele) => {
          tempProcess.push({ name: ele, type: "印章管理人" });
        });

        let pushTempProcessList = baseSignoffProcessList.concat(tempProcess);

        setFinalManagerList(() => {
          return [...pushTempProcessList];
        });
      }
    } else {
      setStampSelectStatus(0);
      setStampGroup("default");
      setFinalManagerList(() => {
        return [...baseSignoffProcessList];
      });
    }
  };
  const SelectDisabled = () => {};
  //上傳檔案限制
  const uploadFileProps = {
    beforeUpload: (file) => {
      switch (file.type) {
        case "image/png":
        case "image/gif":
        case "image/jpg":
        case "image/jpeg":
        case "application/pdf":
          return null;
        default:
          notification.error({
            message: "錯誤",
            description: `檔案格式錯誤`,
          });
          return Upload.LIST_IGNORE;
      }
    },
  };

  useEffect(() => {
    console.log(stampSelectStatus);
  }, [stampSelectStatus]);

  // 上傳動作
  const fileUpload = async (option) => {
    const { file } = option;
    const accessToken = await acquireAccessToken();
    const formData = new FormData();
    formData.append("file", file);

    dispatch({ type: types.INC_LOADING, payload: 1 });
    const resp = await axios({
      method: "post",
      headers: {
        Authorization: accessToken,
        "Content-Type": "application/pdf",
      },
      url: `https://eip-portal-api.azurewebsites.net/Upload/UploadFile`,
      data: formData,
    }).then((resp) => {
      dispatch({ type: types.DEC_LOADING, payload: 1 });
      return resp;
    });
    if (resp.data.code === 200) {
      setUploadedList((prev) => [
        ...prev,
        { name: file.name, url: resp.data.data },
      ]);
      notification.success({
        message: "成功",
        description: "上傳成功",
      });
    } else {
      notification.error({
        message: "失敗",
        description: "上傳失敗",
      });
    }
  };

  // 刪除已上傳檔案
  const removeUploadedList = (props) => {
    let result = [];
    uploadedList.forEach((ele) => {
      if (ele["uid"] !== props["uid"]) {
        result.push(ele);
      }
    });
    setUploadedList(result);
  };

  //送出申請
  const onSubmitApply = () => {
    form.validateFields().then((res) => {
      let result = res;
      let tempFileUrl = [];
      if (!uploadedList.length) {
        notification.error({
          message: "錯誤",
          description: "請上傳檔案!",
        });
      } else {
        dispatch(getMyManager(currentUser.email)).then((resp) => {
          if (stampSelectStatus === 1) {
            result["next_auditor_name"] = finalManagerList[1].name;
            result["next_auditor_mail"] = finalManagerList[1].mail;
          } else {
            result["next_auditor_name"] = resp.displayName;
            result["next_auditor_mail"] = resp.mail;
          }
          result["applicant_name"] = currentUser.name;
          result["applicant_mail"] = currentUser.email;
          result["agent_name"] = agentInfo.label;
          result["agent_mail"] = agentInfo.value;
          uploadedList.forEach((item) => {
            tempFileUrl.push(item.url);
          });
          result["file"] = tempFileUrl;
          if (isActing) {
            result["is_agent"] = "Y";
          } else {
            result["is_agent"] = "N";
            result["agent_mail"] = "";
            result["agent_name"] = "";
          }
          Object.keys(result).forEach((key) => {
            if (result[key] === undefined) {
              result[key] = "";
            }
          });
          if (resp.error !== undefined) {
            result["next_auditor_name"] = "None";
            result["next_auditor_mail"] = "None";
          }
          if (stampSelectStatus === 1) {
            dispatch(hsinchuInvoice(result)).then((res) => {
              if (res && res.code === 200) {
                dispatch(getMenuCount());
                notification.success({
                  message: "成功",
                  description: "新增成功",
                });
                navigate("/Stamp/StampApplication");
              } else {
                notification.error({
                  message: "失敗",
                  description: res.meesage,
                });
              }
            });
          } else {
            dispatch(addStamp(result)).then((res) => {
              if (res && res.code === 200) {
                dispatch(getMenuCount());
                notification.success({
                  message: "成功",
                  description: "新增成功",
                });
                navigate("/Stamp/StampApplication");
              } else {
                notification.error({
                  message: "失敗",
                  description: res.meesage,
                });
              }
            });
          }
        });
      }
    });
  };

  const openViewFileModal = (type) => {
    if (type === "apply") {
      dispatch(viewFiledata(fileList));
    } else {
      dispatch(viewFiledata(pendingUploadFile));
    }
    dispatch(setViewFileModalVisible(true));
  };

  const navigateFunction = () => {
    switch (location) {
      case "StampApplyViewPendingUpload":
        navigate("/Stamp/StampPendingUpload");
        break;
      case "StampApplyViewAudited":
        navigate("/Stamp/StampAudited");
        break;
      case "StampApplyViewPendingProcessed":
        navigate("/Stamp/StampPendingProcessed");
        break;
      default:
        navigate("/Stamp/StampApplication");
    }
  };

  return (
    <>
      <BrowserView>
        <PageLayout spinning={isLoading !== 0 ? true : false}>
          {/* 頁面目前位置 */}
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
              <Breadcrumb.Item href="/Stamp/StampApplication">
                用印申請
              </Breadcrumb.Item>
              {location === "StampApplyViewAudited" ? (
                <>
                  <Breadcrumb.Item href="/Stamp/StampAudited">
                    已審核
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>檢視用印單</Breadcrumb.Item>
                </>
              ) : location === "StampApplyViewPendingUpload" ? (
                <>
                  <Breadcrumb.Item href="/Stamp/StampPendingUpload">
                    待上傳
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>檢視用印單</Breadcrumb.Item>
                </>
              ) : location === "StampApplyViewPendingProcessed" ? (
                <>
                  <Breadcrumb.Item href="/Stamp/StampPendingProcessed">
                    待處理
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>檢視用印單</Breadcrumb.Item>
                </>
              ) : location === "StampApplyView" ? (
                <>
                  <Breadcrumb.Item href="/Stamp/StampApplication">
                    我的申請
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {curPage === "cancel" ? "取消申請" : "檢視用印單"}
                  </Breadcrumb.Item>
                </>
              ) : (
                <>
                  <Breadcrumb.Item href="/Stamp/StampApplication">
                    我的申請
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>新增用印單</Breadcrumb.Item>
                </>
              )}
            </Breadcrumb>
          </Col>
          {/* 頁面標題 */}
          <PageTitle>
            {curPage === "cancel"
              ? "取消申請"
              : curPage === "view"
              ? "檢視用印單"
              : "新增用印單"}
          </PageTitle>
          <Col span={24}>
            <div style={{ backgroundColor: "white" }}>
              <Modal
                title="確認"
                visible={isConfirmModalOpen}
                okText="確認"
                cancelText="取消"
                onCancel={() => setIsConfirmModalOpen(false)}
                onOk={() => onSubmitApply()}
                cancelButtonProps={{
                  style: { width: "auto" },
                }}
                okButtonProps={{
                  style: { width: "auto" },
                }}
              >
                確定要同意這筆用印申請?
              </Modal>
              <Form
                form={form}
                autoComplete={false}
                style={{ paddingTop: "10px" }}
                initialValues={
                  !id
                    ? {
                        applicant_name: currentUser.name,
                      }
                    : null
                }
              >
                {/* 申請單號 */}
                {id ? (
                  <Row
                    gutter={[24, 8]}
                    style={{ color: "black", paddingBottom: "15px" }}
                  >
                    <Col span={5}>
                      <InputPrefix label={"申請單號"} />
                    </Col>
                    <Col span={6}>
                      <Form.Item name="stamp_no">
                        <Input bordered={false} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null}
                {/* 申請人，被代理人申請 */}
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={5}>
                    <InputPrefix require={"true"} label={"申請人"} />
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="applicant_name"
                      rules={[
                        {
                          required: true,
                          message: "請輸入申請人!",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        style={{ width: "80%" }}
                        bordered={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    {id ? (
                      //檢視頁面
                      <Form.Item
                        name="is_agent"
                        valuePropName="checked"
                        style={{ alignItems: "center" }}
                      >
                        <Row>
                          <Checkbox
                            checked={
                              form.getFieldValue("is_agent") === "Y"
                                ? true
                                : false
                            }
                            disabled
                          >
                            {" "}
                            被代理人{" "}
                          </Checkbox>
                          <Form.Item
                            name="agent_name"
                            hidden={form.getFieldValue("is_agent") === "N"}
                            style={{ width: "80%", marginBottom: "0" }}
                          >
                            <Input
                              disabled
                              style={{ width: "80%" }}
                              bordered={false}
                            />
                          </Form.Item>
                        </Row>
                      </Form.Item>
                    ) : (
                      // 新增頁面
                      <Form.Item name="is_agent" valuePropName="checked">
                        <Row>
                          <Checkbox
                            checked={isActing ? true : false}
                            onChange={(e) => onClickActing(e.target.checked)}
                          >
                            {" "}
                            被代理人{" "}
                          </Checkbox>
                          <Form.Item
                            name="agent_name"
                            hidden={!isActing}
                            style={{ width: "80%", marginBottom: "0" }}
                          >
                            <Select
                              showSearch
                              bordered={false}
                              onSelect={(_, option) => setAgentInfo(option)}
                              style={{
                                width: "100%",
                                borderBottom: "1px solid #dddddd",
                              }}
                              options={allUserList}
                              filterOption={(input, option) => {
                                //是英文
                                if (input.search(/^[A-Za-z0-9]*$/) === 0) {
                                  return (option?.label ?? "")
                                    .toLowerCase()
                                    .startsWith(input.toLowerCase());
                                }
                                //不是英文
                                else {
                                  return (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase());
                                }
                              }}
                            />
                          </Form.Item>
                        </Row>
                      </Form.Item>
                    )}
                  </Col>
                </Row>
                {/* 簽核印信 */}
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={5}>
                    <InputPrefix require={"true"} label={"簽核印信"} />
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      name="stamp_type"
                      rules={[
                        {
                          required: true,
                          message: "請選擇印信",
                        },
                      ]}
                    >
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        onChange={(e) => onSelectStamp(e)}
                        disabled={isDisable}
                      >
                        <Row gutter={[0, 8]}>
                          {" "}
                          {stampList.map((item) => {
                            return (
                              <>
                                <Col span={12}>
                                  <Checkbox
                                    disabled={
                                      // item["text3"] !== "direct" &&
                                      // stampSelectStatus === 1
                                      //   ? true
                                      //   : item["text3"] === "direct" &&
                                      //     stampGroup !== item["remark"] &&
                                      //     stampGroup !== "default"
                                      //   ? true
                                      //   : item["text3"] === "direct" &&
                                      //     stampSelectStatus === 2
                                      //   ?
                                      //     true
                                      //   : false
                                      stampGroup !== item["remark"] &&
                                      stampGroup !== "default"
                                    }
                                    value={item.lookup_code}
                                    key={item.lookup_code}
                                  >
                                    {" "}
                                    {item.description}{" "}
                                  </Checkbox>
                                </Col>
                              </>
                            );
                          })}
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={5}>
                    <InputPrefix label={"申請日期"} />
                  </Col>
                  <Col span={6}>
                    {
                      //檢視頁面
                      id ? (
                        <Form.Item name={"cr_date"}>
                          <Input bordered={false} disabled />
                        </Form.Item>
                      ) : (
                        <Form.Item>
                          <Input
                            defaultValue={moment().format("YYYY-MM-DD")}
                            bordered={false}
                            disabled
                          />
                        </Form.Item>
                      )
                    }
                  </Col>
                </Row>
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={5}>
                    <InputPrefix require={"true"} label={"文件名稱"} />
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="document"
                      rules={[
                        {
                          required: true,
                          message: "請輸入文件名稱",
                        },
                      ]}
                    >
                      {
                        <TextArea
                          rows={4}
                          maxLength={50}
                          showCount
                          disabled={isDisable}
                        />
                      }
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={5}>
                    <InputPrefix label={"內容簡述"} />
                  </Col>
                  <Col span={6}>
                    <Form.Item name="description">
                      <TextArea
                        rows={6}
                        maxLength={100}
                        showCount
                        disabled={isDisable}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={5}>
                    <InputPrefix label={"檔案"} require={"true"} />
                  </Col>
                  <Col span={6}>
                    {isDisable ? (
                      <Button onClick={() => openViewFileModal("apply")}>
                        {" "}
                        查看檔案{" "}
                      </Button>
                    ) : (
                      <>
                        <Upload
                          name="uploadContract"
                          {...uploadFileProps}
                          fileList={uploadedList}
                          customRequest={fileUpload}
                          onRemove={(props) => removeUploadedList(props)}
                          accept="image/jpeg, image/png, image/gif, application/pdf"
                        >
                          <Button
                            icon={<UploadOutlined />}
                            type="primary"
                            disabled={isDisable}
                          >
                            {" "}
                            上傳{" "}
                          </Button>
                        </Upload>
                        <p style={{ color: "red", paddingTop: "5px" }}>
                          {" "}
                          僅能上傳 jpg/jpeg/png/gif/pdf 檔{" "}
                        </p>
                      </>
                    )}
                  </Col>
                </Row>
                {pendingUploadFile.length !== 0 ? (
                  <Row
                    gutter={[24, 8]}
                    style={{ color: "black", paddingBottom: "15px" }}
                  >
                    <Col span={5} />
                    <Col span={6}>
                      <Form.Item>
                        <Button onClick={() => openViewFileModal("keep")}>
                          {" "}
                          查看留存檔案{" "}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null}
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={5}>
                    <InputPrefix label={"簽核流程"} />
                  </Col>
                  <Col span={19}>
                    {id ? (
                      <>
                        <Row gutter={[16]}>
                          {finalManagerList.map((ele, index) => {
                            return (
                              <Col
                                style={{
                                  color:
                                    auditer === ele.name &&
                                    ele.type !== "印信保管人"
                                      ? "blue"
                                      : null,
                                  marginTop: index > 5 ? "15px" : 0,
                                }}
                                span={4}
                              >
                                <p>{ele.type}</p>
                                {ele.name}
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    ) : (
                      <>
                        <Row gutter={[16]}>
                          {finalManagerList.map((ele, index) => {
                            return (
                              <Col
                                style={{
                                  color: index === 0 ? "blue" : null,
                                  marginTop: index > 5 ? "15px" : 0,
                                }}
                                span={4}
                              >
                                <p>{ele.type}</p>
                                {ele.name}
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
                <Row
                  justify="end"
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  {id ? (
                    <>
                      <Col span={3} style={{ padding: "0px 5px" }}>
                        <Button
                          type="primary"
                          onClick={() =>
                            dispatch(setSignReocrdModalVisible(true))
                          }
                        >
                          {" "}
                          簽核紀錄{" "}
                        </Button>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={3} style={{ padding: "0px 5px" }}>
                        <Button type="primary" onClick={() => onSubmitApply()}>
                          {" "}
                          送出{" "}
                        </Button>
                      </Col>
                    </>
                  )}
                  <Col span={3} style={{ padding: "0px 5px" }}>
                    <Button onClick={() => navigateFunction()}> 返回 </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </PageLayout>
      </BrowserView>
      <MobileView style={{ marginBottom: "115px" }}>
        <PageLayout spinning={isLoading !== 0 ? true : false}>
          {/* 頁面目前位置 */}
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
              <Breadcrumb.Item href="/Stamp/StampApplication">
                用印申請
              </Breadcrumb.Item>
              {location === "StampApplyViewAudited" ? (
                <>
                  <Breadcrumb.Item href="/Stamp/StampAudited">
                    已審核
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>檢視用印單</Breadcrumb.Item>
                </>
              ) : location === "StampApplyViewPendingUpload" ? (
                <>
                  <Breadcrumb.Item href="/Stamp/StampPendingUpload">
                    待上傳
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>檢視用印單</Breadcrumb.Item>
                </>
              ) : location === "StampApplyViewPendingProcessed" ? (
                <>
                  <Breadcrumb.Item href="/Stamp/StampPendingProcessed">
                    待處理
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>檢視用印單</Breadcrumb.Item>
                </>
              ) : location === "StampApplyView" ? (
                <>
                  <Breadcrumb.Item href="/Stamp/StampApplication">
                    我的申請
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>檢視用印單</Breadcrumb.Item>
                </>
              ) : (
                <>
                  <Breadcrumb.Item href="/Stamp/StampApplication">
                    我的申請
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>新增用印單</Breadcrumb.Item>
                </>
              )}
            </Breadcrumb>
          </Col>
          {/* 頁面標題 */}
          <PageTitle>{id ? "檢視用印單" : "新增用印單"}</PageTitle>
          <Col span={24}>
            <div style={{ backgroundColor: "white" }}>
              <Modal
                title="確認"
                visible={isConfirmModalOpen}
                okText="確認"
                cancelText="取消"
                onCancel={() => setIsConfirmModalOpen(false)}
                onOk={() => onSubmitApply()}
                cancelButtonProps={{
                  style: { width: "auto" },
                }}
                okButtonProps={{
                  style: { width: "auto" },
                }}
              >
                確定要同意這筆用印申請?
              </Modal>
              <Form
                form={form}
                autoComplete={false}
                labelAlign={"left"}
                labelCol={{ span: 24 }}
                initialValues={
                  !id
                    ? {
                        applicant_name: currentUser.name,
                      }
                    : null
                }
              >
                <Row
                  justify="space-around"
                  gutter={[0, 8]}
                  style={{ color: "black", padding: "0 10px 15px 10px" }}
                >
                  {/* 申請單號 */}
                  {id ? (
                    <>
                      <Col span={24}>
                        <Form.Item name="stamp_no" label="申請單號">
                          <Input bordered={false} disabled />
                        </Form.Item>
                      </Col>
                    </>
                  ) : null}
                  {/* 申請人，被代理人申請 */}
                  <Col span={24}>
                    <Form.Item
                      name="applicant_name"
                      label="申請人"
                      rules={[
                        {
                          required: true,
                          message: "請輸入申請人!",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        style={{ width: "80%" }}
                        bordered={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    {id ? (
                      //檢視頁面
                      <Form.Item
                        name="is_agent"
                        valuePropName="checked"
                        style={{ alignItems: "center" }}
                      >
                        <Row>
                          <Checkbox
                            checked={
                              form.getFieldValue("is_agent") === "Y"
                                ? true
                                : false
                            }
                            disabled
                          >
                            {" "}
                            被代理人{" "}
                          </Checkbox>
                          <Form.Item
                            name="agent_name"
                            hidden={form.getFieldValue("is_agent") === "N"}
                            style={{ width: "80%", marginBottom: "0" }}
                          >
                            <Input
                              disabled
                              style={{ width: "80%" }}
                              bordered={false}
                            />
                          </Form.Item>
                        </Row>
                      </Form.Item>
                    ) : (
                      // 新增頁面
                      <Form.Item name="is_agent" valuePropName="checked">
                        <Row>
                          <Checkbox
                            checked={isActing ? true : false}
                            onChange={(e) => onClickActing(e.target.checked)}
                          >
                            {" "}
                            被代理人{" "}
                          </Checkbox>
                          <Form.Item
                            name="agent_name"
                            hidden={!isActing}
                            style={{ width: "80%", marginBottom: "0" }}
                          >
                            <Select
                              showSearch
                              bordered={false}
                              onSelect={(_, option) => setAgentInfo(option)}
                              style={{
                                width: "100%",
                                borderBottom: "1px solid #dddddd",
                              }}
                              options={allUserList}
                              filterOption={(input, option) => {
                                //是英文
                                if (input.search(/^[A-Za-z0-9]*$/) === 0) {
                                  return (option?.label ?? "")
                                    .toLowerCase()
                                    .startsWith(input.toLowerCase());
                                }
                                //不是英文
                                else {
                                  return (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase());
                                }
                              }}
                            />
                          </Form.Item>
                        </Row>
                      </Form.Item>
                    )}
                  </Col>
                  {/* 簽核印信 */}
                  <Col span={24}>
                    <Form.Item
                      label="簽核印信"
                      name="stamp_type"
                      rules={[
                        {
                          required: true,
                          message: "請選擇印信",
                        },
                      ]}
                    >
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        onChange={(e) => onSelectStamp(e)}
                        disabled={isDisable}
                      >
                        <Row
                          style={{ padding: "0 20px" }}
                          justify="center"
                          gutter={[0, 8]}
                        >
                          {stampList.map((item) => {
                            return (
                              <>
                                <Col span={12}>
                                  <Checkbox
                                    disabled={
                                      // item["text3"] !== "direct" &&
                                      // stampSelectStatus === 1
                                      //   ? true
                                      //   : item["text3"] === "direct" &&
                                      //     stampGroup !== item["remark"] &&
                                      //     stampGroup !== "default"
                                      //   ? true
                                      //   : item["text3"] === "direct" &&
                                      //     stampSelectStatus === 2
                                      //   ?
                                      //     true
                                      //   : false
                                      stampGroup !== item["remark"] &&
                                      stampGroup !== "default"
                                    }
                                    value={item.lookup_code}
                                  >
                                    {" "}
                                    {item.description}{" "}
                                  </Checkbox>
                                </Col>
                              </>
                            );
                          })}
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>
                  {/* 申請日期 */}
                  <Col span={24}>
                    {
                      //檢視頁面
                      id ? (
                        <Form.Item label="申請日期" name={"cr_date"}>
                          <Input bordered={false} disabled />
                        </Form.Item>
                      ) : (
                        <Form.Item label="申請日期">
                          <Input
                            defaultValue={moment().format("YYYY-MM-DD")}
                            bordered={false}
                            disabled
                          />
                        </Form.Item>
                      )
                    }
                  </Col>
                  {/* 文件名稱 */}
                  <Col span={24}>
                    <Form.Item
                      name="document"
                      label="文件名稱"
                      rules={[
                        {
                          required: true,
                          message: "請輸入文件名稱",
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        maxLength={50}
                        showCount
                        disabled={isDisable}
                      />
                    </Form.Item>
                  </Col>
                  {/* 內容簡述 */}
                  <Col span={24}>
                    <Form.Item name="description" label="內容簡述">
                      <TextArea
                        rows={6}
                        maxLength={100}
                        showCount
                        disabled={isDisable}
                      />
                    </Form.Item>
                  </Col>
                  {/* 檔案 */}
                  <Col span={24}>
                    <span
                      style={{
                        color: "black",
                        fontWeight: 600,
                        fontSize: "16px",
                      }}
                    >
                      {" "}
                      檔案{" "}
                    </span>
                  </Col>
                  <Col span={24}>
                    {isDisable ? (
                      <Button onClick={() => openViewFileModal("apply")}>
                        {" "}
                        查看檔案{" "}
                      </Button>
                    ) : (
                      <>
                        <Upload
                          name="uploadContract"
                          {...uploadFileProps}
                          fileList={uploadedList}
                          customRequest={fileUpload}
                          onRemove={(props) => removeUploadedList(props)}
                          accept="image/jpeg, image/png, image/gif, application/pdf"
                        >
                          <Button
                            icon={<UploadOutlined />}
                            type="primary"
                            disabled={isDisable}
                          >
                            {" "}
                            上傳{" "}
                          </Button>
                        </Upload>
                        <p style={{ color: "red", paddingTop: "5px" }}>
                          {" "}
                          僅能上傳 jpg/jpeg/png/gif/pdf 檔{" "}
                        </p>
                      </>
                    )}
                  </Col>
                  {/* 簽核流程 */}
                  <Col span={24}>
                    <span
                      style={{
                        color: "black",
                        fontWeight: 600,
                        fontSize: "16px",
                      }}
                    >
                      {" "}
                      簽核流程{" "}
                    </span>
                  </Col>
                  <Col span={24} style={{ marginBottom: "15px" }}>
                    {id ? (
                      <>
                        <Row gutter={[16]}>
                          {finalManagerList.map((ele, index) => {
                            return (
                              <Col
                                style={{
                                  color:
                                    auditer === ele.name &&
                                    ele.type !== "印信保管人"
                                      ? "blue"
                                      : null,
                                  marginTop: index > 2 ? "15px" : 0,
                                }}
                                span={8}
                              >
                                <p>{ele.type}</p>
                                {ele.name}
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    ) : (
                      <>
                        <Row gutter={[10, 30]}>
                          {finalManagerList.map((ele, index) => {
                            return (
                              <Col
                                style={{
                                  color: index === 0 ? "blue" : null,
                                  marginTop: index > 2 ? "15px" : 0,
                                }}
                                span={8}
                              >
                                <p>{ele.type}</p>
                                {ele.name}
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    )}
                  </Col>
                  {/* 按鈕 */}
                  <Col span={8} style={{ padding: "0px 5px" }}>
                    <Button onClick={() => navigateFunction()}> 返回 </Button>
                  </Col>
                  {id ? (
                    <>
                      <Col span={8} style={{ padding: "0px 5px" }}>
                        <Button
                          type="primary"
                          onClick={() =>
                            dispatch(setSignReocrdModalVisible(true))
                          }
                        >
                          {" "}
                          簽核紀錄{" "}
                        </Button>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={8} style={{ padding: "0px 5px" }}>
                        <Button type="primary" onClick={() => onSubmitApply()}>
                          {" "}
                          送出{" "}
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </Form>
            </div>
          </Col>
        </PageLayout>
      </MobileView>
      <SignRecordModal />
      <ViewFileModal />
    </>
  );
}
