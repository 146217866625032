import types from "../action/types"

/* eslint-disable import/no-anonymous-default-export */
const INTIAL_STATE = {
  reApplyData: {},
  myApplicationList: {},
  stampHistoryList: {},
  pendingAuditList: {},
  auditedList: {},
  menuCount: {},
  stampList: [],
  stampTableList: [],
  tableProps: {},
  viewFileData: [],
  globalParams: {}
}

export default (state = INTIAL_STATE, action) =>{
  switch(action.type){
    //取得印章
    case types.GET_STAMP:
      return{ ...state, stampList: action.payload }
    //重送資料
    case types.RE_APPLY_DATA:
      return{ ...state, reApplyData: action.payload }
    //取得用印清單
    case types.GET_STAMP_LIST:
      return{ ...state, stampTableList: action.payload }
    //Set Table Count
    case types.TABLE_PROPS:
      return{ ...state, tableProps: action.payload }
    //我的申請清單
    case types.MY_APPLICATION_LIST:
      return{ ...state, myApplicationList: action.payload }
    //歷史單據清單
    case types.STAMP_HISTORY_LIST:
      return{ ...state, stampHistoryList: action.payload }
    //待審核清單
    case types.PENDING_AUDIT_LIST:
      return{ ...state, pendingAuditList: action.payload }
    //已審核清單
    case types.AUDITED_LIST:
      return{ ...state, auditedList: action.payload }
    //取得SideMenu Count
    case types.MENU_COUNTS:
      return{ ...state, menuCount: action.payload }
    //檢視檔案url
    case types.VIEW_FILE_DATA:
      return{ ...state, viewFileData: action.payload }
    //搜尋Params
    case types.SET_GLOBAL_PARAMS:
      return{ ...state, globalParams: action.payload }
    default:
      return state
  }
}