/* eslint-disable no-loop-func */
import React, {useEffect,useState} from 'react'
import { useOutletContext, useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, Button, Input, Upload, Space, Row, Col, Table, Switch, message, Modal, Popconfirm, Select, DatePicker, Checkbox, Form, Radio, AutoComplete, notification, Image } from 'antd'
import { UploadOutlined, PlusOutlined, MinusCircleOutlined, EyeOutlined, SendOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../components/PageTitle';
import PageLayout from '../components/PageLayout';
import InputPrefix from '../helper/InputPrefix';
import moment from 'moment';
import { getAllWiadvanceUsers } from '../redux/action/graph';
import SignRecordModal from '../components/SignRecordModal'
import { setSignAndRejectModalVisible, setSignReocrdModalVisible, setViewFileModalVisible, viewFiledata } from '../redux/action/Components';
import SignAndRejectModal from '../components/SignAndRejectModal';
import { getStampDetail } from '../redux/action/Stamp';
import mimeTypeObj from '../helper/mimeTypeObj';
import ViewFileModal from '../components/ViewFileModal';
import { BrowserView, MobileView } from 'react-device-detect';

export default function StampCloseAudit () {

	const dispatch = useDispatch()
	const { TextArea } = Input
	let { id } = useParams()
	const { currentUser } = useOutletContext();
	const [form] = Form.useForm()
	//給pdf viewer
	const [ PDFForm ] = Form.useForm()
	const navigate  = useNavigate()
	// 申請檔案
	const [ fileList, setFileList ] = useState([])
	// 留存檔案
	const [ pendingUploadFile, setPendingUploadFile ] = useState([])
	const [ stampOwnerList, setStampOwnerList ] = useState([])
	const [ signoffProcess, setSignoffProcess ] = useState([]) 
	const managerList = useSelector(state => state.graph.managerList)
	const isLoading = useSelector(state => state.globalSetting.isLoading)
  const stampList = useSelector(state => state.Stamp.stampList)
	console.log(currentUser)

		//初始設定
		useEffect(() =>{
			dispatch(getStampDetail(id)).then(res =>{
				if( res['next_auditor_mail'].split(',').indexOf(currentUser['email']) === -1){
					navigate('/Stamp/StampApplication')
					notification.error({
						message: '錯誤',
						description: '您非此單據的結案人或您已結束此單據!',
						duration: 6
					})
				}
				else{	
					setStampOwnerList(res['next_auditor_mail'])
					res['cr_date'] = moment.unix(res['cr_date']/1000).format('YYYY/MM/DD hh:mm:ss')
					// 申請檔案
					if(res['file']){
						res['file'].forEach( ele =>{
							let type = ''
							let tempUrl = ele
							ele = decodeURI(ele).replace( /\+/g, ' ' )
							ele = ele.split('/')
							ele = ele[ele.length-1]
							type = ele.slice(-3)
							setFileList(prev => [ ...prev, { url: tempUrl, name: ele, type: mimeTypeObj[type] }])
						})
					}
					// 留存檔案
					if(res['upload_file_url']){
						res['upload_file_url'].forEach( ele =>{
							let type = ''
							let tempUrl = ele
							ele = decodeURI(ele).replace( /\+/g, ' ' )
							ele = ele.split('/')
							ele = ele[ele.length-1]
							type = ele.slice(-3)
							setPendingUploadFile(prev => [ ...prev, { url: tempUrl, name: ele, type: mimeTypeObj[type] } ])
						})
					}
					form.setFieldsValue(res)
				}
			})
		},[])

	//打開檔案瀏覽Modal
	const openViewFileModal = (type) =>{
		if(type === 'apply'){
			dispatch(viewFiledata(fileList))
		}
		else{
			dispatch(viewFiledata(pendingUploadFile))
		}
		dispatch(setViewFileModalVisible(true))
	}

	return(
		<PageLayout spinning={ isLoading !== 0 ? true : false }>
			<BrowserView>
				{/* 頁面目前位置 */}  
				<Col span={24}>
					<Breadcrumb separator=">">
						<Breadcrumb.Item href='/'>WiAdvance EIP</Breadcrumb.Item>
						<Breadcrumb.Item href="/Stamp/StampApplication">用印申請</Breadcrumb.Item>
						<Breadcrumb.Item href='/Stamp/StampPendingProcessed'>待審核</Breadcrumb.Item>
						<Breadcrumb.Item > 結案審核 </Breadcrumb.Item>
					</Breadcrumb>
				</Col>
				{/* 頁面標題 */}
				<PageTitle>
					結案審核
				</PageTitle>
				<Col span={24}>
					<div style={{ backgroundColor:'white'}}>
						<Form
							form={form}
							autoComplete={false}
							style={{ paddingTop: '10px' }}
						>
							{/* 申請單號 */}
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'申請單號'} />
								</Col>  
								<Col span={6} >
									<Form.Item
										name='stamp_no'
									>
										<Input bordered={false}  disabled />
									</Form.Item>
								</Col>
							</Row>
							{/* 申請人，被代理人申請 */}
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'申請人'} />
								</Col>  
								<Col span={6} >
									<Form.Item
										name='applicant_name'
									>
										<Input bordered={false} disabled/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										name='is_agent'
										valuePropName="checked"
										style={{ alignItems: 'center' }}
									>
										<Row>
											<Checkbox checked={form.getFieldValue('is_agent') === 'Y' ? true : false}  disabled > 被代理人 </Checkbox>
												<Form.Item
													name='agent_name'
													hidden={form.getFieldValue('is_agent') === 'N'}
													style={{ width: '80%', marginBottom: '0' }}
												>
													<Input disabled style={{ width: '80%' }} bordered={false} /> 
											</Form.Item> 
										</Row>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'簽核印信'} />
								</Col>  
								<Col span={6} >
									<Form.Item
										name='stamp_type'
									>
										<Checkbox.Group style={{ width: '100%' }} disabled >
											<Row gutter={[0, 8]}>
											{
												stampList.map( item =>{
													return(
														<>
															<Col span={12}>
																<Checkbox value={item.lookup_code}> {item.description} </Checkbox>
															</Col>
														</>
													)
												})
											}
											</Row>
										</Checkbox.Group>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'申請日期'} />
								</Col>  
								<Col span={6} >
									<Form.Item
										name='cr_date'
									>
										<Input bordered={false} disabled/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'文件名稱'} />
								</Col>
								<Col span={6} >
									<Form.Item
										name='document'
									>
										<TextArea rows={4} maxLength={50} showCount disabled/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'內容簡述'} />
								</Col>  
								<Col span={6} >
									<Form.Item
										name='description'
									>
										<TextArea rows={6} maxLength={100} showCount disabled/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[24,8]} style={{ color: 'black' }}>
								<Col span={5}>
									<InputPrefix label={'檔案'} />
								</Col>  
								<Col span={6} >
									<Form.Item
									>
										<Button onClick={() => openViewFileModal('apply')}> 查看申請檔案 </Button>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5} />
								<Col span={6} >
									<Form.Item
									>
										<Button onClick={() => openViewFileModal('keep')}> 查看留存檔案 </Button>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={5}>
									<InputPrefix label={'簽核流程'} />
								</Col>  
								<Col span={19} style={{ display: 'flex', alignItems:'center' }}>
									結案中
								</Col>
							</Row>
							<Row justify='end' style={{ color: 'black', paddingBottom: '15px' }}>
								<Col span={3} style={{ padding: '0px 5px' }}>
									<Button type='primary' onClick={() => dispatch(setSignReocrdModalVisible(true))}> 簽核紀錄 </Button>
								</Col> 
								<Col span={3} style={{ padding: '0px 5px' }}>
									<Button type='primary' style={{ background: 'blue' }} onClick={() => dispatch(setSignAndRejectModalVisible(true))}> 審核結案 </Button>
								</Col> 
								<Col span={3} style={{ padding: '0px 5px' }}>
									<Button onClick={() => navigate('/Stamp/StampPendingProcessed')}> 返回 </Button>
								</Col>
							</Row>
						</Form>
					</div>
				</Col>
			</BrowserView>
			<MobileView style={{ marginBottom: '115px' }}>
				{/* 頁面目前位置 */}  
				<Col span={24}>
					<Breadcrumb separator=">">
						<Breadcrumb.Item href='/'>WiAdvance EIP</Breadcrumb.Item>
						<Breadcrumb.Item href="/Stamp/StampApplication">用印申請</Breadcrumb.Item>
						<Breadcrumb.Item href='/Stamp/StampPendingProcessed'>待審核</Breadcrumb.Item>
						<Breadcrumb.Item > 結案審核 </Breadcrumb.Item>
					</Breadcrumb>
				</Col>
				{/* 頁面標題 */}
				<PageTitle>
					結案審核
				</PageTitle>
				<Col span={24}>
					<div style={{ backgroundColor:'white'}}>
						<Form
							form={form}
							autoComplete={false}
							labelAlign={'left'}
							labelCol={{ span: 24 }}
						>
							<Row justify='space-around' gutter={[0,8]} style={{ color: 'black', padding: '0 10px 15px 10px' }}>
								{/* 申請單號 */}
								<Col span={24} >
									<Form.Item
										name='stamp_no'
										label='申請單號'
									>
										<Input bordered={false}  disabled />
									</Form.Item>
								</Col> 
								{/* 申請人 */}
								<Col span={24} >
									<Form.Item
										name='applicant_name'
										label='申請人'
									>
										<Input bordered={false} disabled/>
									</Form.Item>
								</Col>
								{/* 被代理人 */}
								<Col span={24}>
									<Form.Item
										name='is_agent'
										valuePropName="checked"
										style={{ alignItems: 'center' }}
									>
										<Row>
											<Checkbox checked={form.getFieldValue('is_agent') === 'Y' ? true : false} disabled > 被代理人 </Checkbox>
												<Form.Item
													name='agent_name'
													hidden={ form.getFieldValue('is_agent') === 'N' }
													style={{ width: '80%', marginBottom: '0' }}
												>
													<Input disabled style={{ width: '80%' }} bordered={false} /> 
											</Form.Item> 
										</Row>
									</Form.Item>
								</Col>
								{/* 簽核印信 */}
								<Col span={24} >
									<Form.Item
										label='簽核印信'
										name='stamp_type'
									>
										<Checkbox.Group style={{ width: '100%' }} disabled >
											<Row style={{ padding: '0 20px' }} justify='center' gutter={[0, 8]}>
												{
													stampList.map( item =>{
														return(
															<>
																<Col span={12}>
																	<Checkbox value={item.lookup_code}> {item.description} </Checkbox>
																</Col>
															</>
														)
													})
												}
											</Row>
										</Checkbox.Group>
									</Form.Item>
								</Col>
								{/* 申請日期 */}
								<Col span={24}>
									<Form.Item
										label='申請日期'
										name={'cr_date'}
									>
										<Input bordered={false} disabled/>
									</Form.Item>
								</Col>
								{/* 文件名稱 */}
								<Col span={24} >
									<Form.Item
										name='document'
										label='文件名稱'
									>
										<TextArea rows={4} maxLength={50} showCount disabled/>
									</Form.Item>
								</Col>
								{/* 內容簡述 */}
								<Col span={24} >
									<Form.Item
										name='description'
										label='內容簡述'
									>
										<TextArea rows={6} maxLength={100} showCount disabled/>
									</Form.Item>
								</Col>
								{/* 檔案 */}
								<Col span={24}>
									<span style={{ color:'black', fontWeight: 600, fontSize: '16px' }}> 檔案 </span>
								</Col>
								<Col span={24}>
									<Form.Item
										name={'file'}
									>
										<Button onClick={() => openViewFileModal('apply')}> 查看申請檔案 </Button>
									</Form.Item>
								</Col>
								{
									pendingUploadFile.length !== 0 ?
										<Col span={24} >
											<Form.Item
											>
												<Button onClick={() => openViewFileModal('keep')}> 查看留存檔案 </Button>
											</Form.Item>
										</Col>
									:null
								}
								{/* 簽核流程 */}
								<Col span={24}>
									<span style={{ color:'black', fontWeight: 600, fontSize: '16px' }}> 簽核流程 </span>
								</Col>  
								<Col span={24} style={{ marginBottom: '15px' }}>
									結案中
								</Col>
								{/* 按鈕 */}
								<Col span={8} style={{ padding: '0px 5px' }}>
									<Button onClick={() => navigate('/Stamp/StampPendingProcessed')}> 返回 </Button>
              	</Col> 
								<Col span={8} style={{ padding: '0px 5px' }}>
									<Button type='primary' onClick={() => dispatch(setSignReocrdModalVisible(true))}> 簽核紀錄 </Button>
								</Col>
								<Col span={8} style={{ padding: '0px 5px' }}>
									<Button type='primary' style={{ background: 'blue' }} onClick={() => dispatch(setSignAndRejectModalVisible(true))}> 審核結案 </Button>
								</Col> 
							</Row>
						</Form>
					</div>
				</Col>
			</MobileView>
		<SignRecordModal />
		<SignAndRejectModal 
			status={'pendingProcessed'}
			stampOwnerList={stampOwnerList}
		/>
		<ViewFileModal />
	</PageLayout>
	)
}