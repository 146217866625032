/* eslint-disable no-loop-func */
import React, { useEffect } from "react";

import {
  Breadcrumb,
  Button,
  Input,
  Upload,
  Row,
  Col,
  Modal,
  Select,
  Checkbox,
  Form,
  notification,
} from "antd";

import PageTitle from "../components/PageTitle";
import InputPrefix from "../helper/InputPrefix";

import { BrowserView, isMobile, MobileView } from "react-device-detect";
import MobilForm from "../components/MobilForm";

function CalenderDetailModel({isModalOpen, handleCancel, modalData}) {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  useEffect(()=>{
    form.setFieldsValue(modalData.item)
  },[form, modalData])
  return (
    <Modal
      title={null}
      visible={isModalOpen}
      width={isMobile?"100%":800} 
      footer={null}
      onCancel={handleCancel}
      zIndex={9999} 
      style={isMobile?{
        top: 20,
      }:{}}
    >
      <BrowserView>
          <PageTitle>{modalData?.date?.format('YYYY-MM-DD') || ""} {modalData?.item?.Status ==="agree"?"通過":"審核中"}</PageTitle>
          <Col span={24}>
            <div style={{ backgroundColor: "white" }}>
              <Form
                form={form}
                autoComplete={false}
                style={{ paddingTop: "10px" }}
                initialValues={{}}
              >
                {/* 申請單號 */}

                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={7}>
                    <InputPrefix label={"申請單號"} />
                  </Col>
                  <Col span={12}>
                    <Form.Item name="WfhNo">
                      <Input bordered={false} disabled />
                    </Form.Item>
                  </Col>
                </Row>

                {/* 申請人，被代理人申請 */}
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={7}>
                    <InputPrefix label={"申請人"} />
                  </Col>
                  <Col span={12}>
                    <Form.Item name="ApplicantName">
                      <Input
                        disabled
                        bordered={false}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={7}>
                    <InputPrefix label={"申請在家上班日期"} />
                  </Col>
                  <Col span={12}>
                    <Form.Item name={"WfhDate"}>
                      <Input bordered={false} disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                ></Row>

                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={7}>
                    <InputPrefix label={"原因"} />
                  </Col>
                  <Col span={16}>
                    <Form.Item name="WfhReason">
                      <TextArea maxLength={200} showCount disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black"}}
                >
                  <Col span={7}>
                    <InputPrefix label={"審核意見"} />
                  </Col>
                  <Col span={16}>
                    <Form.Item name="AuditComments">
                      <TextArea maxLength={200} showCount disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
      </BrowserView>
      <MobileView>
          <PageTitle>{modalData?.date?.format('YYYY-MM-DD') || ""} {modalData?.item?.Status ==="agree"?"通過":"審核中"}</PageTitle>
          <Col span={24}>
            <MobilForm
              form={form}
              initialValues={{}}
            >
              <Row
                justify="space-around"
                gutter={[0, 8]}
                style={{ color: "black", padding: "0 10px 15px 10px" }}
              >
                {/* 申請單號 */}
                <Col span={23} style={{ marginTop: "10px" }}>
                  <Form.Item name="WfhNo" label="申請單號">
                    <Input bordered={false} disabled />
                  </Form.Item>
                </Col>
                <Col span={23}>
                  <Form.Item name="ApplicantName" label="申請人">
                    <Input
                      disabled
                      // style={{ width: "80%" }}
                      bordered={false}
                    />
                  </Form.Item>
                </Col>
                {/* 申請日期 */}
                <Col span={23}>
                  <Form.Item label="申請日期" name={"WfhDate"}>
                    <Input bordered={false} disabled />
                  </Form.Item>
                </Col>
                {/* 文件名稱 */}
                <Col span={23}>
                  <Form.Item
                    name="WfhReason"
                    label="原因"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "請輸入文件名稱",
                    //   },
                    // ]}
                  >
                    <TextArea maxLength={200} showCount disabled />
                  </Form.Item>
                </Col>
                {/* 內容簡述 */}
                <Col span={23}>
                  <Form.Item name="AuditComments" label="審核意見">
                    <TextArea maxLength={200} showCount disabled />
                  </Form.Item>
                </Col>
              </Row>
            </MobilForm>
          </Col>
      </MobileView>
    </Modal>
  )
}

export default CalenderDetailModel
