import React, {useEffect,useState} from 'react'
import { useOutletContext, useNavigate, Link, useParams } from "react-router-dom";
import { Breadcrumb, Button, Input, Steps, Row, Col, Table, Switch, message, Modal, Divider, Select, DatePicker, Form, Upload, Checkbox, notification, Popconfirm } from 'antd'
import { UploadOutlined, PlusOutlined, MinusCircleOutlined, EditOutlined, SendOutlined, EyeOutlined  } from '@ant-design/icons';
import PageTitle from '../components/PageTitle';
import FormLayout from '../components/FormLayout';
import PageLayout from '../components/PageLayout';
import moment from 'moment';
import InputPrefix from '../helper/InputPrefix';

export default function ContractMgtAddAndEdit({roleFunction}){

  const { TextArea } = Input
  const { RangePicker } = DatePicker
  const [form] = Form.useForm()
  const { Step } = Steps
  let { id } = useParams()
  const { Option } = Select
  const navigate  = useNavigate()
  const [currrentStep, setCurrentStep] = useState(0)
  const [ beforeEditContractInfo, setBeforeEditContractInfo ] = useState()
  const [ contractInfo, setCotractInfo ] = useState({})
  const [ serialNum, setSerialNum ] = useState(1)
  const [ modalContent, setModalContent ]=  useState({
    title:'',
    content: ''
  })
  const [ isEmergencyOpen, setIsEmergencyOpen ] = useState(false)
  const [ modalType, setModalType ] = useState()
  const [ isModifyModalVisible, setIsModifyModalVisible ] = useState(false)
  const [ isModalVisible, setIsModalVisible ] = useState(false)
  const [ isDisable, setIsDisable ] = useState(true)

  useEffect(() =>{
    form.setFieldsValue({
      status: '作用中',
      contractNO: '20220830',
      companyAddress: '新北市汐止區新台五路一段',
      postalCode: '22121',
      microsoftDomain: 'Test',
      mainContactName: 'Ray',
      mainContactMobile: '09811145123',
      mainContactEmail: 'test@gmail.com',
      serviceType: 'CSP',
      subscriptionStatus: 'newPurchase',
      productType: '品項',
      expectedStartToEndDate: [moment(), moment()],
      uploadContract: 1,
      MCA: 1,
      paymentCondition: 'transfer',
      emergencyOpening: false,
      taxID: '11111',
      companyName: '緯謙科技'
    })
    setBeforeEditContractInfo(form.getFieldValue())
  }, [])

  useEffect(() =>{
    console.log(beforeEditContractInfo)
  }, [beforeEditContractInfo])

  const onNextStep = (value) =>{
    let tempInfo = Object.assign({}, contractInfo ,value)
    setCotractInfo(tempInfo)
    if (currrentStep !== 3){
      setCurrentStep(currrentStep + 1)
    }
    else{
      navigate('/ContractMgt')
      notification.success({
        message:'成功',
        description:'操作成功!'
      })
    }
  }

  const onValidateFailed = () =>{
    notification.error({
      message: '錯誤',
      description: '請確認必填欄位皆有填寫!'
    })
  }

  const onOpenModal = (visible, type) =>{
    switch(type){
      case '開通':
        setModalContent({
          title:'開通確認',
          content:'開通確認信件內容'
        })
      break;
      case '退件':
        setModalContent({
          title:'退件確認',
          content: '退件內容'
        })
      break;
      default:
        return;
    }
    setModalType(type)
    setIsModalVisible(visible)
  }

  const modifyModalContent = () =>{
    return(
      <>
        <Row>
          <Col span={6}>
            <InputPrefix label={'主要聯繫人電話'}/>
          </Col>
          <Col span={12}>
            0981412515 =&gt; 09812311514
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <InputPrefix label={'修改原因'}/>
          </Col>
          <Col span={12}>
            <Input />
          </Col>
        </Row>
      </>
    )
  }

  //服務類型下拉選單
  const serviceTypeDropdown = [
    {
      'key': 'CSP',
      'value': 'CSP'
    },
    {
      'key': 'CSP 2-T',
      'value': 'CSP 2-T'
    },
    {
      'key': 'OPEN',
      'value': 'OPEN'
    },
    {
      'key': 'EA',
      'value': 'EA'
    },
    {
      'key': '其他',
      'value': 'others'
    }
  ]

  // ['CSP', 'CSP 2-T',].map(item => return {
  //   key: '',
  //   value
  // })
  //訂閱狀態下拉選單
  const subscriptionStatusDropdown = [
    {
      'key': '新購',
      'value': 'newPurchase'
    },
    {
      'key': '增購',
      'value': 'additionalPurchase'
    },
    {
      'key': '續約',
      'value': 'renew'
    }
  ]

  //付款條件下拉選單
  const paymentTermsDropdown = [
    {
      'key': '匯款',
      'value': 'transfer'
    },
    {
      'key': '月結',
      'value': 'monthly'
    },
    {
      'key': '線上訂購',
      'value': 'onlineOrder'
    }
  ]

  return(
    <PageLayout spinning={false}>
      {/* 頁面目前位置 */}
      <Col span={24}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item href='/'>WiAdvance EIP</Breadcrumb.Item>
          <Breadcrumb.Item href="/ContractMgt">合約管理</Breadcrumb.Item>
          <Breadcrumb.Item > 審核合約 </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      {/* 頁面標題 */}
      <PageTitle>
        審核合約
      </PageTitle>
      <Col span={24}
      style={{
        margin:'10px 0px',
      }}
      >
      </Col>
      <Col span={24}>
        <div style={{ backgroundColor:'white', padding:'10px'}}>
          <Modal
            title={modalContent.title}
            visible={isModalVisible}
            onOk={() => navigate('/ContractMgt')}
            onCancel={() => setIsModalVisible(false)}
            cancelButtonProps={{
              style:{backgroundColor:'red'}
            }}
          > {modalContent.content}</Modal>
          <Modal
            width='50%'
            title='修改紀錄'
            visible={isModifyModalVisible}
            onOk={() => navigate('/ContractMgt')}
            onCancel={() => setIsModifyModalVisible(false)}
            okText='審核通過'
            cancelButtonProps={{
              style:{backgroundColor: 'red'}
            }}
          > {modifyModalContent()} </Modal>
          <Form
            form={form}
            autoComplete='off'
            initialValues={{
              emergencyOpening: false
            }}
          >
            <Row style={{ paddingBottom: '15px' }}>
              <Col span={1}/>
              <Col span={3}>
                <Button icon={<EditOutlined/>} onClick={() => setIsDisable(false)}>
                  編輯
                </Button>
              </Col>
            </Row>
            <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
              <Col span={5}>
                <InputPrefix label={'狀態'} />
              </Col>  
              <Col span={6} >
                <Form.Item
                  name='status'
                >
                  <Input disabled/>
                </Form.Item>
              </Col>
              <Col span={5}>
                <InputPrefix label={'合約編號'} />
              </Col>
              <Col span={6} >
                <Form.Item
                  name= 'contractNO'
                >
                  <Input disabled/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
              <Col span={5}>
                <InputPrefix label={'公司名稱'} require/>
              </Col>  
              <Col span={6} >
                <Form.Item
                  name='companyName'
                  rules={[
                    {
                      required: true,
                      message: '公司名稱為必填'
                    }
                  ]}
                >
                  <Input disabled={isDisable}/>
                </Form.Item>
              </Col>
              <Col span={5}>
                <InputPrefix label={'統一編號'} require/>
              </Col>
              <Col span={6} >
                <Form.Item
                  name= 'taxID'
                  rules={[
                    {
                      required: true,
                      message: '統一編號為必填'
                    }
                  ]}
                >
                  <Input disabled={isDisable}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
              <Col span={5}>
                <InputPrefix label={'用戶名稱(開通對象)'} />
              </Col>
              <Col span={6} >
                <Form.Item
                  name= 'microsoftClientName'
                >
                  <Input disabled={isDisable}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
              <Col span={5}>
                <InputPrefix label={'微軟識別網域名稱'} require/>
              </Col>  
              <Col span={10} >
                <Form.Item
                  name='microsoftDomain'
                  rules={[
                    {
                      required: true,
                      message: '微軟識別網域名稱為必填'
                    }
                  ]}
                >
                  <Input disabled={isDisable}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
              <Col span={5}>
                <InputPrefix label={'郵遞區號'} require/>
              </Col>  
              <Col span={6} >
                <Form.Item
                  name='postalCode'
                  rules={[
                    {
                      required: true,
                      message: '郵遞區號為必填'
                    }
                  ]}
                >
                  <Input disabled={isDisable}/>
                </Form.Item>
              </Col>
              <Col span={5}>
                <InputPrefix label={'公司地址'} require/>
              </Col>
              <Col span={6} >
                <Form.Item
                  name= 'companyAddress'
                  rules={[
                    {
                      required: true,
                      message: '公司地址為必填'
                    }
                  ]}
                >
                  <Input disabled={isDisable}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
              <Col span={5}>
                <InputPrefix label={'主要聯繫人姓名'} require/>
              </Col>  
              <Col span={6} >
                <Form.Item
                  name='mainContactName'
                  rules={[
                    {
                      required: true,
                      message: '主要聯繫人姓名為必填'
                    }
                  ]}
                >
                  <Input disabled={isDisable}/>
                </Form.Item>
              </Col>
              <Col span={5}>
                <InputPrefix label={'主要聯繫人電話'} require/>
              </Col>
              <Col span={6} >
                <Form.Item
                  name= 'mainContactMobile'
                  rules={[
                    {
                      required: true,
                      message: '主要聯繫人電話為必填'
                    }
                  ]}
                >
                  <Input disabled={isDisable}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
              <Col span={5}>
                <InputPrefix label={'主要聯繫人Email'} require/>
              </Col>  
              <Col span={10} >
                <Form.Item
                  name='mainContactEmail'
                  rules={[
                    {
                      required: true,
                      message: '主要聯繫人Email為必填'
                    }
                  ]}
                >
                  <Input disabled={isDisable}/>
                </Form.Item>
              </Col>
            </Row>
            <Form.List name='subContactInfo'>
              {(fields, { add, remove }) =>(
                <>
                  {fields.map((field) =>(
                    <>
                    {(console.log(field, fields))}
                      <Form.Item
                        shouldUpdate={(prev, cur) =>
                          prev.subcontactName !== cur.subcontactName || prev.subcontactMobile !== cur.subcontactMobile || prev.subcontactEmail !== cur.subcontactEmail
                        }
                      >
                        {() =>(
                        <div style={{ marginLeft: '-10px' }}>
                        <Divider/>
                          <Row gutter={[24,8]} style={{ color: 'black', padding: '15px 0' }}>
                            <Col span={5}>
                              <InputPrefix label={'次要聯繫人姓名'}/>
                            </Col>  
                            <Col span={6} >
                              <Form.Item
                                {...field}
                                name= {[field.name, 'subcontactName']}
                              >
                                <Input disabled={isDisable}/>
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <InputPrefix label={'次要聯繫人電話'}/>
                            </Col>  
                            <Col span={6} >
                              <Form.Item
                                {...field}
                                name= {[field.name, 'subcontactMobile']}
                              >
                                <Input disabled={isDisable}/>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
                            <Col span={5}>
                              <InputPrefix label={'次要聯繫人Email'}/>
                            </Col>  
                            <Col span={10} >
                              <Form.Item
                                {...field}
                                name= {[field.name, 'subcontactEmail']}
                              >
                                <Input disabled={isDisable}/>
                              </Form.Item>
                            </Col>
                            <Col span={3}/>
                            <Col span={5}>
                              <Form.Item>
                              <Button disabled={isDisable} style={{ backgroundColor: 'red' }} icon={<MinusCircleOutlined />} onClick={() => remove(field.name)}> 移除此次要聯繫人</Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        )}
                      </Form.Item>
                    </>
                  ))}

                  <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
                    <Col span={1}/>
                    <Col span={4}>
                      <Form.Item>
                      <Button disabled={isDisable} type='primary' icon={<PlusOutlined />} onClick={() => add()}> 增加次要聯繫人欄位</Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>
            <Divider/>
            <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
              <Col span={5}>
              <InputPrefix label={'服務類型'} require/>
              </Col>  
              <Col span={6} >
                <Form.Item
                  name='serviceType'
                  rules={[
                    {
                      required: true,
                      message: '請選擇服務類型!'
                    }
                  ]}
                >
                  <Select
                    disabled={isDisable}
                    style={{ width:'100%' }}
                  >
                    {
                      serviceTypeDropdown.map(ele => {
                        return <Option key={ele.value}> {ele.key} </Option>
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5}>
                <InputPrefix label={'訂閱狀態'} require/>
              </Col>  
              <Col span={6} >
                <Form.Item
                  name='subscriptionStatus'
                  rules={[
                    {
                      required: true,
                      message: '請選擇訂閱狀態!'
                    }
                  ]}
                >
                  <Select
                    disabled={isDisable}
                    style={{ width:'100%' }}
                  >
                    {
                      subscriptionStatusDropdown.map(ele => {
                        return <Option key={ele.value}> {ele.key} </Option>
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
              <Col span={5}>
              <InputPrefix label={'產品品項'} require/>
              </Col>  
              <Col span={6} >
                <Form.Item
                  name='productType'
                  rules={[
                    {
                      required: true,
                      message: '產品品項為必填!'
                    }
                  ]}
                >
                  <Input disabled={isDisable}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
              <Col span={5}>
              <InputPrefix label={'預計啟用至結束日期'} require/>
              </Col>  
              <Col span={10} >
                <Form.Item
                  name='expectedStartToEndDate'
                  rules={[
                    {
                      required: true,
                      message: '請選擇預計啟用至結束日期!'
                    }
                  ]}
                >
                  <RangePicker disabled={isDisable} style={{ width:'100%' }}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
              <Col span={5}>
              <InputPrefix label={'實際啟用至結束日期'} />
              </Col>  
              <Col span={10} >
                <Form.Item
                  name='actualStartToEndate'
                >
                  <RangePicker style={{ width:'100%' }}/>
                </Form.Item>
              </Col>
            </Row>
            <Divider/>
            <Form.List name={`${form.getFieldValue('contractNO')}-${serialNum}`}>
              {(fields, { add, remove }) =>(
                <>
                  {fields.map((field) =>(
                    <>
                    {(console.log(field, fields))}
                      <Form.Item
                        shouldUpdate={(prev, cur) =>
                          prev.serviceType !== cur.serviceType 
                      ||  prev.subscriptionStatus !== cur.subscriptionStatus 
                      ||  prev.productType !== cur.productType
                      ||  prev.expectedStartDate !== cur.expectedStartDate 
                      ||  prev.expectedEndDate !== cur.expectedEndDate
                      ||  prev.actualStartDate !== cur.actualStartDate 
                      ||  prev.actualEndDate !== cur.actualEndDate
                        }
                      >
                        {() =>(
                        <div style={{ marginLeft: '-10px' }}>
                          <Row gutter={[24,8]} style={{ color: 'black', padding: '15px 0' }}>
                            <Col span={5}>
                              <InputPrefix label={'服務類型'} require/>
                            </Col>  
                            <Col span={6} >
                              <Form.Item
                                {...field}
                                name= {[field.name, 'serviceType']}
                                rules={[
                                  {
                                    required: true,
                                    message: '請選擇服務類型!'
                                  }
                                ]}
                              >
                                <Select
                                  style={{ width:'100%' }}
                                >
                                  {
                                    serviceTypeDropdown.map(ele => {
                                      return <Option key={ele.value}> {ele.key} </Option>
                                    })
                                  }
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <InputPrefix label={'訂閱狀態'} require/>
                            </Col>  
                            <Col span={6} >
                              <Form.Item
                                {...field}
                                name= {[field.name, 'subscriptionStatus']}
                                rules={[
                                  {
                                    required: true,
                                    message: '請選擇訂閱狀態!'
                                  }
                                ]}
                              >
                                <Select
                                  style={{ width:'100%' }}
                                >
                                  {
                                    subscriptionStatusDropdown.map(ele => {
                                      return <Option key={ele.value}> {ele.key} </Option>
                                    })
                                  }
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
                            <Col span={5}>
                              <InputPrefix label={'產品品項'} require/>
                            </Col>  
                            <Col span={6} >
                              <Form.Item
                                {...field}
                                name={[field.name, 'productType']}
                                rules={[
                                  {
                                    required: true,
                                    message: '產品品項為必填!'
                                  }
                                ]}
                              >
                                <Input/>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
                            <Col span={5}>
                              <InputPrefix label={'預計啟用至結束日期'} require/>
                            </Col>  
                            <Col span={10} >
                              <Form.Item
                                name='expectedStartToEndDate'
                                rules={[
                                  {
                                    required: true,
                                    message: '請選擇預計啟用至結束日期!'
                                  }
                                ]}
                              >
                                <RangePicker style={{ width:'100%' }}/>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
                            <Col span={5}>
                              <InputPrefix label={'實際啟用至結束日期'} />
                            </Col>  
                            <Col span={10} >
                              <Form.Item
                                name='actualStartToEndate'
                              >
                                <RangePicker style={{ width:'100%' }}/>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
                            <Col span={16}/>
                            <Col span={5}>
                              <Form.Item>
                              <Button style={{ backgroundColor: 'red' }} icon={<MinusCircleOutlined />} onClick={() => remove(field.name)}> 移除此服務類型</Button>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Divider/>
                        </div>
                        )}
                      </Form.Item>
                    </>
                  ))}

                  <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
                    <Col span={1}/>
                    <Col span={5}>
                      <Form.Item>
                        <Button type='primary' disabled={isDisable} icon={<PlusOutlined />} onClick={() => add()}> 新增服務欄位 </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>
              <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
                <Col span={5}>
                  <InputPrefix label={'上傳開通文件'} require/>
                </Col>  
                <Col span={6} >
                {
                  form.getFieldValue('uploadContract') !== undefined ?
                    <Button disabled={isDisable} icon={<EyeOutlined />} type='primary'> 查看開通文件 </Button>
                    :
                      <Form.Item
                        name='uploadContract'
                        rules={[
                          {
                            required: true,
                            message: '需上傳開通文件'
                          }
                        ]}
                      >
                        <Upload
                          name='uploadContract'
                          disabled={isDisable}
                        > <Button disabled={isDisable} icon={<UploadOutlined />} type='primary' > 上傳開通文件 </Button> </Upload>
                      </Form.Item>
                  }
                </Col>
              </Row>
              <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
                <Col span={5}>
                  <InputPrefix label={'緊急開通'} />
                </Col>  
                <Col span={6} >
                  <Form.Item
                    name='emergencyOpening'
                    valuePropName='checked'
                  >
                    <Switch onChange={(e) => setIsEmergencyOpen(e)} disabled={isDisable} />
                  </Form.Item>
                </Col>
                  <Col span={5} style={{ display: `${ isEmergencyOpen ? 'block' : 'none' }`}}>
                    <InputPrefix label={'緊急開通主管同意信'} />
                  </Col>
                  <Col span={5} style={{ display: `${ isEmergencyOpen ? 'block' : 'none' }`}}>
                    <Form.Item
                      name= 'consentLetter'
                    >
                      <Button disabled={isDisable} icon={<UploadOutlined />} > 上傳緊急開通主管同意信 </Button>
                    </Form.Item>
                  </Col>
              </Row>
              <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
                <Col span={5}>
                  <InputPrefix label={'Microsoft Customer Agreement'} require/>
                </Col>  
                <Col span={6} >
                  {
                    form.getFieldValue('MCA') !== undefined ?
                      <Button disabled={isDisable} icon={<EyeOutlined />} type='primary'> 查看同意書 </Button>
                      :
                      <Form.Item
                        name='MCA'
                        rules={[
                          {
                            required: true,
                            message: '需上傳同意書檔案'
                          }
                        ]}
                      >
                        <Upload
                          name='MCA'
                          disabled={isDisable}
                        > <Button disabled={isDisable} icon={<UploadOutlined />} type='primary' > 上傳同意書 </Button> </Upload>
                      </Form.Item>
                  }
                </Col>
                <Col span={5}>
                  <InputPrefix label={'付款方式'} require/>
                </Col>  
                <Col span={6} >
                  <Form.Item
                    name='paymentCondition'
                    rules={[
                      {
                        required: true,
                        message: '請選擇付款條件'
                      }
                    ]}
                  >
                    <Select
                      disabled={isDisable}
                      style={{ width:'100%' }}
                    >
                      {
                        paymentTermsDropdown.map(ele =>{
                          return <Option value={ele.value}> {ele.key} </Option>
                        })
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
                <Col span={5}>
                  <InputPrefix label={'上單單號'} />
                </Col>  
                <Col span={6} >
                  <Form.Item
                    name='orderNum'
                  >
                    <Input disabled={isDisable}/>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <InputPrefix label={'發票號碼'} />
                </Col>
                <Col span={6} >
                  <Form.Item
                    name= 'InvoiceNum'
                  >
                    <Input disabled={isDisable}/>
                  </Form.Item>
                </Col>
              </Row>
              <Divider/>
              <Row gutter={[24,8]} style={{ color: 'black', paddingBottom: '15px' }}>
                <Col span={5}>
                  <InputPrefix label={'備註'} />
                </Col>  
                <Col span={10} >
                  <Form.Item
                    name='remark'
                  >
                    <TextArea rows={4} disabled={isDisable}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify='end' style={{ paddingTop: '15px' }}>
                <Col span={3} style={{ paddingRight:'10px' }}>
                  {/* <Popconfirm 
                    title="確認審核通過?"
                    okText="確定"
                    okButtonProps={{
                      className:"for-popconfirm-btn",
                      onClick:() => navigate('/ContractMgt')
                    }}
                    cancelText="取消"
                    cancelButtonProps={{
                      className:"for-popconfirm-btn",
                      style:{backgroundColor:'red'}
                    }}
                  > */}
                    <Button style={{ backgroundColor: '#2c26b9' }} onClick={() => setIsModifyModalVisible(true)}> 審核通過 </Button>
                  {/* </Popconfirm> */}
                </Col>
                <Col span={3} style={{ paddingRight:'10px' }}>
                  <Button type='primary' onClick={() => onOpenModal(true, '開通')}> 開通 </Button>
                </Col>
                <Col span={3} style={{ paddingRight:'10px' }}>
                  <Button style={{ backgroundColor: 'red' }} onClick={() => onOpenModal(true, '退件')}> 退件 </Button>
                </Col>
                <Col span={3} style={{ paddingRight:'10px' }}>
                  <Button onClick={() => setCurrentStep(currrentStep -1 )}> 上一步 </Button> 
                </Col>
                <Col span={3} style={{ paddingRight:'10px' }}>
                  <Button style={{ backgroundColor: '#a98d91' }} onClick={() => navigate('/ContractMgt')}> 取消 </Button>
                </Col>
              </Row>
          </Form>
        </div>
      </Col>
    </PageLayout>
  )
}