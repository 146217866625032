import React, { useState } from 'react'
import { useOutletContext, useNavigate } from "react-router-dom";
import { Breadcrumb, Button, Input, Row, Col, Table, Select, DatePicker, Drawer } from 'antd'
import { CaretDownOutlined, PlusCircleOutlined } from '@ant-design/icons';
import PageTitle from '../components/PageTitle';
import PageSearch from '../components/PageSearch';
import PageLayout from '../components/PageLayout';
import moment from 'moment';

const { RangePicker } = DatePicker
const { Option } = Select

export default function ContractMgt({roleFunction}){
  
  const navigate = useNavigate();

  const searchParamsInitialParams = {
    'state':'',
    'contractGuid':'',
    'sales': '',
    'clientName': '',
    'clientVatNO': '',
    'contractStartDate': '',
    'contractEndDate': ''
  }

  const [ searchParams, setSearchParams ] = useState(searchParamsInitialParams)
  const [ tableData, setTableData ] = useState([])
  const [ drawerInfo, setDrawerInfo ] = useState({
    isVisible: false,
    companyName: ''
  })

  const salesOption = [
    {
      label: 'Ray Wu 吳品燁',
      value: '0'
    },
    {
      label: 'Alex Lo 羅哲琛',
      value: '1'
    },
    {
      label: 'Jason Lin 林聖斌',
      value: '2'
    },
    {
      label: 'Howard Chou 周昊勳',
      value: '3'
    }
  ]

  const tempStateForSelect = [
    {
      'key': '全部',
      'value': 'All'
    },
    {
      'key': '暫存',
      'value': '暫存'
    },
    {
      'key': '待審核',
      'value': '待審核'
    },
    {
      'key': '審核通過',
      'value': '審核通過'
    },
    {
      'key': '退件',
      'value': '退件'
    },
    {
      'key': '作用中',
      'value': '作用中'
    },
    {
      'key': '作用中待補件',
      'value': '作用中待補件'
    },
    {
      'key': '已補件待審',
      'value': '已補件待審'
    },
    {
      'key': '已續約',
      'value': '已續約'
    },
    {
      'key': '停用',
      'value': '停用'
    },
  ]

  const columns = [
    {
      title: '合約編號',
      dataIndex: 'contractGuid',
      key: 'contractGuid',
      width:'10%',
      render:(_, record) =>{
        return <Button type='link' onClick={() => { navigate(`/ContractMgtView/${record.contractGuid}`)}}> {record.contractGuid} </Button>
      }
    },
    {
      title: '狀態',
      dataIndex: 'status',
      key: 'status',
      width:'10%',
    },
    {
      title: '公司名稱',
      dataIndex: 'companyName',
      key: 'companyName',
      width:'15%',
      render:(_, record) =>{
        return <Button type='link' onClick={() => setDrawerInfo({ companyName: record.companyName, isVisible: true })}> {record.companyName} </Button>
      }
    },
    {
      title: '微軟識別網域名稱',
      dataIndex: 'domain',
      key: 'domain',
      width:'15%',
    },
    {
      title: '啟用日期',
      dataIndex: 'contractStartDate',
      key: 'contractStartDate',
      width:'15%',
    },
    {
      title: '結束日期',
      dataIndex: 'contractEndDate',
      key: 'contractEndDate',
      width:'15%',
    },
    {
      title: '功能',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
      render:(_, record) =>{
          return (
            <>
              <Button type='link' onClick={() => navigate(`/ContractMgtEdit/${record.contractGuid}`)}> 編輯 </Button>
              <span>|</span>
              <Button type='link' onClick={() => navigate(`/ContractMgtVerify/${record.contractGuid}`)}> 審核 </Button>
            </>
        )
      }
    }
  ];

  const data =[
    {
      emergencyOpening: true,
      contractGuid: '111',
      status: '作用中',
      companyName: 'AA',
      domain: '',
      contractStartDate: `${moment().format('YYYY/MM/DD hh:mm:ss')}`,
      contractEndDate: `${moment().format('YYYY/MM/DD hh:mm:ss')}`,
      role: 'sales'
    },
    {
      emergencyOpening: false,
      contractGuid: '222',
      status: '作用中待補件',
      companyName: 'BB',
      domain: '',
      contractStartDate: `${moment().format('YYYY/MM/DD hh:mm:ss')}`,
      contractEndDate: `${moment().format('YYYY/MM/DD hh:mm:ss')}`,
      role: 'OP'
    }
]
  
  //變更合約區間，依照antd格式給moment值
  const onChangeDate = (date, dateString) =>{
    setSearchParams({
      ...searchParams,
      contractStartDate: moment(dateString[0], 'YYYY-MM-DD'),
      contractEndDate: moment(dateString[1], 'YYYY-MM-DD')
    })
  }

  //變更搜尋參數
  const onChangeSearchParams = ( key, value ) =>{
    setSearchParams({
      ...searchParams,
      [key] : value
    })
  }

  //依據使用者參數送出搜尋
  const onSearchContract = () =>{

    let convertStartDate = searchParams.contractStartDate ? moment(moment(searchParams.contractStartDate).toDate().toISOString()).format('YYYY/MM/DD hh:mm:ss') : ''
    let convertEndDate = searchParams.contractEndDate ? moment(moment(searchParams.contractEndDate).toDate().toISOString()).format('YYYY/MM/DD hh:mm:ss') : ''
    let tempSearchData = searchParams

    //轉換時間格式
    Object.keys(tempSearchData).forEach(key => {
      //判斷物件的值是否有undefined，並轉換為''
      if(tempSearchData[key] === undefined){
        tempSearchData[key] = ''
      }
      switch(key){
        case 'contractStartDate':
          tempSearchData[key] = convertStartDate
          break;
        case 'contractEndDate':
          tempSearchData[key] = convertEndDate
          break;
        default:
          return
      }
    })
    setTableData(data)
  }
 
  // console.log(currentUser,roleFunction)
  return (
    <PageLayout spinning={false}>
      {/* 頁面目前位置 */}
      <Col span={24}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item href='/'>WiAdvance EIP</Breadcrumb.Item>
          <Breadcrumb.Item >合約管理</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      {/* 頁面標題 */}
      <PageTitle>
        合約管理
      </PageTitle>
      <Drawer
        title= {`修改${drawerInfo.companyName}基本資料`}
        visible={drawerInfo.isVisible}
        footer={[
          <>
          <Row justify='end'>
            <Col span={6} style={{ paddingRight: '10px' }}>
              <Button type='primary' onClick={() => setDrawerInfo({isVisible: false})}> 儲存 </Button>
            </Col>
            <Col span={6}>
              <Button onClick={() => setDrawerInfo({isVisible: false})}> 取消 </Button>
            </Col>
          </Row>
          </>
        ]}
      >
        <div style={{ color: 'black' }}>
          <Row style={{ paddingBottom: '10px' }}>
            公司名稱:
          </Row>
          <Row style={{ paddingBottom: '10px' }}>
            <Input value={drawerInfo.companyName} disabled />
          </Row>
          <Row style={{ paddingBottom: '10px' }}>
            主要聯繫人姓名:
          </Row>
          <Row style={{ paddingBottom: '10px' }}>
            <Input value={'Ray'} />
          </Row>
          <Row style={{ paddingBottom: '10px' }}>
            主要聯繫人電話:
          </Row>
          <Row style={{ paddingBottom: '10px' }}>
            <Input value={'0981461541'} />
          </Row>
          <Row style={{ paddingBottom: '10px' }}>
            主要聯繫人Email:
          </Row>
          <Row style={{ paddingBottom: '10px' }}>
            <Input value={'a@mail.com'} />
          </Row>
        </div>
      </Drawer>
      {/* 頁面搜尋功能 */}
      <PageSearch>
        <Row gutter={[8,8]} justify="start" style={{margin:'10px 0px'}}>
          {/* 狀態下拉選單 */}
          <Col flex='auto'>
            <Select
              mode='multiple'
              style={{ width:'100%' }}
              // value={searchParams.state ? searchParams.state : null}
              placeholder='請選擇合約狀態'
              allowClear
              // onChange={(e) => onChangeSearchParams('state', e )}
            >
              {
                tempStateForSelect.map(ele => {
                  return <Option label={ele.key} value ={ele.value}> {ele.key} </Option>
                })
              }
            </Select>
          </Col>
          {/* 合約編號輸入框 */}
          <Col flex='auto'>
            <Input
              name='contractGuid'
              placeholder='合約編號'
              value={searchParams.contractGuid}
              onChange={e => onChangeSearchParams('contractGuid', e.target.value)}
            />
          </Col>
          {/* 客戶名稱輸入框 */}
          <Col flex='auto'>
            <Input
              placeholder='客戶名稱'
              value={searchParams.clientName}
              onChange={e => onChangeSearchParams('clientName', e.target.value)}
            />
          </Col>
          {/* 客戶統編輸入框 */}
          <Col flex='auto'>
            <Input
              placeholder='客戶統編'
              value={searchParams.clientVatNO}
              onChange={e => onChangeSearchParams('clientVatNO', e.target.value)}
            />
          </Col>
          {/* 合約起迄日期選擇框 */}
          <Col flex='auto'>
            <RangePicker
              value={[searchParams.contractStartDate, searchParams.contractEndDate]}
              onChange={onChangeDate}
            />
          </Col>
        </Row>
        <Row style={{ paddingTop:'15px' }}>
          {/* 業務選擇框 */}
          <Col flex='auto'>
            <Select
              style={{ width: '100%' }}
              mode='multiple'
              placeholder='選擇業務(可複選)'
            > 
            {
              salesOption.map(item =>{
                return(
                  <Option value={item.value}> {item.label} </Option>
                )
              })
            }
            </Select>
          </Col>
        </Row>
        <Row justify='space-between' style={{ paddingTop:'15px' }}>
          <Col span={16}/>
          {/* 清除搜尋按鈕 */}
          <Col span={3} style={{margin: 'auto 10px'}}>
            <Button style={{height:'40.14px'}} onClick={() => setSearchParams({...searchParamsInitialParams})}>清除</Button>
          </Col>
          {/* 確認按鈕 */}
          <Col span={3} style={{margin: 'auto 10px'}}>
            <Button type='primary' style={{height:'40.14px'}} onClick={() => onSearchContract()} >確認</Button>
          </Col>
        </Row>
      </PageSearch>
      <Col span={24}>
        <Row justify="space-between" style={{margin: '10px 10px'}} gutter={[24, 8]}>
          <Col span={19}/>
          <Col span={3}>
            <Button 
              type={'primary'} 
              style={{height:'40.14px', backgroundColor: '#00a2dd'}}
              onClick={()=>navigate('/ContractMgtAdd')}
            >
              新增合約<PlusCircleOutlined />
            </Button>
          </Col>
          {/* <Col flex="150px">
            <Button 
              style={{height:'40.14px'}}
              onClick={()=>setTableData([])}
            >
              返回<RollbackOutlined />
            </Button>
          </Col> */}
        </Row>
      </Col>
      <Col span={24}
      style={{
        margin:'10px 0px',
      }}
      >
      </Col>
      <Col span={24}>
        <Table 
          // onRow={(record, rowIndex) =>{
          //   return {
          //     onClick: e => { navigate(`/ContractView/${record.contractGuid}`) }
          //   }
          // }}
          pagination={{
            position: ['topRight', 'bottomRight']
          }}
          dataSource={tableData} 
          columns={columns}
          footer={(ele) => {
            return(
            <Col flex="auto">
              <div style={{'textAlign':'end', 'margin':'8px', 'color':'#7C7C7C'}}>
                共有{ele? ele.length : 0}筆資料，每頁顯示10<CaretDownOutlined />筆
              </div>
            </Col>
            )
          }}
        />
      </Col>
    </PageLayout>
  )
}