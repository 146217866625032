import { Modal, Form, Button, Col, Row, Spin, Progress } from "antd"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setViewFileModalVisible } from "../redux/action/Components"
import { HiDownload } from 'react-icons/hi'
import { FaHandPointLeft, FaHandPointRight } from 'react-icons/fa'
import { Document, Page, pdfjs } from 'react-pdf'
import { BrowserView, MobileView } from 'react-device-detect';
import ImageLoad from "./ImageLoad"

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.js`;

export default function ViewFileModal(props){

  const [ curFilePage, setCurFilePage ] = useState(0)
  const viewFileModalVisible = useSelector(state => state.Modal.viewFileModalVisible)
  const viewFileData = useSelector(state => state.Stamp.viewFileData)
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isPDFLoaded, setIsPDFLoaded] = useState(false)
  const [percent, setPercent] = useState(0)
//  useEffect(()=>{
//   console.log(viewFileData)
//     viewFileData.map((image, index)=>{
//       if(image.type !== 'application/pdf'){
//         const imageElement = new Image();
//         imageElement.src = image;
//         console.log(imageElement)
//       }
//     })
//   },[viewFileData])
  const viewPDF = () =>{
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

    return (
      <div>
        <div style={{minHeight:'30vh'}}>
          <Document 
            style={{width:'100%'}}
            loading='載入中...'
            className={'PDFViewer'}
            file={viewFileData[curFilePage].url} 
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadProgress={({ loaded, total }) => { 
              setIsPDFLoaded(false)
              setPercent(parseInt(loaded*100/total))
              if(loaded === total){
                setIsPDFLoaded(true)
                setPercent(0)
              }
            }}
            onLoadError={(error) => console.log('Error while loading document! ' + error.message)}
            error={<div style={{color:"red"}}>載入PDF時發生問題，系統重新載入中!</div>}
          >
            <Page 
              loading='載入中...'
              pageNumber={pageNumber} 
            />
          </Document>
        </div>

        {
          isPDFLoaded ?
          <>
            <Row style={{ paddingTop: '10px' }} justify="space-between" gutter={16}>
              <Col>
                <Button type='primary' disabled={pageNumber <= 1} onClick={() => setPageNumber(pageNumber - 1)} style={{ width: '100%' }} icon={<FaHandPointLeft/>}> 上一頁 </Button>
              </Col>
              <Col>
                <Button type='primary' disabled={pageNumber === numPages} onClick={() => setPageNumber(pageNumber + 1)} style={{ width: '100%' }} icon={<FaHandPointRight/>}> 下一頁 </Button>
              </Col>
            </Row>
            <p className="pageInfo">
              第 {pageNumber} 頁，共 {numPages} 頁
            </p> 
          </>:null
        }
      </div>
    );
  }
  const fileShow = ()=>{
    return viewFileData.map((data, index)=>{
      if(data.type === 'application/pdf'){
        return <Spin style={index === curFilePage ?{position: 'fixed', top: '25%'}: {display:'none'}} tip={<Progress style={{width:'50vw'}} percent={percent}/>} spinning={!isPDFLoaded}><div style={index === curFilePage ?{}: {display:'none'}}>{viewPDF()}</div></Spin>
      }else{
        // return <embed style={index === curFilePage ?{maxHeight: '60vh', maxWidth: '100%', minHeight:'30vh'}: {display:'none'}} src={data.url} type={data.type}/>
        // return <>{!isImgLoaded&&<>123</>}<img onLoad={() => setIsImgLoaded(true)} style={index === curFilePage ?{maxHeight: '60vh', maxWidth: '100%', minHeight:'30vh'}: {display:'none'}} src={data.url}/></>
        return <ImageLoad show={index === curFilePage} url={data.url}/>
      }
    })
  }

  return(
    <>
      <BrowserView>
        <Modal
          title='查看檔案'
          visible={viewFileModalVisible}
          onCancel={() => { dispatch(setViewFileModalVisible(false)); setPageNumber(1); setCurFilePage(0);}}
          width={'60%'}
          footer={null}
          maskClosable={false}
        >
          <Form
            form={form}
          >
            <Row justify='end' style={{ width: '100%' }}>
              {
                viewFileData.length !== 0 ?
                  <Col xs={12} sm={8} lg={4} span={6}>
                    <Button style={{ width: '100%', height: 'auto' }} type="primary" icon={<HiDownload />} href={viewFileData[curFilePage].url} > 下載檔案 </Button>
                  </Col>
                : null
              }
            </Row>
            <Col style={{ display: 'flex', justifyContent: 'center', paddingTop: '30px' }} span={24}>
            { 
              // viewFileData.length !== 0  ? 
              // viewFileData[curFilePage].type === 'application/pdf' ?
              //   viewPDF()
              // : 
              // <embed style={{ maxHeight: '60vh', maxWidth: '100%' }} src={viewFileData[curFilePage].url} type={viewFileData[curFilePage].type}/> : 
              // null 
              fileShow()
            }
            </Col>
            <Row style={{ paddingTop: '30px'}} justify='space-between'>
              <Button type='primary' disabled={curFilePage === 0} onClick={() =>{ setCurFilePage(curFilePage - 1); setPageNumber(1); setIsPDFLoaded(false)}}> 上一個檔案 </Button>
              <Button type='primary' disabled={viewFileData.length -1 === curFilePage} onClick={() =>{ setCurFilePage(curFilePage + 1); setPageNumber(1); setIsPDFLoaded(false)}}> 下一個檔案 </Button>
            </Row>
          </Form>
        </Modal>
      </BrowserView>
      <MobileView>
        <Modal
          title='查看檔案'
          visible={viewFileModalVisible}
          onCancel={() => { dispatch(setViewFileModalVisible(false)); setPageNumber(1); setCurFilePage(0);}}
          width={'80%'}
          footer={null}
          style={{ height: '80vh' }}
        >
          <Form
            form={form}
          >
            <Row justify='end' style={{ width: '100%' }}>
              {
                viewFileData.length !== 0 ?
                  <Col span={12}>
                    <Button style={{ width: '100%', height: 'auto' }} type="primary" icon={<HiDownload />} href={viewFileData[curFilePage].url} > 下載檔案 </Button>
                  </Col>
                : null
              }
            </Row>
            <Col style={{ display: 'flex', justifyContent: 'center', paddingTop: '30px' }} span={24}>
            { 
              // viewFileData.length !== 0  ? 
              //   viewFileData[curFilePage].type === 'application/pdf' ?
              //     viewPDF()
              // : 
              // <embed style={{ maxHeight: '60vh', maxWidth: '100%' }} src={viewFileData[curFilePage].url} type={viewFileData[curFilePage].type}/> : 
              // null 
              fileShow()
            }
            </Col>
            <Row style={{ paddingTop: '30px'}} justify='space-between'>
              <Col span={8}>
                <Button type='primary' style={{ width: '100%' }} disabled={curFilePage === 0} onClick={() =>{ setCurFilePage(curFilePage - 1); setPageNumber(1); setIsPDFLoaded(false)}}> 上一個檔案 </Button>
              </Col>
              <Col span={8}>
                <Button type='primary' style={{ width: '100%' }} disabled={viewFileData.length -1 === curFilePage} onClick={() =>{ setCurFilePage(curFilePage + 1); setPageNumber(1); setIsPDFLoaded(false)}}> 下一個檔案 </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </MobileView>
    </>
  )
}