export const msalConfig = {
    auth: {
      clientId: '70e45c97-8c85-4749-bf57-b98ef4a61771',
      authority: 'https://login.microsoftonline.com/10f0f3b2-c2b5-445f-84f7-584515916a82', 
      redirectUri: process.env.REACT_APP_REDIRECT_URL
    },
    cache: {
      cacheLocation: 'localStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ['User.Read', 'User.Read.All', 'User.ReadWrite.All', 'Directory.Read.All', 'Directory.ReadWrite.All']
  // scopes: ['Directory.ReadWrite.All']
  };
  
