
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Breadcrumb, Button, Row, Col, Form, Input, Switch } from 'antd';
import PageTitle from '../components/PageTitle';
import FormLayout from '../components/FormLayout';
import PageLayout from '../components/PageLayout';
import InputPrefix from '../helper/InputPrefix';

export default function TagUpdate() {
  const {state} = useLocation();
  const navigate = useNavigate();
  return (
    <PageLayout spinning={false}>
      <Col span={24}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item href='/'>WiAdvance EIP</Breadcrumb.Item>
          <Breadcrumb.Item href='/'>Tag維護管理</Breadcrumb.Item>
          <Breadcrumb.Item >編輯Tag</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <PageTitle>
        編輯Tag
      </PageTitle>
      <Col span={24}>  
      <Form
        name="basic"
        // initialValues={{ remember: true }}
        onFinish={(value)=>{console.log(value)}}
        autoComplete="off"
      >
      <FormLayout>
        <Row align='top'>
          <InputPrefix label={'關鍵字'} require={true}/>
          <Col flex="auto">
            <Form.Item
              name="tag"
              rules={[{ 
                  required: true,
                  message: '請輸入關鍵字' 
              }]}
            >
                <Input defaultValue={state.tag} placeholder="請輸入關鍵字" size={'large'} bordered={false} style={{borderBottom: '1px solid #ddd'}}/>
            </Form.Item>
          </Col>
        </Row>
        <Row align='top'>
          <InputPrefix label={'刪除狀態'} require={true}/>
          <Col flex="auto">
            <Form.Item
              name="status"
              >
                <Switch checkedChildren="啟用" unCheckedChildren="停用" checked={state.status}  />
            </Form.Item>
          </Col>
        </Row>
      </FormLayout>
      <Row align='bottom' style={{marginTop:'30px'}}>
          <Col>
            <Form.Item>
              <Button style={{width:'150px', marginRight:'10px'}} type="primary" htmlType="submit">更新</Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button style={{width:'150px'}} onClick={()=>navigate('/tag')}>取消</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      </Col>
    </PageLayout>
  )
}
