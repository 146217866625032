/* eslint-disable no-loop-func */
import React, { useEffect, useState } from "react";
import {
  useOutletContext,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  Upload,
  Row,
  Col,
  Modal,
  Select,
  Checkbox,
  Form,
  notification,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { connect, useDispatch, useSelector } from "react-redux";
import PageTitle from "../components/PageTitle";
import PageLayout from "../components/PageLayout";
import InputPrefix from "../helper/InputPrefix";
import moment from "moment";
import axios from "axios";
import { getWFH } from "../newApi/wfh.api";
import mimeTypeObj from "../helper/mimeTypeObj";
import ViewFileModal from "../components/ViewFileModal";
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import MobilForm from "../components/MobilForm";
import PageBtns from "../components/PageBtns";

export default function WFHApplicationView() {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  let { id } = useParams();
  const location = useLocation().pathname.split("/")[2];
  const { currentUser } = useOutletContext();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const isLoading = useSelector((state) => state.globalSetting.isLoading);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [ApplicantName, setApplicantName] = useState("");
  const { accounts } = useMsal();
  const blankInfo = () => {
    notification.warning({
      message: "提示",
      description: "查無此單號或權限不足!",
    });
    navigate(`/WFH/WFHApplication`);
  };
  // const approvedInfo = () => {
  //   notification.info({
  //     message: "提示",
  //     description: "您已審核過此單號!",
  //   });
  //   navigate(`/WFH/WFHApplication`);
  // };

  const initial = (params) => {
    setPageLoading(true);
    getWFH(params).then((res) => {
      if (res?.isSuccess) {
        form.setFieldsValue(res?.data?.[0]);
        if (res?.data.length === 0) {
          return (
            <p spinning={loading} tip="Loading..." size="large">
              {blankInfo()}
            </p>
          );
        }
      }
    }).finally(() => setPageLoading(false));
  };

  useEffect(() => {
    initial({
      WfhNo: id,
      PageRow: "50",
      PageIndex: "1",
      mail:
        localStorage.getItem("mockUser") || undefined,
    });
  }, []);

  return (
    <>
      <BrowserView>
        <PageLayout spinning={pageLoading}>
          {/* 頁面目前位置 */}
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
              <Breadcrumb.Item href="/WFH/WFHApplication">
                WFH申請
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                WFH申請檢視
                {!!localStorage.getItem("mockUser") &&
                <span style={{color:'#ed3b3b'}}>（{localStorage.getItem("mockUser")}）</span>}
                </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* 頁面標題 */}
          <PageTitle>{"WFH申請檢視"}</PageTitle>
          <Col span={24}>
            <div style={{ backgroundColor: "white" }}>
              <Form
                form={form}
                autoComplete={false}
                style={{ paddingTop: "10px" }}
                initialValues={
                  id
                    ? {
                        WfhNo: id,
                        ApplicantName: id.ApplicantName,
                      }
                    : null
                }
              >
                {/* 申請單號 */}

                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={5}>
                    <InputPrefix label={"申請單號"} />
                  </Col>
                  <Col span={6}>
                    <Form.Item name="WfhNo">
                      <Input bordered={false} disabled />
                    </Form.Item>
                  </Col>
                </Row>

                {/* 申請人，被代理人申請 */}
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={5}>
                    <InputPrefix label={"申請人"} />
                  </Col>
                  <Col span={6}>
                    <Form.Item name="ApplicantName">
                      <Input
                        disabled
                        style={{ width: "80%" }}
                        bordered={false}
                        defaultValue={id.ApplicantName}
                        value={ApplicantName}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <InputPrefix label={"申請在家上班日期"} />
                  </Col>
                  <Col span={6}>
                    <Form.Item name={"WfhDate"}>
                      <Input bordered={false} disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                ></Row>

                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={5}>
                    <InputPrefix label={"原因"} />
                  </Col>
                  <Col span={18}>
                    <Form.Item name="WfhReason">
                      <TextArea rows={6} maxLength={200} showCount disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={[24, 8]}
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={5}>
                    <InputPrefix label={"審核意見"} />
                  </Col>
                  <Col span={18}>
                    <Form.Item name="AuditComments">
                      <TextArea rows={6} maxLength={200} showCount disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  justify="end"
                  style={{ color: "black", paddingBottom: "15px" }}
                >
                  <Col span={1} />
                  <Col span={3} style={{ padding: "0px 5px" }}>
                    <PageBtns onClick={() => navigate("/WFH/WFHApplication")}>
                      返回
                    </PageBtns>
                  </Col>{" "}
                  <Col span={1} />
                </Row>
              </Form>
            </div>
          </Col>
        </PageLayout>
      </BrowserView>
      <MobileView style={{ marginBottom: "115px" }}>
        <PageLayout spinning={pageLoading}>
          {/* 頁面目前位置 */}
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
              <Breadcrumb.Item href="/WFH/WFHApplication">
                WEH申請
              </Breadcrumb.Item>
              <Breadcrumb.Item>WFH申請檢視</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* 頁面標題 */}
          <PageTitle>WFH申請檢視</PageTitle>
          <Col span={24}>
            <MobilForm
              form={form}
              initialValues={
                id
                  ? {
                      WfhNo: id,
                      ApplicantName: id.ApplicantName,
                    }
                  : null
              }
            >
              <Row
                justify="space-around"
                gutter={[0, 8]}
                style={{ color: "black", padding: "0 10px 15px 10px" }}
              >
                {/* 申請單號 */}
                <Col span={23} style={{ marginTop: "10px" }}>
                  <Form.Item name="WfhNo" label="申請單號">
                    <Input bordered={false} disabled />
                  </Form.Item>
                </Col>
                <Col span={23}>
                  <Form.Item name="ApplicantName" label="申請人">
                    <Input
                      disabled
                      // style={{ width: "80%" }}
                      bordered={false}
                    />
                  </Form.Item>
                </Col>
                {/* 申請日期 */}
                <Col span={23}>
                  <Form.Item label="申請日期" name={"WfhDate"}>
                    <Input bordered={false} disabled />
                  </Form.Item>
                </Col>
                {/* 文件名稱 */}
                <Col span={23}>
                  <Form.Item
                    name="WfhReason"
                    label="原因"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "請輸入文件名稱",
                    //   },
                    // ]}
                  >
                    <TextArea rows={4} maxLength={200} showCount disabled />
                  </Form.Item>
                </Col>
                {/* 內容簡述 */}
                <Col span={23}>
                  <Form.Item name="AuditComments" label="審核意見">
                    <TextArea rows={6} maxLength={200} showCount disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Col span={24} style={{ marginBottom: "15px" }}></Col>
              {/* 按鈕 */}
              <Col span={16} style={{ padding: "0px 5px" }}>
                <PageBtns onClick={() => navigate("/WFH/WFHApplication")}>
                返回
                </PageBtns>
              </Col>
            </MobilForm>
          </Col>
        </PageLayout>
      </MobileView>
      {/* <SignRecordModal /> */}
      {/* <ViewFileModal /> */}
    </>
  );
}
