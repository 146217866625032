import axios from "axios";
import { acquireAccessToken } from "../../msalInstance";
import types from "../action/types";

const graphRequests = (dispatch) =>{
  const instance = axios.create({
    baseURL: 'https://graph.microsoft.com/v1.0',
    timeout: 18000
  })
  instance.interceptors.request.use(
    async (config) => {
      dispatch({
        type: types.INC_LOADING,
        payload: 1
      })
      var accessToken = null
      await acquireAccessToken().then((accessTokenResponse) => {
        // Acquire token silent success
        accessToken = accessTokenResponse;
      })
      .catch((error) => {
        // console.log(error);
      });
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`
      }
      config.headers["Content-Type"] = "application/json"
      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )
  instance.interceptors.response.use(function (resp){
    dispatch({
      type: types.DEC_LOADING,
      payload: 1
    })
    return resp
  }, function (err){
    dispatch({
      type: types.DEC_LOADING,
      payload: 1
    })
    return Promise.reject(err)
  })

  return instance
}
export default graphRequests