/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect,useState} from 'react'
import { useOutletContext, useNavigate, Link } from "react-router-dom";
import { Breadcrumb, List, Input, Spin, Menu, Space, Row, Col, Table, Switch, message, Modal, Divider, Select, DatePicker, Checkbox, Form } from 'antd'
import { SearchOutlined, CaretDownOutlined, AlignLeftOutlined, PlusCircleOutlined, RollbackOutlined} from '@ant-design/icons';
import PageTitle from '../components/PageTitle';
import PageSearch from '../components/PageSearch';
import PageLayout from '../components/PageLayout';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { setReApplyData, setSignReocrdModalVisible, setViewFileModalVisible, setSignAndRejectModalVisible  } from '../redux/action/Components';
import types from '../redux/action/types';
import StampStatusObj from '../helper/StampStatusObj';
import { queryStampTableList, setGlobalSearchParams } from '../redux/action/Stamp';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MobileView, BrowserView, isMobile } from 'react-device-detect';

const { Option } = Select

export default function StampPendingUpload(){

  const dispatch = useDispatch()
  const { currentUser } = useOutletContext();
  const isLoading = useSelector(state => state.globalSetting.isLoading)
  const navigate = useNavigate()
  const stampTableList = useSelector(state => state.Stamp.stampTableList)
  const tableProps = useSelector(state => state.Stamp.tableProps)
  const globalParams = useSelector(state => state.Stamp.globalParams)
  const [ pageSize, setPageSize ] = useState(10)
  const [ pageNow, setPageNow ] = useState(1)
  // 手機板
  const [ mobileTableData, setMobileTableData ] = useState([])
  const [ total, setTotal ] = useState(0)
  const [isMobileFirstRender, setIsMobileFirstRender] = useState(true)

  //初次載入先初始化，避免載入之前搜尋資料
  useEffect(() =>{
    dispatch({ type: types.GET_STAMP_LIST, payload: {} })
    //存取當前位置
    localStorage.setItem('currentMenuItemsKey', 'StampPendingUpload')
    dispatch(setSignAndRejectModalVisible(false))
    dispatch(setSignReocrdModalVisible(false))
    dispatch(setViewFileModalVisible(false))
    setIsMobileFirstRender(false)
    setTimeout(() => {
      //手機板
      if(isMobile){
        dispatch(setGlobalSearchParams({
          "stamp_type": ['pendingUpload'],
          "next_auditor_mail": null,
          "stamp_no": null,
          "document": null,
          "start_date": null,
          "end_date": null,
          "page_record_set": 10,
          "page": 1
        }))
      }
    }, 200);
  }, [])

  useEffect(() =>{
    if(Object.keys(tableProps).length !== 0){
      setPageNow(tableProps['page_now'])
      setPageSize(tableProps['page_size'])
      setTotal(tableProps['count'])
    }
  }, [tableProps])

  useEffect(() =>{
    dispatch(setReApplyData(null))
  },[])

  // 以下是手機板function
  const loadMoreData = () =>{
    let newPage = pageNow + 1
    dispatch(setGlobalSearchParams({ ...globalParams, page_record_set: 10, page: newPage }))
  }

  useEffect(() =>{
    if(Object.keys(globalParams).length !== 0 && isMobile && !isMobileFirstRender){
      let tempTableData = JSON.parse(JSON.stringify(mobileTableData))

      if(globalParams.page === 1){
        setPageNow(1)
        tempTableData = []
        let content = document.getElementById('scrollableDiv')
        if(content){
          content.scrollTop = 0
        }
      }
      dispatch(queryStampTableList(globalParams)).then(resp =>{
        setMobileTableData([...tempTableData, ...resp])
      })
    }
  }, [globalParams])

  const statusOptionList = [
    {
			label: '待上傳',
			value: 'pendingUpload'
    }
  ]
  
  const columns = [
    {
      title: '功能',
      align: 'center',
      dataIndex: 'action',
      key: 'action',
      // width: '15%',
      render:(_, record) =>{
        return(
          <Row justify='space-evenly'>
            <a onClick={() => navigate(`/Stamp/StampApplyViewPendingUpload/${record.stamp_no}`)}> 檢視 </a> 
            {
              record?.applicant_mail?.toLowerCase() === currentUser?.email?.toLowerCase() ?
              <>
                <Divider type='vertical' /><a onClick={() => navigate(`/Stamp/StampTempUpload/${record.stamp_no}`)}> 留存上傳 </a>
              </>
              : null
            }
          </Row>
        )
      }
    },
    {
      title: '編號',
      align: 'center',
      dataIndex: 'stamp_no',
      key: 'stamp_no',
    },
    {
      title: '申請人',
      align: 'center',
      dataIndex: 'applicant_name',
      key: 'applicant_name',
      width:'15%',
    },
    {
      title: '文件名稱',
      align: 'center',
      dataIndex: 'document',
      key: 'document',
      width:'15%',
    },
    {
      title: '申請日期',
      align: 'center',
      dataIndex: 'cr_date',
      key: 'cr_date',
      width:'15%',
      render:(_, record) =>{
        return moment.unix((record.cr_date/1000)).format('YYYY/MM/DD')
      }
    },
    {
      title: '狀態',
      align: 'center',
      dataIndex: 'stamp_status',
      key: 'stamp_status',
      render:(_, record) =>{
        return StampStatusObj[record.stamp_status]
      }
    }
  ];

  return(
    <PageLayout spinning={ isLoading !== 0 ? true : false }>
      <BrowserView>
        {/* 頁面目前位置 */}
        <Col span={24}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item href='/'>WiAdvance EIP</Breadcrumb.Item>
            <Breadcrumb.Item href='/Stamp/StampApplication'>用印申請</Breadcrumb.Item>
            <Breadcrumb.Item >待上傳</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {/* 頁面標題 */}
        <PageTitle>
          待上傳
        </PageTitle>
        {/* 頁面搜尋功能 */}
        <PageSearch
          statusList={statusOptionList}
          defaultSearchParams={['pendingUpload']}
          nextAuitor={null}
        />
        <Col span={24}
          style={{
            margin:'10px 0px',
          }}
        >
        </Col>
        <Col span={24}>
          <Table 
            pagination={{
              position: ['topRight', 'bottomRight'],
              total: Object.keys(tableProps).length !== 0 ? tableProps.count : 0,
              pageSize: pageSize,
              current: pageNow
            }}
            dataSource={Object.keys(stampTableList).length !== 0 ? stampTableList : []}
            onChange={(page) => { dispatch(setGlobalSearchParams({ ...globalParams, page: page.current, page_record_set: pageSize })); setPageNow(page.current) }}
            columns={columns}
            footer={(ele) => {
              return(
                <Col flex="auto">
                  <div style={{'textAlign':'end', 'margin':'8px', 'color':'#7C7C7C'}}>
                    共有{Object.keys(tableProps).length !== 0 ? tableProps.count : 0}筆資料，每頁顯示
                    <Select
                      value={pageSize}
                      style={{
                        width: ( pageSize < 100 ? 60 : 70 ),
                        color:'#a5a5a5'
                      }}
                      bordered={false}
                      suffixIcon={<CaretDownOutlined />}
                      onChange={(e)=> { dispatch(setGlobalSearchParams({ ...globalParams, page_record_set: e, page: 1 })); setPageSize(e); setPageNow(1)}}
                    >
                      <Option value= {5}>5</Option>
                      <Option value= {10}>10</Option>
                      <Option value= {25}>25</Option>
                      <Option value= {50}>50</Option>
                      <Option value= {100}>100</Option>
                    </Select>
                    筆
                  </div>
                </Col>
              )
            }}
          />
        </Col>
      </BrowserView>
      <MobileView style={{ marginBottom: '115px' }}>
        {/* 頁面目前位置 */}
        <Col span={24}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item href='/'>WiAdvance EIP</Breadcrumb.Item>
            <Breadcrumb.Item href='/Stamp/StampApplication'>用印申請</Breadcrumb.Item>
            <Breadcrumb.Item >待上傳</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {/* 頁面標題 */}
        <PageTitle>
          待上傳
        </PageTitle>
        {/* 頁面搜尋功能 */}
        <PageSearch 
          statusList={statusOptionList}
          defaultSearchParams={['pendingUpload']}
          nextAuitor={null}
        />
        <Col span={24} style={{ margin:'10px 0px' }} />
        <div
          id="scrollableDiv"
          style={{
            height: 300,
            overflowY: 'auto',
            overflowX: 'hidden',
            // border: '1px solid rgba(140, 140, 140, 0.35)',
            zIndex: 1000
          }}
        >
          <InfiniteScroll
            dataLength={mobileTableData.length}
            next={loadMoreData}
            hasMore={total - mobileTableData.length > 0}
            scrollableTarget="scrollableDiv"
          >
            <List
              dataSource={mobileTableData}
              style={{ background: 'white' }}
              renderItem={(record, index) => {
                return(
                  <>
                    <Row style={{ padding: '10px 0', background: '#71AA1F'}}>
                      <Col span={12} style={{ fontSize: '18px', fontWeight: 'bold', paddingLeft: '16px', color: 'white' }}>
                        編號
                      </Col>
                      <Col span={12} style={{ fontSize: '18px', fontWeight: 'bold', paddingRight: '16px', color: 'white' }}>
                        {record.stamp_no}
                      </Col>
                    </Row>
                    <Row gutter={[ 0, 16 ]} style={{ padding: '10px 16px 0 16px'}}>
                      <Col span={12}>
                        申請人
                      </Col>
                      <Col span={12}>
                        {record.applicant_name}
                      </Col>
                      <Col span={12}>
                        文件名稱
                      </Col>
                      <Col span={12}>
                        {record.document}
                      </Col>
                      <Col span={12}>
                        申請日期
                      </Col>  
                      <Col span={12}>
                        {moment.unix((record.cr_date/1000)).format('YYYY/MM/DD')}
                      </Col>
                      <Col span={12}>
                        狀態
                      </Col>
                      <Col span={12}>
                        {StampStatusObj[record.stamp_status]}
                      </Col>
                      <Divider style={{ margin: '0' }}/>
                      <Row style={{ width: '100%' }} justify='space-evenly'>
                        <a onClick={() => navigate(`/Stamp/StampApplyViewPendingUpload/${record.stamp_no}`)}> 檢視 </a> 
                        {
                          record?.applicant_mail?.toLowerCase() === currentUser?.email?.toLowerCase() ?
                          <>
                            <Divider type='vertical' /><a onClick={() => navigate(`/Stamp/StampTempUpload/${record.stamp_no}`)}> 留存上傳 </a>
                          </>
                          : null
                        }
                      </Row>
                      <Divider style={{ margin: '0 0 16px 0' }}/>
                    </Row>
                    <Row style={{ background: '#f6f6f6', height: '20px', margin: '0 0 16px 0' }} />
                  </>
                )
              }}
            />
          </InfiniteScroll>
        </div>
      </MobileView>
    </PageLayout>
  )
}