/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import {
  useOutletContext,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  Row,
  Col,
  Table,
  Select,
  Divider,
  List,
  Form,
  DatePicker,
  Modal,
  message,
  notification,
} from "antd";
import {
  PlusCircleOutlined,
} from "@ant-design/icons";
import PageTitle from "../components/PageTitle";
import PageLayout from "../components/PageLayout";
import moment from "moment";
import PageTable from "../components/PageTable";
import {
  getSearchConditions,
  getWFH,
  putWFHApply,
  getWFHExcel,
} from "../newApi/wfh.api";

import WFHpStatusObj from "../helper/WFHpStatusObj";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import InfiniteScroll from "react-infinite-scroll-component";
import MobileBtns from "../components/MobileBtns";
import PageBtns from "../components/PageBtns";

export default function WFHApplication() {
  const { Option } = Select;
  const { currentUser } = useOutletContext();
  const navigate = useNavigate();
  // const isLoading = useSelector((state) => state.globalSetting.isLoading);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  // const [pageSize, setPageSize] = useState(10);
  const [pageNow, setPageNow] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [revokeOpen, setRevokeOpen] = useState(false);

  // 手機板
  const [mobileTableData, setMobileTableData] = useState([]);
  const [DateConditions, setDateConditions] = useState([
    { label: "申請在家上班日期", value: "WfhDate" },
  ]);
  const [DepartmentConditions, setDepartmentConditions] = useState([]);
  const [StatusConditions, setStatusConditions] = useState([]);
  const initialValue = { DateType: "WfhDate" };
  //分頁
  const [searchParams, setSearchParams] = useSearchParams();
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState(initialValue);
  const p = searchParams.get("p") || 1;
  const ps = searchParams.get("ps") || 10;
  const [startDateError, setStartDateError] = useState(null);
  const [endDateError, setEndDateError] = useState(null);
  const endDatePickerRef = useRef(null);
  const submitBtnRef = useRef(null);

  const statusRender = (record) => {
    if (WFHpStatusObj[record?.Status] === "審核中") {
      return (
        <div style={{ fontWeight: 600, color: "blue" }}>
          {WFHpStatusObj[record?.Status]}
        </div>
      );
    } else if (WFHpStatusObj[record?.Status] === "通過") {
      return (
        <div style={{ color: "green" }}>{WFHpStatusObj[record?.Status]}</div>
      );
    } else if (WFHpStatusObj[record?.Status] === "退回") {
      return (
        <div style={{ color: "red" }}>{WFHpStatusObj[record?.Status]}</div>
      );
    } else {
      return (
        <div style={{ color: "#E98B2A" }}>{WFHpStatusObj[record?.Status]}</div>
      );
    }
  };

  const initial = (params) => {
    setPageLoading(true);
    getWFH(params)
      .then((res) => {
        if (res?.isSuccess) {
          if (isMobile) {
            let tempTableData = JSON.parse(JSON.stringify(mobileTableData));
            setMobileTableData([...tempTableData, ...res?.data]);
          }
          setData(res?.data);
          setTotal(res?.pageCounts);
        }
      })
      .finally(() => setPageLoading(false));
  };
  
  useEffect(() => {
    if (isMobile) {
      searchParams.set("p", 1);
      searchParams.set("ps", 10);
      setSearchParams(searchParams);
      initial({
        ...searchValues,
        PageIndex: pageNow,
        PageRow: 10,
        mail:
          localStorage.getItem("mockUser") || undefined,
      });
    } else {
      initial({
        ...searchValues,
        PageIndex: p,
        PageRow: ps,
        mail:
          localStorage.getItem("mockUser") || undefined,
      });
    }
  }, [p, ps, searchValues]);

  useEffect(() => {
    getSearchConditions({
      mail:
        localStorage.getItem("mockUser") || undefined,
    }).then((res) => {
      if (res?.isSuccess) {
        setDateConditions(
          res?.data?.DateConditions?.map((i) => {
            return { label: i.Text, value: i.Value };
          })
        );
        setDepartmentConditions(
          res?.data?.DepartmentConditions?.map((i) => {
            return { label: i.Text, value: i.Value };
          })
        );
        setStatusConditions(
          res?.data?.StatusConditions?.map((i) => {
            return { label: i.Text, value: i.Value };
          })
        );
      }
    });
  }, []);

  const exportFile = () => {
    setPageLoading(true);
    getWFHExcel({
      ...searchValues,
      mail:
        localStorage.getItem("mockUser") || undefined,
    })
      .then((res) => {
        // if (res?.isSuccess) {
        //   // setData(res?.data);
        //   // setMobileTableData(res?.data);
        // } else {
        //   message.error(
        //     res?.errorMessage === "" || !res?.errorMessage
        //       ? "服務異常"
        //       : res?.errorMessage
        //   );
        // }
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `WFH_${moment().format("YYYYMMDDHHMMSS")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => setPageLoading(false));
  };

  const onSearch = () => {
    const value = form.getFieldsValue();
    // console.log(value);
    setSearchValues({
      ...value,
      StartDate: value.StartDate?.format("YYYY-MM-DD"),
      EndDate: value.EndDate?.format("YYYY-MM-DD"),
    });
    searchParams.set("p", 1);
    setSearchParams(searchParams);
  };
  const onMSearch = () => {
    const value = form.getFieldsValue();
    setSearchValues({
      ...value,
      StartDate: value.StartDate?.format("YYYY-MM-DD"),
      EndDate: value.EndDate?.format("YYYY-MM-DD"),
    });
    setMobileTableData([]);
    setPageNow(1);
    // searchParams.set("p", 1);
    // setSearchParams(searchParams);
  };
  const onClearSearch = () => {
    form.resetFields();
    setStartDate(null);
    setEndDate(null);
    setSearchValues(initialValue);
  };

  const confirmCancel = () => {
    setLoading(true);
    putWFHApply({
      WfhNo: revokeOpen?.WfhNo,
      Status: "revoke",
      AuditComments: null,
    })
      .then((res) => {
        if (res?.isSuccess) {
          notification.success({
            message: "成功",
            description: "抽回成功",
          });
          // setRevokeOpen(false);
          if (isMobile) {
            onMSearch();
          } else {
            onSearch();
          }
        }
      })
      .finally(() => {
        setRevokeOpen(false);
        setLoading(false);
      });
  };

  // 以下是手機板function
  const loadMoreData = () => {
    if (!mobileTableData?.length) {
      return;
    }
    let newPage = pageNow + 1;
    setPageNow(newPage);
    initial({
      ...searchValues,
      PageIndex: newPage,
      PageRow: 10,
      mail:
        localStorage.getItem("mockUser") || undefined,
    });
  };

  const columns = [
    {
      title: "操作",
      align: "center",
      dataIndex: "Action",
      key: "Action",
      width: "70px",
      render: (_, record) => {
        return (
          <Row justify="space-evenly">
            {/* <a
              onClick={() =>
                navigate(`/WFH/WFHApplication/view/${record.WfhNo}`)
              }
            >
              檢視
            </a>
            {record.ActionCode === "revoke" ? (
              <>
                <Divider type="vertical" />
                <a
                  onClick={() => {
                    setRevokeOpen(record);
                  }}
                >
                  抽回
                </a>
              </>
            ) : record.ActionCode === "audit" ? (
              <>
                <Divider type="vertical" />
                <a
                  onClick={() =>
                    navigate(`/WFH/WFHApplication/audit/${record.WfhNo}`)
                  }
                >
                  審核
                </a>
              </>
            ) : null}
          </Row>
        );
      },
    }, */}
            {record.ActionCode === "revoke" ? (
              <>
                {/* <Divider type="vertical" /> */}
                <a
                  onClick={() => {
                    setRevokeOpen(record);
                  }}
                  style={{ color: "#E98B2A" }}
                >
                  抽回
                </a>
              </>
            ) : record.ActionCode === "audit" ? (
              <>
                {/* <Divider type="vertical" /> */}
                <a
                  onClick={() =>
                    navigate(`/WFH/WFHApplication/audit/${record.WfhNo}`)
                  }
                >
                  審核
                </a>
              </>
            ) : null}
          </Row>
        );
      },
    },
    {
      title: "申請單號",
      align: "center",
      dataIndex: "WfhNo",
      key: "WfhNo",
      render: (item, record) => {
        return (
          <div style={{ fontSize: "14px" }}>
            <a
              onClick={() =>
                navigate(`/WFH/WFHApplication/view/${record.WfhNo}`)
              }
            >
              {item}
            </a>
          </div>
        );
      },
    },
    {
      title: <div style={{ fontSize: "14px" }}>申請在家上班日期</div>,
      align: "center",
      dataIndex: "WfhDate",
      key: "WfhDate",
      width: "145px",
      // render: (_, record) => {
      //   return moment.unix(record.WfhDate / 1000).format("YYYY/MM/DD");
      // },
    },
    {
      title: "申請人",
      align: "center",
      dataIndex: "ApplicantName",
      key: "ApplicantName",
      // width: "10%",
      render: (item) => {
        return <div style={{ fontSize: "14px", fontWeight: 600 }}>{item}</div>;
      },
    },

    {
      title: "填單人",
      align: "center",
      dataIndex: "FillerName",
      key: "FillerName",
      // width: "15%",
      render: (item) => {
        return <div style={{ fontSize: "14px" }}>{item}</div>;
      },
    },
    {
      title: "填單日期",
      align: "center",
      dataIndex: "FillingDate",
      key: "FillingDate",
      width: "110px",
      render: (item) => {
        return <div style={{ fontSize: "14px" }}>{item}</div>;
      },
      // render: (_, record) => {
      //   return moment.unix(record.FillingDate / 1000).format("YYYY/MM/DD");
      // },
    },
    {
      title: "審核人",
      align: "center",
      dataIndex: "AuditorName",
      key: "AuditorName",
      render: (item) => {
        return <div style={{ fontSize: "14px" }}>{item}</div>;
      },
    },
    {
      title: "審核日期",
      align: "center",
      dataIndex: "AuditDate",
      key: "AuditDate",
      width: "110px",
      render: (item) => {
        return <div style={{ fontSize: "14px" }}>{item}</div>;
      },
    },
    {
      title: "狀態",
      align: "center",
      dataIndex: "Status",
      key: "Status",
      width: "100px",
      render: (_, record) => {
        return statusRender(record);
      },
    },
  ];

  const onStartDateChange = (dataString) => {
    if (
      dataString?.length === 8 &&
      moment(dataString, "YYYYMMDD", true).isValid()
    ) {
      if (
        !endDate ||
        moment(dataString, "YYYYMMDD") <= moment(endDate).endOf("day")
      ) {
        form.setFieldValue("StartDate", moment(dataString, "YYYYMMDD"));
        setStartDate(moment(dataString, "YYYYMMDD"));
        setStartDateError(false);
        endDatePickerRef.current?.focus();
      } else {
        setStartDateError(true);
      }
    } else if (
      dataString?.length === 10 &&
      moment(dataString, "YYYY-MM-DD", true).isValid()
    ) {
      if (
        !endDate ||
        moment(dataString, "YYYY-MM-DD") <= moment(endDate).endOf("day")
      ) {
        form.setFieldValue("StartDate", moment(dataString, "YYYY-MM-DD"));
        setStartDate(moment(dataString, "YYYY-MM-DD"));
        setStartDateError(false);
        endDatePickerRef.current?.focus();
      } else {
        setStartDateError(true);
      }
    } else if (dataString?.length !== 8) {
      setStartDateError(true);
      if (!startDate) {
        form.setFieldValue("StartDate", null);
        setStartDate(null);
      }
    } else {
      setStartDateError(true);
    }
  };

  const onEndDateChange = (dataString) => {
    if (
      dataString?.length === 8 &&
      moment(dataString, "YYYYMMDD", true).isValid()
    ) {
      if (
        !startDate ||
        moment(dataString, "YYYYMMDD") >= moment(startDate).startOf("day")
      ) {
        form.setFieldValue("EndDate", moment(dataString, "YYYYMMDD"));
        setEndDate(moment(dataString, "YYYYMMDD"));
        setEndDateError(false);
        submitBtnRef.current?.focus();
      } else {
        setEndDateError(true);
      }
    } else if (
      dataString?.length === 10 &&
      moment(dataString, "YYYY-MM-DD", true).isValid()
    ) {
      if (
        !startDate ||
        moment(dataString, "YYYY-MM-DD") >= moment(startDate).startOf("day")
      ) {
        form.setFieldValue("EndDate", moment(dataString, "YYYY-MM-DD"));
        setEndDate(moment(dataString, "YYYY-MM-DD"));
        setEndDateError(false);
        submitBtnRef.current?.focus();
      } else {
        setEndDateError(true);
      }
    } else if (dataString?.length !== 8) {
      setEndDateError(true);
      if (!endDate) {
        form.setFieldValue("EndDate", null);
        setEndDate(null);
      }
    } else {
      setEndDateError(true);
    }
  };
  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  return (
    <PageLayout spinning={pageLoading}>
      {/* 申請取消Modal */}
      <Modal
        visible={!!revokeOpen}
        onCancel={() => !loading && setRevokeOpen(false)}
        title="抽回申請"
        maskClosable={false}
        footer={[
          <PageBtns type="primary" loading={loading} onClick={confirmCancel}>
            確認
          </PageBtns>,
          <PageBtns
            onClick={() => {
              !loading && setRevokeOpen(false);
            }}
          >
            取消
          </PageBtns>,
        ]}
      >
        <Row
          gutter={[24, 8]}
          style={{
            color: "#3f3f3f",
            padding: "15px",
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          <Col span={23}>
            <p>確認要抽回此申請？</p>
          </Col>
        </Row>
      </Modal>
      <BrowserView>
        {/* 頁面目前位置 */}
        <Col span={24}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
            <Breadcrumb.Item href="/WFH/WFHApplication">
              WFH申請
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              WFH申請查詢
              {!!localStorage.getItem("mockUser") &&
                <span style={{color:'#ed3b3b'}}>（{localStorage.getItem("mockUser")}）</span>}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {/* 頁面標題 */}
        <PageTitle>WFH申請查詢</PageTitle>
        {/* 頁面搜尋功能 */}
        <Col
          style={{
            margin: "0px 0px 10px",
            padding: "10px 20px",
            minHeight: "60px",
            width: "100%",
            backgroundColor: "white",
          }}
          span={24}
        >
          <Form
            colon={false}
            labelAlign="left"
            form={form}
            initialValues={initialValue}
            labelCol={{ flex: "70px" }}
          >
            <Row
              justify="start"
              style={{ paddingTop: "5px", paddingRight: "7px" }}
            >
              <Col span={6} style={{ marginTop: "0px", padding: "7px" }}>
                {/* <Col style={{ paddingTop: "7px" }} span={24}> */}
                <Form.Item label="日期類型" name="DateType" justify="flex">
                  <Select
                    // mode='multiple'
                    style={{ width: "100%" }}
                    placeholder="請選擇"
                    // allowClear
                    options={DateConditions}
                  ></Select>
                </Form.Item>
                {/* </Col> */}
              </Col>
              {/* 日期選擇框 */}
              <Col
                span={6}
                offset={1}
                style={{ marginTop: "0px", padding: "7px" }}
              >
                {/* <Col style={{ paddingTop: "7px" }} span={24}> */}
                <Form.Item
                  label="開始日期"
                  name="StartDate"
                  onChange={(e) => {
                    onStartDateChange(e.target.value);
                  }}
                  extra="允許自行輸入數字，格式YYYYMMDD"
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    disabledDate={(cur) => {
                      if (endDate) {
                        return endDate <= moment(cur).startOf("day");
                      } else {
                        return false;
                      }
                    }}
                    onChange={(e) => {
                      setStartDate(e);
                      endDatePickerRef.current?.focus();
                    }}
                    status={startDateError ? "error" : null}
                    onOpenChange={(open) => {
                      console.log(open);
                      if (!open) {
                        setStartDateError(false);
                      }
                    }}
                  />
                </Form.Item>
                {/* </Col> */}
              </Col>
              <Col span={1}>
                <Form.Item style={{ marginTop: "-1px" }}>
                  <p></p>
                  <p style={{ textAlign: "center" }}>～</p>
                </Form.Item>
              </Col>
              {/* 日期選擇框 */}
              <Col span={6} style={{ marginTop: "0px", padding: "7px" }}>
                <Form.Item
                  label="結束日期"
                  name="EndDate"
                  onChange={(e) => {
                    onEndDateChange(e.target.value);
                  }}
                  extra="允許自行輸入數字，格式YYYYMMDD"
                >
                  <DatePicker
                    ref={endDatePickerRef}
                    style={{ width: "100%" }}
                    disabledDate={(cur) => {
                      return startDate >= moment(cur).endOf("day");
                    }}
                    onChange={(e) => {
                      setEndDate(e);
                    }}
                    status={endDateError ? "error" : null}
                    onOpenChange={(open) => {
                      if (!open) {
                        setEndDateError(false);
                      }
                    }}
                  />
                </Form.Item>
                {/* </Col> */}
              </Col>
            </Row>
            <Row
              justify="start"
              style={{ paddingRight: "7px", marginTop: "-20px" }}
            >
              <Col span={6} style={{ padding: "7px" }}>
                {/* <Col style={{ paddingTop: "7px" }} span={24}> */}
                <Form.Item label="部門" name="DeptCode">
                  <Select
                    placeholder="請選擇部門"
                    options={DepartmentConditions}
                    allowClear
                    showSearch
                    filterOption={filterOption}
                  />
                </Form.Item>
                {/* </Col> */}
              </Col>
              {/* <Col span={3} /> */}
              <Col span={6} offset={1} style={{ padding: "7px" }}>
                <Form.Item label="申請人" name="Applicant">
                  <Input placeholder="請輸入申請人" allowClear/>
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={4} style={{ padding: "7px" }}>
                {/* <Col style={{ paddingTop: "7px" }} span={24}> */}
                <Form.Item label="狀態" name="Status" justify="flex">
                  <Select
                    style={{ width: "100%" }}
                    placeholder="請選擇狀態"
                    allowClear
                    options={StatusConditions}
                  ></Select>
                </Form.Item>
                {/* </Col> */}
              </Col>
              {/* 清除按鈕 */}
              <Col
                span={2}
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  alignSelf: "flex-end",
                }}
              >
                <Button
                  style={{
                    height: "40.14px",
                    marginBottom: "5px",
                    marginLeft: "5px",
                    width: "120%",
                  }}
                  type="primary"
                  onClick={() => onSearch()}
                  ref={submitBtnRef}
                >
                  {" "}
                  查詢{" "}
                </Button>
              </Col>
              {/* 清除按鈕 */}
              <Col
                span={2}
                style={{
                  // marginTop: "-2.6px",
                  display: "flex",
                  alignSelf: "flex-end",
                }}
              >
                <Button
                  style={{
                    height: "40.14px",
                    marginBottom: "5px",
                    marginLeft: "5px",
                    width: "120%",
                  }}
                  onClick={() => onClearSearch()}
                >
                  {" "}
                  清除{" "}
                </Button>
              </Col>
            </Row>
            <Row
              justify="end"
              style={{
                paddingBottom: "3px",
                // marginTop: "-20px"
              }}
            ></Row>
          </Form>
        </Col>
        <Col span={24}>
          <Row
            justify="space-between"
            style={{ margin: "10px 10px" }}
            gutter={[24, 8]}
          >
            <Col span={16} />
            <Col span={4} style={{ paddingRight: "0px", marginTop: "10px" }}>
              {" "}
              <Button
                ghost
                type={"primary"}
                style={{
                  height: "40.14px",
                  border: "1px solid #00a2dd",
                  alignSelf: "flex-end",
                }}
                onClick={exportFile}
                disabled={!data?.length}
              >
                匯出
              </Button>
            </Col>

            <Col span={4} style={{ marginTop: "10px", marginLeft: "0px" }}>
              <Button
                type={"primary"}
                style={{
                  height: "40.14px",
                  backgroundColor: "#00a2dd",
                  paddingLeft: "0px",
                }}
                onClick={() => navigate("/WFH/WFHApplication/add")}
              >
                新增申請
                <PlusCircleOutlined />
              </Button>
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          style={{
            margin: "10px 0px",
          }}
        ></Col>
        <Col span={24}>
          <PageTable columns={columns} dataSource={data} total={total || 0} />{" "}
        </Col>
      </BrowserView>
      <MobileView style={{ marginBottom: "50px" }}>
        {/* 頁面目前位置 */}
        <Col span={24}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/">WiAdvance EIP</Breadcrumb.Item>
            <Breadcrumb.Item href="/WFH/WFHApplication">
              WFH申請
            </Breadcrumb.Item>
            <Breadcrumb.Item>WFH申請查詢</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {/* 頁面標題 */}
        <PageTitle>WFH申請查詢</PageTitle>

        {/* 頁面搜尋功能 */}
        <Col
          style={{
            margin: "10px 0px",
            padding: "10px 10px",
            minHeight: "60px",
            width: "100%",
            backgroundColor: "white",
          }}
          span={24}
        >
          <Form
            form={form}
            autoComplete="false"
            style={{ padding: "5px" }}
            // statusList={DateConditions}
            // nextAuitor={null}
            initialValues={initialValue}
          >
            <Row gutter={[8, 8]} justify="start">
              {/* 狀態下拉選單 */}
              <Col span={24} style={{ marginTop: "-4.9px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item
                    label="日期類型"
                    labelCol={{ span: 24 }}
                    name="DateType"
                  >
                    <Select
                      // mode='multiple'
                      style={{ width: "80%" }}
                      placeholder="請選擇"
                      // allowClear
                      options={DateConditions}
                    ></Select>
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            {/* 日期選擇框  */}
            <Row gutter={[8, 8]} justify="start">
              <Col span={24} style={{ marginTop: "-2.6px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item
                    label="開始日期"
                    labelCol={{ span: 24 }}
                    name="StartDate"
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      disabledDate={(cur) => {
                        if (endDate) {
                          return endDate <= moment(cur).startOf("day");
                        } else {
                          return false;
                        }
                      }}
                      onChange={(e) => {
                        setStartDate(e);
                        endDatePickerRef.current?.focus();
                      }}
                      inputReadOnly
                    />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            {/* 日期選擇框  */}
            <Row gutter={[8, 8]} justify="start">
              <Col span={24} style={{ marginTop: "-2.6px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item
                    label="結束日期"
                    labelCol={{ span: 24 }}
                    name="EndDate"
                  >
                    <DatePicker
                      ref={endDatePickerRef}
                      style={{ width: "100%" }}
                      disabledDate={(cur) => {
                        return startDate >= moment(cur).endOf("day");
                      }}
                      onChange={(e) => {
                        setEndDate(e);
                      }}
                      inputReadOnly
                    />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            <Row gutter={[8, 8]} justify="start">
              <Col span={24} style={{ marginTop: "-2.6px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item
                    label="部門"
                    labelCol={{ span: 24 }}
                    name="DeptCode"
                  >
                    <Select
                      // style={{ width: "80%" }}
                      placeholder="請選擇部門"
                      options={DepartmentConditions}
                      showSearch
                      allowClear
                      filterOption={filterOption}
                    />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            {/* 申請人 */}
            <Row gutter={[8, 8]} justify="start">
              <Col span={24} style={{ marginTop: "-2.6px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item
                    label="申請人"
                    labelCol={{ span: 24 }}
                    name="Applicant"
                  >
                    <Input placeholder="請輸入申請人" allowClear />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            {/* 狀態 */}
            <Row gutter={[8, 8]} justify="start">
              <Col span={24} style={{ marginTop: "-2.6px" }}>
                <Col style={{ paddingTop: "7px" }} span={24}>
                  <Form.Item label="狀態" labelCol={{ span: 24 }} name="Status">
                    <Select
                      // style={{ width: "80%" }}
                      placeholder="請選擇狀態"
                      options={StatusConditions}
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            {/* 文件名稱輸入框  */}

            <Row gutter={[8]} justify="center">
              {/* 查詢按鈕  */}
              <MobileBtns
                type="primary"
                ref={submitBtnRef}
                onClick={() => onMSearch()}
              >
                {" "}
                查詢{" "}
              </MobileBtns>
              {/* 清除按鈕  */}
              <MobileBtns onClick={() => onClearSearch()}> 清除 </MobileBtns>
            </Row>
          </Form>
        </Col>
        <Col span={24}>
          <Row gutter={[8]} justify="center" style={{ margin: "10px 10px" }}>
            <Col span={12} style={{ padding: "5px" }}>
              <Button
                // type={"primary"}
                ghost
                type={"primary"}
                style={{
                  height: "40.14px",
                  border: "1px solid #00a2dd",
                  // alignSelf: "flex-end",
                }}
                onClick={exportFile}
                disabled={!mobileTableData?.length}
              >
                匯出
              </Button>
            </Col>
            <Col span={12} style={{ padding: "5px" }}>
              <Button
                type={"primary"}
                style={{ height: "40.14px",backgroundColor: "#00a2dd" }}
                onClick={() => navigate("/WFH/WFHApplication/add")}
              >
                新增申請
                <PlusCircleOutlined />
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ margin: "10px 0px" }} />
        <div
          id="scrollableDiv"
          style={
            total < 10
              ? {
                  // height: 300,
                  overflowY: "auto",
                  // border: '1px solid rgba(140, 140, 140, 0.35)',
                  zIndex: 1000,
                  // height:'100vh'
                }
              : {
                  overflowY: "auto",
                  // border: '1px solid rgba(140, 140, 140, 0.35)',
                  zIndex: 1000,
                  height: "100vh",
                }
          }
        >
          <InfiniteScroll
            dataLength={mobileTableData.length}
            next={loadMoreData}
            hasMore={total - mobileTableData.length > 0}
            scrollableTarget="scrollableDiv"
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b style={{ color: "#8c8c8c" }}>
                  已瀏覽所有單據。請繼續操作或返回頂部。
                </b>
              </p>
            }
          >
            <List
              dataSource={mobileTableData}
              style={{ background: "white" }}
              renderItem={(record) => {
                return (
                  <>
                    <Row
                      style={{ padding: "10px 0", background: "#71AA1F" }}
                      justify="space-between"
                    >
                      <Col
                        // span={12}
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          paddingLeft: "16px",
                          color: "white",
                        }}
                      >
                        申請單號
                      </Col>
                      <Col
                        // span={12}
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          paddingRight: "16px",
                          color: "white",
                          align: "right",
                        }}
                      >
                        {record.WfhNo}
                      </Col>
                    </Row>
                    <Row
                      gutter={[0, 16]}
                      style={{ padding: "10px 16px 0 16px" }}
                    >
                      <Col span={12}>申請在家上班日期</Col>
                      <Col span={12}>{record.WfhDate}</Col>
                      <Col span={12}>申請人</Col>
                      <Col span={12}>
                        <div style={{ fontWeight: 600 }}>
                          {record.ApplicantName}
                        </div>
                      </Col>
                      <Col span={12}>填單人</Col>
                      <Col span={12}>{record.FillerName}</Col>
                      <Col span={12}>填單日期</Col>
                      <Col span={12}>{record.FillingDate}</Col>
                      <Col span={12}>審核人</Col>
                      <Col span={12}>{record.AuditorName}</Col>
                      <Col span={12}>審核日期</Col>
                      <Col span={12}>{record.AuditDate}</Col>
                      <Col span={12}>狀態</Col>
                      <Col span={12}>{statusRender(record)}</Col>
                      <Divider style={{ margin: "0" }} />
                      <Row style={{ width: "100%" }} justify="space-evenly">
                        {/* {record.stamp_status === "done" ? ( */}
                        <a
                          onClick={() =>
                            navigate(`/WFH/WFHApplication/view/${record.WfhNo}`)
                          }
                        >
                          {" "}
                          檢視{" "}
                        </a>
                        {record.ActionCode === "revoke" ? (
                          <>
                            <Divider
                              type="vertical"
                              style={{ height: "28px" }}
                            />
                            <a
                              onClick={() => {
                                setRevokeOpen(record);
                              }}
                              style={{ color: "#E98B2A" }}
                            >
                              抽回
                            </a>
                          </>
                        ) : record.ActionCode === "audit" ? (
                          <>
                            <Divider
                              type="vertical"
                              style={{ height: "28px" }}
                            />
                            <a
                              onClick={() =>
                                navigate(
                                  `/WFH/WFHApplication/audit/${record.WfhNo}`
                                )
                              }
                            >
                              {" "}
                              審核{" "}
                            </a>
                          </>
                        ) : null}
                        {/* </>
                        ) : null} */}
                      </Row>
                      <Divider style={{ margin: "0" }} />
                    </Row>
                    <Row
                      style={{
                        background: "#f6f6f6",
                        height: "20px",
                        margin: "16px 0 0 0",
                      }}
                    />
                  </>
                );
              }}
            />
          </InfiniteScroll>
        </div>
      </MobileView>
    </PageLayout>
  );
}
