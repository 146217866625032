import { combineReducers } from "redux";
import globalReducer from "./globalReducer";
import graphReducer from "./graphReducer";
import ModalReducer from './ModalReducer'
import StampReducer from "./StampReducer";

export default combineReducers({
  graph: graphReducer,
  globalSetting: globalReducer,
  Modal: ModalReducer,
  Stamp: StampReducer
})