import React, { useState } from "react";
import { Col, Form, Select, Row, Card, Checkbox, List } from "antd";
import { isMobile } from "react-device-detect";
import BarChart from "./Charts/BarChart"; // import echarts from 'echarts';
import PageTable from "./PageTable";
import InfiniteScroll from "react-infinite-scroll-component";

export default function Tab3Content(props) {
  const columns = [
    {
      title: <div style={{ fontSize: "14px", fontWeight: 700 }}>排名</div>,
      align: "center",
      dataIndex: "Ranking",
      key: "Ranking",
      width: "7%",
      render: (item) => {
        return <div style={{ fontSize: "14px", fontWeight: 600 }}>{item}</div>;
      },
    },
    {
      title: <div style={{ fontSize: "14px", fontWeight: 600 }}>金額合計</div>,
      align: "center",
      dataIndex: "Amount_LCY",
      key: "Amount_LCY",
      width: "20%",
      render: (item) => {
        return <div style={{ fontSize: "14px" }}>{item}</div>;
      },
    },
    {
      title: <div style={{ fontSize: "14px", fontWeight: 600 }}>工號</div>,
      align: "center",
      dataIndex: "Salesperson_Code",
      key: "Salesperson_Code",
      idth: "25%",
      render: (item) => {
        return <div style={{ fontSize: "14px" }}>{item}</div>;
      },
    },
    {
      title: <div style={{ fontSize: "14px", fontWeight: 600 }}>中文姓名</div>,
      align: "center",
      dataIndex: "Cname",
      key: "Cname",
      width: "20%",
      render: (item) => {
        return <div style={{ fontSize: "14px" }}>{item}</div>;
      },
    },
    {
      title: <div style={{ fontSize: "14px", fontWeight: 600 }}>英文姓名</div>,
      align: "center",
      dataIndex: "Ename",
      key: "Ename",
      width: "20%",
      render: (item) => {
        return <div style={{ fontSize: "14px" }}>{item}</div>;
      },
    },
  ];
  const columnsMobile = [columns[0], columns[1], columns[7]];
  const switchColumns = isMobile ? columnsMobile : columns;
  const chartStyle = isMobile
    ? { height: "50vh", width: "35vh", padding: "0px 0px 0px 0px" }
    : { height: "50vh", width: "100vh", padding: "0px 0px 10px 20px" };

  const data3A = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: isMobile?("2%"):("3%"),
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      isMobile
        ? {
            type: "category",
            data: props.data?.[3]?.data.slice(0, 10).map((i) => i?.Ename),
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              rotate: 40,
            },
          }
        : {
            type: "value",
          },
    ],
    yAxis: [
      isMobile
        ? {
            type: "value",
          }
        : {
            type: "category",
            data: props.data?.[3]?.data
              .slice(0, 10)
              .map((i) => i?.Ename)
              .reverse(),
            axisTick: {
              alignWithLabel: true,
            },
            label: {
              position: "right",
              show: true,
            },
          },
    ],
    series: [
      isMobile
        ? {
            name: "金額合計",
            type: "bar",
            barWidth: "60%",
            data: props.data?.[3]?.data.slice(0, 10).map((i) => i?.Amount_LCY),
          }
        : {
            name: "金額合計",
            type: "bar",
            barWidth: "60%",
            data: props.data?.[3]?.data
              .slice(0, 10)
              .map((i) => i?.Amount_LCY)
              .reverse(),
          },
    ],
  };

  const displayData = props.switchChart ? (
    isMobile ? (
      <>
        <div
          id="scrollableDiv"
          style={{
            overflowY: "auto",
            // border: '1px solid rgba(140, 140, 140, 0.35)',
            zIndex: 1000,
            // height: "100vh",
          }}
        >
          <InfiniteScroll
            dataLength={props.data?.length}
            next={props.data}
            // hasMore={props.data?.length > 0}
            scrollableTarget="scrollableDiv"
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b style={{ color: "#8c8c8c" }}>
                  已瀏覽所有單據。請繼續操作或返回頂部。
                </b>
              </p>
            }
          >
            <List
              dataSource={props.data?.[3]?.data}
              style={{ background: "white" }}
              renderItem={(record) => {
                return (
                  <>
                    <Row
                      style={{ padding: "10px 0", background: "#71AA1F" }}
                      justify="space-between"
                    >
                      <Col
                        // span={12}
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          paddingLeft: "16px",
                          color: "white",
                        }}
                      >
                        排名
                      </Col>
                      <Col
                        // span={12}
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          paddingRight: "16px",
                          color: "white",
                          align: "right",
                        }}
                      >
                        {record.Ranking}
                      </Col>
                    </Row>
                    <Row
                      gutter={[0, 16]}
                      style={{ padding: "10px 16px 0 16px" }}
                    >
                      <Col span={12}><div style={{ fontWeight: 600 }}>金額合計</div></Col>
                      <Col span={12}> <div style={{ fontWeight: 600 }}>{record.Amount_LCY}</div></Col>
                      <Col span={12}><div style={{ fontWeight: 600 }}>工號</div></Col>
                      <Col span={12}>
                        <div style={{ fontWeight: 600 }}>
                          {record.Salesperson_Code}
                        </div>
                      </Col>
                      <Col span={12}>中文姓名</Col>
                      <Col span={12}> {record.Cname}</Col>
                      <Col span={12}>英文姓名</Col>
                      <Col span={12}> {record.Ename}</Col>

                      {/* <Divider style={{ margin: "0" }} /> */}
                    </Row>
                    <Row
                      style={{
                        background: "#f6f6f6",
                        height: "20px",
                        margin: "16px 0 0 0",
                      }}
                    />
                  </>
                );
              }}
            />
          </InfiniteScroll>
        </div>
      </>
    ) : (
      <PageTable
        // columns={switchColumns}
        columns={columns}
        dataSource={props.data?.[3]?.data}
        style={{ height: "50vh", padding: "0px 20px 10px 20px" }}
      />
    )
  ) : (
    <div style={{ position: "absolute" }}>
      <b>
        <BarChart option={data3A} style={chartStyle} />
        {console.log(props.data)}
      </b>
    </div>
  );

  return (
    <>
      <div>
        <Row>
          <Col span={24}>
            {" "}
            <Card
              bordered={false}
              style={{
                minWidth: "100%",
                height: "50vh",
              }}
            >
              <Row>
                <Col span={24}>{displayData}</Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
