import types from "../action/types"

/* eslint-disable import/no-anonymous-default-export */
const INTIAL_STATE = {
  signRecordModalVisible: false,
  signAndRejectModalVisible: false,
  viewFileModalVisible: false
}

export default (state = INTIAL_STATE, action) =>{
  switch(action.type){
    case types.SET_SIGN_RECORD_MODAL_VISIBLE:
      return { ...state, signRecordModalVisible: action.payload }
    case types.SET_SIGN_AND_REJECT_MODAL_VISIBLE:
      return { ...state, signAndRejectModalVisible: action.payload }
    case types.VIEW_FILE_MODAL_VISIBLE:
      return { ...state, viewFileModalVisible: action.payload }
    default:
      return state
  }
}